import React from 'react';

import { KeyShortcut, Layout, Modal, Text } from '@neptune/shared/venus-ui';

export const RegExpInstructionsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} data={{ role: 'regexp-instructions-modal' }}>
      <Modal.Header title="Regular expressions" onClose={onClose} />
      <Modal.Body>
        <Layout.Column spacedChildren="lg">
          <Text size="base">
            Enhance filtering with regular expressions (regex). Here are some tips for advanced
            search options.
          </Text>
          <Layout.Column spacedChildren="sm">
            <Example example=".">any character (except newline character), e.g. “he..o”</Example>
            <Example example="^">starts with, e.g. “^hello”</Example>
            <Example example="$">ends with, e.g. “hello$”</Example>
            <Example example="+">
              one or more occurrences of the preceding element, e.g., “a+“
            </Example>
            <Example example="?">
              zero or one occurrence of the preceding element, e.g., “a?“
            </Example>
            <Example example="{n}">
              exactly n occurrences of the preceding element, e.g., “a&#123;2&#125;“
            </Example>
            <Example example="ab|cd">
              returns a match where any of the specified words ab or cd are present
            </Example>
            <Example example="[a-n]">
              returns a match for any character between <b>a</b> and <b>n</b>
            </Example>
            <Example example="[abc]">
              returns a match where any of the specified characters (a, b, or c) are present
            </Example>
            <Example example="[123]">
              returns a match where any of the specified digits (0, 1, 2, or 3) are present
            </Example>
            <Example example="[^abc]">
              returns a match where any of the specified characters (a, b, or c) are not present
            </Example>
          </Layout.Column>
        </Layout.Column>
      </Modal.Body>
    </Modal>
  );
};

function Example({ example, children }: { example: string; children: React.ReactNode }) {
  return (
    <Layout.Row alignItems="start" spacedChildren="sm">
      <KeyShortcut theme="light">{example}</KeyShortcut>
      <Layout.Row spacedChildren="xs">
        <Text>&#8212;</Text>
        <Text>{children}</Text>
      </Layout.Row>
    </Layout.Row>
  );
}

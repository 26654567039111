import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  isServiceAccountsEnabled,
  useAddProjectServiceAccount,
  useProjectServiceAccounts,
  useServiceAccounts,
  useServiceAccountsTokens,
} from '@neptune/service-accounts-business-logic';
import {
  ServiceAccount,
  ServiceAccountWithRole,
  ServiceAccountWithTokenState,
} from '@neptune/service-accounts-domain';
import {
  AlreadyAssignedToProjectModal,
  IndividualServiceAccountsPlaceholder,
  ProjectEmptyState,
  ProjectEmptyStateCtaType,
  ServiceAccountList,
  ServiceAccountListView,
  ServiceAccountsPlaceholder,
} from '@neptune/service-accounts-ui';
import { useGenericModal } from '@neptune/shared/common-business-logic';
import { ErrorPlaceholderContainer } from '@neptune/shared/common-feature';
import { useLocalModal } from '@neptune/shared/common-util';
import { OrganizationType } from '@neptune/shared/core-organizations-domain';
import {
  availableProjectRoles,
  hasWorkspaceServiceAccountPermissions,
} from '@neptune/shared/core-permissions-business-logic';
import {
  ProjectRole,
  projectRoleLabels,
  ProjectWithRole,
} from '@neptune/shared/core-project-domain';
import { makeProjectIdentifier } from '@neptune/shared/core-project-util';
import { useObtainOwnerEmails } from '@neptune/shared/organization-business-logic';
import { navigateTo } from '@neptune/shared/routing-business-logic';
import { Layout } from '@neptune/shared/venus-ui';

import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import { getContextOrganization } from 'state/selectors-global';
import { showServerErrorModal } from 'state/ui/modals/server-error/actions';

import { AddProjectServiceAccountContainer } from './AddProjectServiceAccountContainer';
import {
  CHANGE_SERVICE_ACCOUNT_ROLE_MODAL_NAME,
  ChangeServiceAccountRoleModalContainer,
  ChangeServiceAccountRoleModalData,
} from './ChangeServiceAccountRoleModalContainer';
import {
  REMOVE_PROJECT_SERVICE_ACCOUNT_MODAL_NAME,
  RemoveProjectServiceAccountModalContainer,
  RemoveProjectServiceAccountModalParams,
} from './RemoveProjectServiceAccountModalContainer';

type ProjectServiceAccountsProps = {
  project: ProjectWithRole;
};

export const ProjectServiceAccounts = ({ project }: ProjectServiceAccountsProps) => {
  const organization = useSelector(getContextOrganization);
  const dispatch = useDispatch();
  const organizationName = organization?.name;
  const projectIdentifier = makeProjectIdentifier(project.organizationName, project.name);
  const canManageServiceAccount = hasWorkspaceServiceAccountPermissions(organization);

  const handleTryTeamPlan = React.useCallback(() => {
    return dispatch(navigateTo('organization.subscription', { organizationName }));
  }, [dispatch, organizationName]);

  const handleManageAtOrganizationLevel = React.useCallback(() => {
    return dispatch(navigateTo('organization.service-accounts', { organizationName }));
  }, [dispatch, organizationName]);

  const adminEmails = useObtainOwnerEmails(organizationName);

  const {
    value: serviceAccounts,
    loading,
    error,
    refresh,
  } = useProjectServiceAccounts(projectIdentifier);

  const { value: workspaceServiceAccounts } = useServiceAccounts(organizationName);

  const assignedServiceAccountIds = React.useMemo(
    () => serviceAccounts?.map(({ serviceAccountInfo }) => serviceAccountInfo.id) || [],
    [serviceAccounts],
  );

  const { tokensMap, toggleApiToken, getApiToken, resetApiToken } = useServiceAccountsTokens({
    organizationName,
  });

  const {
    close: closeAlreadyAssignedModal,
    isOpen: isAlreadyAssignedModalOpen,
    open: openAlreadyAssignedModal,
  } = useLocalModal();

  const serviceAccountsWithTokens = React.useMemo(
    () =>
      serviceAccounts?.map((serviceAccount: ServiceAccountWithRole) => ({
        ...serviceAccount,
        tokenState: tokensMap[serviceAccount.serviceAccountInfo.id],
      })),
    [serviceAccounts, tokensMap],
  );

  const { addAccount, isLoading: isAdding } = useAddProjectServiceAccount(projectIdentifier);

  const addServiceAccount = React.useCallback(
    async (params: { accountId: string; role: ProjectRole }) => {
      try {
        await addAccount(params);
        await refresh();
      } catch (e: any) {
        // service account is already assigned to the project
        if (e.code === 409) {
          openAlreadyAssignedModal();
        } else {
          dispatch(showServerErrorModal());
        }
      }
    },
    [addAccount, dispatch, openAlreadyAssignedModal, refresh],
  );

  const { openWithData: requestRemoveProjectServiceAccount } =
    useGenericModal<RemoveProjectServiceAccountModalParams>(
      REMOVE_PROJECT_SERVICE_ACCOUNT_MODAL_NAME,
    );

  const handleRemoveAccount = React.useCallback(
    async (params: ServiceAccountWithTokenState) => {
      if (!organizationName) {
        return;
      }

      requestRemoveProjectServiceAccount({
        serviceAccount: params.serviceAccountInfo,
        projectIdentifier,
      });
    },
    [requestRemoveProjectServiceAccount, organizationName, projectIdentifier],
  );

  const onAfterRemove = React.useCallback(
    async (serviceAccount: ServiceAccount) => {
      resetApiToken(serviceAccount.id);
      await refresh();
    },
    [refresh, resetApiToken],
  );

  const { openWithData: requestServiceAccountRoleChange } =
    useGenericModal<ChangeServiceAccountRoleModalData>(CHANGE_SERVICE_ACCOUNT_ROLE_MODAL_NAME);

  const handleUpdateRole = React.useCallback(
    ({ serviceAccount, role }: { serviceAccount: ServiceAccount; role: ProjectRole }) => {
      if (!organizationName) {
        return;
      }

      requestServiceAccountRoleChange({
        projectIdentifier,
        serviceAccount,
        role,
      });
    },
    [organizationName, projectIdentifier, requestServiceAccountRoleChange],
  );

  if (!organization || !organizationName) {
    return null;
  }

  if (!isServiceAccountsEnabled(organization.traits)) {
    return organization.organizationType === 'individual' ? (
      <IndividualServiceAccountsPlaceholder />
    ) : (
      <ServiceAccountsPlaceholder onClick={handleTryTeamPlan} />
    );
  }

  if (error) {
    return <ErrorPlaceholderContainer context="service-accounts" />;
  }

  const safeWorkspaceServiceAccounts = canManageServiceAccount
    ? workspaceServiceAccounts || []
    : [];
  const showEmptyState = !loading && safeWorkspaceServiceAccounts.length === 0;

  return (
    <Layout.Column data-role="project-service-accounts">
      {showEmptyState ? (
        <ProjectEmptyState
          cta={
            canManageServiceAccount
              ? ProjectEmptyStateCtaType.manage
              : ProjectEmptyStateCtaType.notify
          }
          notifyRecipients={adminEmails}
          onManage={handleManageAtOrganizationLevel}
        />
      ) : (
        serviceAccountsWithTokens != undefined && (
          <ServiceAccountListView spacedChildren="md">
            <AddProjectServiceAccountContainer
              availableRoles={availableProjectRoles[OrganizationType.team]}
              organizationName={organizationName}
              onSubmit={addServiceAccount}
              roleLabels={projectRoleLabels}
              assignedServiceAccountIds={assignedServiceAccountIds}
            />
            <ServiceAccountList
              availableRoles={availableProjectRoles[OrganizationType.team]}
              entries={serviceAccountsWithTokens}
              isUserWorkspaceOwner={canManageServiceAccount}
              onRemoveAccount={handleRemoveAccount}
              onUpdateRole={handleUpdateRole}
              onToggleApiToken={toggleApiToken}
              getApiToken={getApiToken}
              roleLabels={projectRoleLabels}
              data-role="project-service-account-list"
            />
          </ServiceAccountListView>
        )
      )}

      <LoadingIndicator isVisible={loading || isAdding} centered />
      <RemoveProjectServiceAccountModalContainer onSubmit={onAfterRemove} />
      <ChangeServiceAccountRoleModalContainer onSubmit={refresh} />
      <AlreadyAssignedToProjectModal
        isOpen={isAlreadyAssignedModalOpen}
        onClose={closeAlreadyAssignedModal}
      />
    </Layout.Column>
  );
};

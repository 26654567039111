import React from 'react';

import { ServiceAccount } from '@neptune/service-accounts-domain';
import { AddServiceAccount } from '@neptune/service-accounts-ui';
import { ProjectRole } from '@neptune/shared/core-project-domain';

import { SelectServiceAccountContainer } from './SelectServiceAccountContainer';

type AddProjectServiceAccountContainerProps = {
  availableRoles: ProjectRole[];
  assignedServiceAccountIds: string[];
  onSubmit(params: { accountId: string; role: ProjectRole }): void;
  organizationName: string;
  roleLabels: Record<ProjectRole, string>;
};

export const AddProjectServiceAccountContainer: React.FC<
  AddProjectServiceAccountContainerProps
> = ({ availableRoles, assignedServiceAccountIds, onSubmit, organizationName, roleLabels }) => {
  const [invitee, setInvitee] = React.useState<ServiceAccount>();
  const [role, setRole] = React.useState(ProjectRole.member);
  const handleChange = React.useCallback((account?: ServiceAccount) => setInvitee(account), []);

  const handleSubmit = React.useCallback(async () => {
    if (!invitee) {
      return;
    }

    await onSubmit({
      accountId: invitee.id,
      role,
    });

    setInvitee(undefined);
  }, [invitee, onSubmit, role]);

  const serviceAccountSelect = (
    <SelectServiceAccountContainer
      assignedServiceAccountIds={assignedServiceAccountIds}
      onChange={handleChange}
      organizationName={organizationName}
      selected={invitee}
    />
  );

  return (
    <AddServiceAccount
      availableRoles={availableRoles}
      data-role="add-project-service-account"
      disabled={!invitee}
      organizationName={organizationName}
      onSelectRole={setRole}
      onSubmit={handleSubmit}
      role={role}
      roleLabels={roleLabels}
      serviceAccountSelect={serviceAccountSelect}
      title="Assign service accounts to your project"
    />
  );
};

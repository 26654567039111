/**
 *
 * @param projectIdentifier project qualified name
 * @param entityShortId entity short id
 *
 * @example
 * makeEntityIdentifier('workspace/project', 'PROJ-123') // 'workspace/project/PROJ-123'
 */
export function makeEntityIdentifier(projectIdentifier: string, entityShortId: string): string;
/**
 *
 * @param organizationName organization name
 * @param projectName project name
 * @param entityShortId entity short id
 *
 * @example
 * makeEntityIdentifier('workspace', 'project', 'PROJ-123') // 'workspace/project/PROJ-123'
 */
export function makeEntityIdentifier(
  organizationName: string,
  projectName: string,
  entityShortId: string,
): string;

export function makeEntityIdentifier(...parts: string[]): string {
  return parts.join('/');
}

import {
  ColorsConfigDTO,
  DashboardDTO,
  DashboardLayoutsDTO,
  ListDashboardsDashboardTypeEnum,
  NewDashboardDTO,
  NewDashboardDTOTypeEnum,
  NewVersionDashboardDTO,
  NewVersionDashboardDTOTypeEnum,
  UpdateDashboardDTO,
  UpdateDashboardDTOTypeEnum,
  WidgetDTO,
} from '@neptune/shared/core-apis-leaderboard-domain';
import type { GlyphName } from '@neptune/shared/core-glyphs-domain';
import { DashboardConfigModelConverter } from '@neptune/shared/dashboard-domain';
import { TableViewParamsConverter } from '@neptune/shared/leaderboard-domain';

import { dashboardIcons } from 'domain/dashboard/dashboard-icons';
import {
  Dashboard,
  DashboardType,
  NewDashboard,
  NewVersionDashboard,
  WidgetLayouts,
} from 'domain/dashboard/dashboard-model';
import { Widget, WidgetModelConverter } from 'domain/widget';

const newDashboardTypeMapping: Record<DashboardType, NewDashboardDTOTypeEnum> = {
  compare: NewDashboardDTOTypeEnum.Compare,
  experiment: NewDashboardDTOTypeEnum.Experiment,
  report: NewDashboardDTOTypeEnum.Report,
};

const updateDashboardTypeMapping: Record<DashboardType, UpdateDashboardDTOTypeEnum> = {
  compare: UpdateDashboardDTOTypeEnum.Compare,
  experiment: UpdateDashboardDTOTypeEnum.Experiment,
  report: UpdateDashboardDTOTypeEnum.Report,
};

const newVersionTypeMapping: Record<DashboardType, NewVersionDashboardDTOTypeEnum> = {
  compare: NewVersionDashboardDTOTypeEnum.Compare,
  experiment: NewVersionDashboardDTOTypeEnum.Experiment,
  report: NewVersionDashboardDTOTypeEnum.Report,
};

const listDashboardTypeMapping: Record<DashboardType, ListDashboardsDashboardTypeEnum> = {
  compare: ListDashboardsDashboardTypeEnum.Compare,
  experiment: ListDashboardsDashboardTypeEnum.Experiment,
  report: ListDashboardsDashboardTypeEnum.Report,
};

export abstract class DashboardModelConverter {
  static dashboardFromApiToDomain(dashboard: DashboardDTO): Dashboard {
    return {
      ...dashboard,
      config: DashboardConfigModelConverter.fromApiToDomain(dashboard.config),
      gridLayouts: DashboardModelConverter.gridLayoutsFromApiToDomain(dashboard.layouts),
      versionBranchId: dashboard.versionBranch,
      icon: DashboardModelConverter.dashboardIconFromApiToDomain(dashboard.icon),
      widgets: dashboard.widgets.map(WidgetModelConverter.widgetFromApiToDomain),
      viewParams: dashboard.viewParams
        ? TableViewParamsConverter.fromApiToDomain(dashboard.viewParams)
        : undefined,
      colorsConfig: DashboardModelConverter.colorsFromApiToDomain(dashboard.colorsConfig),
    };
  }

  static dashboardIconFromApiToDomain(icon: string | undefined): GlyphName | undefined {
    if (!dashboardIcons.includes(icon as GlyphName)) {
      return undefined;
    }

    return icon as GlyphName;
  }

  static gridLayoutsFromApiToDomain(layouts: DashboardLayoutsDTO): WidgetLayouts {
    // isStatic is a legacy field. We remove it here to ensure data stability in domain objects,
    // so that they can be compared with isEqual.
    return layouts.md.map(({ isStatic, ...rest }) => rest);
  }

  static gridLayoutsFromDomainToApi(layouts: WidgetLayouts): DashboardLayoutsDTO {
    return {
      xs: [], // required for legacy reasons
      md: layouts,
      lg: [], // required for legacy reasons
      sm: [], // required for legacy reasons
      xl: [], // required for legacy reasons
    };
  }

  static dashboardWidgetsFromDomainToApi(widgets: Widget[]): WidgetDTO[] {
    return widgets
      .map(WidgetModelConverter.widgetFromDomainToApi)
      .filter((widget): widget is WidgetDTO => widget != null);
  }

  static newDashboardFromDomainToApi(dashboard: NewDashboard): NewDashboardDTO {
    const { experimentsOnly, runsLineage, ...rest } = dashboard;
    return {
      ...rest,
      config: DashboardConfigModelConverter.fromDomainToApi(dashboard.config),
      layouts: DashboardModelConverter.gridLayoutsFromDomainToApi(dashboard.gridLayouts),
      type: DashboardModelConverter.newDashboardTypeFromDomainToApi(dashboard.type),
      widgets: DashboardModelConverter.dashboardWidgetsFromDomainToApi(dashboard.widgets),
      viewParams: dashboard.viewParams
        ? TableViewParamsConverter.fromDomainToApi(dashboard.viewParams)
        : undefined,
      colorsConfig: DashboardModelConverter.colorsFromDomainToApi(dashboard.colorsConfig),
    };
  }

  static newDashboardTypeFromDomainToApi(type: DashboardType): NewDashboardDTOTypeEnum {
    return newDashboardTypeMapping[type];
  }

  static updateDashboardTypeFromDomainToApi(type: DashboardType): UpdateDashboardDTOTypeEnum {
    return updateDashboardTypeMapping[type];
  }

  static updateDashboardFromDomainToApi(dashboard: Dashboard): UpdateDashboardDTO {
    return {
      ...dashboard,
      config: DashboardConfigModelConverter.fromDomainToApi(dashboard.config),
      layouts: DashboardModelConverter.gridLayoutsFromDomainToApi(dashboard.gridLayouts),
      type: DashboardModelConverter.updateDashboardTypeFromDomainToApi(dashboard.type),
      widgets: DashboardModelConverter.dashboardWidgetsFromDomainToApi(dashboard.widgets),
      viewParams: dashboard.viewParams
        ? TableViewParamsConverter.fromDomainToApi(dashboard.viewParams)
        : undefined,
      colorsConfig: DashboardModelConverter.colorsFromDomainToApi(dashboard.colorsConfig),
    };
  }

  static newVersionDashboardFromDomainToApi(
    dashboard: NewVersionDashboard,
  ): NewVersionDashboardDTO {
    return {
      ...dashboard,
      config: DashboardConfigModelConverter.fromDomainToApi(dashboard.config),
      layouts: DashboardModelConverter.gridLayoutsFromDomainToApi(dashboard.gridLayouts),
      versionBranch: dashboard.versionBranchId,
      type: DashboardModelConverter.newVersionTypeFromDomainToApi(dashboard.type),
      widgets: DashboardModelConverter.dashboardWidgetsFromDomainToApi(dashboard.widgets),
      viewParams: dashboard.viewParams
        ? TableViewParamsConverter.fromDomainToApi(dashboard.viewParams)
        : undefined,
      colorsConfig: DashboardModelConverter.colorsFromDomainToApi(dashboard.colorsConfig),
    };
  }
  static newVersionTypeFromDomainToApi(type: DashboardType): NewVersionDashboardDTOTypeEnum {
    return newVersionTypeMapping[type];
  }

  static listDashboardTypeFromDomainToApi(types: DashboardType[]): ListDashboardsDashboardTypeEnum {
    // casting is a workaround for Enum[] generated as Enum in our TS client (bug in swagger generation)
    return types.map(
      (type) => listDashboardTypeMapping[type],
    ) as any as ListDashboardsDashboardTypeEnum;
  }

  static colorsFromApiToDomain(colorsConfig?: ColorsConfigDTO): Record<string, string> | undefined {
    return colorsConfig?.colors?.reduce<Record<string, string>>(
      (res, { owner, color }) => ((res[owner] = color), res),
      {},
    );
  }

  static colorsFromDomainToApi(colors?: Record<string, string>): ColorsConfigDTO | undefined {
    if (!colors) {
      return undefined;
    }

    return {
      colors: Object.entries(colors).map(([owner, color]) => ({ owner, color })),
    };
  }
}

// Libs
import { format, isValid, parseISO } from 'date-fns';

import { isPeriodValue, valueToLabel } from '../period-map';
// App
import { DateTimeCriterion, PartialSearchCriterion } from '../search-query-model';
import { criterionHasAttributeAndOperator } from '../search-query-model-core-utils';

// Module
import { CriterionHelpers } from './types';

const dateTimeFormat = 'yyyy-MM-dd HH:mm:ss';

export function isDateTimeCriterion(
  criterion: PartialSearchCriterion,
): criterion is DateTimeCriterion {
  return criterionHasAttributeAndOperator(criterion) && criterion.type === 'datetime';
}

export const dateTimeCriterionHelpers: CriterionHelpers<DateTimeCriterion> = {
  getReadableValue(criterion) {
    return criterion.operator === 'last'
      ? valueToLabel[criterion.value]
      : format(parseISO(criterion.value), dateTimeFormat);
  },
  getShortReadableValue(criterion) {
    return criterion.operator === 'last'
      ? valueToLabel[criterion.value]
      : format(parseISO(criterion.value), dateTimeFormat);
  },
  isComplete(criterion) {
    return criterion.operator === 'last'
      ? isPeriodValue(criterion.value)
      : criterion.value != null && isValid(parseISO(criterion.value));
  },
};

import PubSub from 'pubsub-js';

import { KnownSignals, ParamsMap, SubscriptionMap } from './types';

function subscribe<T extends KnownSignals>(msg: T, subscription: SubscriptionMap<T>): string {
  return PubSub.subscribe(msg, subscription);
}

function publish<T extends KnownSignals>(msg: T, params: ParamsMap[T]) {
  return PubSub.publish(msg, params);
}

function once<T extends KnownSignals>(msg: T, subscription: SubscriptionMap<T>) {
  const token = PubSub.subscribe(msg, (msg: T, params: ParamsMap[T]) => {
    subscription(msg, params);
    PubSub.unsubscribe(token);
  });
}

export const CustomTracingBus = {
  unsubscribe: (params: any) => PubSub.unsubscribe(params),
  once,
  subscribe,
  publish,
};

'use strict';

import {
  leaderboardClient,
  LimitedChannelValuesDTO,
  PointValueDTO,
} from '@neptune/shared/core-apis-leaderboard-domain';
// Module
import { makeEntityIdentifier } from '@neptune/shared/entity-domain';

let neptuneApi: Pick<typeof leaderboardClient, 'getChannelValues'>;

type CachedDataSection = {
  offset: number;
  values: PointValueDTO[];
};

type CacheEntry = {
  totalItemCount?: number;
  sections: CachedDataSection[];
};

type Cache = Partial<Record<string, Partial<Record<string, CacheEntry>>>>;

const CACHE: Cache = {};

type ParentAndChannelId = {
  parentId: string;
  parentType: string;
  channelId: string;
};

export type ChannelDataRequest = ParentAndChannelId & {
  limit: number;
  offset?: number | null | undefined;
};

type OffsetRequired = { offset: number };

// {{{ Helpers

function getCacheLocation(id: ParentAndChannelId, createIfMissing?: true): CacheEntry;
function getCacheLocation(id: ParentAndChannelId, createIfMissing?: false): CacheEntry | undefined;
function getCacheLocation(
  { parentId, parentType, channelId }: ParentAndChannelId,
  createIfMissing = true,
): CacheEntry | undefined {
  const id = makeEntityIdentifier(parentId, parentType);

  const cacheById = CACHE[id] ?? (CACHE[id] = {});

  if (cacheById[channelId] || !createIfMissing) {
    return cacheById[channelId];
  }

  return (cacheById[channelId] = {
    sections: [],
  });
}

function getSectionEnd(section: CachedDataSection) {
  return section.offset + section.values.length;
}

// Helpers }}}

function addValuesToCache({
  parentId,
  parentType,
  offset,
  channelData,
}: Omit<ChannelDataRequest, 'channelId' | 'limit'> & { channelData: LimitedChannelValuesDTO }) {
  const { totalItemCount, values } = channelData;

  const cache = getCacheLocation({ parentId, parentType, channelId: channelData.channelId });
  cache.totalItemCount = totalItemCount;

  let newSection: CachedDataSection | null = {
    offset: offset == null ? totalItemCount - values.length : offset,
    values: [...values],
  };
  const newSectionEnd = getSectionEnd(newSection);

  if (cache.sections.length === 0) {
    cache.sections.push(newSection);
    return;
  }

  cache.sections = cache.sections.reduce<CachedDataSection[]>((sections, section) => {
    const sectionEnd = getSectionEnd(section);

    if (!newSection || newSectionEnd < section.offset || sectionEnd < newSection.offset) {
      // disjoint
      // #1  \\\ ///
      // #13 /// \\\
      sections.push(section);
    } else if (newSection.offset <= section.offset && newSectionEnd < sectionEnd) {
      // new section overlaps an old section from the left
      // #2 \\\///
      // #3 \\XX//
      // #7 XXX//
      const tailValues = section.values.slice(newSectionEnd - section.offset);
      newSection.values.push(...tailValues);
    } else if (section.offset < newSection.offset) {
      if (sectionEnd <= newSectionEnd) {
        // new section overlaps an old section from the right
        // #9  //XXX
        // #11 //XX\\
        // #12 ///\\\
        const headValues = section.values.slice(0, newSection.offset - section.offset);
        newSection.values.unshift(...headValues);
        newSection.offset = section.offset;
      } else {
        // an old section contains the new section
        // #8 //XXX//
        newSection = null;
        sections.push(section);
      }
    }
    // For the following cases do nothing:
    // #4  \\XXX
    // #5  \\XXX\\
    // #6  XXX
    // #10 XXX\\

    return sections;
  }, []);

  if (newSection) {
    cache.sections.push(newSection);
    cache.sections.sort((s1, s2) => s1.offset - s2.offset);
  }
}

async function fetchChannelData({
  parentId,
  parentType,
  channelId,
  offset,
  limit,
}: ChannelDataRequest) {
  const channelData = await neptuneApi.getChannelValues({
    channelId,
    offset: offset ?? undefined,
    limit,
  });

  addValuesToCache({
    parentId,
    parentType,
    offset,
    channelData,
  });

  return channelData;
}

export function getChannelData({
  parentId,
  parentType,
  channelId,
  offset = undefined,
  limit,
}: ChannelDataRequest) {
  if (offset == null) {
    // not defined offset means we want to take data from the end, so we need to ask API as this may not be in the cache
    return fetchChannelData({ parentId, parentType, channelId, limit });
  }

  const values = getValuesFromCache({ parentId, parentType, channelId, offset, limit });

  if (!values) {
    return fetchChannelData({ parentId, parentType, channelId, offset, limit });
  }

  const totalItemCount = getCacheLocation({ parentId, parentType, channelId })?.totalItemCount ?? 0;

  return Promise.resolve({
    channelId,
    totalItemCount,
    values,
  });
}

function getValuesFromCache({
  parentId,
  parentType,
  channelId,
  offset,
  limit,
}: ChannelDataRequest & OffsetRequired) {
  const cacheEntry = getCacheLocation({ parentId, parentType, channelId });

  if (!cacheEntry) {
    // no experiment / channel
    return null;
  }

  // find section
  const section = cacheEntry.sections.find((section) => {
    return section.offset <= offset && offset + limit <= getSectionEnd(section);
  });

  if (!section) {
    return null;
  }

  const subSectionBegin = offset - section.offset;
  return section.values.slice(subSectionBegin, subSectionBegin + limit);
}

function injectApi(api: typeof neptuneApi) {
  neptuneApi = api;
}

function invalidateCache() {
  Object.keys(CACHE).forEach((id) => {
    delete CACHE[id];
  });
}

// TODO: expose public api only
export default {
  CACHE,
  addValuesToCache,
  getChannelData, // public
  getValuesFromCache,
  injectApi, // public
  invalidateCache, // public
};

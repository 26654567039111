import { includes, isNaN, isFunction, padStart } from 'lodash';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

// Module
const DEFAULT_PRECISION = 6;

function removeTrailingZeros(str) {
  // if no decimal or scientific notation used, return unchanged
  if (!includes(str, '.') || includes(str, 'e') || includes(str, 'E')) {
    return str;
  }
  //  remove trailing zeros
  str = str.replace(/0+$/, '');

  // if number ends with decimal, remove it too
  if (str.charAt(str.length - 1) === '.') {
    str = str.slice(0, -1);
  }
  return str;
}

/**
 * @deprecated. If digits is not provided or is 0, default value (6) will be used.
 *
 * @param value {string|number}
 * @param digits {number=}
 * @returns {string|number}
 */
export function precision(value, digits) {
  if (includes(value, 'e') || includes(value, 'E')) {
    return value;
  }

  const numVal = parseFloat(value);
  if (isNaN(numVal)) {
    return value;
  }

  if (!digits) {
    digits = DEFAULT_PRECISION;
  }

  return removeTrailingZeros(numVal.toPrecision(digits));
}

/**
 * @deprecated Use version from @neptune/shared/common-util
 */
export function naturalStringComparator(a = '', b = '') {
  const numOrNotNumRe = /(\d+)|(\D+)/g;
  const numRe = /\d+/;

  a = (a + '').toLowerCase().match(numOrNotNumRe);
  b = (b + '').toLowerCase().match(numOrNotNumRe);

  while (a && b && a.length && b.length) {
    const a1 = a.shift();
    const b1 = b.shift();
    const isNumA = numRe.test(a1);
    const isNumB = numRe.test(b1);

    if (isNumA || isNumB) {
      if (!isNumA) {
        return 1;
      }

      if (!isNumB) {
        return -1;
      }

      if (a1 != b1) {
        return a1 - b1 < 0 ? -1 : 1;
      }
    } else if (a1 != b1) {
      return a1 > b1 ? 1 : -1;
    }
  }

  return getLength(a) - getLength(b);
}

function getLength(variable) {
  return variable ? variable.length : 0;
}

export function formatDuration(duration, unit = 'ms') {
  const sign = duration < 0 ? '-' : '';
  const momentDuration = moment.duration(duration, unit);
  const days = Math.floor(momentDuration.asDays());
  const dayString = days ? `${days}d ` : '';
  const hours = momentDuration.hours();
  const hoursWithLeadingZero = padStart(hours, 2, '0');
  const hourString = hours ? `${hoursWithLeadingZero}:` : '';
  const minutes = Math.abs(momentDuration.minutes());
  const minutesWithLeadingZero = padStart(minutes, 2, '0');
  const seconds = Math.abs(momentDuration.seconds());
  const secondsWithLeadingZero = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${sign}${dayString}${hourString}${minutesWithLeadingZero}:${secondsWithLeadingZero}`;
}

/**
 * @deprecated Use version from @neptune/shared/common-util
 */
export function getTextVariantByValue(value, pluralVariant, singularVariant, zeroVariant) {
  const variant = (value === 0 && zeroVariant) || (value === 1 && singularVariant) || pluralVariant;

  if (isFunction(variant)) {
    return variant(value);
  }
  return variant;
}

export function withIndefiniteArticle(text, nowrap = false) {
  const separator = nowrap ? '\u00A0' : ' ';
  const article = 'AEIOUYaeiouy'.includes(text[0]) ? 'an' : 'a';

  return `${article}${separator}${text}`;
}

export function isThreadNonExpandable(thread) {
  return thread && thread.commentsCount <= 4;
}

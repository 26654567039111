import { isEmpty, isEqual, omitBy } from 'lodash';

import { LeaderboardView } from '@neptune/shared/lb-views-domain';

export function isNullishOption(value: any) {
  // isEmpty returns strange values for non-arrays
  if (Array.isArray(value)) {
    return isEmpty(value);
  }

  return !value;
}

export type LbViewWriteableOptions = Pick<
  LeaderboardView,
  'query' | 'sortOptions' | 'groupOptions'
>;

export function compareLbViewProps(lbView1: LeaderboardView, lbView2: LeaderboardView): boolean {
  const writeable1: LbViewWriteableOptions = {
    query: lbView1.query ?? '',
    sortOptions: omitBy(lbView1.sortOptions, isNullishOption),
    groupOptions: omitBy(lbView1.groupOptions, isNullishOption),
  };

  const writeable2: LbViewWriteableOptions = {
    query: lbView2.query ?? '',
    sortOptions: omitBy(lbView2.sortOptions, isNullishOption),
    groupOptions: omitBy(lbView2.groupOptions, isNullishOption),
  };

  return isEqual(writeable1, writeable2);
}

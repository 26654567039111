import React from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import { ColumnsContext } from '@neptune/shared/columns-domain';
import {
  EnhancedLeaderboardColumn,
  getColumnName,
  LeaderboardColumn,
  localTableStateColumns,
} from '@neptune/shared/leaderboard-domain';

import { Merge } from 'common/utility-types';

const EMPTY_ARRAY: LeaderboardColumn[] = [];

export function useColumnsContext(): {
  columns: LeaderboardColumn[];
  publicColumns: LeaderboardColumn[];
  enhancedColumns: Merge<LeaderboardColumn, EnhancedLeaderboardColumn>[];
} {
  const { selector } = React.useContext(ColumnsContext);
  const columns = useSelector(selector, isEqual) ?? EMPTY_ARRAY;

  return {
    columns,
    ...React.useMemo(
      () => ({
        publicColumns: columns.filter((column) => !localTableStateColumns.includes(column.id)),
        enhancedColumns: columns.map((column) => ({ ...column, title: getColumnName(column) })),
      }),
      [columns],
    ),
  };
}

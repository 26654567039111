// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { State } from 'router5';

// App
import WindowUnloadPrompt from 'components/window-unload-prompt/WindowUnloadPrompt';
import getDownloadState from 'state/ui/global/download/selectors';

// Module
import { RouteDeactivateWatch } from './route-deactivate-watch/RouteDeactivateWatch';
import { UnsavedChangesDialog } from './unsaved-changes-dialog/UnsavedChangesDialog';

type UnsavedChangesPromptProps = {
  canNavigate?(toState: State, fromState: State): boolean;
  isEnabled?: boolean;
  handlerId?: string;
  role?: string;
  onConfirm?: () => void;
};

export const UnsavedChangesPrompt: React.FC<UnsavedChangesPromptProps> = ({
  canNavigate,
  isEnabled = true,
  handlerId,
  role,
  onConfirm: parentOnConfirm,
  ...rest
}) => {
  const isDownloading = useSelector(getDownloadState).isDownloading;
  const [isOpen, setIsOpen] = React.useState(false);
  const [onConfirm, setOnConfirm] = React.useState<() => void>();
  const [onCancel, setOnCancel] = React.useState<() => void>();

  function handleDeactivate(toState: State, fromState: State) {
    // If this handler is not called when you expect to be,
    // please make sure that a route param that you want to react on is explicite specified in route 'path'. For further discussion see: https://gitlab.com/neptune.ml/neptune/-/merge_requests/9072

    // don't run additional checks on forced redirections, actual checks were most likely perform prior to the redirection anyway
    if (toState.meta?.options.redirected && toState.meta.options.force) {
      return true;
    }

    if (canNavigate?.(toState, fromState)) {
      return true;
    }

    return new Promise<boolean>((resolve, reject) => {
      const handleConfirm = () => {
        resolve(true);
        parentOnConfirm?.();
        setIsOpen(false);
      };

      const handleCancel = () => {
        reject();
        setIsOpen(false);
      };

      setIsOpen(true);
      setOnCancel(() => handleCancel);
      setOnConfirm(() => handleConfirm);
    });
  }

  const watchElement = isEnabled && !isDownloading && (
    <React.Fragment>
      <WindowUnloadPrompt />
      <RouteDeactivateWatch onDeactivate={handleDeactivate} handlerId={handlerId} />
    </React.Fragment>
  );
  return (
    <React.Fragment>
      {watchElement}
      {isOpen && onConfirm && onCancel && (
        <UnsavedChangesDialog
          isOpen={isOpen}
          onConfirm={onConfirm}
          onCancel={onCancel}
          role={role}
          {...rest}
        />
      )}
    </React.Fragment>
  );
};

import React from 'react';

import { GlyphName } from '@neptune/shared/core-glyphs-domain';
import { ProjectPrivacy } from '@neptune/shared/core-organizations-domain';
import { bemBlock, Icon, LegacyEmblem, Text } from '@neptune/shared/venus-ui';

import './ProjectPrivacyEmblem.less';

type ProjectPrivacyIndicatorProps = {
  privacy: ProjectPrivacy;
  className?: string;
};

const privacyIconMap: Partial<Record<ProjectPrivacy, GlyphName | undefined>> = {
  [ProjectPrivacy.private]: 'lock',
  [ProjectPrivacy.workspace]: 'lock-open',
  [ProjectPrivacy.public]: 'lock-open',
};

const privacyTextMap: Partial<Record<ProjectPrivacy, string>> = {
  [ProjectPrivacy.private]: 'Private',
  [ProjectPrivacy.workspace]: 'Workspace',
  [ProjectPrivacy.public]: 'Public',
};

const block = bemBlock('project-privacy-emblem');

export const ProjectPrivacyEmblem: React.FC<ProjectPrivacyIndicatorProps> = ({
  privacy,
  className,
}) => {
  const glyph = privacyIconMap[privacy];

  if (!glyph) {
    return null;
  }

  return (
    <LegacyEmblem
      className={block({ modifiers: { privacy }, extra: className })}
      spacedChildren="xs"
    >
      <Icon
        glyph={glyph}
        className={block({ element: 'icon', modifiers: { privacy } })}
        size="sm"
      />
      <Text>{privacyTextMap[privacy]}</Text>
    </LegacyEmblem>
  );
};

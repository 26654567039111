import React from 'react';

import { Button } from '@neptune/shared/venus-ui';

import ContactLink from 'components/contact-link/ContactLink';

export const ContactUs: React.FC = () => {
  return (
    <ContactLink>
      <Button>Contact us</Button>
    </ContactLink>
  );
};

// Libs
import React from 'react';

import { PropertyRow, PropertyTable } from '@neptune/shared/common-ui';
import { bemBlock, Layout, Section } from '@neptune/shared/venus-ui';

// App
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';

// Module
import './ProjectUsageWidget.less';

export type ProjectUsageWidgetProps = {
  isLoading: boolean;
  properties: PropertyRow[];
};

const block = bemBlock('project-usage-widget');

export const ProjectUsageWidget: React.FC<ProjectUsageWidgetProps> = ({
  isLoading,
  properties,
}) => {
  return (
    <Section withBorder className={block()} withPadding="md" data-role="project-usage-widget">
      <Layout.Column spacedChildren="md">
        <Section.Title size="small">Project usage</Section.Title>
        <PropertyTable properties={properties} columnsSpans={['greedy', 'auto']} />
      </Layout.Column>
      <LoadingIndicator isVisible={isLoading} centered />
    </Section>
  );
};

import React from 'react';

import { getEmojiBasedOnTime } from '@neptune/shared/avatars-util';
import { ProjectWithRole } from '@neptune/shared/core-project-domain';
import { AvatarSource } from '@neptune/shared/core-users-domain';
import { bemBlock, Emoji, GlyphName, Icon, Layout, layout } from '@neptune/shared/venus-ui';

import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';

import './ProjectAvatar.less';

type ProjectAvatarProps = {
  glyph?: GlyphName;
  loading?: boolean;
  size?: Extract<layout.LayoutUnit, 'xxxl' | 'xl' | 'lg' | 'md'>;
  projectData?: Pick<ProjectWithRole, 'avatarUrl' | 'avatarSource' | 'timeOfCreation'>;
  withBackground?: boolean;
  iconSize?: Extract<React.ComponentProps<typeof Icon>['size'], 'xs' | 'sm' | 'lg'>;
  radius?: 'xs' | 'sm' | 'md';
};

const block = bemBlock('project-avatar');

export const ProjectAvatar: React.FC<ProjectAvatarProps> = ({
  glyph = 'project',
  size,
  iconSize = 'lg',
  loading = false,
  projectData,
  withBackground = false,
  radius = 'md',
}) => {
  const projectEmoji =
    projectData?.avatarSource === AvatarSource.unicode ||
    projectData?.avatarSource === AvatarSource.default
      ? projectData?.avatarUrl || getEmojiBasedOnTime(projectData?.timeOfCreation)
      : undefined;

  const renderContent = () => {
    if (loading) {
      return <LoadingIndicator isVisible size="small" />;
    }

    return projectEmoji ? (
      <Emoji size={iconSize} unicode={projectEmoji} />
    ) : (
      <Icon glyph={glyph} size={iconSize} />
    );
  };

  return (
    <Layout.Row
      className={block({
        modifiers: {
          size,
          withBackground,
          radius,
        },
      })}
      alignItems="center"
      justifyContent="center"
      span="auto"
    >
      {renderContent()}
    </Layout.Row>
  );
};

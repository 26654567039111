import { escapeRegExp } from 'lodash';

export class UserRegExp extends RegExp {
  constructor(userRegex: string, flags = 'i') {
    try {
      super(userRegex.trim(), flags);
    } catch {
      super(escapeRegExp(userRegex.trim()), flags);
    }
  }
}

import React from 'react';

import { bemBlock, ButtonProps, TabsMenu, TabsMenuItemProps } from '@neptune/shared/venus-ui';

import './SidebarActionItem.less';

const block = bemBlock('sidebar-action-item');

type SidebarActionItemProps = Pick<
  TabsMenuItemProps<ButtonProps<{}>>,
  'label' | 'icon' | 'withPadding' | 'spacedChildren'
> & {
  onToggle?: React.ReactEventHandler;
  pressed?: boolean;
  className?: string;
};

export const SidebarActionItem: React.FC<SidebarActionItemProps> = ({
  label,
  onToggle,
  pressed,
  icon,
  className,
  children,
  spacedChildren = 'none',
  ...passProps
}) => {
  return (
    <TabsMenu.Item
      className={block({ modifiers: { pressed }, extra: className })}
      component="button"
      data-selected={pressed}
      size="lg"
      icon={icon}
      radius="sm"
      vertical
      spacedChildren={spacedChildren}
      selected={pressed}
      label={label}
      onClick={onToggle}
      children={children}
      {...passProps}
    />
  );
};

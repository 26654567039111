import { pick } from 'lodash';

import { ChartConfigurationSettings, DEFAULT_CHART_CONFIG } from '@neptune/charts-domain';

/** For each of config fields contains a list of widgetIds, for which that field should be cleared. */
export type GlobalChartConflictedOptions = {
  [K in ChartGlobalConfigFieldNames]?: string[];
} & {
  total: number;
};

export const chartGlobalConfigFieldNames = [
  'yAxisScale',
  'xAxisScale',
  'xAxisMetric',
  'xAxisMode',
  'metricsStepsRange',
  'smoothing',
  'xAxisRange',
] as const;
type ChartGlobalConfigFieldNames = (typeof chartGlobalConfigFieldNames)[number];

export type ChartGlobalConfig = Partial<
  Pick<ChartConfigurationSettings, ChartGlobalConfigFieldNames>
>;

/**
 * @deprecated should be removed after all global config modals are replaced by new modular action menus
 */
export const DEFAULT_CHART_GLOBAL_CONFIG: Pick<
  ChartConfigurationSettings,
  | 'xAxisMetric'
  | 'xAxisMode'
  | 'xAxisScale'
  | 'yAxisScale'
  | 'yAxisRange'
  | 'metricsStepsRange'
  | 'smoothing'
> = pick(DEFAULT_CHART_CONFIG, chartGlobalConfigFieldNames);

import { backendClient } from '@neptune/shared/core-apis-backend-domain';

import { ProductTour } from './product-tour';

async function persist(username: string, key: string, value: string) {
  return await backendClient.setScopedProperty({
    propertyType: 'USER',
    scope: username,
    propertyBody: { key, value },
  });
}

export async function persistTourTriggerTime(
  username: string,
  tour: ProductTour,
  date: Date,
): Promise<void> {
  try {
    return await persist(username, getTriggerTimePropertyKey(tour), date.toISOString());
  } catch {}
}

export async function persistAdditionalTriggerTime(
  username: string,
  tour: ProductTour,
  date: Date,
): Promise<void> {
  try {
    return await persist(username, getAdditionalPropertyKey(tour), date.toISOString());
  } catch {}
}

async function load(username: string, key: string) {
  const response = await backendClient.getScopedProperty({
    propertyType: 'USER',
    scope: username,
    key,
  });

  return response.value;
}

export async function loadTourTriggerTime(
  username: string,
  tour: ProductTour,
): Promise<Date | undefined> {
  const key = getTriggerTimePropertyKey(tour);

  try {
    const result = await load(username, key);

    if (result) {
      return new Date(result);
    }
  } catch {}
}

export async function loadAdditionalTriggerTime(
  username: string,
  tour: ProductTour,
): Promise<Date | undefined> {
  const key = getAdditionalPropertyKey(tour);

  try {
    const result = await load(username, key);

    if (result) {
      return new Date(result);
    }
  } catch {}
}

function getTriggerTimePropertyKey(tour: ProductTour) {
  return `intercom_tour/${tour}`;
}

function getAdditionalPropertyKey(tour: ProductTour) {
  return `intercom_tour/${tour}/additional`;
}

import { backendClient } from '@neptune/shared/core-apis-backend-domain';

import { UsageProjectModelConverter } from './usage-project-model-converter';

export async function getMonitoringTimeTopProjects30Days({
  organizationIdentifier,
  sortBy,
}: {
  organizationIdentifier: string;
  sortBy: string;
}) {
  const data = await backendClient.monitoringTimeTopProjects30Days({
    organizationIdentifier,
    sortBy,
  });
  return data.projects.map(UsageProjectModelConverter.usageProjectFromApiToDomain);
}

import React from 'react';

import { HttpErrorPage } from '@neptune/shared/common-ui';

import { neptune } from 'analytics/neptune';

type HttpErrorPageContainerProps = {
  error: {
    code?: number;
  };
};

export const HttpErrorPageContainer: React.VFC<HttpErrorPageContainerProps> = ({ error }) => {
  const isNotFoundOrGoneError = error.code === 404 || error.code === 410;

  React.useEffect(() => {
    if (isNotFoundOrGoneError) {
      neptune.trackError('App - View - 404');
    }
  }, [isNotFoundOrGoneError]);

  return <HttpErrorPage isNotFoundOrGoneError={isNotFoundOrGoneError} />;
};

import { useSelector } from 'react-redux';

import { useGenericModal } from '@neptune/shared/common-business-logic';

import { isTrial } from 'state/organization-limits/selectors';
import { getContextOrganization } from 'state/selectors-global';

import { getWorkspaceStoragePricing } from './redux/organization-pricing-selectors';
import {
  CHANGE_STORAGE_PLACEHOLDER_MODAL_NAME,
  CHANGE_STORAGE_QUOTA_MODAL_NAME,
  CHANGE_TRIAL_STORAGE_MODAL_NAME,
} from './modals';

export function useChangeStorageFlow() {
  const workspace = useSelector(getContextOrganization);
  const limitsEnforced = workspace?.limitsEnforced;
  const isTeamTrial = useSelector(isTrial);
  const storagePricing = useSelector(getWorkspaceStoragePricing);
  const hasStoragePricing = !!storagePricing;

  const { open: openChangeStorageQuotaModal } = useGenericModal(CHANGE_STORAGE_QUOTA_MODAL_NAME);
  const { open: openChangeStoragePlaceholderModal } = useGenericModal(
    CHANGE_STORAGE_PLACEHOLDER_MODAL_NAME,
  );
  const { open: openChangeTrialStorageModal } = useGenericModal(CHANGE_TRIAL_STORAGE_MODAL_NAME);

  // note: this flag should reflect inner ifs in start function below
  const canStart = !limitsEnforced || isTeamTrial || hasStoragePricing;

  const start = () => {
    if (!limitsEnforced) {
      return openChangeStoragePlaceholderModal();
    }

    if (isTeamTrial) {
      return openChangeTrialStorageModal();
    }

    if (hasStoragePricing) {
      return openChangeStorageQuotaModal();
    }

    throw new Error('Should not reach here');
  };

  return {
    start: canStart ? start : undefined,
  };
}

import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentProject } from '@neptune/current-project-business-logic';
import { PaymentsLink } from '@neptune/shared/common-ui';
import { getTextVariantByValue } from '@neptune/shared/common-util';
import { getDashboardsPerProjectEffectiveLimit } from '@neptune/shared/core-organizations-business-logic';
import { Organization } from '@neptune/shared/core-organizations-domain';
import { hasDashboardModifyPermission } from '@neptune/shared/core-permissions-business-logic';

import { isFetchStatusCompleted, isFetchStatusSuccess } from 'state/fetch-status';
import {
  getProjectDashboardFetchStatus,
  getProjectDashboards,
} from 'state/project-dashboards/selectors';
import { getCurrentOrganization } from 'state/selectors';

export function useDashboardCreate() {
  const project = useSelector(getCurrentProject);
  const workspace: Organization | undefined = useSelector(getCurrentOrganization);
  const dashboardsFetchStatus = useSelector(getProjectDashboardFetchStatus);
  const numberOfDashboards = useSelector(getProjectDashboards).dashboards.length ?? 0;
  const limit = getDashboardsPerProjectEffectiveLimit(workspace?.traits ?? {});
  const dashboardsLeft = isFetchStatusCompleted(dashboardsFetchStatus)
    ? limit - numberOfDashboards
    : -1;
  const canCreate =
    isFetchStatusSuccess(dashboardsFetchStatus) &&
    hasDashboardModifyPermission(project) &&
    dashboardsLeft > 0;

  return {
    canCreate,
    creatingDashboardDisabledReason: !canCreate ? getDisabledReason() : undefined,
  };

  function getDisabledReason(): React.ReactNode | undefined {
    if (!isFetchStatusCompleted(dashboardsFetchStatus)) {
      return undefined;
    }

    if (isFetchStatusSuccess(dashboardsFetchStatus)) {
      return dashboardsLeft <= 0 ? (
        <span>
          You can have a maximum of {limit}{' '}
          {getTextVariantByValue(limit, 'dashboards', 'dashboard', 'dashboards')} on your current
          plan. This number is the total limit of dashboards created either for single runs or for
          comparison.
          <br />
          To create more dashboards, go to{' '}
          <PaymentsLink
            organizationName={project?.organizationName}
            children="Billing & payments"
          />{' '}
          in the workspace settings and upgrade your subscription.
        </span>
      ) : (
        'You need to be a project owner or contributor to create new dashboards.'
      );
    }

    return 'Unable to determine whether you can create additional dashboards. Refresh to try again.';
  }
}

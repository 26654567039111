import {
  loadAdditionalTriggerTime,
  loadTourTriggerTime,
  persistAdditionalTriggerTime,
  persistTourTriggerTime,
  ProductTour,
} from '@neptune/onboarding-domain';

import { neptune } from 'analytics/neptune';

import config from 'config';

type Environment = 'dist' | 'dev';

const tourMap: Record<ProductTour, Record<Environment, number | undefined>> = {
  'onboarding/day_1/basic_ui_tour': {
    dist: 412310,
    dev: 413876,
  },
  'onboarding/day_1/run_details_ui_tour': {
    dist: undefined,
    dev: undefined,
  },
  'onboarding/day_1/prompt_to_explore_run_details': {
    dist: undefined,
    dev: undefined,
  },
  'onboarding/day_1/prompt_to_integrate_with_code': {
    dist: undefined,
    dev: undefined,
  },
  'onboarding/day_1/example_project_ui_tour': {
    dist: 413847,
    dev: 413878,
  },
  'onboarding/day_1/prompt-to-create-new-project': {
    dist: undefined,
    dev: undefined,
  },
  'new_ui/legacy_users_1': {
    dist: 417992,
    dev: 418060,
  },
};

export class IntercomTour {
  static getTourId(tour: ProductTour): number | undefined {
    return tourMap[tour]?.[config.isProduction ? 'dist' : 'dev'];
  }

  static async triggerTour(username: string, tour: ProductTour) {
    const lastTriggered = await loadTourTriggerTime(username, tour);

    if (lastTriggered) {
      return;
    }

    const tourId = this.getTourId(tour);

    if (!tourId) {
      return;
    }

    neptune.startProductTour(tourId);

    await persistTourTriggerTime(username, tour, new Date());
  }

  static async updateFirstCreatedProject(username: string): Promise<void> {
    const tour: ProductTour = 'onboarding/day_1/prompt-to-create-new-project';
    const lastTriggered = await loadAdditionalTriggerTime(username, tour);

    if (lastTriggered) {
      return;
    }

    await persistAdditionalTriggerTime(username, tour, new Date());
  }

  private static lastVisitedProject?: string;

  static updateLastVisitedProject(projectIdentifier: string): void {
    IntercomTour.lastVisitedProject = projectIdentifier;
  }

  static getLastVisitedProject(): string | undefined {
    return IntercomTour.lastVisitedProject;
  }

  static async getFirstCreatedProjectTriggerTime(username: string): Promise<Date | undefined> {
    const tour: ProductTour = 'onboarding/day_1/prompt-to-create-new-project';
    return loadAdditionalTriggerTime(username, tour);
  }

  static async getLastTriggerTime(username: string, tour: ProductTour): Promise<Date | undefined> {
    return loadTourTriggerTime(username, tour);
  }
}

export const PROJECT_QUALIFIED_NAME_REGEX = /^.+\/.+\//;

/**
 *
 * @param organizationName organization name
 * @param projectName project name
 * @returns
 *
 * @example
 * makeProjectIdentifier('workspace', 'project') // 'workspace/project'
 */
export function makeProjectIdentifier(organizationName: string, projectName: string): string {
  return `${organizationName}/${projectName}`;
}

import { LayoutRect } from '@neptune/shared/grid-layout-domain';

import { compactGridLayouts } from './compact-grid-layouts';

export function extractSectionItems<ItemLayoutT extends LayoutRect>(
  layouts: ItemLayoutT[],
  sectionId: string,
  isSection: (item: ItemLayoutT) => boolean,
  getItemKey: (item: ItemLayoutT) => string,
): ItemLayoutT[] {
  const compactedLayouts = layouts.map((layout) => ({ ...layout }));

  // Make sure the layouts are correctly sorted and overlaps resolved.
  // Only sorting should be necessary, but calling compactGridLayouts
  // gives us a bit more confidence that the ordering is consistent
  // with what we display.
  compactGridLayouts(compactedLayouts);

  const sectionIndex = compactedLayouts.findIndex(
    (item) => isSection(item) && getItemKey(item) === sectionId,
  );

  if (sectionIndex === -1) {
    return [];
  }

  const result: ItemLayoutT[] = [];

  for (
    let i = sectionIndex + 1;
    i < compactedLayouts.length && !isSection(compactedLayouts[i]);
    ++i
  ) {
    result.push(compactedLayouts[i]);
  }

  return result;
}

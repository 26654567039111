import { RegisteredMemberInfoDTO } from '@neptune/shared/core-apis-backend-domain';
import { AvatarSource, avatarSourceFromApiToDomain } from '@neptune/shared/core-users-domain';

export interface RegisteredMemberInfo {
  avatarSource: AvatarSource;
  lastName: string;
  firstName: string;
  username: string;
  avatarUrl: string;
}

export function registeredMemberInfoFromApiToDomain(
  source: RegisteredMemberInfoDTO,
): RegisteredMemberInfo {
  return {
    avatarSource: avatarSourceFromApiToDomain(source.avatarSource),
    lastName: source.lastName,
    firstName: source.firstName,
    username: source.username,
    avatarUrl: source.avatarUrl,
  };
}

import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';

import {
  AttributeDefinition,
  AttributeDefinitionConverter,
  AttributeType,
} from 'domain/experiment/attribute';

export async function listAttributeDefinitions({
  identifier,
  attributeTypes,
}: {
  identifier: string;
  attributeTypes?: AttributeType[];
}): Promise<AttributeDefinition[]> {
  const result = await leaderboardClient.queryAttributeDefinitions({
    experimentIdentifier: identifier,
    attributeType: attributeTypes
      ?.map(AttributeDefinitionConverter.attributeTypeToApi)
      .map((at) => at?.valueOf())
      .filter((s): s is string => !!s),
  });

  return result.entries.map(AttributeDefinitionConverter.attributeDefinitionFromApiToDomain);
}

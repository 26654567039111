import Cookies from 'js-cookie';

import config from 'config';

const neptuneTeamTrialRegistrationCookieLiveTimeMs = 15 * 60 * 1000;
export const teamTrialRegistrationCookieName = 'neptune-team-trial-registration';

export function setTeamTrialCookieForKeycloakRegistration() {
  Cookies.set(teamTrialRegistrationCookieName, 'true', {
    path: '/',
    expires: new Date(Date.now() + neptuneTeamTrialRegistrationCookieLiveTimeMs),
    domain: config.neptuneCookieDomain,
  });
}

export function clearTeamTrialCookieForKeycloakRegistration() {
  Cookies.remove(teamTrialRegistrationCookieName, {
    path: '/',
    domain: config.neptuneCookieDomain,
  });
}

export function isTeamTrialCookieForKeycloakRegistrationSet() {
  return !!Cookies.get(teamTrialRegistrationCookieName);
}

import React from 'react';

import { backendClient } from '@neptune/shared/core-apis-backend-domain';
import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';

import { ProjectUsageModel, ProjectUsageModelConverter } from 'domain/project-usage';
import { fetchStatus } from 'state/fetch-status';

type DataWithStatus = {
  fetchStatus: fetchStatus;
  data?: ProjectUsageModel;
};

export const useProjectUsage = (projectIdentifier: string): DataWithStatus => {
  const [data, setData] = React.useState<ProjectUsageModel>();
  const [status, setStatus] = React.useState<fetchStatus>(fetchStatus.NONE);

  React.useEffect(() => {
    setStatus(fetchStatus.PENDING);
    apiCall();

    async function apiCall() {
      try {
        const [leaderboardResponse, storageResponse, monitoringResponse] = await Promise.all([
          leaderboardClient.searchLeaderboardEntries({
            projectIdentifier,
            params: { pagination: { limit: 0 } },
            type: ['run'],
          }),
          backendClient.storageUsage({ projectIdentifier }),
          backendClient.monitoringTimeUsage({ projectIdentifier }),
        ]);

        setData(
          ProjectUsageModelConverter.usageFromApiToDomain(
            leaderboardResponse,
            storageResponse,
            monitoringResponse,
          ),
        );
        setStatus(fetchStatus.SUCCESS);
      } catch {
        setStatus(fetchStatus.FAILED);
      }
    }
  }, [projectIdentifier]);

  return {
    fetchStatus: status,
    data,
  };
};

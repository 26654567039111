import React from 'react';

import { Layout, RadioWithLabel, Text } from '@neptune/shared/venus-ui';

import { KeycloakLabel } from 'components/keycloak';

import { Benefits } from '../Benefits';

const AVAILABLE_OPTIONS = [
  { key: 'yes', value: 'Yes!' },
  { key: 'later', value: 'Maybe later' },
] as const;
type OptionType = { key: string; value: string };

type TeamTrialFormOptions = {
  data: { teamTrial?: string };
  onChange: (form: { teamTrial?: string }) => void;
};

export const TeamTrialForm: React.FC<TeamTrialFormOptions> = ({ data, onChange, children }) => {
  return (
    <Layout.Column component="form" spacedChildren="md" data-role="team-trial-form">
      <KeycloakLabel>Do you want to start a free 14-day trial right away?</KeycloakLabel>
      <Layout.Column spacedChildren="sm" span="auto">
        {AVAILABLE_OPTIONS.map((option: OptionType, i: number) => (
          <RadioWithLabel
            key={option.key}
            id={`survey-team-trial-${i}`}
            data-role={option.key}
            value={option.value}
            name="survey-team-trial"
            checked={data.teamTrial === option.key}
            onChange={() => onChange({ teamTrial: option.key })}
            label={option.value}
          />
        ))}
      </Layout.Column>
      <Benefits
        caption="Free team workspace trial for 14 days!"
        items={[
          <Text size="sm">No credit card required</Text>,
          <Text size="sm">Invite an unlimited number of users</Text>,
          <Text size="sm">Check out collaboration features</Text>,
          <Text size="sm">Get dedicated support during the trial</Text>,
        ]}
      />
      {children}
    </Layout.Column>
  );
};

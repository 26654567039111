import React from 'react';
import Picker, { EmojiClickData, EmojiStyle } from 'emoji-picker-react';

import {
  bemBlock,
  Dropdown,
  DropdownProps,
  DropdownToggleProps,
  Emoji,
  Layout,
} from '@neptune/shared/venus-ui';

import './AvatarPicker.less';

export type AvatarPickerProps = {
  avatar?: string;
  onAvatarChange: (avatar: string) => void;
  toggle?: DropdownProps['toggle'];
};

const block = bemBlock('avatar-picker');

export const AvatarPicker: React.FC<AvatarPickerProps> = ({ avatar, onAvatarChange, toggle }) => {
  return (
    <Dropdown
      toggle={toggle ?? <Toggle variant="basic" activeEmoji={avatar} />}
      attachment="top left"
      targetAttachment="bottom left"
      constraints={[
        {
          to: 'window',
          attachment: 'together',
          pin: true,
        },
      ]}
    >
      {({ collapse }) => (
        <Dropdown.Menu data-role="emoji-picker" withPadding="none">
          <Layout.Row>
            <Picker
              width={300}
              height={400}
              emojiStyle={EmojiStyle.NATIVE}
              previewConfig={{ showPreview: false }}
              onEmojiClick={(emojiData: EmojiClickData) => {
                onAvatarChange(emojiData.unified);
                collapse();
              }}
            />
          </Layout.Row>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

const Toggle = ({
  activeEmoji,
  elementRef,
  pressed,
  onToggle,
  variant,
}: DropdownToggleProps & { activeEmoji?: string }) => {
  const planetEmoji = '1FA90';

  return (
    <Dropdown.Toggle
      className={block('toggle')}
      data-role="emoji-picker-toggle"
      elementRef={elementRef}
      onToggle={onToggle}
      variant={variant}
    >
      <Emoji unicode={activeEmoji ?? planetEmoji} size="md" />
      <Dropdown.Toggle.Icon pressed={pressed} />
    </Dropdown.Toggle>
  );
};

import { actions as routerActions } from 'redux-router5';
import { navigateTo } from 'router/actions';
import { parseRouteParams } from 'router/router-utils';

import type { GroupByStorage, GroupByStorageStrategy } from '@neptune/shared/leaderboard-domain';

import { getDirtyLbView } from 'state/project-leaderboard/lb-views/selectors';
import { getCurrentRoute, getQueryFromRouteParams } from 'state/selectors-global';
import type { AppState, NThunkDispatch } from 'state/types';
import { getRawGroupBy } from 'state/ui/route-params/selectors';

import { updateDirtyLbView } from './update-dirty-lb-view';

export const inLbViewGroupByStorageStrategy: GroupByStorageStrategy = {
  selector: (state: AppState) => getDirtyLbView(state)?.groupOptions,
  actionCreator: updateDirtyLbView,
  currentQuerySelector: (state: AppState) => getDirtyLbView(state)?.query || '',
};

export const inUrlGroupByStorageStrategy: GroupByStorageStrategy = {
  selector: getRawGroupBy,
  actionCreator: updateUrl,
  currentQuerySelector: getQueryFromRouteParams,
};

function updateUrl(newParams: GroupByStorage) {
  return (
    dispatch: NThunkDispatch<ReturnType<typeof routerActions.navigateTo>>,
    getState: () => AppState,
  ) => {
    const route = getCurrentRoute(getState());
    dispatch(navigateTo(route.name, parseRouteParams({ ...route.params, ...newParams })));
  };
}

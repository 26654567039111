/* tslint:disable */
/* eslint-disable */
/**
 * scissors
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SnipRequest
 */
export interface SnipRequest {
    /**
     * 
     * @type {string}
     * @memberof SnipRequest
     */
    content: string;
}

/**
 * Check if a given object implements the SnipRequest interface.
 */
export function instanceOfSnipRequest(value: object): value is SnipRequest {
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function SnipRequestFromJSON(json: any): SnipRequest {
    return SnipRequestFromJSONTyped(json, false);
}

export function SnipRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SnipRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'content': json['content'],
    };
}

export function SnipRequestToJSON(value?: SnipRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'content': value['content'],
    };
}


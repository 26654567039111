import React from 'react';

import { ProjectSettings } from '@neptune/project-ui';
import { ProjectServiceAccounts } from '@neptune/service-accounts-feature';
import {
  hasProjectServiceAccountPermissions,
  hasProjectSettingsAccessPermission,
  hasProjectUsageAccessPermission,
} from '@neptune/shared/core-permissions-business-logic';
import { ProjectWithRole } from '@neptune/shared/core-project-domain';

import { People } from './People';
import { Properties } from './Properties';
import { Usage } from './Usage';

export type ProjectSettingsContainerProps = {
  project: ProjectWithRole;
};

export const ProjectSettingsContainer: React.FC<ProjectSettingsContainerProps> = ({ project }) => {
  const [activeTabId, setActiveTabId] = React.useState<string | undefined>(
    () => tabsDefinitions.find((tab) => tab.permission?.(project))?.tabId,
  );

  const tabs = React.useMemo(
    () =>
      tabsDefinitions.map((tab) => {
        const disabled = tab.permission && !tab.permission(project);
        return {
          ...tab,
          disabled,
        };
      }),
    [project],
  );

  return (
    <ProjectSettings
      project={project}
      tabs={tabs}
      onActiveTabChange={setActiveTabId}
      activeTabId={activeTabId}
      selectedOutlet={tabs.find((tab) => tab.tabId === activeTabId)?.Component}
    />
  );
};

const tabsDefinitions = [
  {
    label: 'General settings',
    tabId: 'general-settings',
    Component: Properties,
    permission: hasProjectSettingsAccessPermission,
    disabledReason: 'You need to be a project owner to edit project details.',
  },
  {
    label: 'Usage',
    tabId: 'usage',
    Component: Usage,
    permission: hasProjectUsageAccessPermission,
    disabledReason: 'You need to be a project owner or contributor to view usage details.',
  },
  {
    label: 'Team',
    tabId: 'team',
    Component: People,
  },
  {
    label: 'Service accounts',
    tabId: 'service-accounts',
    Component: ProjectServiceAccounts,
    permission: hasProjectServiceAccountPermissions,
    disabledReason: 'You need to be a project owner to assign service accounts.',
  },
];

// Libs
import React from 'react';

import { bemBlock, Layout, Text } from '@neptune/shared/venus-ui';

// Module
import './GetStartedVideo.less';

const block = bemBlock('get-started-video');

export const GetStartedVideo: React.FC = () => (
  <Layout.Column alignItems="center" spacedChildren="sm">
    <div className={block('video-container')}>
      <iframe
        className={block('iframe')}
        src="https://www.youtube.com/embed/9iX6DxcijO8"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>

    <Text align="center" size="sm" italic>
      {' '}
      Got 2 minutes? Watch our Get Started video!
    </Text>
  </Layout.Column>
);

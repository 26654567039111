import { IntercomTour } from '@neptune/onboarding-business-logic';
import { neptuneUrls } from '@neptune/shared/common-domain';
import { tick } from '@neptune/shared/common-util';
import { OrganizationType } from '@neptune/shared/core-organizations-domain';
import { AppRoute } from '@neptune/shared/routing-domain';

import { isOnPremiseDeployment } from 'common/deploymentModes';
import { API_TOKEN_MODAL_NAME } from 'components/modals/api-token-modal/ApiTokenModal';
import { getContextOrganization } from 'state/selectors-global';
import { showGenericModal } from 'state/ui/modals/generic/actions';
import {
  getCurrentUserCreated,
  getCurrentUsername,
  isUserAuthenticated,
} from 'state/users/current-user/selectors';
import viewsRoutes from 'views/routes';

import {
  navigateTo,
  redirectToIndividualSubscription,
  redirectToWorkspaceDashboard,
} from './actions';

const applicationRoutes: AppRoute[] = [
  {
    name: 'home',
    path: '/',
    onlyLoggedUser: true,
    onActivate(dispatch) {
      return () => {
        tick(() => dispatch(redirectToWorkspaceDashboard()));
      };
    },
  },
  {
    name: 'onboarding',
    path: '/-/onboarding',
    onlyLoggedUser: true,
    onActivate(dispatch, getState) {
      return () => {
        tick(() => dispatch(navigateTo('home'))).then(() => {
          const username = getCurrentUsername(getState());
          const organization = getContextOrganization(getState());
          const userCreated = getCurrentUserCreated(getState());
          const enrollmentDateOfBasicUiTour = new Date('2021-08-19');
          const userSignedUpAfterEnrollment = userCreated
            ? userCreated > enrollmentDateOfBasicUiTour
            : false;
          const userIsIndividualOnly =
            organization && organization.organizationType === OrganizationType.individual;

          if (
            !isOnPremiseDeployment() &&
            username &&
            userIsIndividualOnly &&
            userSignedUpAfterEnrollment
          ) {
            IntercomTour.triggerTour(username, 'onboarding/day_1/basic_ui_tour');
          }
        });
      };
    },
  },
  {
    name: 'get-my-api-token',
    path: '/get_my_api_token',
    onActivate(dispatch, getState) {
      return () => {
        const authenticated = isUserAuthenticated(getState());

        if (authenticated) {
          tick(() => dispatch(redirectToWorkspaceDashboard())).then(() =>
            dispatch(showGenericModal(API_TOKEN_MODAL_NAME)),
          );
        } else {
          window.location.replace(neptuneUrls.setupApiTokenDoc);
        }
      };
    },
  },
  {
    // route used by external services for easier navigation to user's subscription
    name: 'individual-subscription',
    path: '/-/subscription',
    onlyLoggedUser: true,
    onActivate(dispatch) {
      return () => {
        return tick(() => dispatch(redirectToIndividualSubscription()));
      };
    },
  },
  ...viewsRoutes,
];

export default applicationRoutes;

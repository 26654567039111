// App
import { fetchStatus as FetchStatus, isFetchStatusSuccess } from 'state/fetch-status';
import { AppState } from 'state/types';

// Module
import { OrganizationLimitsState } from './reducer';

function getOrganizationLimits(state: any): OrganizationLimitsState {
  return state.organizationLimits;
}

export const getExpirationDate = (state: AppState): Date | undefined =>
  getOrganizationLimits(state).expirationDate;
export const getIsExpired = (state: AppState): boolean => getOrganizationLimits(state).isExpired;
export const getLimitsFetchStatus = (state: AppState): FetchStatus =>
  getOrganizationLimits(state).fetchStatus;
export const getMembersLimit = (state: AppState): number | undefined =>
  getOrganizationLimits(state).membersLimit;
export const getProjectsLimit = (state: AppState): number | undefined =>
  getOrganizationLimits(state).projectsLimit;
export const getStorageLimit = (state: AppState): number | undefined =>
  getOrganizationLimits(state).storageSize;

export const isTrial = (state: AppState): boolean | undefined =>
  isFetchStatusSuccess(getLimitsFetchStatus(state)) ? !!getExpirationDate(state) : undefined;

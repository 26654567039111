import React from 'react';

import { convertSizeToUnit } from '@neptune/shared/common-util';
import { bemBlock, Button, Card, InfoIcon, Layout, Section, Text } from '@neptune/shared/venus-ui';

import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';

import './ProjectUsageSettings.less';

type ProjectUsageSettingsProps = {
  onOpenStorageModal: () => void;
  isLoading: boolean;
  storageLimit: number | undefined;
};

const block = bemBlock('project-usage-settings-loading');

const STORAGE_UNIT = 'GB';

export const ProjectUsageSettings: React.FC<ProjectUsageSettingsProps> = ({
  isLoading,
  storageLimit,
  onOpenStorageModal,
}) => {
  return (
    <Section withBorder withPadding="md" spacedChildren="md">
      <Section.Title size="small">Settings</Section.Title>
      {isLoading ? (
        <Layout.Element className={block()}>
          <LoadingIndicator isVisible centered size="small" />
        </Layout.Element>
      ) : (
        <Layout.Row>
          <Layout.Row alignItems="center" spacedChildren="xs">
            <Card.LabelText>Storage limit</Card.LabelText>
            <InfoIcon>The limit controls the storage space available for the project.</InfoIcon>
          </Layout.Row>
          <Layout.Row spacedChildren="sm" alignItems="baseline" justifyContent="end">
            {storageLimit === undefined ? (
              <Text data-role="no-limit" size="sm">
                No limit
              </Text>
            ) : (
              <Text data-role="storage-limit-value" fontWeight="semibold" size="sm">
                {convertSizeToUnit(storageLimit, STORAGE_UNIT)} {STORAGE_UNIT}
              </Text>
            )}
            <Button variant="basic" data-role="configure-button" onClick={onOpenStorageModal}>
              Configure
            </Button>
          </Layout.Row>
        </Layout.Row>
      )}
    </Section>
  );
};

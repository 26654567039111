// Libs
import React from 'react';

import { Button, ButtonProps, Link } from '@neptune/shared/venus-ui';

type SalesLink = {
  asButton?: boolean;
} & Pick<ButtonProps<{}>, 'size' | 'variant'>;

export const SalesLink: React.FC<SalesLink> = ({
  asButton,
  children,
  size = 'lg',
  variant = 'primary',
}) => {
  const href = 'mailto:sales@neptune.ai';

  if (asButton) {
    return (
      <Button
        data-role="sales-link"
        children={children}
        size={size}
        variant={variant}
        onClick={() => window.open(href)}
      />
    );
  }

  return <Link data-role="sales-link" href={href} children={children} external />;
};

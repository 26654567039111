import { FILTERING_QUERY_PARAMS } from 'router/config';
import { makeEnterpriseRoute } from 'router/helpers';
import { storage } from 'common/storage';

const trashRoute = {
  name: 'project.trash',
  path: `/trash?${FILTERING_QUERY_PARAMS}`,
  onActivate: () => () => storage.local.setItem('lastProjectTab', 'trash'),
};

export const projectDashboardRoutes = [trashRoute, makeEnterpriseRoute(trashRoute)];

export default projectDashboardRoutes;

import { SearchQuery } from '@neptune/search-query-domain';
import { stripNonReadableCharacters as defaultStripNonReadableCharacters } from '@neptune/shared/common-util';

export type Dependencies = {
  stripNonReadableCharacters: typeof defaultStripNonReadableCharacters;
};

export const createQueryValuesSanitizer = (dependencies: Partial<Dependencies> = {}) => {
  const { stripNonReadableCharacters = defaultStripNonReadableCharacters } = dependencies;

  function sanitize({ criteria, ...rest }: SearchQuery): SearchQuery {
    return {
      ...rest,
      criteria: criteria.map((criterion) => {
        if (!('type' in criterion)) {
          return sanitize(criterion);
        }

        if (!('value' in criterion) || criterion.type !== 'string') {
          return criterion;
        }

        return {
          ...criterion,
          value: stripNonReadableCharacters(criterion.value),
        };
      }),
    };
  }

  return { sanitize };
};

export const queryValuesSanitizer = createQueryValuesSanitizer();

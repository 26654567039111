import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { WithPaddingRenderer } from 'neptune-core-ui/components/table/renderers';

import { OrganizationInvitation } from '@neptune/invitations-domain';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UserProfileDTO } from '@neptune/shared/core-apis-backend-domain';
import { Icon, Link } from '@neptune/shared/venus-ui';
import { BaseMember } from '@neptune/user-management-domain';

export type DeleteCallbackParams = Partial<{
  userId: string;
  invitee: string;
  invitationId: string;
}>;

export const DeleteButtonRenderer: React.FC<{
  data: BaseMember & {
    canLeaveProject?: boolean;
    organizationInvitationInfo?: OrganizationInvitation;
  };
  params: {
    user: UserProfileDTO | null;
    onClick?: (params: DeleteCallbackParams) => void;
  };
}> = ({ data, params }) => {
  const { user, onClick, ...otherParams } = params;

  const { editableRole, canLeaveProject } = data;

  const { username: userId } = data.registeredMemberInfo || {};

  const { id: invitationId, invitee } = data.invitationInfo || {};

  const leaveHandler = React.useCallback(() => {
    onClick?.({});
  }, [onClick]);

  const removeHandler = React.useCallback(() => {
    onClick?.({ invitationId, invitee, userId });
  }, [onClick, invitationId, invitee, userId]);

  if (data.organizationInvitationInfo) {
    return null;
  }

  if (canLeaveProject) {
    return (
      <WithPaddingRenderer>
        <Link onClick={leaveHandler} data-role="leave-action" disabled={!onClick} {...otherParams}>
          <Icon glyph="exit" />
        </Link>
      </WithPaddingRenderer>
    );
  } else if (editableRole && user?.username !== userId) {
    return (
      <WithPaddingRenderer>
        <Link
          onClick={removeHandler}
          data-role="remove-action"
          disabled={!onClick}
          {...otherParams}
        >
          <Icon glyph="trash" />
        </Link>
      </WithPaddingRenderer>
    );
  }

  return null;
};

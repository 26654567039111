import { compareRectsByYX, RGLLayout, SectionsLayoutMap } from '@neptune/shared/grid-layout-domain';

import { compactGridLayouts } from './compact-grid-layouts';

export function reconstructUnfoldedLayoutsAfterChange(
  updatedRglLayouts: RGLLayout[],
  unfoldedGridLayouts: RGLLayout[],
  sectionsLayoutsMap: SectionsLayoutMap,
): {
  updatedLayouts: RGLLayout[];
  unfoldingSections: string[];
} {
  const updatedLayouts: RGLLayout[] = [];
  let yOffset = 0;
  let foldedSectionJustBefore: string | undefined = undefined;
  const unfoldingSections: string[] = [];

  for (const { i, x, y, w, h } of Array.from(updatedRglLayouts).sort(compareRectsByYX)) {
    if (h === 0) {
      // This is a dummy layout for a folded widget. Skip.
      // We will insert the true layout when processing the section.
      continue;
    }

    updatedLayouts.push({ i, x, y: y + yOffset, w, h });

    const sectionDetails = sectionsLayoutsMap[i];

    if (sectionDetails?.folded) {
      const sectionItems = unfoldedGridLayouts.slice(
        sectionDetails.firstItemIndex,
        sectionDetails.endIndex,
      );

      const newFirstItemUnfoldedY = y + yOffset + h;
      const sectionMoveOffset = newFirstItemUnfoldedY - sectionDetails.firstItemUnfoldedY;

      updatedLayouts.push(
        ...sectionItems.map(({ i, x, y, w, h }) => {
          return { i, x, y: y + sectionMoveOffset, w, h };
        }),
      );

      yOffset += sectionDetails.contentsRowHeight;
      foldedSectionJustBefore = i;
    } else if (sectionDetails) {
      // Unfolded section
      foldedSectionJustBefore = undefined;
    } else if (foldedSectionJustBefore) {
      // Regular widget just after a section. That means that this widget was added/moved here
      // just now and will fall into the folded section. It would immediately disappear if the
      // section stayed folded To avoid the disappearance, we unfold the section.
      unfoldingSections.push(foldedSectionJustBefore);
      foldedSectionJustBefore = undefined;
    }
  }

  // In some cases, at least when dropping widgets into folded sections, the resulting layout may
  // not be correctly compacted - make sure it's not saved like that.
  compactGridLayouts(updatedLayouts);

  return {
    updatedLayouts,
    unfoldingSections,
  };
}

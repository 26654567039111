import { CriterionType, SearchOperator, SearchQueryOperator } from './search-query-model';

export const operatorMap: Record<CriterionType, SearchOperator[]> = {
  bool: ['=', '!='],
  datetime: ['before', 'after', 'exists', '!exists', 'last'],
  experimentState: ['oneOf', '='],
  float: ['=', '!=', '>=', '>', '<=', '<', 'exists', '!exists'],
  floatSeries: ['=', '!=', '>=', '>', '<=', '<'],
  gitRef: ['='],
  int: ['=', '!=', '>=', '>', '<=', '<', 'exists', '!exists'],
  owner: ['oneOf', '!oneOf'],
  stage: ['oneOf', '!oneOf'],
  string: ['=', '!=', 'contains', '!contains', 'matches', '!matches', 'exists', '!exists'],
  stringSeries: ['=', '!=', 'contains', '!contains'],
  stringSet: [
    'oneOf',
    '!oneOf',
    'allOf' /* TODO: temporarily disabled - we can't infer those from NQL, 'empty'*/,
  ],
};

export const nqlOperatorToOperatorMap: Record<
  string,
  Partial<Record<'*' | SearchQueryOperator, SearchOperator>>
> = {
  '=': {
    '*': 'oneOf',
  },
  contains: {
    '*': 'oneOf',
    and: 'allOf',
    or: 'oneOf',
  },
  matches: {
    '*': 'oneOf',
    and: 'allOf',
    or: 'oneOf',
  },
  '!=': {
    '*': '!oneOf',
  },
  '!contains': {
    '*': '!oneOf',
  },
};

import {
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  interval,
  map,
  mapTo,
  merge,
  Observable,
  of,
  pipe,
  switchMap,
  tap,
} from 'rxjs';

import { DocumentVisibilityFacade } from './document-visibility-facade';

/**
 * Creates observable that emits event every time that application become visible (e.g. after browser tab switching)
 */
const createAppShowed$: () => Observable<unknown> = () => {
  // implementation:
  // * take document visibility change events, and at every browser event
  // * read current document hidden status,
  // * omit duplicates
  // * and skip those when app is hidden
  return DocumentVisibilityFacade.fromVisibilityChange().pipe(
    map(() => DocumentVisibilityFacade.isAppHidden()),
    distinctUntilChanged(),
    filter((hidden) => !hidden),
  );
};

/**
 * Emits events that should trigger leaderboard refresh
 */
export const tempo = <T>(
  pollingInterval?: number,
  manualHit$?: Observable<unknown>,
  debounceTimeMs?: number,
) =>
  pipe(
    // implementation:
    // * when query changes, reset everything (especially polling) and
    // * on:
    //   - query change, or
    //   - app become visible again, or
    //   - polling interval triggers
    // * but only when application is visible
    // * with event squashing (with given throttling)
    // * emit query
    switchMap((query: T) =>
      merge(
        of('onStartAndEveryQueryChanged'),
        manualHit$ ? manualHit$ : EMPTY,
        createAppShowed$(),
        pollingInterval ? interval(pollingInterval) : EMPTY,
      ).pipe(
        filter(() => !DocumentVisibilityFacade.isAppHidden()),
        debounceTimeMs == null ? tap() : debounceTime(debounceTimeMs),
        mapTo(query),
      ),
    ),
  );

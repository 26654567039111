import { TimeScale } from 'domain/common/time-scale';

import { WidgetDefinition } from '../core/domain/widget-definition';
import { Widget } from '../core/widget';

export type ValueListWidget = Widget & {
  type: 'valueList';
  options: ValueListWidgetOptions;
};
export type ValueListWidgetOptions = {
  timeScale: TimeScale;
  showIndex: boolean;
};

export function isValueListWidget(widget: Widget): widget is ValueListWidget {
  return widget.type === 'valueList';
}

export function valueListWidgetOptionsFromApiToDomain(options?: Record<string, any>) {
  return {
    timeScale: options?.timeScale,
    showIndex: options?.showIndex,
  };
}

export const valueListWidgetName = 'Value list';

export const valueListWidgetDefinition: WidgetDefinition = {
  type: 'valueList',
  name: valueListWidgetName,
  fromApiToDomain: valueListWidgetOptionsFromApiToDomain,
};

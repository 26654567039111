import React from 'react';

import { FilterSearchMatch } from '@neptune/shared/search-ui';
import { Layout, Text } from '@neptune/shared/venus-ui';

import { ServiceAccountAvatar } from '../service-account-avatar/ServiceAccountAvatar';

type ServiceAccountRendererProps = {
  name: string;
};

export const ServiceAccountRenderer: React.FC<ServiceAccountRendererProps> = ({ name }) => {
  const icon = <ServiceAccountAvatar size="xs" />;

  return (
    <Layout.Row span="auto" spacedChildren="xs" data-role="service-account">
      {icon}
      <Text fontWeight="semibold">
        <FilterSearchMatch text={name} />
      </Text>
    </Layout.Row>
  );
};

import { SearchOperator } from './search-query-model';

export const operatorNames: Partial<Record<SearchOperator, string>> = {
  '!contains': 'not contains',
  '!exists': 'not exists',
  '!oneOf': 'not one of',
  '!matches': 'not matches',
  allOf: 'all of',
  oneOf: 'one of',
  empty: 'is empty',
};

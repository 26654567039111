import { IntercomTour } from '@neptune/onboarding-business-logic';
import { serviceAccountsRoutes } from '@neptune/service-accounts-api';
import { tick } from '@neptune/shared/common-util';
import { OrganizationType } from '@neptune/shared/core-organizations-domain';
import { makeEnterpriseRoute } from '@neptune/shared/enterprise-context-business-logic';
import { convertRouteName, isEnterpriseRouteName } from '@neptune/shared/enterprise-context-util';
import { isV3Pricing } from '@neptune/shared/organization-business-logic';
import { navigateTo } from '@neptune/shared/routing-business-logic';
import { AppRoute } from '@neptune/shared/routing-domain';

import { isOnPremiseDeployment } from 'common/deploymentModes';
import { getContextOrganization, getWorkspaceByName } from 'state/selectors-global';
import { AppState } from 'state/types';
import { getCurrentUserCreated, getCurrentUsername } from 'state/users/current-user/selectors';

const mainRoute: AppRoute = {
  name: 'organization',
  path: '/-/organization',
  onlyLoggedUser: true,
};

const activityRoute: AppRoute = {
  name: 'organization.activity',
  path: '~/:organizationName/-/activity',
  onlyLoggedUser: true,
  canActivate:
    () =>
    ({ name, params }) => {
      return Promise.reject({
        redirect: {
          name: convertRouteName('organization.projects', isEnterpriseRouteName(name)),
          params,
        },
      });
    },
};

const subscriptionRoute: AppRoute = {
  name: 'organization.subscription',
  path: '~/:organizationName/-/subscription',
  canActivate:
    (router, dependencies) =>
    ({ name, params }) => {
      const state: AppState = dependencies?.store.getState();
      const workspace = getWorkspaceByName(state, params.organizationName);

      if (isV3Pricing(workspace?.pricingPlan)) {
        return Promise.reject({
          redirect: {
            name: convertRouteName('organization.payments', isEnterpriseRouteName(name)),
            params,
          },
        });
      }

      return true;
    },
  forwardTo: 'organization.subscription.your-plan',
  onlyLoggedUser: true,
};

const paymentsRoute: AppRoute = {
  name: 'organization.payments',
  path: '~/:organizationName/-/payments',
  canActivate:
    (router, dependencies) =>
    ({ name, params }) => {
      const state: AppState = dependencies?.store.getState();
      const workspace = getWorkspaceByName(state, params.organizationName);

      if (!isV3Pricing(workspace?.pricingPlan)) {
        return Promise.reject({
          redirect: {
            name: convertRouteName('organization.projects', isEnterpriseRouteName(name)),
            params,
          },
        });
      }

      return true;
    },
  onlyLoggedUser: true,
};

const usageRoute: AppRoute = {
  name: 'organization.usage',
  path: '~/:organizationName/-/usage',
  onlyLoggedUser: true,
};

const peopleRoute: AppRoute = {
  name: 'organization.people',
  path: '~/:organizationName/-/people',
  onlyLoggedUser: true,
};

const projectsRoute: AppRoute = {
  name: 'organization.projects',
  path: '~/:organizationName/-/projects',
  onlyLoggedUser: true,
};

const yourPlanRoute: AppRoute = {
  name: 'organization.subscription.your-plan',
  path: '/your-plan',
};

const invoicesRoute: AppRoute = {
  name: 'organization.subscription.invoices',
  path: '/invoices',
};

const settingsRoute: AppRoute = {
  name: 'organization.settings',
  path: '~/:organizationName/-/settings',
};

const subscriptionUsageRoute: AppRoute = {
  name: 'organization.subscription.usage',
  path: '/usage',
};

const workspaceRoute: AppRoute = {
  name: 'workspace',
  path: '/-/workspace/:organizationName',
  onlyLoggedUser: true,
  canActivate:
    () =>
    ({ name, params }) => {
      return Promise.reject({
        redirect: {
          name: convertRouteName('organization.projects', isEnterpriseRouteName(name)),
          params,
        },
      });
    },
};

const onboardingRoute: AppRoute = {
  name: 'organization.onboarding',
  path: '~/:organizationName/-/onboarding',
  onlyLoggedUser: true,
  onActivate(dispatch, getState) {
    return () => {
      tick(() => {
        const organization = getContextOrganization(getState());
        const isTeamOrganization = organization?.organizationType === OrganizationType.team;
        return isTeamOrganization
          ? dispatch(
              navigateTo(
                'workspace',
                {
                  organizationName: organization?.name,
                },
                {
                  forceContext: OrganizationType.team,
                },
              ),
            )
          : dispatch(
              navigateTo('home', {
                forceContext: OrganizationType.individual,
              }),
            );
      }).then(() => {
        const username = getCurrentUsername(getState());
        const userCreated = getCurrentUserCreated(getState());
        const enrollmentDateOfBasicUiTour = new Date('2021-08-19');
        const userSignedUpAfterEnrollment = userCreated
          ? userCreated > enrollmentDateOfBasicUiTour
          : false;

        if (!isOnPremiseDeployment() && username && userSignedUpAfterEnrollment) {
          IntercomTour.triggerTour(username, 'onboarding/day_1/basic_ui_tour');
        }
      });
    };
  },
};

export default [
  mainRoute,
  activityRoute,
  subscriptionRoute,
  paymentsRoute,
  usageRoute,
  peopleRoute,
  projectsRoute,
  settingsRoute,
  workspaceRoute,
  subscriptionUsageRoute,
  invoicesRoute,
  yourPlanRoute,

  {
    ...makeEnterpriseRoute(mainRoute),
    path: '/o/-/organization',
  },
  makeEnterpriseRoute({
    ...activityRoute,
    path: '~/o/:organizationName/-/activity',
  }),
  makeEnterpriseRoute({
    ...subscriptionRoute,
    path: '~/o/:organizationName/-/subscription',
  }),
  makeEnterpriseRoute({
    ...paymentsRoute,
    path: '~/o/:organizationName/-/payments',
  }),
  makeEnterpriseRoute({
    ...usageRoute,
    path: '~/o/:organizationName/-/usage',
  }),
  makeEnterpriseRoute({
    ...peopleRoute,
    path: '~/o/:organizationName/-/people',
  }),
  makeEnterpriseRoute({
    ...projectsRoute,
    path: '~/o/:organizationName/-/projects',
  }),
  makeEnterpriseRoute({
    ...settingsRoute,
    path: '~/o/:organizationName/-/settings',
  }),
  makeEnterpriseRoute({
    ...workspaceRoute,
    path: '~/o/workspace/:organizationName',
  }),
  makeEnterpriseRoute({
    ...onboardingRoute,
    path: '~/o/:organizationName/-/onboarding',
  }),
  makeEnterpriseRoute(subscriptionUsageRoute),
  makeEnterpriseRoute(yourPlanRoute),
  makeEnterpriseRoute(invoicesRoute),
  ...serviceAccountsRoutes,
];

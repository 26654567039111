/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LicenseDTO,
  NeptuneApiToken,
  NewOrganizationDTO,
  NewUsersDTO,
  OrganizationIdDTO,
  OrganizationTraitName,
  OrganizationTraitsSetDTO,
  ProjectIdDTO,
  SetOrganizationMembersLimitDTO,
  UserActionsDTO,
  UserRegistrationDTO,
} from '../models/index';
import {
    LicenseDTOFromJSON,
    LicenseDTOToJSON,
    NeptuneApiTokenFromJSON,
    NeptuneApiTokenToJSON,
    NewOrganizationDTOFromJSON,
    NewOrganizationDTOToJSON,
    NewUsersDTOFromJSON,
    NewUsersDTOToJSON,
    OrganizationIdDTOFromJSON,
    OrganizationIdDTOToJSON,
    OrganizationTraitNameFromJSON,
    OrganizationTraitNameToJSON,
    OrganizationTraitsSetDTOFromJSON,
    OrganizationTraitsSetDTOToJSON,
    ProjectIdDTOFromJSON,
    ProjectIdDTOToJSON,
    SetOrganizationMembersLimitDTOFromJSON,
    SetOrganizationMembersLimitDTOToJSON,
    UserActionsDTOFromJSON,
    UserActionsDTOToJSON,
    UserRegistrationDTOFromJSON,
    UserRegistrationDTOToJSON,
} from '../models/index';

export interface AddProjectMonitoringTimeUsageRequest {
    projectIdentifier: string;
    date: Date;
    hoursAmount: number;
}

export interface AddProjectStorageUsageRequest {
    projectIdentifier: string;
    storageBytes: number;
}

export interface CopyProjectRequest {
    projectIdentifier: string;
    targetOrganizationIdentifier: string;
}

export interface CreateOrUpdateOrganizationMembersLimitRequest {
    organizationIdentifier: string;
    membersLimit: SetOrganizationMembersLimitDTO;
}

export interface CreateOrUpdateOrganizationTraitsRequest {
    organizationIdentifier: string;
    organizationTraits: OrganizationTraitsSetDTO;
}

export interface CreateOrganizationRequest {
    newOrganization: NewOrganizationDTO;
}

export interface CreateUsersRequest {
    newUsers: NewUsersDTO;
}

export interface DeleteOrganizationTraitsRequest {
    organizationIdentifier: string;
    traitNames: Array<OrganizationTraitName>;
}

export interface GetUserApiTokenRequest {
    username: string;
}

export interface MarkProjectAsStockRequest {
    projectIdentifier: string;
    stockType: string;
    stockSupply?: number;
}

export interface MoveProjectRequest {
    projectIdentifier: string;
    targetOrganizationIdentifier: string;
}

export interface RegisterUserRequest {
    userData: UserRegistrationDTO;
}

export interface SetLicenseRequest {
    license: LicenseDTO;
}

export interface SetLoginActionsRequest {
    userData: UserActionsDTO;
}

export interface SynchronizeUsersRequest {
    onlyLdap?: boolean;
    xNeptuneApiToken?: string;
}

export interface UpdateOrganizationRequest {
    organizationIdentifier: string;
    nextTopUpTime?: Date;
    limitsEnforced?: boolean;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async addProjectMonitoringTimeUsageRaw(requestParameters: AddProjectMonitoringTimeUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling addProjectMonitoringTimeUsage().'
            );
        }

        if (requestParameters['date'] == null) {
            throw new runtime.RequiredError(
                'date',
                'Required parameter "date" was null or undefined when calling addProjectMonitoringTimeUsage().'
            );
        }

        if (requestParameters['hoursAmount'] == null) {
            throw new runtime.RequiredError(
                'hoursAmount',
                'Required parameter "hoursAmount" was null or undefined when calling addProjectMonitoringTimeUsage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['date'] != null) {
            queryParameters['date'] = (requestParameters['date'] as any).toISOString();
        }

        if (requestParameters['hoursAmount'] != null) {
            queryParameters['hoursAmount'] = requestParameters['hoursAmount'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/admin/v1/projects/tracking`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addProjectMonitoringTimeUsage(requestParameters: AddProjectMonitoringTimeUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addProjectMonitoringTimeUsageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async addProjectStorageUsageRaw(requestParameters: AddProjectStorageUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling addProjectStorageUsage().'
            );
        }

        if (requestParameters['storageBytes'] == null) {
            throw new runtime.RequiredError(
                'storageBytes',
                'Required parameter "storageBytes" was null or undefined when calling addProjectStorageUsage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['storageBytes'] != null) {
            queryParameters['storageBytes'] = requestParameters['storageBytes'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/admin/v1/projects/storage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addProjectStorageUsage(requestParameters: AddProjectStorageUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addProjectStorageUsageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async copyProjectRaw(requestParameters: CopyProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectIdDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling copyProject().'
            );
        }

        if (requestParameters['targetOrganizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'targetOrganizationIdentifier',
                'Required parameter "targetOrganizationIdentifier" was null or undefined when calling copyProject().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['targetOrganizationIdentifier'] != null) {
            queryParameters['targetOrganizationIdentifier'] = requestParameters['targetOrganizationIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/admin/v1/projects/copy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectIdDTOFromJSON(jsonValue));
    }

    /**
     */
    async copyProject(requestParameters: CopyProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectIdDTO> {
        const response = await this.copyProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createOrUpdateOrganizationMembersLimitRaw(requestParameters: CreateOrUpdateOrganizationMembersLimitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling createOrUpdateOrganizationMembersLimit().'
            );
        }

        if (requestParameters['membersLimit'] == null) {
            throw new runtime.RequiredError(
                'membersLimit',
                'Required parameter "membersLimit" was null or undefined when calling createOrUpdateOrganizationMembersLimit().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/admin/v1/organizations/limits/members`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetOrganizationMembersLimitDTOToJSON(requestParameters['membersLimit']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createOrUpdateOrganizationMembersLimit(requestParameters: CreateOrUpdateOrganizationMembersLimitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createOrUpdateOrganizationMembersLimitRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createOrUpdateOrganizationTraitsRaw(requestParameters: CreateOrUpdateOrganizationTraitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling createOrUpdateOrganizationTraits().'
            );
        }

        if (requestParameters['organizationTraits'] == null) {
            throw new runtime.RequiredError(
                'organizationTraits',
                'Required parameter "organizationTraits" was null or undefined when calling createOrUpdateOrganizationTraits().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/admin/v1/organizations/traits`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationTraitsSetDTOToJSON(requestParameters['organizationTraits']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createOrUpdateOrganizationTraits(requestParameters: CreateOrUpdateOrganizationTraitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createOrUpdateOrganizationTraitsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createOrganizationRaw(requestParameters: CreateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationIdDTO>> {
        if (requestParameters['newOrganization'] == null) {
            throw new runtime.RequiredError(
                'newOrganization',
                'Required parameter "newOrganization" was null or undefined when calling createOrganization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/admin/v1/organizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewOrganizationDTOToJSON(requestParameters['newOrganization']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationIdDTOFromJSON(jsonValue));
    }

    /**
     */
    async createOrganization(requestParameters: CreateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationIdDTO> {
        const response = await this.createOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createUsersRaw(requestParameters: CreateUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['newUsers'] == null) {
            throw new runtime.RequiredError(
                'newUsers',
                'Required parameter "newUsers" was null or undefined when calling createUsers().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/admin/v1/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewUsersDTOToJSON(requestParameters['newUsers']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createUsers(requestParameters: CreateUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createUsersRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteOrganizationTraitsRaw(requestParameters: DeleteOrganizationTraitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling deleteOrganizationTraits().'
            );
        }

        if (requestParameters['traitNames'] == null) {
            throw new runtime.RequiredError(
                'traitNames',
                'Required parameter "traitNames" was null or undefined when calling deleteOrganizationTraits().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/admin/v1/organizations/traits`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['traitNames']!.map(OrganizationTraitNameToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteOrganizationTraits(requestParameters: DeleteOrganizationTraitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOrganizationTraitsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getUserApiTokenRaw(requestParameters: GetUserApiTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NeptuneApiToken>> {
        if (requestParameters['username'] == null) {
            throw new runtime.RequiredError(
                'username',
                'Required parameter "username" was null or undefined when calling getUserApiToken().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/admin/users/{username}/api-token`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters['username']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NeptuneApiTokenFromJSON(jsonValue));
    }

    /**
     */
    async getUserApiToken(requestParameters: GetUserApiTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NeptuneApiToken> {
        const response = await this.getUserApiTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async markProjectAsStockRaw(requestParameters: MarkProjectAsStockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling markProjectAsStock().'
            );
        }

        if (requestParameters['stockType'] == null) {
            throw new runtime.RequiredError(
                'stockType',
                'Required parameter "stockType" was null or undefined when calling markProjectAsStock().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['stockType'] != null) {
            queryParameters['stockType'] = requestParameters['stockType'];
        }

        if (requestParameters['stockSupply'] != null) {
            queryParameters['stockSupply'] = requestParameters['stockSupply'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/admin/v1/projects/stock`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markProjectAsStock(requestParameters: MarkProjectAsStockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markProjectAsStockRaw(requestParameters, initOverrides);
    }

    /**
     */
    async moveProjectRaw(requestParameters: MoveProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling moveProject().'
            );
        }

        if (requestParameters['targetOrganizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'targetOrganizationIdentifier',
                'Required parameter "targetOrganizationIdentifier" was null or undefined when calling moveProject().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['targetOrganizationIdentifier'] != null) {
            queryParameters['targetOrganizationIdentifier'] = requestParameters['targetOrganizationIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/admin/v1/projects/move`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async moveProject(requestParameters: MoveProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.moveProjectRaw(requestParameters, initOverrides);
    }

    /**
     */
    async registerUserRaw(requestParameters: RegisterUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['userData'] == null) {
            throw new runtime.RequiredError(
                'userData',
                'Required parameter "userData" was null or undefined when calling registerUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/admin/users/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRegistrationDTOToJSON(requestParameters['userData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async registerUser(requestParameters: RegisterUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.registerUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setLicenseRaw(requestParameters: SetLicenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['license'] == null) {
            throw new runtime.RequiredError(
                'license',
                'Required parameter "license" was null or undefined when calling setLicense().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/license`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LicenseDTOToJSON(requestParameters['license']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setLicense(requestParameters: SetLicenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setLicenseRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setLoginActionsRaw(requestParameters: SetLoginActionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['userData'] == null) {
            throw new runtime.RequiredError(
                'userData',
                'Required parameter "userData" was null or undefined when calling setLoginActions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/admin/users/actions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserActionsDTOToJSON(requestParameters['userData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setLoginActions(requestParameters: SetLoginActionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setLoginActionsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async synchronizeUsersRaw(requestParameters: SynchronizeUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['onlyLdap'] != null) {
            queryParameters['onlyLdap'] = requestParameters['onlyLdap'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xNeptuneApiToken'] != null) {
            headerParameters['X-Neptune-Api-Token'] = String(requestParameters['xNeptuneApiToken']);
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/admin/users/synchronize`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async synchronizeUsers(requestParameters: SynchronizeUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.synchronizeUsersRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateOrganizationRaw(requestParameters: UpdateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling updateOrganization().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        if (requestParameters['nextTopUpTime'] != null) {
            queryParameters['nextTopUpTime'] = (requestParameters['nextTopUpTime'] as any).toISOString();
        }

        if (requestParameters['limitsEnforced'] != null) {
            queryParameters['limitsEnforced'] = requestParameters['limitsEnforced'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/admin/v1/organizations`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateOrganization(requestParameters: UpdateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateOrganizationRaw(requestParameters, initOverrides);
    }

}

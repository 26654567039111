import React from 'react';

import { ProjectRole } from '@neptune/shared/core-project-domain';
import { RoleDropdown } from '@neptune/shared/user-management-ui';
import { Button, Layout, Section } from '@neptune/shared/venus-ui';

export type AddServiceAccountProps = {
  availableRoles: ProjectRole[];
  'data-role'?: string;
  disabled?: boolean;
  organizationName: string;
  onSelectRole(role: ProjectRole): void;
  onSubmit(): void;
  role: ProjectRole;
  roleLabels: Record<ProjectRole, string>;
  title: string;
  serviceAccountSelect: React.ReactElement;
};

export const AddServiceAccount: React.FC<AddServiceAccountProps> = ({
  availableRoles,
  'data-role': dataRole,
  disabled,
  onSelectRole,
  onSubmit,
  role,
  roleLabels,
  serviceAccountSelect,
  title,
}) => {
  return (
    <Layout.Column data-role={dataRole} spacedChildren="md" span="shrink">
      <Section.Title>{title}</Section.Title>
      <Layout.Row alignItems="center" span="auto" spacedChildren="md">
        <Layout.Element width={400}>{serviceAccountSelect}</Layout.Element>
        <Layout.Row width={130} span="auto">
          <RoleDropdown
            roleLabels={roleLabels}
            availableRoles={availableRoles}
            value={role}
            onSelect={onSelectRole}
          />
        </Layout.Row>
        <Button
          disabled={disabled}
          size="lg"
          data-role="add-service-account-button"
          onClick={onSubmit}
        >
          Assign
        </Button>
      </Layout.Row>
    </Layout.Column>
  );
};

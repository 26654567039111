import React from 'react';

import { EmptyView, InfoTooltip, PaymentsLink } from '@neptune/shared/common-ui';
import { Icon, Link } from '@neptune/shared/venus-ui';

export const ArchiveProjectEmptyView: React.FC<{
  organizationName: string;
  canUnarchive?: boolean;
  onUnarchive?: () => void;
  shouldUpgradePlanHint?: boolean;
  archiveProjectDocumentationLink: string;
}> = ({
  organizationName,
  canUnarchive,
  onUnarchive,
  shouldUpgradePlanHint,
  archiveProjectDocumentationLink,
}) => {
  const button = onUnarchive ? (
    <InfoTooltip
      show={!canUnarchive}
      text={
        shouldUpgradePlanHint ? (
          "You've reached the limit of active projects set for this workspace. Upgrade your subscription to be able to increase the limit of active projects in Billing & payments section of your Workspace settings."
        ) : (
          <span>
            You've reached the limit of active projects set for this workspace. Increase the limit
            in <PaymentsLink organizationName={organizationName} children="Billing & payments" />{' '}
            section of your Workspace settings.
          </span>
        )
      }
    >
      <EmptyView.ActionButton
        disabled={!canUnarchive}
        label="Unarchive this project"
        onClick={onUnarchive}
      />
    </InfoTooltip>
  ) : undefined;

  const subTitleSection = (
    <span>
      To view the contents or log more metadata, unarchive the project.
      <br />
      Learn more about{' '}
      <Link href={archiveProjectDocumentationLink} external>
        {' '}
        archiving projects&nbsp;
        <Icon glyph="external-link" />
      </Link>
    </span>
  );

  return (
    <EmptyView
      variant="default"
      title="This project is archived"
      subtitle={subTitleSection}
      icon="box-archive"
      action={button}
      data-role="archived-project"
    />
  );
};

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router5';
import { Store } from 'redux';
import type { Router } from 'router5';

import { NavigateToParams } from '@neptune/invitations-business-logic';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { BulkAcceptInvitationContainer } from '@neptune/invitations-feature';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ServerErrorModal } from '@neptune/shared/legacy-feature';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { NormalizedLinkProvider } from '@neptune/shared/routing-feature';

/** @note: not sure it is the best possible use */
export function renderInvitations(
  store: Store,
  router: Router,
  onFinish: (
    value: PromiseLike<NavigateToParams | undefined> | NavigateToParams | undefined,
  ) => void,
  container: HTMLElement | null,
) {
  render(
    <Provider store={store}>
      <RouterProvider router={router}>
        <NormalizedLinkProvider>
          <ServerErrorModal />
          <BulkAcceptInvitationContainer onFinish={onFinish} />
        </NormalizedLinkProvider>
      </RouterProvider>
    </Provider>,
    container,
  );
}

import React from 'react';
import { isNumber } from 'lodash';

import { InvitationType } from '@neptune/invitations-domain';
import { bemBlock, Button, Layout, SimpleTooltip, Text } from '@neptune/shared/venus-ui';
import { AddToAllProjects, RoleDropdownItemProps } from '@neptune/user-management-ui';

import { SelectMember } from './SelectMember';

import './AddMember.less';

export enum AddMemberContext {
  privateProject = 'privateProject',
  workspaceProject = 'workspaceProject',
  workspace = 'workspace',
}

type AddMemberProps<T extends string> = {
  defaultRole: T;
  context: AddMemberContext;
  invitesLeft?: number;
  filterOptions?: {
    organizationIdentifier?: string;
    projectIdentifier?: string;
  };
  isAddingBlocked: boolean;
  isAddingBlockedReason?: string;
  onSubmit?: (params: {
    invitee: string;
    invitationType: InvitationType;
    roleGrant: T;
    addToAllProjects: boolean;
  }) => void;
  renderRoleDropdown: React.FunctionComponent<RoleDropdownItemProps<T>>;
  showAddToAllProjects?: boolean;
};

const actionCopy: Record<AddMemberContext, string> = {
  [AddMemberContext.privateProject]: 'Add to project',
  [AddMemberContext.workspaceProject]: 'Add to workspace',
  [AddMemberContext.workspace]: 'Invite',
};

const block = bemBlock('add-member');

export const AddMember = <T extends string>({
  defaultRole,
  onSubmit,
  filterOptions,
  isAddingBlocked,
  isAddingBlockedReason,
  invitesLeft,
  context,
  renderRoleDropdown,
  showAddToAllProjects,
}: AddMemberProps<T>) => {
  const [invitee, setInvitee] = React.useState('');
  const [roleGrant, setRoleGrant] = React.useState(defaultRole);
  const [isFormValid, setIsFormValid] = React.useState(true);
  const [addToAllProjects, setAddToAllProjects] = React.useState(false);

  const onAddToAllProjectsChange = React.useCallback(
    (ev, { checked }) => setAddToAllProjects(checked),
    [],
  );
  const onMemberChange = React.useCallback((username?: string) => setInvitee(username || ''), []);
  const onValidationChange = setIsFormValid;
  const onRoleChange = setRoleGrant;
  const handleSubmit = React.useCallback(() => {
    const invitationType = invitee.includes('@') ? InvitationType.email : InvitationType.user;
    onSubmit?.({ invitee, roleGrant, addToAllProjects, invitationType });
    setInvitee('');
  }, [onSubmit, invitee, roleGrant, addToAllProjects]);

  const action = actionCopy[context];

  const roleDropdown = renderRoleDropdown({
    value: roleGrant,
    disabled: isAddingBlocked,
    onSelect: (role?: T) => role && onRoleChange(role),
  });

  return (
    <Layout.Column
      className={block()}
      span="auto"
      spacedChildren="md"
      data-role="add-member-section"
    >
      <Layout.Column span="auto" spacedChildren="xs">
        <Layout.Row alignItems="center" span="auto" spacedChildren="md">
          <SelectMember
            selected={invitee}
            allowMails
            placeholder="Type username or e-mail"
            filterOptions={filterOptions}
            disabled={isAddingBlocked}
            onChange={onMemberChange}
            onValidationChange={onValidationChange}
          />
          <Layout.Row width={130} span="auto">
            {roleDropdown}
          </Layout.Row>
          {showAddToAllProjects && (
            <AddToAllProjects
              role={roleGrant}
              checked={addToAllProjects}
              onChange={onAddToAllProjectsChange}
            />
          )}
          <SimpleTooltip content={isAddingBlocked ? isAddingBlockedReason : undefined}>
            <Button
              disabled={invitee === '' || !isFormValid || isAddingBlocked}
              size="lg"
              data-role="add-member-action"
              onClick={handleSubmit}
            >
              {action}
            </Button>
          </SimpleTooltip>

          <Layout.Fill />
          {isNumber(invitesLeft) ? (
            <Text color="text-secondary" size="base">
              Invites left: {invitesLeft}
            </Text>
          ) : null}
        </Layout.Row>
      </Layout.Column>
    </Layout.Column>
  );
};

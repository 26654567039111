import { isEmpty } from 'lodash';
import { actionTypes as routerActionTypes } from 'redux-router5';
import { getNormalizedMultipleURLParam } from 'router/router-utils';

import { fetchStatus } from 'state/fetch-status';
import { TransitionSuccessAction } from 'state/types';

import { GroupActions, LeaderboardGroupPreviewActionTypes } from './actions';

export type FetchStatusMap = {
  [key: string]: fetchStatus;
};

export interface GroupPreviewState {
  openedGroups: { openedGroup: string; continuationToken?: string; beforeToken?: string }[];
  fetchStatusMap: FetchStatusMap;
}

export const groupPreviewInitialState: GroupPreviewState = {
  openedGroups: [],
  fetchStatusMap: {},
};

export type GroupPreviewsState = Record<string, GroupPreviewState | undefined>;

const groupPreviewsInitialState = {};

const groupPreviewReducer = (
  state: GroupPreviewState = groupPreviewInitialState,
  action: GroupActions | TransitionSuccessAction,
): GroupPreviewState => {
  switch (action.type) {
    case routerActionTypes.TRANSITION_SUCCESS: {
      const currentRoute = action.payload.route;
      const previousRoute = action.payload.previousRoute;

      if (!previousRoute || !currentRoute) {
        return state;
      }

      const currentGroupByParams = getNormalizedMultipleURLParam(currentRoute.params.groupBy);
      const previousGroupByParams = getNormalizedMultipleURLParam(previousRoute.params.groupBy);

      const routeChanged = currentRoute.name !== previousRoute.name;
      const groupByColumnsRemoved =
        !isEmpty(previousGroupByParams) && isEmpty(currentGroupByParams);

      if (routeChanged || groupByColumnsRemoved) {
        return { ...groupPreviewInitialState };
      }

      return state;
    }

    case LeaderboardGroupPreviewActionTypes.request: {
      return {
        ...state,
        openedGroups: [
          ...state.openedGroups.filter(
            ({ openedGroup }) => openedGroup !== action.payload.groupIdentifier,
          ),
          {
            openedGroup: action.payload.groupIdentifier,
            ...action.payload.paginationTarget,
          },
        ],
        fetchStatusMap: {
          ...state.fetchStatusMap,
          [action.payload.groupIdentifier]: fetchStatus.PENDING,
        },
      };
    }

    case LeaderboardGroupPreviewActionTypes.success: {
      return {
        ...state,
        fetchStatusMap: {
          ...state.fetchStatusMap,
          [action.payload.groupIdentifier]: fetchStatus.SUCCESS,
        },
      };
    }

    case LeaderboardGroupPreviewActionTypes.fail: {
      return {
        ...state,
        openedGroups: state.openedGroups.filter(
          ({ openedGroup }) => openedGroup !== action.payload.groupIdentifier,
        ),
        fetchStatusMap: {
          ...state.fetchStatusMap,
          [action.payload.groupIdentifier]: fetchStatus.FAILED,
        },
      };
    }

    case LeaderboardGroupPreviewActionTypes.closeGroup: {
      return {
        ...state,
        openedGroups: state.openedGroups.filter(
          ({ openedGroup }) => openedGroup !== action.payload.groupId,
        ),
      };
    }

    default:
      return state;
  }
};

export const groupPreviewsReducer = (
  state: GroupPreviewsState = groupPreviewsInitialState,
  action: GroupActions | TransitionSuccessAction,
) => {
  if (action.type === routerActionTypes.TRANSITION_SUCCESS) {
    return Object.entries(state).reduce<GroupPreviewsState>((acc, [id, state]) => {
      acc[id] = groupPreviewReducer(state, action);
      return acc;
    }, {});
  }

  if (action.payload?.leaderboardIdentifier) {
    const oldSubstate = state[action.payload.leaderboardIdentifier];
    const newSubstate = groupPreviewReducer(state[action.payload.leaderboardIdentifier], action);

    if (oldSubstate === newSubstate) {
      return state;
    }

    return {
      ...state,
      [action.payload.leaderboardIdentifier]: newSubstate,
    };
  }

  return state;
};

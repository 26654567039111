import { isObject } from 'lodash';

import { OrganizationRole, OrganizationWithRole } from '@neptune/shared/core-organizations-domain';

const minimalOrganizationRolesMapping: Record<OrganizationRole, OrganizationRole[]> = {
  [OrganizationRole.owner]: [OrganizationRole.owner],
  [OrganizationRole.member]: [OrganizationRole.owner, OrganizationRole.member],
  [OrganizationRole.notSupported]: [],
};

export function hasAtLeastRole(
  organization: OrganizationWithRole | undefined,
  minimalRole: OrganizationRole,
) {
  if (!isObject(organization)) {
    return false;
  }

  return minimalOrganizationRolesMapping[minimalRole].includes(organization.userRole);
}

const roleWeights: Record<OrganizationRole, number> = {
  [OrganizationRole.owner]: 1,
  [OrganizationRole.member]: 0,

  [OrganizationRole.notSupported]: -1,
};

export function organizationRoleComparator(a: OrganizationRole, b: OrganizationRole) {
  return roleWeights[b] - roleWeights[a];
}

import { ExpandableLayoutConverter, LayoutType } from '@neptune/shared/common-domain';

import { storage } from 'common/storage';

export type ExpandableLayoutState = Record<
  string,
  Record<string, LayoutType | undefined> | undefined
>;

const localStorageKey = 'expandableLayout';

export const getInitialState = (): ExpandableLayoutState => {
  const state = storage.local.getItem(localStorageKey);

  if (!state) {
    return {};
  }

  return Object.keys(state).reduce((result: ExpandableLayoutState, key) => {
    const value = state[key];

    if (value) {
      result[key] = Object.keys(value).reduce((acc: Record<string, LayoutType>, current) => {
        if (value[current]) {
          acc[current] = ExpandableLayoutConverter.fromLocalStorageToDomain(
            value[current],
            LayoutType.Mosaic,
          );
        }

        return acc;
      }, {});
    }

    return result;
  }, {});
};

export const storeState = (state: ExpandableLayoutState) => {
  storage.local.setItem(localStorageKey, state);
};

import React from 'react';

import { bemBlock, Layout, Text } from '@neptune/shared/venus-ui';

import {
  ApiTokenLayoutComponentProps,
  ApiTokenRenderer,
  ApiTokenRendererProps,
} from '../api-token-renderer/ApiTokenRenderer';

import './ApiTokenSection.less';

type ApiTokenSectionProps = Omit<ApiTokenRendererProps, 'layout'>;

const block = bemBlock('api-token-section');

const SectionLayout: React.FC<ApiTokenLayoutComponentProps> = ({
  apiTokenText,
  copyToClipboardButton,
  showTokenIcon,
}) => (
  <Layout.Column>
    <Layout.Row>
      <Text fontWeight="bold">API Token</Text>
      <Layout.Fill />
      {showTokenIcon}
    </Layout.Row>
    <Layout.Row className={block('token')} overflow="hidden" alignItems="center">
      <Layout.Row className={block('container')} span="shrink">
        {apiTokenText}
        {copyToClipboardButton}
      </Layout.Row>
    </Layout.Row>
  </Layout.Column>
);

export const ApiTokenSection: React.FC<ApiTokenSectionProps> = (props) => {
  return <ApiTokenRenderer layout={SectionLayout} {...props} />;
};

// TODO: make this lib non-shared
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SearchQueryModelConverter } from '@neptune/search-query-domain';
import { GroupByField, searchLeaderboard } from '@neptune/shared/leaderboard-domain';

import { createSelectionQuery } from 'common/query';
import { KnownAttributes } from 'domain/experiment/attribute';
import { createAsyncActions } from 'state/async-actions';

import config from 'config';

export enum LeaderboardGroupCompareActionTypes {
  request = 'LEADERBOARD_GROUP_COMPARE_FETCH',
  success = 'LEADERBOARD_GROUP_COMPARE_FETCH_SUCCESS',
  fail = 'LEADERBOARD_GROUP_COMPARE_FETCH_FAIL',
}

const COMPARE_LIMIT = config.compareRunsLimit ?? 250;

const groupCompareActions = createAsyncActions({
  types: LeaderboardGroupCompareActionTypes,
  resolver: async ({
    projectIdentifier,
    selectedIds,
    type,
    query,
    groupBy,
    leaderboardIdentifier,
    openedGroupIds = [],
    experimentsOnly,
  }: {
    projectIdentifier: string;
    selectedIds: string[];
    type?: string[];
    query?: string;
    groupBy: GroupByField[];
    leaderboardIdentifier: string;
    openedGroupIds?: string[];
    experimentsOnly?: boolean;
  }): Promise<{
    created: number;
    leaderboardIdentifier: string;
    groupCount: { id: string; itemCount: number }[];
    runIdsInGroup: Record<string, string[]>;
  }> => {
    const created = Date.now();

    if (selectedIds.length === 0 || !groupBy?.length) {
      return { created, leaderboardIdentifier, groupCount: [], runIdsInGroup: {} };
    }

    const onlySelectedIds = createSelectionQuery(selectedIds);
    const querySelected = query
      ? SearchQueryModelConverter.convertSearchQueryToNql({
          criteria: [
            SearchQueryModelConverter.convertNqlToSearchQuery(query),
            SearchQueryModelConverter.convertNqlToSearchQuery(onlySelectedIds),
          ],
          operator: 'and',
        })
      : onlySelectedIds;

    const { groups = [] } = await searchLeaderboard({
      projectIdentifier,
      type,
      query: querySelected,
      grouping: {
        groupBy,
        openedGroups: openedGroupIds.map((id) => ({
          openedGroup: id,
          pagination: { limit: COMPARE_LIMIT, offset: 0 },
        })),
      },
      pagination: { offset: 0, limit: 10_000 }, // all pages
      attributesToFetch: [KnownAttributes.Id], // we don't need here to fetch any fields, but sth has to be fetched
      experimentsOnly,
    });

    const runIdsInGroup = groups.reduce((acc: Record<string, string[]>, group) => {
      acc[group.id] = group.childrenIds;

      return acc;
    }, {});

    const groupCount = groups.map(({ id, itemCount }) => ({ id, itemCount }));

    return {
      created,
      leaderboardIdentifier,
      groupCount,
      runIdsInGroup,
    };
  },
});

export const { execute: fetchGroupCompareStatus } = groupCompareActions;

export type GroupCompareActions = ReturnType<
  | typeof groupCompareActions.request
  | typeof groupCompareActions.success
  | typeof groupCompareActions.fail
>;

import React from 'react';

import { createErrorDescriptor } from 'common/error-handler';
import { fetchStatus, isFetchStatusFailed } from 'state/fetch-status';

import { HttpErrorPageContainer } from './HttpErrorPageContainer';

type UseNotFoundInput = {
  fetchStatus: fetchStatus;
  error: unknown;
};

type UseNotFoundValue = React.ComponentType | undefined;

export function useNotFound({ fetchStatus, error }: UseNotFoundInput): UseNotFoundValue {
  if (!isFetchStatusFailed(fetchStatus)) {
    return;
  }

  const errorDescriptor = createErrorDescriptor(error);

  if (errorDescriptor.code === 404) {
    return () => React.createElement(HttpErrorPageContainer, { error: errorDescriptor });
  }
}

// Libs
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// App
import { getConfirmationModalDataByType, getConfirmationModalStateByType } from 'state/selectors';
import {
  closeConfirmationModal,
  openConfirmationModal,
} from 'state/ui/global/confirmation-modal/actions';

// Module
import { ModalHookOutput } from './types';

export function useConfirmationModal<T = any>(
  modalName: string,
  modalData?: T,
): ModalHookOutput<T> {
  const isOpen = useSelector((state) => getConfirmationModalStateByType(state, modalName));
  const data: T = useSelector((state) => getConfirmationModalDataByType(state, modalName));
  const dispatch = useDispatch();

  const open = useCallback(
    () => dispatch(openConfirmationModal(modalName, modalData)),
    [dispatch, modalName, modalData],
  );
  const openWithData = useCallback(
    (data: T) => dispatch(openConfirmationModal(modalName, data)),
    [dispatch, modalName],
  );
  const close = useCallback(
    () => dispatch(closeConfirmationModal(modalName)),
    [dispatch, modalName],
  );

  return {
    isOpen: !!isOpen,
    data,
    open,
    openWithData,
    close,
  };
}

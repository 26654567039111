import React from 'react';

import { collapseSiblingRe, markGroupsRe } from '@neptune/shared/common-domain';

export const FilterSearchMatch: React.FC<{ text: string }> = ({ text }) => {
  const pairs = [...text.replace(collapseSiblingRe, '').matchAll(markGroupsRe)]
    .filter(([, normal, matching]) => normal || matching)
    .map(([, normal, matching]) => ({ normal, matching }));

  return (
    <span data-role="filter-search-match">
      {pairs.map(({ normal, matching }, index) => (
        <span key={index}>
          {normal}
          {matching && <mark>{matching}</mark>}
        </span>
      ))}
    </span>
  );
};

import React from 'react';

import { UserAvatar } from '@neptune/shared/avatars-ui';
import { bemBlock, Layout, Link } from '@neptune/shared/venus-ui';

import { UserProfileLink } from 'components/user-panel/user-profile-link/UserProfileLink';

import './PersonRenderer.less';

type PersonRendererProps = {
  name: string;
};

const block = bemBlock('person-renderer');

export const PersonRenderer: React.FC<PersonRendererProps> = ({ name }) => {
  return (
    <Layout.Row span="auto" spacedChildren="xs" data-role="person-link">
      <UserProfileLink
        userName={name}
        onClick={(event: React.MouseEvent) => event.stopPropagation()}
      >
        <UserAvatar className={block('avatar')} username={name} size="xs" />
      </UserProfileLink>
      <UserProfileLink
        userName={name}
        component={Link}
        children={name}
        onClick={(event: React.MouseEvent) => event.stopPropagation()}
      />
    </Layout.Row>
  );
};

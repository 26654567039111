import { KnownAttributes } from 'domain/experiment/attribute';

import { LeaderboardSortingParams, SortOptions as LegacySortOptions } from './sort-options-model';

export const STANDARD_SORTING_OPTIONS: LegacySortOptions = {
  sortBy: [KnownAttributes.CreationTime],
  sortDirection: ['descending'],
  sortFieldType: ['datetime'],
  sortFieldAggregationMode: ['auto'],
};

export const DEFAULT_SORT_OPTIONS: LeaderboardSortingParams = {
  dir: 'descending',
  sortBy: {
    name: KnownAttributes.CreationTime,
    type: 'datetime',
    aggregationMode: 'auto',
  },
};

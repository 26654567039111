// Libs
import React from 'react';

import { useGenericModal } from '@neptune/shared/common-business-logic';
import { Dialog } from '@neptune/shared/venus-ui';

// App

// Module
export const MODAL_NAME = 'service-accounts-limit-modal';

type ServiceAccountsLimitModalProps = {
  limit?: number;
};

export const ServiceAccountsLimitModal: React.FC<ServiceAccountsLimitModalProps> = ({ limit }) => {
  const { isOpen, close } = useGenericModal(MODAL_NAME);

  if (!limit) {
    return null;
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      onPrimaryButtonClick={close}
      title="Can't create service account"
      children={`You reached the limit of service accounts in your workspace (${limit}).`}
      role="sa-limit-modal"
    />
  );
};

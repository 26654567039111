// Libs
import React from 'react';
import { useSelector } from 'react-redux';

import { Modal } from '@neptune/shared/venus-ui';

// App
import { getOrganizationNameFromRouteParams } from 'state/selectors-global';

// Module
import { WorkspaceCreateServiceAccountFormContainer } from './WorkspaceCreateServiceAccountFormContainer';

type WorkspaceCreateServiceAccountModalContainerProps = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(): void;
};

export const WorkspaceCreateServiceAccountModalContainer: React.FC<
  WorkspaceCreateServiceAccountModalContainerProps
> = ({ isOpen, onClose, onSubmit }) => {
  const organizationName = useSelector(getOrganizationNameFromRouteParams);
  const renderFields: React.FC = ({ children }) => <Modal.Body>{children}</Modal.Body>;

  return (
    <Modal isOpen={isOpen} data={{ role: 'create-service-account-modal' }}>
      <Modal.Header title="Create new service account" onClose={onClose} />
      <WorkspaceCreateServiceAccountFormContainer
        organizationName={organizationName}
        renderFields={renderFields}
        renderSubmit={({ isValid }: { isValid?: boolean }) => (
          <Modal.Footer>
            <Modal.Button
              type="submit"
              variant="primary"
              data-role="create-service-account-submit"
              disabled={!isValid}
              children="Create"
            />
            <Modal.Button variant="basic" onClick={onClose} children="Cancel" />
          </Modal.Footer>
        )}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

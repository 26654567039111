import React from 'react';
import { useDispatch } from 'react-redux';

import {
  reactivateServiceAccount,
  ServiceAccountReactivateData,
} from '@neptune/service-accounts-domain';
import { ReactivateServiceAccountModal } from '@neptune/service-accounts-ui';
import { useGenericModal } from '@neptune/shared/common-business-logic';

import { showServerErrorModal } from 'state/ui/modals/server-error/actions';

type ReactivateServiceAccountModalContainerProps = {
  onSubmit(): void;
};

export const REACTIVATE_SERVICE_ACCOUNT_MODAL_NAME = 'REACTIVATE_SERVICE_ACCOUNT_MODAL';

export const ReactivateServiceAccountModalContainer: React.FC<
  ReactivateServiceAccountModalContainerProps
> = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const { isOpen, close, data } = useGenericModal<ServiceAccountReactivateData | undefined>(
    REACTIVATE_SERVICE_ACCOUNT_MODAL_NAME,
  );

  const handleSubmit = React.useCallback(async () => {
    if (!data) {
      return;
    }

    try {
      await reactivateServiceAccount(data.organizationId, data.id);
      close();
      onSubmit();
    } catch {
      dispatch(showServerErrorModal());
    }
  }, [close, data, dispatch, onSubmit]);

  if (!data) {
    return null;
  }

  return (
    <ReactivateServiceAccountModal
      isOpen={isOpen}
      onClose={close}
      onSubmit={handleSubmit}
      serviceAccount={data}
    />
  );
};

import React from 'react';
import { uniq } from 'lodash';

// todo this lib should not be shared (= it should be explicitly be depended by 3 specific object table instances)
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useSearchQuery } from '@neptune/object-table-search-business-logic';
// todo npt-13160: move this lib outside of shared
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SearchQuery, SearchQueryModelConverter } from '@neptune/search-query-domain';
import { useColumnsContext } from '@neptune/shared/columns-business-logic';
import { LeaderboardContext } from '@neptune/shared/entity-leaderboard-domain';
import {
  useExperimentsOnlyOptions,
  useGroupBy,
  useLeaderboardPagination,
  useLeaderboardPolling,
  useSortOptions,
  useSuggestionsOptions,
} from '@neptune/shared/leaderboard-business-logic';
import {
  isColumnExportable,
  LeaderboardGrouping,
  searchLeaderboard,
  SearchLeaderboardRequest,
} from '@neptune/shared/leaderboard-domain';

import { knownAttributes } from 'domain/experiment/attribute';

import { buildSearchLeaderboardMetaQuery } from './buildSearchLeaderboardQuery';

type UseEntityLeaderboardPollingParams = {
  grouping?: LeaderboardGrouping;
  selectedEntities?: string[];
  enabled?: boolean;
};

export const useEntityLeaderboardPolling = ({
  grouping,
  selectedEntities,
  enabled,
}: UseEntityLeaderboardPollingParams = {}) => {
  const { entityId, entityType, trash, id, projectIdentifier, types } =
    React.useContext(LeaderboardContext);

  const { columns } = useColumnsContext();
  const suggestions = useSuggestionsOptions();
  const { metaQuery } = useSearchQuery();
  const [sorting] = useSortOptions();
  const { pagination } = useLeaderboardPagination(id);
  const [experimentsOnlyEnabled] = useExperimentsOnlyOptions();
  const { offset, limit } = pagination;

  const queryPagination = React.useMemo(() => ({ offset, limit }), [offset, limit]);

  const baseQuery = React.useMemo(
    () => buildSearchLeaderboardMetaQuery(entityType, entityId, trash, selectedEntities),
    [entityId, entityType, trash, selectedEntities],
  );

  const query: SearchQuery = React.useMemo(
    () =>
      metaQuery.criteria.length
        ? {
            criteria: [baseQuery, metaQuery],
            operator: 'and',
          }
        : baseQuery,
    [baseQuery, metaQuery],
  );
  const attributesToFetch = React.useMemo(
    () =>
      uniq([
        ...knownAttributes,
        ...(grouping?.groupBy ?? []).map(({ name }) => name),
        ...columns.map(({ id }) => id).filter(isColumnExportable),
      ]),
    [columns, grouping?.groupBy],
  );

  const sortOptionsHash = JSON.stringify(sorting);
  const groupingHash = JSON.stringify(grouping);
  const searchParams: SearchLeaderboardRequest = React.useMemo(
    () => ({
      projectIdentifier,
      query: SearchQueryModelConverter.convertSearchQueryToNql(query),
      type: types,
      grouping,
      sorting,
      pagination: queryPagination,
      suggestions,
      attributesToFetch,
      experimentsOnly: experimentsOnlyEnabled,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      projectIdentifier,
      query,
      types,
      groupingHash,
      sortOptionsHash,
      queryPagination,
      suggestions,
      attributesToFetch,
      experimentsOnlyEnabled,
    ],
  );

  const { updateGroupCompare } = useGroupBy();

  const resolver = React.useCallback(
    async (query: SearchLeaderboardRequest) => {
      const result = await searchLeaderboard(query);

      if (query.grouping && query.grouping.compareIds && result.groups) {
        const openedGroupIds = result.groups.map(({ id }) => id);

        updateGroupCompare(
          query.grouping?.groupBy,
          query.grouping.compareIds,
          query.query,
          openedGroupIds,
        );
      }

      return result;
    },
    [updateGroupCompare],
  );

  return useLeaderboardPolling({
    id,
    query: searchParams,
    resolver,
    enabled,
  });
};

import { WidgetDTOTypeEnum } from '@neptune/shared/core-apis-leaderboard-domain';

import { invertMapTyped } from 'common/tsHelpers';
import { AttributeType } from 'domain/experiment/attribute';

export type Widget = {
  id: string;
  name?: string;
  type: WidgetType;
  options?: WidgetOptions;
  sources: WidgetSource[];
};

export type WidgetSourceType = 'field' | 'custom-y-expression' | 'namespace';

export type WidgetSource = {
  type: WidgetSourceType;
  value: string;
  metadata?: {
    attributeType?: AttributeType; // this field may be unnecessary, but it is introduced to maintain compatibility with the old code
    subproperty?: string;
    alias?: string;
  };
};

export type WidgetType =
  | 'chart'
  | 'valueList'
  | 'file'
  | 'fileSet'
  | 'gallery'
  | 'image'
  | 'imageComparison'
  | 'interactiveTable'
  | 'scatterPlot'
  | 'singleValue'
  | 'table'
  | 'section'
  | 'notSupported';
export type WidgetOptions = object | undefined;
const widgetTypeMapping: Partial<Record<WidgetType, WidgetDTOTypeEnum>> = {
  chart: WidgetDTOTypeEnum.Chart,
  file: WidgetDTOTypeEnum.File,
  fileSet: WidgetDTOTypeEnum.FileSet,
  gallery: WidgetDTOTypeEnum.Gallery,
  image: WidgetDTOTypeEnum.Image,
  imageComparison: WidgetDTOTypeEnum.ImageComparison,
  interactiveTable: WidgetDTOTypeEnum.InteractiveTable,
  valueList: WidgetDTOTypeEnum.ValueList,
  scatterPlot: WidgetDTOTypeEnum.ScatterPlot,
  singleValue: WidgetDTOTypeEnum.SingleValue,
  table: WidgetDTOTypeEnum.Table,
  section: WidgetDTOTypeEnum.Section,
};
const invertedWidgetTypeMapping = invertMapTyped(widgetTypeMapping);

export function widgetTypeFromApiToDomain(widgetType: WidgetDTOTypeEnum): WidgetType {
  return invertedWidgetTypeMapping[widgetType] || 'notSupported';
}

export function widgetTypeFromDomainToApi(widgetType: WidgetType): WidgetDTOTypeEnum | undefined {
  return widgetTypeMapping[widgetType];
}

import { uniq } from 'lodash';

import { AttributeNameFilter } from '@neptune/shared/search-domain';

export const updateAttributeNameFilter = (
  filter: AttributeNameFilter | undefined,
  update: AttributeNameFilter | undefined,
): AttributeNameFilter | undefined => {
  const newMustMatchRegexes = uniq([
    ...(filter?.mustMatchRegexes ?? []),
    ...(update?.mustMatchRegexes ?? []),
  ]);
  const newMustNotMatchRegexes = uniq([
    ...(filter?.mustNotMatchRegexes ?? []),
    ...(update?.mustNotMatchRegexes ?? []),
  ]);

  if (newMustMatchRegexes.length === 0 && newMustNotMatchRegexes.length === 0) {
    return undefined;
  }

  return {
    mustMatchRegexes: newMustMatchRegexes.length > 0 ? newMustMatchRegexes : undefined,
    mustNotMatchRegexes: newMustNotMatchRegexes.length > 0 ? newMustNotMatchRegexes : undefined,
  };
};

/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LoginActionDTO } from './LoginActionDTO';
import {
    LoginActionDTOFromJSON,
    LoginActionDTOFromJSONTyped,
    LoginActionDTOToJSON,
} from './LoginActionDTO';

/**
 * 
 * @export
 * @interface UserActionsDTO
 */
export interface UserActionsDTO {
    /**
     * 
     * @type {string}
     * @memberof UserActionsDTO
     */
    currentUsername: string;
    /**
     * 
     * @type {Array<LoginActionDTO>}
     * @memberof UserActionsDTO
     */
    loginActions: Array<LoginActionDTO>;
}

/**
 * Check if a given object implements the UserActionsDTO interface.
 */
export function instanceOfUserActionsDTO(value: object): value is UserActionsDTO {
    if (!('currentUsername' in value) || value['currentUsername'] === undefined) return false;
    if (!('loginActions' in value) || value['loginActions'] === undefined) return false;
    return true;
}

export function UserActionsDTOFromJSON(json: any): UserActionsDTO {
    return UserActionsDTOFromJSONTyped(json, false);
}

export function UserActionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserActionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'currentUsername': json['currentUsername'],
        'loginActions': ((json['loginActions'] as Array<any>).map(LoginActionDTOFromJSON)),
    };
}

export function UserActionsDTOToJSON(value?: UserActionsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'currentUsername': value['currentUsername'],
        'loginActions': ((value['loginActions'] as Array<any>).map(LoginActionDTOToJSON)),
    };
}


import React from 'react';

import { Dialog } from '@neptune/shared/venus-ui';

import { VisibilityChangeConfirmationModalProps } from './interface';

export const WorkspacePrivacyChangeConfirmationModal: React.FC<
  VisibilityChangeConfirmationModalProps
> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Dialog
      isOpen={isOpen}
      title="Set project privacy to workspace"
      primaryButtonLabel="Yes, set privacy to workspace-wide"
      primaryButtonRole="visibility-confirm"
      secondaryButtonLabel="Cancel"
      onPrimaryButtonClick={onConfirm}
      onSecondaryButtonClick={onClose}
    >
      You are about to to set this project’s visibility to <strong>Workspace</strong>. This will
      make it available to all workspace members.
      <br />
      Do you want to continue?
    </Dialog>
  );
};

import { useQuery } from '@tanstack/react-query';

import { isEmptyShortIdsMap, ShortIdsByProject } from '@neptune/shared/entity-domain';
import { queryFieldsFromAllPages } from '@neptune/shared/leaderboard-domain';

import {
  KnownAttributes,
  knownAttributesForExperimentIdentification,
} from 'domain/experiment/attribute';

const FIELDS = [...knownAttributesForExperimentIdentification, KnownAttributes.GroupTags];
const EMPTY_SELECTION: Awaited<ReturnType<typeof queryFieldsFromAllPages>> = [];

/**
 *
 * Returns a list of attributes that are required to build entity color.
 *
 */
export function useColorSourceAttributesQuery(scope: string, shortIdsByProject: ShortIdsByProject) {
  return useQuery({
    keepPreviousData: true,
    initialData: EMPTY_SELECTION,
    enabled: !isEmptyShortIdsMap(shortIdsByProject),
    queryFn: () => queryFieldsFromAllPages({ shortIdsByProject, fields: FIELDS }),
    queryKey: [scope, 'entity-colors-keys', [...shortIdsByProject.entries()]],
  });
}

/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationLimitNameDTO } from './OperationLimitNameDTO';
import {
    OperationLimitNameDTOFromJSON,
    OperationLimitNameDTOFromJSONTyped,
    OperationLimitNameDTOToJSON,
} from './OperationLimitNameDTO';

/**
 * 
 * @export
 * @interface OperationsLimitDTO
 */
export interface OperationsLimitDTO {
    /**
     * 
     * @type {OperationLimitNameDTO}
     * @memberof OperationsLimitDTO
     */
    name: OperationLimitNameDTO;
    /**
     * 
     * @type {number}
     * @memberof OperationsLimitDTO
     */
    durationSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof OperationsLimitDTO
     */
    value?: number;
}



/**
 * Check if a given object implements the OperationsLimitDTO interface.
 */
export function instanceOfOperationsLimitDTO(value: object): value is OperationsLimitDTO {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('durationSeconds' in value) || value['durationSeconds'] === undefined) return false;
    return true;
}

export function OperationsLimitDTOFromJSON(json: any): OperationsLimitDTO {
    return OperationsLimitDTOFromJSONTyped(json, false);
}

export function OperationsLimitDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationsLimitDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'name': OperationLimitNameDTOFromJSON(json['name']),
        'durationSeconds': json['durationSeconds'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function OperationsLimitDTOToJSON(value?: OperationsLimitDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': OperationLimitNameDTOToJSON(value['name']),
        'durationSeconds': value['durationSeconds'],
        'value': value['value'],
    };
}


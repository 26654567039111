import React from 'react';

import { bemBlock, Icon, Layout, Text } from '@neptune/shared/venus-ui';

import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';

import './FilterSearchResultsText.less';

const block = bemBlock('filter-search-results-text');

type FilterSearchResultsTextProps = {
  currentCount: number;
  error?: boolean;
  loading?: boolean;
  showCounters: boolean;
  /** @deprecated to be removed, new api does not return total count */
  collectionCount?: number | undefined;
  emptyQuery: boolean;
  disabledNoResults?: boolean;
  thereIsMore?: boolean;
};

type getSearchResultsText = {
  emptyQuery: boolean;
  currentCount: number;
  thereIsMore: boolean;
};

const getSearchResultsText = ({
  emptyQuery,
  currentCount,
  thereIsMore,
}: getSearchResultsText): string => {
  if (currentCount === 0) {
    return emptyQuery ? 'No results' : 'No matching results';
  }

  const baseText = emptyQuery ? 'available' : 'matching';
  const showingText = thereIsMore ? `Showing first ${currentCount}` : `Showing all ${currentCount}`;

  return `${showingText} ${baseText} search results:`;
};

export const FilterSearchResultsText: React.FC<FilterSearchResultsTextProps> = ({
  emptyQuery,
  currentCount,
  error,
  loading,
  showCounters,
  collectionCount,
  disabledNoResults,
  thereIsMore,
}) => {
  if ((!showCounters && currentCount > 0) || (currentCount === 0 && disabledNoResults)) {
    return null;
  }

  const results = getSearchResultsText({
    emptyQuery,
    currentCount,
    thereIsMore: thereIsMore ?? (collectionCount !== undefined && collectionCount > currentCount),
  });

  const text = `${error ? 'Error occurred. ' : ''}${results}`;

  return (
    <Layout.Row span="shrink">
      {error || loading ? (
        <Layout.Element className={block('indicator')}>
          {error && !loading && <Icon glyph="warning" color="warning" />}
          <LoadingIndicator size="small" isVisible={loading} centered />
        </Layout.Element>
      ) : null}
      <Text size="xs" color="text-secondary">
        {text}
      </Text>
    </Layout.Row>
  );
};

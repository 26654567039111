// Lib
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { WithPaddingRenderer } from 'neptune-core-ui/components/table/renderers';

import { Icon, InfoIcon, SimpleTooltip, Text } from '@neptune/shared/venus-ui';
import { OrganizationMember } from '@neptune/user-management-domain';

export type ProjectsAccessRendererProps = {
  data: OrganizationMember;
  params: {
    projectAccessTooltipText?: string;
  };
};

export const ProjectsAccessRenderer: React.FC<ProjectsAccessRendererProps> = ({ data, params }) => {
  const { numberOfProjects, totalNumberOfProjects, invitationInfo } = data || {};

  if (invitationInfo) {
    return null;
  }

  return (
    <WithPaddingRenderer>
      <SimpleTooltip content={params.projectAccessTooltipText}>
        <Text>
          {numberOfProjects} of {totalNumberOfProjects} projects
        </Text>{' '}
        {numberOfProjects === 0 && (
          <InfoIcon
            triggerElement={<Icon glyph="exclamation-triangle" color="warning" />}
            placement="bottom-center"
          >
            This user hasn’t been added to any project yet. Make sure to grant access in{' '}
            <Text italic>Project Settings</Text>.
          </InfoIcon>
        )}
      </SimpleTooltip>
    </WithPaddingRenderer>
  );
};

const validRunDetailsTabs = [
  'table', // actually empty details, which means only Neptune Object Table
  'charts',
  'metadata',
  'dashboard',
];

export function isValidRunDetailsTab(tab?: string): boolean {
  return tab != null && validRunDetailsTabs.includes(tab);
}

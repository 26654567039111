// Libs
import React from 'react';

// App
import { CopyToClipboard, selectContentBeforeWrap } from '@neptune/shared/common-ui';
import { bemBlock, InputWithSuffix } from '@neptune/shared/venus-ui';

// Module
import './ShareInput.less';

type ShareInputProps = {
  link: string;
};

const block = bemBlock('share-input');

export const ShareInput: React.FC<ShareInputProps> = ({ link }) => {
  return (
    <InputWithSuffix
      inputProps={{
        value: link,
        autoFocus: true,
        onFocus: selectContentBeforeWrap(),
        readOnly: true,
      }}
      className={block()}
      suffix={<CopyToClipboard text={link} className={block('clipboard')} />}
    />
  );
};

import React from 'react';

import { Layout, RadioWithLabel } from '@neptune/shared/venus-ui';

export type RoleGroupProps = {
  group: string[];
  role: string | undefined;
  onChange: (option: { role?: string; otherRoleDescription?: string }) => void;
};
export const RoleGroup: React.FC<RoleGroupProps> = ({ group, role, onChange }) => (
  <Layout.Column spacedChildren="sm">
    {group.map((option) => (
      <RadioWithLabel
        key={option}
        data-role={option}
        id={option}
        checked={role === option}
        onChange={() => onChange({ role: option })}
        label={option}
        value={option}
      />
    ))}
  </Layout.Column>
);

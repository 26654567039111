/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrganizationTraitName = {
    ProjectVisibilityRestricted: 'projectVisibilityRestricted',
    PlanUpgrade: 'planUpgrade',
    ServiceAccounts: 'serviceAccounts',
    OperationsLimit: 'operationsLimit',
    UiWorkspaceSwitching: 'uiWorkspaceSwitching',
    ActiveProjects: 'activeProjects',
    DashboardsLimits: 'dashboardsLimits',
    TableViewsLimits: 'tableViewsLimits',
    ModelRegistryLimits: 'modelRegistryLimits'
} as const;
export type OrganizationTraitName = typeof OrganizationTraitName[keyof typeof OrganizationTraitName];


export function instanceOfOrganizationTraitName(value: any): boolean {
    for (const key in OrganizationTraitName) {
        if (Object.prototype.hasOwnProperty.call(OrganizationTraitName, key)) {
            if (OrganizationTraitName[key as keyof typeof OrganizationTraitName] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OrganizationTraitNameFromJSON(json: any): OrganizationTraitName {
    return OrganizationTraitNameFromJSONTyped(json, false);
}

export function OrganizationTraitNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationTraitName {
    return json as OrganizationTraitName;
}

export function OrganizationTraitNameToJSON(value?: OrganizationTraitName | null): any {
    return value as any;
}


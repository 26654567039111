import React from 'react';

import { neptuneUrls } from '@neptune/shared/common-domain';
import { Link } from '@neptune/shared/venus-ui';

type PricingLinkProps = React.ComponentProps<typeof Link>;

export const PricingLink: React.FC<PricingLinkProps> = (props) => {
  return <Link {...props} href={neptuneUrls.pricing} external />;
};

import type Stripe from 'stripe';

import { backendClient } from '@neptune/shared/core-apis-backend-domain';

import { createErrorDescriptor } from 'common/error-handler';

type FetchPastInvoicesResponse = {
  data: Stripe.invoices.IInvoice[];
};

export async function fetchPastInvoices(
  organizationIdentifier: string,
): Promise<FetchPastInvoicesResponse> {
  const response = await backendClient.getPastInvoices({ organizationIdentifier });

  return JSON.parse(response.pastInvoices);
}

export async function fetchUpcomingInvoice(
  organizationIdentifier: string,
): Promise<Stripe.invoices.IInvoice | undefined> {
  try {
    const response = await backendClient.getUpcomingInvoice({ organizationIdentifier });

    return JSON.parse(response.invoice);
  } catch (e) {
    const error = createErrorDescriptor(e);

    if (error.code === 404) {
      return undefined;
    }

    throw e;
  }
}

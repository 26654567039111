import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Badge } from '@neptune/shared/venus-ui';
import {
  fetchOrganizationMemberList,
  getOrganizationMembersCount,
} from '@neptune/user-management-business-logic';

import { getOrganizationNameFromRouteParams } from 'state/selectors-global';

export const OrganizationPeopleBadge: React.FC = () => {
  const dispatch = useDispatch();
  const organizationName = useSelector(getOrganizationNameFromRouteParams);
  const membersCount = useSelector(getOrganizationMembersCount);

  React.useEffect(() => {
    dispatch(fetchOrganizationMemberList({ organizationIdentifier: organizationName }));
  }, [dispatch, organizationName]);

  return <Badge>{membersCount}</Badge>;
};

import React from 'react';

import { Dialog } from '@neptune/shared/venus-ui';

import { UpgradePlanLink } from './UpgradePlanLink';

type MembersLimitReachedModalProps = {
  isOpen: boolean;
  close: () => void;
  workspaceName: string;
};

export const MembersLimitReachedModal: React.FC<MembersLimitReachedModalProps> = ({
  isOpen,
  close,
  workspaceName,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      title="Invitations limit reached"
      primaryButtonLabel="OK"
      onPrimaryButtonClick={close}
      onClose={close}
    >
      <Dialog.Text>
        You have reached limit of available invitations. If you want to invite more members to your
        workspace, you can{' '}
        <span onClick={close}>
          <UpgradePlanLink workspaceName={workspaceName}>upgrade your plan</UpgradePlanLink>
        </span>
        .
      </Dialog.Text>
    </Dialog>
  );
};

// Libs
import { actions as routerActions } from 'redux-router5';

import { OrganizationType } from '@neptune/shared/core-organizations-domain';
import { isEnterpriseContext } from '@neptune/shared/enterprise-context-business-logic';
import { convertRouteName } from '@neptune/shared/enterprise-context-util';
import { NavigationOptions } from '@neptune/shared/routing-domain';

// App
import { NThunkAction } from 'state/types';

// Module
import { getCurrentRouteParams } from './selectors';

/**
 * Navigates through routes. Abstracts solo/enterprise context switching, which is part of routing system.
 */
export function navigateTo(
  routeName: string,
  routeParams?: { [key: string]: any },
  options: NavigationOptions = {},
): NThunkAction<void, ReturnType<typeof routerActions.navigateTo>> {
  return (dispatch, getState) => {
    const { forceContext, extendParams, ...routeOptions } = options;

    const isEnterprise = forceContext
      ? forceContext === OrganizationType.team
      : isEnterpriseContext(getState());

    const params = extendParams
      ? {
          ...getCurrentRouteParams(getState()),
          ...routeParams,
        }
      : routeParams;

    const convertedRouteName = convertRouteName(routeName, isEnterprise);
    dispatch(routerActions.navigateTo(convertedRouteName, params, routeOptions));
  };
}

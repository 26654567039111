import React from 'react';

import { DropdownItem } from '@neptune/shared/venus-ui';

import { FilterSearchMatch } from './FilterSearchMatch';

export interface FilterSearchItemProps<T> {
  match: string;
  value: T;
  active: boolean;
  selected?: boolean;
  onClick: React.MouseEventHandler;
}

export const FilterSearchItem = <T,>({ match, active, onClick }: FilterSearchItemProps<T>) => (
  <DropdownItem onClick={onClick} active={active}>
    <FilterSearchMatch text={match} />
  </DropdownItem>
);

import React from 'react';

export enum LeaderboardId {
  Runs = 'runs',
  Models = 'models',
  ModelVersions = 'modelVersions',
  Compare = 'compare',
  Reports = 'reports',
  Trash = 'trash',
}

type LeaderboardProjectId = string & { __leaderboard_project_id__: true };

export type LeaderboardContextType = Readonly<{
  /** arbitrary value created on FE side that identifies context scope */
  id: LeaderboardId | `${LeaderboardId}-${string}`;
  /** value that uniquely identifies the entity. It can be **UUID** ot **qualified id** */
  entityId: string;
  entityType: string;
  types: string[];
  trash: boolean;
  /** value that uniquely identifies the project. It can be **UUID** ot **qualified id** like: _**${workspace name}/${project name}**_ */
  projectIdentifier: LeaderboardProjectId;
}>;

export const LeaderboardContext = React.createContext<LeaderboardContextType>({
  get id(): LeaderboardContextType['id'] {
    throw new Error('LeaderboardContext is uninitialized here.');
  },
  get entityId(): string {
    throw new Error('LeaderboardContext is uninitialized here.');
  },
  get entityType(): LeaderboardContextType['entityType'] {
    throw new Error('LeaderboardContext is uninitialized here.');
  },
  get types(): LeaderboardContextType['types'] {
    throw new Error('LeaderboardContext is uninitialized here.');
  },
  get trash(): boolean {
    throw new Error('LeaderboardContext is uninitialized here.');
  },
  get projectIdentifier(): LeaderboardContextType['projectIdentifier'] {
    throw new Error('LeaderboardContext is uninitialized here.');
  },
});

import { backendClient } from '@neptune/shared/core-apis-backend-domain';
import { projectWithRoleFromApiToDomain } from '@neptune/shared/core-project-domain';

export function updateLastViewedProject(projectId: string) {
  return backendClient.updateLastViewed({ projectId });
}

export async function getProjectsDetails(projectIds: string[]) {
  return (
    await Promise.all(
      projectIds.map(async (projectIdentifier) => {
        try {
          return await backendClient.getProjectDetails({ projectIdentifier });
        } catch (error) {
          if (error instanceof Response && error.status === 404) {
            return null;
          }

          throw error;
        }
      }),
    )
  )
    .filter((project) => project !== null)
    .map(projectWithRoleFromApiToDomain);
}

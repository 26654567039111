import React from 'react';

import { isBrowserFullySupported } from '@neptune/shared/common-util';
import { InfoBar, Link } from '@neptune/shared/venus-ui';

import { loadState, persistState } from 'common/localStorage';

import { recommendedBrowsersLinks } from './recommendedBrowsersLinks';

const supported = isBrowserFullySupported();

export const BrowserNotFullySupportedInfo: React.FC = () => {
  const [visible, setVisible] = React.useState<boolean>(() => {
    const storedState = loadState() || {};

    return supported ? false : !storedState.dontShowBrowserSupportInfo;
  });

  function closeForever() {
    setVisible(false);
    persistState({ dontShowBrowserSupportInfo: true });
  }

  return (
    <InfoBar level="info" canBeClosedManually visible={visible}>
      <span>
        Your browser is not fully supported by Neptune, therefore some features may not work as
        intended. For better experience we advise you to use the latest version of{' '}
        {recommendedBrowsersLinks}
        <p>
          <Link onClick={closeForever}>Do not show this again</Link>.
        </p>
      </span>
    </InfoBar>
  );
};

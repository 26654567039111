import React from 'react';

import { useHitProjectStorageLimit } from '@neptune/project-business-logic';
import {
  hasProjectUsageManagingPermission,
  hasProjectUsageViewPermission,
} from '@neptune/shared/core-permissions-business-logic';
import { ProjectWithRole } from '@neptune/shared/core-project-domain';

import { ProjectAdminStorageErrorBannerContainer } from './ProjectAdminStorageErrorBannerContainer';
import { ProjectMemberStorageErrorBannerContainer } from './ProjectMemberStorageErrorBannerContainer';

type ProjectStorageBannersContainerProps = {
  project: ProjectWithRole;
};

export const ProjectStorageBannersContainer: React.FC<ProjectStorageBannersContainerProps> = ({
  project,
}) => {
  const projectIdentifier = project.projectIdentifier ?? '';
  const { value: isStorageLimitHit, refresh } = useHitProjectStorageLimit(projectIdentifier);

  if (!isStorageLimitHit) {
    return null;
  }

  if (hasProjectUsageManagingPermission(project)) {
    return (
      <ProjectAdminStorageErrorBannerContainer
        projectIdentifier={projectIdentifier}
        refresh={refresh}
      />
    );
  }

  if (hasProjectUsageViewPermission(project)) {
    return <ProjectMemberStorageErrorBannerContainer />;
  }

  return null;
};

import React from 'react';

import { SectionLayoutDetails, SectionsLayoutMap } from './layouts';

export type GridSectionsContext = {
  sectionLayoutsDetailsMap: SectionsLayoutMap;
  getLayoutPixelHeight: (layoutRows: number) => number;
};

export const GridSectionsContext = React.createContext<GridSectionsContext | undefined>(undefined);

export function useGridSectionContext(sectionKey: string): {
  sectionDetails: SectionLayoutDetails | undefined;
  getLayoutPixelHeight: GridSectionsContext['getLayoutPixelHeight'];
} {
  const { sectionLayoutsDetailsMap, getLayoutPixelHeight } =
    React.useContext(GridSectionsContext) ??
    (() => {
      throw new Error('useGridSectionContext called without GridSectionsContext');
    })();

  return {
    sectionDetails: sectionLayoutsDetailsMap?.[sectionKey],
    getLayoutPixelHeight,
  };
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigateTo } from 'router/actions';

import {
  fetchPendingInvitationMatching,
  getHomeNavigationParams,
  getInvitationNavigationParams,
} from '@neptune/invitations-business-logic';
import { fetchInvitation, Invitation, isOrganizationInvitation } from '@neptune/invitations-domain';
import { InvitationError } from '@neptune/invitations-ui';
import { fetchOrganizations } from '@neptune/shared/core-organizations-business-logic';
import { convertStringToUserManagementLevel } from '@neptune/user-management-common-domain';

import { getCurrentRouteParams } from 'state/selectors-global';
import { getCurrentUser } from 'state/users/current-user/selectors';

import { AcceptSingleInvitationContainer } from './AcceptSingleInvitationContainer';

export const AcceptInvitationContainer: React.FC = () => {
  const [error, setError] = React.useState<unknown>();
  const [current, setCurrent] = React.useState<Invitation>();
  const routeParams = useSelector(getCurrentRouteParams);
  const user = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  const finish = async () => {
    dispatch(navigateTo(...getHomeNavigationParams()));
  };

  React.useEffect(() => {
    doFetchInvitation();

    async function doFetchInvitation() {
      try {
        const { type, invitationId, invitationTarget } = routeParams;
        const level = convertStringToUserManagementLevel(type);

        if (!level) {
          return;
        }

        if (invitationId && invitationId !== '-') {
          setCurrent(await fetchInvitation({ level, invitationId }));
        } else {
          setCurrent(await fetchPendingInvitationMatching(type, invitationTarget, user?.email));
        }
      } catch (error) {
        setError(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirm = React.useCallback(async () => {
    if (!current) {
      return;
    }

    if (isOrganizationInvitation(current)) {
      await dispatch(fetchOrganizations());
    }

    const navigationParams = getInvitationNavigationParams(current);

    if (navigationParams) {
      dispatch(navigateTo(...navigationParams));
    } else {
      dispatch(navigateTo(...getHomeNavigationParams()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  if (error) {
    return <InvitationError onClose={finish} />;
  }

  if (!current) {
    return null;
  }

  return (
    <AcceptSingleInvitationContainer
      invitation={current}
      afterAccept={onConfirm}
      afterClose={finish}
    />
  );
};

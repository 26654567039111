import React from 'react';

import { cannotUnarchiveProjectModalName } from '@neptune/project-domain';
import { ArchiveLimitReachedModal } from '@neptune/project-ui';
import { useGenericModal } from '@neptune/shared/common-business-logic';

export const ArchiveModalsContainer: React.FC = () => {
  const { isOpen: isArchiveLimitReachedModalOpen, close: closeArchiveLimitReachedModal } =
    useGenericModal(cannotUnarchiveProjectModalName);

  return (
    <>
      <ArchiveLimitReachedModal
        isOpen={isArchiveLimitReachedModalOpen}
        close={closeArchiveLimitReachedModal}
      />
    </>
  );
};

import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { ScrollableTable } from 'neptune-core-ui/components';

import { formatMonitoringExtended, toMonthPeriod } from '@neptune/shared/common-util';
import { bemBlock } from '@neptune/shared/venus-ui';

import { UsageHistoryPeriod, UsageHistoryPeriodType } from 'domain/usage-history';

import { CellRenderer } from './CellRenderer';
import { EmptyRenderer } from './EmptyRenderer';

import './UsageHistory.less';

const block = bemBlock('project-usage-history');

type UsageHistoryProps = {
  data: UsageHistoryPeriod[];
  periodType?: UsageHistoryPeriodType;
};

type UsageHistoryRendererProps = { data: UsageHistoryPeriod };

const periodsNames: Record<UsageHistoryPeriodType, string> = {
  [UsageHistoryPeriodType.month]: 'Month',
};

const periodsRenderer: Record<UsageHistoryPeriodType, React.FC<UsageHistoryRendererProps>> = {
  [UsageHistoryPeriodType.month]: ({ data }: UsageHistoryRendererProps) => (
    <CellRenderer numeric children={toMonthPeriod(data.date)} />
  ),
};

const UsageHistoryMinutesRenderer = ({ data }: UsageHistoryRendererProps) => (
  <CellRenderer numeric children={formatMonitoringExtended(data.usedMinutes)} />
);

export const UsageHistory: React.FC<UsageHistoryProps> = ({
  data,
  periodType = UsageHistoryPeriodType.month,
}) => {
  const columnsDefinition = [
    {
      width: 100,
      head: { align: 'right', data: periodsNames[periodType] },
      body: { renderer: periodsRenderer[periodType] },
    },
    {
      width: 150,
      head: { align: 'right', data: 'Monitoring time' },
      body: { renderer: UsageHistoryMinutesRenderer },
    },
    {
      head: { data: '' },
      body: { renderer: EmptyRenderer },
    },
  ];

  return (
    <ScrollableTable
      className={block()}
      data={data}
      renderParts={['head', 'body']}
      columns={columnsDefinition}
    />
  );
};

import { ChartConfigurationSettings } from './chart-configuration';

type ChartGlobalConfig = Partial<
  Pick<
    ChartConfigurationSettings,
    'metricsStepsRange' | 'xAxisMetric' | 'xAxisMode' | 'yAxisScale' | 'xAxisScale' | 'smoothing'
  >
>;

export const DEFAULT_CHART_GLOBAL_CONFIG: ChartGlobalConfig = {
  xAxisMode: undefined,
  xAxisMetric: undefined,
  xAxisScale: undefined,
  yAxisScale: undefined,
  metricsStepsRange: undefined,
  smoothing: undefined,
};

import React from 'react';
import { isEmpty } from 'lodash';

import { createSessionStorageAccessor } from 'common/storage-session';
import { AttributeDefinition } from 'domain/experiment/attribute';

const sessionStorage = createSessionStorageAccessor<{
  storedGroupBy: Record<string, AttributeDefinition[] | undefined>;
}>();

export const useManageStoredGroupBy = (projectIdentifier: string) => {
  const getStoredGroupBy = React.useCallback(() => {
    const lastStoredGroupBy = sessionStorage.getItem('storedGroupBy') ?? {};
    const lastStoredGroupByForTheProject = lastStoredGroupBy[projectIdentifier] ?? [];

    return { lastStoredGroupBy, lastStoredGroupByForTheProject };
  }, [projectIdentifier]);

  const setStoredGroupBy = React.useCallback(
    (attributesToAdd: AttributeDefinition[]) => {
      const { lastStoredGroupBy } = getStoredGroupBy();

      sessionStorage.setItem('storedGroupBy', {
        ...lastStoredGroupBy,
        [projectIdentifier]: attributesToAdd,
      });
    },
    [projectIdentifier, getStoredGroupBy],
  );

  const clearStoredGroupBy = React.useCallback((): void => {
    sessionStorage.removeItem('storedGroupBy');
  }, []);

  const removeAttributeFromStoredGroupBy = React.useCallback(
    (attributeName: string): void => {
      const { lastStoredGroupBy, lastStoredGroupByForTheProject } = getStoredGroupBy();

      if (isEmpty(lastStoredGroupByForTheProject)) {
        return;
      }

      const newAttributes = lastStoredGroupByForTheProject.filter(
        ({ name }) => name !== attributeName,
      );

      sessionStorage.setItem('storedGroupBy', {
        ...lastStoredGroupBy,
        [projectIdentifier]: newAttributes,
      });
    },
    [projectIdentifier, getStoredGroupBy],
  );

  return {
    getStoredGroupBy,
    setStoredGroupBy,
    clearStoredGroupBy,
    removeAttributeFromStoredGroupBy,
  } as const;
};

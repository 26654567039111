import { LeaderboardListPaginationOptions } from '@neptune/shared/leaderboard-domain';

import getUiState from 'state/ui/selectors';

import { defaultPaginationOptions, PaginationState } from './pagination-reducer';

export const getPaginationState = (state: any): PaginationState => {
  return getUiState(state).pagination;
};

export const getPaginationOptions = (state: any, id: string): LeaderboardListPaginationOptions => {
  return getPaginationState(state)[id] || defaultPaginationOptions;
};

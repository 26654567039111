// Libs
import React from 'react';

// App
import { CopyToClipboard } from '@neptune/shared/common-ui';
import { Icon, Layout, LayoutColumnProps, Link } from '@neptune/shared/venus-ui';

import type { Merge } from 'common/utility-types';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import { fetchStatus, isFetchStatusPending } from 'state/fetch-status';

export type ApiTokenLayoutComponentProps = {
  apiTokenText: React.ReactElement;
  copyToClipboardButton?: React.ReactElement;
  showTokenIcon?: React.ReactElement;
};

export type ApiTokenRendererProps = Merge<
  LayoutColumnProps<{}>,
  {
    apiToken?: string;
    layout: React.FC<ApiTokenLayoutComponentProps>;
    shownApiToken?: boolean;
    showApiTokenFetchStatus?: fetchStatus;
    onToggleApiToken?: () => void;
    getApiToken?: () => Promise<string | undefined>;
  }
>;

export const ApiTokenRenderer: React.FC<ApiTokenRendererProps> = ({
  apiToken,
  getApiToken,
  layout,
  shownApiToken,
  showApiTokenFetchStatus = fetchStatus.NONE,
  onToggleApiToken,
}) => {
  const showTokenIcon = onToggleApiToken ? (
    <Layout.Column height={24} width={22} alignItems="center">
      {isFetchStatusPending(showApiTokenFetchStatus) ? (
        <LoadingIndicator fetchStatus={showApiTokenFetchStatus} size="small" />
      ) : (
        <Link data-role="show-hide-token-button" onClick={onToggleApiToken}>
          <Icon title={shownApiToken ? 'hide' : 'show'} glyph={shownApiToken ? 'hide' : 'show'} />
        </Link>
      )}
    </Layout.Column>
  ) : undefined;

  const apiTokenText = (
    <Layout.Row
      overflow="auto"
      span="shrink"
      withCustomScrollbar
      withPadding="xs"
      data-role="api-token"
    >
      {shownApiToken ? apiToken : '*'.repeat(184)}
    </Layout.Row>
  );

  const copyToClipboardButton =
    getApiToken !== undefined || apiToken !== undefined ? (
      <Layout.Column width={22} justifyContent="center">
        <CopyToClipboard text={apiToken} getText={getApiToken} />
      </Layout.Column>
    ) : undefined;

  return layout({
    apiTokenText,
    copyToClipboardButton,
    showTokenIcon,
  });
};

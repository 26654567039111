import { differenceInDays } from 'date-fns';

import { WorkspaceStatus } from '@neptune/pricing-workspace-status-domain';

import { fetchStatus as FetchStatus } from 'state/fetch-status';
import { AppState } from 'state/types';

import {
  monitoringAdminTrigger,
  monitoringMemberTrigger,
  monitoringReadOnlyTrigger,
} from './banner-triggers/monitoring-trigger';
import {
  paymentAdminTrigger,
  paymentMemberTrigger,
  paymentReadOnlyTrigger,
} from './banner-triggers/payment-triggers';
import {
  storageAdminTrigger,
  storageMemberTrigger,
  storageReadOnlyTrigger,
} from './banner-triggers/storage-trigger';
import {
  trialAdminTrigger,
  trialMemberTrigger,
  trialReadOnlyTrigger,
} from './banner-triggers/trial-triggers';
import { calculateDaysLeft } from './calculate-days-left';
import { SingleWorkspaceStatusState, WorkspaceStatusState } from './reducer';

const selectWorkspaceStatusState = (state: any): WorkspaceStatusState =>
  state.pricing.workspaceStatus;

export const selectOrganizationWorkspaceStatusState = (
  state: AppState,
  organizationIdentifier: string | undefined,
): SingleWorkspaceStatusState | undefined =>
  organizationIdentifier
    ? selectWorkspaceStatusState(state).byOrganization[organizationIdentifier]
    : undefined;

const isFullyFetchedWorkspaceStatus = (
  input: Partial<WorkspaceStatus> | undefined,
): input is WorkspaceStatus =>
  !!(input && input.mode && input.storageBytesAvailable != null && input.storageBytesLimit != null);

const applyWithDate = <T>(
  state: AppState,
  organizationIdentifier: string,
  triggerFn: (status: WorkspaceStatus, now: Date) => T,
  defaultValue: T,
): T => {
  const maybeWorkspaceStatus = selectOrganizationWorkspaceStatusState(
    state,
    organizationIdentifier,
  );
  const date = maybeWorkspaceStatus?.date;
  return !!(date && isFullyFetchedWorkspaceStatus(maybeWorkspaceStatus))
    ? triggerFn(maybeWorkspaceStatus, new Date(date))
    : defaultValue;
};

export const selectShowAdminPaymentBanner = (state: AppState, orgId: string): boolean =>
  applyWithDate(state, orgId, paymentAdminTrigger, false);
export const selectShowMemberPaymentBanner = (state: AppState, orgId: string): boolean =>
  applyWithDate(state, orgId, paymentMemberTrigger, false);
export const selectIsReadonlyDueToPayment = (state: AppState, orgId: string): boolean =>
  applyWithDate(state, orgId, paymentReadOnlyTrigger, false);

export const selectShowAdminTrialBanner = (state: AppState, orgId: string): boolean =>
  applyWithDate(state, orgId, trialAdminTrigger, false);
export const selectShowMemberTrialBanner = (state: AppState, orgId: string): boolean =>
  applyWithDate(state, orgId, trialMemberTrigger, false);
export const selectIsReadonlyDueToTrial = (state: AppState, orgId: string): boolean =>
  applyWithDate(state, orgId, trialReadOnlyTrigger, false);

export const selectDaysWithoutPayment = (state: AppState, orgId: string): number =>
  applyWithDate(
    state,
    orgId,
    ({ subscriptionFailedChargeAttempt }, now: Date) =>
      subscriptionFailedChargeAttempt
        ? differenceInDays(now, new Date(subscriptionFailedChargeAttempt))
        : 0,
    0,
  );

export const selectDaysToEndOfTrial = (state: AppState, orgId: string): number =>
  applyWithDate(
    state,
    orgId,
    ({ trialEndDate }, now: Date) =>
      trialEndDate ? calculateDaysLeft(trialEndDate, now) : Number.NaN,
    Number.NaN,
  );

const applyWithoutDate = <T>(
  state: AppState,
  organizationIdentifier: string,
  triggerFn: (status: WorkspaceStatus) => T,
  defaultValue: T,
): T => {
  const maybeWorkspaceStatus = selectOrganizationWorkspaceStatusState(
    state,
    organizationIdentifier,
  );
  return isFullyFetchedWorkspaceStatus(maybeWorkspaceStatus)
    ? triggerFn(maybeWorkspaceStatus)
    : defaultValue;
};

export const selectShowAdminMonitoringBanner = (state: AppState, orgId: string): boolean =>
  applyWithoutDate(state, orgId, monitoringAdminTrigger, false);
export const selectShowMemberMonitoringBanner = (state: AppState, orgId: string): boolean =>
  applyWithoutDate(state, orgId, monitoringMemberTrigger, false);
export const selectIsReadonlyDueToMonitoring = (state: AppState, orgId: string): boolean =>
  applyWithoutDate(state, orgId, monitoringReadOnlyTrigger, false);

export const selectShowAdminStorageBanner = (state: AppState, orgId: string): boolean =>
  applyWithoutDate(state, orgId, storageAdminTrigger, false);
export const selectShowMemberStorageBanner = (state: AppState, orgId: string): boolean =>
  applyWithoutDate(state, orgId, storageMemberTrigger, false);
export const selectIsReadonlyDueToStorage = (state: AppState, orgId: string): boolean =>
  applyWithoutDate(state, orgId, storageReadOnlyTrigger, false);

export const selectPercentOfStorageQuotaUsage = (state: AppState, orgId: string): number =>
  applyWithoutDate(
    state,
    orgId,
    ({ storageBytesAvailable, storageBytesLimit }) =>
      Math.round(100 * (1 - storageBytesAvailable / storageBytesLimit)),
    0,
  );

export const selectMonitoringHoursAvailable = (state: AppState, orgId: string): number =>
  applyWithoutDate(
    state,
    orgId,
    ({ monitoringMinutesAvailable }) =>
      monitoringMinutesAvailable == null
        ? Number.POSITIVE_INFINITY
        : Math.round(monitoringMinutesAvailable / 60),
    0,
  );

const selectFetchStatus = (state: AppState, orgId: string): FetchStatus =>
  selectOrganizationWorkspaceStatusState(state, orgId)?.fetchStatus ?? FetchStatus.NONE;

export const selectActiveProjectsUsage = (state: AppState, orgId: string): number | undefined =>
  selectOrganizationWorkspaceStatusState(state, orgId)?.activeProjectsUsage;

export const selectFirstFetchStatus = (state: AppState, orgId: string): FetchStatus =>
  // Use date as a proxy for the state being set from successful fetch.
  selectOrganizationWorkspaceStatusState(state, orgId)?.date != null
    ? FetchStatus.SUCCESS
    : selectFetchStatus(state, orgId);

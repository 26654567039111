import React from 'react';
import { CellProps, Column, HeaderGroup, useTable } from 'react-table';
import { isString } from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { WithPaddingRenderer } from 'neptune-core-ui/components/table/renderers';

import type {
  ServiceAccount,
  ServiceAccountWithTokenState,
} from '@neptune/service-accounts-domain';
import { ProjectRole } from '@neptune/shared/core-project-domain';
import { ServiceAccountRenderer } from '@neptune/shared/service-accounts-ui';
import { RoleDropdown } from '@neptune/shared/user-management-ui';
import { bemBlock, Icon, Layout, Link, Section, Text } from '@neptune/shared/venus-ui';

import { ApiTokenRenderer } from '../api-token-renderer/ApiTokenRenderer';

import { ApiTokenLayout } from './ApiTokenLayout';

import './ServiceAccountList.less';

type ExtraProps = {
  availableRoles: ProjectRole[];
  onRemoveAccount(serviceAccount: ServiceAccountWithTokenState): void;
  onToggleApiToken(id: string): void;
  getApiToken(id: string): Promise<string | undefined>;
  onUpdateRole(params: { serviceAccount: ServiceAccount; role: ProjectRole }): void;
  roleLabels: Record<ProjectRole, string>;
};

type ServiceAccountListProps = {
  'data-role'?: string;
  entries: ServiceAccountWithTokenState[];
  isUserWorkspaceOwner: boolean;
} & ExtraProps;

type RendererProps = CellProps<ServiceAccountWithTokenState> & ExtraProps;

const block = bemBlock('service-account-list');

const NameRenderer: React.FC<CellProps<ServiceAccountWithTokenState>> = ({ value }) => {
  return <ServiceAccountRenderer name={value} />;
};

export const ServiceAccountList: React.FC<ServiceAccountListProps> = ({
  availableRoles,
  'data-role': dataRole,
  entries,
  isUserWorkspaceOwner,
  getApiToken,
  onRemoveAccount,
  onToggleApiToken,
  onUpdateRole,
  roleLabels,
}) => {
  const columns: Column[] = React.useMemo(
    () => [
      {
        accessor: 'serviceAccountInfo.displayName',
        id: 'name',
        Header: 'Account name',
        Cell: NameRenderer,
        width: 'auto',
      },
      ...(isUserWorkspaceOwner
        ? [
            {
              id: 'token',
              Header: 'API token',
              Cell: TokenRenderer,
              width: 300,
            },
          ]
        : []),
      {
        accessor: 'role',
        id: 'role',
        Header: 'Access',
        Cell: RoleRenderer,
        width: 150,
      },
      {
        id: 'remove',
        Header: '',
        Cell: RemoveAccountRenderer,
        width: 44,
      },
    ],
    [isUserWorkspaceOwner],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: entries,
  });

  return (
    <Section className={block()} overflow="hidden" data-role={dataRole} withBorder>
      <table className={block('table')} {...getTableProps()}>
        {headerGroups.map((group) => (
          <colgroup {...group.getHeaderGroupProps()}>
            {group.headers.map((header) => (
              <col
                {...header.getHeaderProps({
                  style: getHeaderStyle(header),
                })}
              />
            ))}
          </colgroup>
        ))}
        <thead>
          {headerGroups.map((group) => (
            <tr {...group.getHeaderGroupProps()}>
              {group.headers.map((header) => (
                <th className={block('header')} {...header.getHeaderProps()}>
                  {header.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr className={block('row')} data-role="table-row" {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td className={block('cell')} {...cell.getCellProps()} data-role="table-cell">
                    <WithPaddingRenderer>
                      {cell.render('Cell', {
                        availableRoles,
                        getApiToken,
                        onRemoveAccount,
                        onToggleApiToken,
                        onUpdateRole,
                        roleLabels,
                      })}
                    </WithPaddingRenderer>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {rows.length === 0 && (
        <Layout.Column
          height={100}
          alignItems="center"
          justifyContent="center"
          children={<Text color="text-secondary">No service accounts assigned yet</Text>}
        />
      )}
    </Section>
  );
};

function getHeaderStyle({ width }: HeaderGroup) {
  return {
    width: isString(width) ? width : `${width}px`,
  };
}

function TokenRenderer(props: RendererProps) {
  const serviceAccount = props.row.original.serviceAccountInfo;
  const tokenStatus = props.row.original.tokenState;
  return (
    <ApiTokenRenderer
      apiToken={tokenStatus?.token}
      layout={ApiTokenLayout}
      showApiTokenFetchStatus={tokenStatus?.fetchStatus}
      getApiToken={() => props.getApiToken(serviceAccount.id)}
      onToggleApiToken={() => props.onToggleApiToken(serviceAccount.id)}
      shownApiToken={tokenStatus?.isShown}
    />
  );
}

function RoleRenderer(props: RendererProps) {
  return (
    <RoleDropdown<ProjectRole>
      availableRoles={props.availableRoles}
      roleLabels={props.roleLabels}
      value={props.value}
      onSelect={(role) =>
        props.onUpdateRole({
          serviceAccount: props.row.original.serviceAccountInfo,
          role,
        })
      }
    />
  );
}

function RemoveAccountRenderer(props: RendererProps) {
  return (
    <Link onClick={() => props.onRemoveAccount(props.row.original)} data-role="remove-action">
      <Icon glyph="trash" />
    </Link>
  );
}

import { createSelector } from 'reselect';

import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';
import {
  areColumnSetsEqual,
  ensureDefaultColumns,
  filterGroupByColumns,
  getDefaultColumnsIds,
  getOrderedColumns,
} from '@neptune/shared/columns-business-logic';
import { ColumnsRootKey } from '@neptune/shared/columns-domain';
import { LeaderboardView } from '@neptune/shared/lb-views-domain';
import { LeaderboardColumn } from '@neptune/shared/leaderboard-domain';

import { naturalStringComparator } from 'common/tools';
import { standardView } from 'state/project-leaderboard/lb-views/standard-view';
import { createCurrentRouteParamSelector, getCurrentRouteParams } from 'state/selectors-global';
import { AppState } from 'state/types';
import { getRawGroupBy, getRawQuery, getRawSortOptions } from 'state/ui/route-params/selectors';

import getParentState from '../selectors';

import { LeaderboardViewsState } from './types';
import { compareLbViewProps } from './utils';

export function getLeaderboardViewsState(state: AppState): LeaderboardViewsState {
  return getParentState(state).lbViews;
}

export const getSortedViews = createSelector(getLeaderboardViewsState, (viewsState) => {
  return viewsState.entries.sort((a, b) => {
    if (a.id === standardView.id) {
      return -1;
    }

    if (b.id === standardView.id) {
      return 1;
    }

    return naturalStringComparator(a.name, b.name);
  });
});

export function getLeaderboardViewList(state: AppState): LeaderboardView[] {
  const { entries } = getLeaderboardViewsState(state);
  return entries;
}

export function getActiveView(state: AppState): LeaderboardView | undefined {
  const views = getLeaderboardViewList(state);
  const routeParams = getCurrentRouteParams(state);
  const selectedView = views.find((view) =>
    [routeParams.viewId, routeParams.filterId].includes(view.id),
  );

  return selectedView;
}

export function getViewById(state: AppState, id: string): LeaderboardView | undefined {
  const views = getLeaderboardViewList(state);

  return views.find((view) => view.id === id);
}

export function getDefaultView(state: AppState): LeaderboardView | undefined {
  const views = getLeaderboardViewList(state);
  return views.find((view) => view.defaultView);
}

export const getDirtyColumnsForActiveLbView = (
  state: AppState,
): LeaderboardColumn[] | undefined => {
  const activeLbView = getActiveView(state);

  return activeLbView ? getDirtyColumnsForView(state, activeLbView) : undefined;
};

const getRunsDefaultColumnsIds = (state: AppState) =>
  getDefaultColumnsIds(state, ColumnsRootKey.Runs);

export const areColumnsDirty = createSelector(
  [getActiveView, getLeaderboardOrderedColumns, isGroupingEnabled, getRunsDefaultColumnsIds],
  (lbView, currentLeaderboardColumns, groupingEnabled, runsDefaultColumnsIds): boolean => {
    if (lbView === undefined || currentLeaderboardColumns === undefined) {
      return false;
    }

    // Some views were saved without neptune-compare column. This caused a discrepancy here
    // and resulted in one case of NPT-12632, as ensureDefaultColumns is called on columns
    // that end up in leaderboard (the other side of comparison here). The solution is to apply
    // ensureDefaultColumns on the lbview columns in a compatible way.
    const viewColumnsWithDefaults = ensureDefaultColumns(lbView.columnList, runsDefaultColumnsIds);
    const viewColumnsToCompare: LeaderboardColumn[] = filterGroupByColumns(
      viewColumnsWithDefaults,
      groupingEnabled,
    );
    const leaderboardColumnsToCompare: LeaderboardColumn[] = filterGroupByColumns(
      currentLeaderboardColumns,
      groupingEnabled,
    );

    return !areColumnSetsEqual(viewColumnsToCompare, leaderboardColumnsToCompare);
  },
);

function getLeaderboardOrderedColumns(state: AppState) {
  const projectIdentifier = getCurrentProjectIdentifier(state);

  return getOrderedColumns(state, ColumnsRootKey.Runs, projectIdentifier);
}

function parseBoolean(value: string | undefined) {
  return typeof value === 'string' ? value === 'true' : value;
}

export const getDirtyLbView = createSelector(
  [
    createCurrentRouteParamSelector('lbViewUnpacked'),
    createCurrentRouteParamSelector('suggestionsEnabled'),
    createCurrentRouteParamSelector('experimentsOnly'),
    createCurrentRouteParamSelector('runsLineage'),
    areColumnsDirty,
    getActiveView,
    getDirtyColumnsForActiveLbView,
    getRawQuery,
    getRawGroupBy,
    getRawSortOptions,
  ],
  (
    lbViewUnpacked,
    suggestionsEnabledInUrl,
    experimentsOnlyInUrl,
    runsLineageInUrl,
    areColumnsDirty,
    activeLbView,
    columns,
    query,
    rawGroupByOptions,
    rawSortOptions,
  ) => {
    const dirty = lbViewUnpacked || areColumnsDirty;

    if (!dirty || !activeLbView || !columns) {
      return activeLbView;
    }

    const suggestionsEnabled = parseBoolean(suggestionsEnabledInUrl);
    const experimentsOnly = parseBoolean(experimentsOnlyInUrl);
    const runsLineage = runsLineageInUrl?.toLowerCase() === 'none' ? 'NONE' : 'FULL';

    const columnList = columns.filter((column): column is LeaderboardColumn => column != null);

    let quickFilterProps = {};

    if (lbViewUnpacked) {
      quickFilterProps = {
        query,
        sortOptions: rawSortOptions,
        suggestionsEnabled:
          suggestionsEnabled !== undefined ? suggestionsEnabled : activeLbView.suggestionsEnabled,
        experimentsOnly:
          experimentsOnly !== undefined ? experimentsOnly : activeLbView.experimentsOnly,
        runsLineage: runsLineage !== undefined ? runsLineage : activeLbView.runsLineage,
        groupOptions: rawGroupByOptions,
      };
    }

    return {
      ...activeLbView,
      columnList,
      ...quickFilterProps,
    };
  },
);

export function getDirtyColumnsForView(
  state: AppState,
  view: LeaderboardView,
): LeaderboardColumn[] {
  const dirtyColumns = getLeaderboardOrderedColumns(state);

  if (Array.isArray(dirtyColumns) && dirtyColumns.length > 0) {
    return dirtyColumns;
  }

  return view.columnList;
}

export function areSuggestionsDirty(state: AppState): boolean {
  const activeView = getActiveView(state);
  const dirtyView = getDirtyLbView(state);

  if (!activeView || !dirtyView) {
    return false;
  }

  return activeView.suggestionsEnabled !== dirtyView.suggestionsEnabled;
}

export function areExperimentsOnlyDirty(state: AppState): boolean {
  const activeView = getActiveView(state);
  const dirtyView = getDirtyLbView(state);

  if (!activeView || !dirtyView) {
    return false;
  }

  return activeView.experimentsOnly !== dirtyView.experimentsOnly;
}

export function isRunsLineageDirty(state: AppState): boolean {
  const activeView = getActiveView(state);
  const dirtyView = getDirtyLbView(state);

  if (!activeView || !dirtyView) {
    return false;
  }

  return activeView.runsLineage !== dirtyView.runsLineage;
}

export function areLbViewFiltersDirty(state: AppState): boolean {
  const activeView = getActiveView(state);
  const dirtyView = getDirtyLbView(state);

  if (!activeView || !dirtyView) {
    return false;
  }

  return !compareLbViewProps(activeView, dirtyView);
}

export const getIsLbViewModified = createSelector(
  [
    areColumnsDirty,
    areLbViewFiltersDirty,
    areSuggestionsDirty,
    areExperimentsOnlyDirty,
    isRunsLineageDirty,
  ],
  (columnsDirty, lbViewFiltersDirty, suggestionsDirty, experimentsOnlyDirty, runsLineageDirty) =>
    columnsDirty ||
    lbViewFiltersDirty ||
    suggestionsDirty ||
    experimentsOnlyDirty ||
    runsLineageDirty,
);

export function isGroupingEnabled(state: AppState): boolean {
  const { lbViewUnpacked } = getCurrentRouteParams(state);

  const activeView = getActiveView(state);
  const rawGroupBy = getRawGroupBy(state);

  // if view is unpacked then rawGroupBy has priority
  if (lbViewUnpacked) {
    if (rawGroupBy == null || rawGroupBy.groupBy == null) {
      return false;
    }

    return rawGroupBy.groupBy.length > 0;
  }

  if (
    activeView == null ||
    activeView.groupOptions == null ||
    activeView.groupOptions.groupBy == null
  ) {
    return false;
  }

  return activeView.groupOptions.groupBy.length > 0;
}

const sortRouteParams = createSelector(
  [
    createCurrentRouteParamSelector('sortBy'),
    createCurrentRouteParamSelector('sortFieldType'),
    createCurrentRouteParamSelector('sortFieldAggregationMode'),
    createCurrentRouteParamSelector('sortDirection'),
  ],
  (sortBy, sortFieldType, sortFieldAggregationMode, sortDirection) => ({
    sortBy,
    sortFieldType,
    sortFieldAggregationMode,
    sortDirection,
  }),
);

const groupByRouteParams = createSelector(
  [
    createCurrentRouteParamSelector('groupBy'),
    createCurrentRouteParamSelector('groupByFieldType'),
    createCurrentRouteParamSelector('groupByFieldAggregationMode'),
  ],
  (groupBy, groupByFieldType, groupByFieldAggregationMode) => ({
    groupBy,
    groupByFieldType,
    groupByFieldAggregationMode,
  }),
);

export const getDirtyLbViewRouteParams = createSelector(
  [
    createCurrentRouteParamSelector('lbViewUnpacked'),
    sortRouteParams,
    groupByRouteParams,
    createCurrentRouteParamSelector('openedGroups'),
    createCurrentRouteParamSelector('suggestionsEnabled'),
    createCurrentRouteParamSelector('query'),
    createCurrentRouteParamSelector('experimentsOnly'),
    createCurrentRouteParamSelector('runsLineage'),
  ],
  (
    lbViewUnpacked,
    { sortBy, sortFieldType, sortFieldAggregationMode, sortDirection },
    { groupBy, groupByFieldType, groupByFieldAggregationMode },
    openedGroups,
    suggestionsEnabled,
    query,
    experimentOnly,
    runsLineage,
  ) => {
    if (!lbViewUnpacked) {
      return {};
    }

    return {
      lbViewUnpacked,
      sortBy,
      sortFieldType,
      sortFieldAggregationMode,
      sortDirection,
      groupBy,
      groupByFieldType,
      groupByFieldAggregationMode,
      openedGroups,
      suggestionsEnabled,
      query,
      experimentOnly,
      runsLineage,
    };
  },
);

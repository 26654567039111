import React from 'react';

import { Button } from '@neptune/shared/venus-ui';

import { SalesLink } from 'components/sales-link/SalesLink';

type AdminActionProps = {
  caption: string;
  withContactSales?: boolean;
  onAction: () => void;
};

export const AdminAction: React.FC<AdminActionProps> = ({
  caption,
  withContactSales,
  onAction,
}) => {
  return (
    <>
      <Button data-role="admin-action" children={caption} onClick={onAction} />
      {withContactSales && <SalesLink children="Contact sales" />}
    </>
  );
};

// Libs
import React from 'react';

import { bemBlock, Button, Icon, Text } from '@neptune/shared/venus-ui';

// App
// Module
import './CreateServiceAccountTile.less';

const block = bemBlock('create-service-account-tile');

type CreateServiceAccountTileProps = {
  onCreate(): void;
};

export const CreateServiceAccountTile: React.FC<CreateServiceAccountTileProps> = ({ onCreate }) => {
  return (
    <Button
      className={block()}
      data-role="create-new-service-account-tile"
      onClick={onCreate}
      withPadding="none"
      variant="transparent"
    >
      <Text size="md">
        <Icon glyph="plus-circle" /> New service account
      </Text>
    </Button>
  );
};

// Libs
import React from 'react';
import { Emojione } from 'react-emoji-render';

import { bemBlock, Layout, Link, Text } from '@neptune/shared/venus-ui';

import { GetStartedVideo } from 'components/get-started-video/GetStartedVideo';

// Module
import { useUserProfile } from './useUserProfile';

import './MobileNotice.less';

const block = bemBlock('mobile-notice');

type MobileNoticeProps = {
  onClick: () => void;
};

export const MobileNotice: React.FC<MobileNoticeProps> = ({ onClick }) => {
  const profile = useUserProfile();
  const userName = profile ? profile.username : undefined;

  const welcomeText = userName
    ? `Great to have you onboard ${userName}!`
    : 'Great to have you onboard!';

  return (
    <Layout.Column className={block()} alignItems="center" overflow="auto" height="100%">
      <Layout.Column className={block('small-padding')} alignItems="center">
        <Text className={block('emoji')}>
          <Emojione text="👋" />
        </Text>
        <Text className={block('header')} fontWeight="semibold">
          {welcomeText}
        </Text>
      </Layout.Column>
      <Text color="text-secondary">What next?</Text>
      <Text className={block('centered')}>
        To have a good first grasp of Neptune we strongly recommend switching to desktop.
      </Text>
      <GetStartedVideo />
      <Layout.Column className={block('small-padding')} alignItems="center">
        <Text className={block('centered')}>or</Text>
        <Link onClick={onClick}>Go to desktop version of the site</Link>
      </Layout.Column>
    </Layout.Column>
  );
};

// Libs
import React from 'react';

import { bemBlock, Button, ButtonProps } from '@neptune/shared/venus-ui';

// App
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';

// Module
import './ButtonWithLoading.less';

type ButtonWithLoadingProps = ButtonProps<{
  loading?: boolean;
  pressed?: boolean;
}>;

const block = bemBlock('button-with-loading');

export const ButtonWithLoading: React.FC<ButtonWithLoadingProps> = ({
  children,
  className,
  loading = false,
  pressed = false,
  ...props
}) => {
  const isPressed = loading || pressed;

  return (
    <Button
      className={block({
        modifiers: {
          loading,
        },
        extra: className,
      })}
      pressed={isPressed}
      {...props}
    >
      <div className={block('loading-wrapper')}>
        <LoadingIndicator
          className={block('indicator')}
          theme="white"
          size="small"
          isVisible={loading}
        />
      </div>
      {children}
    </Button>
  );
};

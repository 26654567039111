export const featureFlags = [
  'withAdminPanel',

  'withRunGroupsV4',

  'withSingleRunAutoFollow',
  'withSectionsInReports',
  'withSectionsInDashboards',

  'withXAxisGlobalSettings',
  'withCompoundSearch',
  'withCompoundSearchWithEditableCriterionItems',
  'withNewAddEditWidgetModal',
  'withNewReportsGlobalConfig',
  'withNewChartssGlobalConfig',
  'withXAxisRangeFilter',
  'withReportWidgetsSearch',
  'withMultiProjectReports',
  'withNewChartConfig',
  'withImprovedMultiRunSeriesVisibility',
] as const;
export type FeatureFlag = (typeof featureFlags)[number];

export type FeatureFlagConfig = `${FeatureFlag}Config`;

import React from 'react';

import { makeProjectIdentifier } from '@neptune/shared/core-project-util';

class WorkingProject {
  constructor(
    public workspaceName: string,
    public projectName: string,
  ) {}

  get projectIdentifier() {
    return makeProjectIdentifier(this.workspaceName, this.projectName);
  }
}

const WorkingProjectContext = React.createContext<WorkingProject | undefined>(undefined);

export const useWorkingProject = () => {
  const workingProject = React.useContext(WorkingProjectContext);

  if (!workingProject) {
    throw new Error('Working project context is not provided');
  }

  return workingProject;
};

export const WorkingProjectProvider: React.FC<{ workspaceName: string; projectName: string }> = ({
  workspaceName,
  projectName,
  children,
}) => {
  const workingProject = React.useMemo(
    () => new WorkingProject(workspaceName, projectName),
    [workspaceName, projectName],
  );
  return (
    <WorkingProjectContext.Provider value={workingProject}>
      {children}
    </WorkingProjectContext.Provider>
  );
};

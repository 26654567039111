import React from 'react';

import {
  getInvitationEntityName,
  getInvitationTarget,
  Invitation,
  isOrganizationInvitation,
} from '@neptune/invitations-domain';
import {
  OrganizationRole,
  organizationRoleLabels,
} from '@neptune/shared/core-organizations-domain';
import { ProjectRole, projectRoleLabels } from '@neptune/shared/core-project-domain';
import { bemBlock, Dialog, Text } from '@neptune/shared/venus-ui';

import './AcceptInvitation.less';

type InvalidInvitationProps = {
  invitation: Invitation;
  onClose: () => void;
};
const block = bemBlock('accept-invitation');

export const InvalidInvitation: React.FC<InvalidInvitationProps> = ({ invitation, onClose }) => {
  const target = getInvitationTarget(invitation);
  const entityString = getInvitationEntityName(invitation);
  const adminRole = isOrganizationInvitation(invitation)
    ? organizationRoleLabels[OrganizationRole.owner]
    : projectRoleLabels[ProjectRole.manager];

  return (
    <div className={block()}>
      <Dialog
        isOpen
        title="Failed"
        closeOnBackgroundClick={false}
        primaryButtonRole="confirm-button"
        onPrimaryButtonClick={onClose}
      >
        Your invite is no longer valid. We couldn't add you to{' '}
        <Text fontWeight="semibold">{entityString}</Text>.<br />
        Please contact {adminRole} of this {target}.
      </Dialog>
    </div>
  );
};

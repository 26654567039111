import { MonitoringTimeUsageDTO, StorageUsage } from '@neptune/shared/core-apis-backend-domain';
import { LeaderboardEntriesSearchResultDTO } from '@neptune/shared/core-apis-leaderboard-domain';

import { ProjectUsageModel } from './project-usage-model';

export abstract class ProjectUsageModelConverter {
  static usageFromApiToDomain(
    leaderboard: LeaderboardEntriesSearchResultDTO,
    storage: StorageUsage,
    monitoring: MonitoringTimeUsageDTO,
  ): ProjectUsageModel {
    return {
      experimentCount: leaderboard.matchingItemCount,
      last30DaysMinutes: monitoring.last30DaysSeconds / 60,
      storageBytes: storage.usage,
      totalMinutes: monitoring.totalSeconds / 60,
    };
  }
}

import { neptuneUrls } from '@neptune/shared/common-domain';

import type { Integration } from './types';

export const xGBoost: Integration = {
  id: 'xgboost',
  title: 'XGBoost',
  installCommand: 'pip install neptune neptune-xgboost scikit-learn',
  scriptName: 'train_xgboost.py',
  runCommand: 'python train_xgboost.py',
  link: neptuneUrls.integrationXgboostDoc,
  script: `import xgboost as xgb
from sklearn.datasets import load_boston
from sklearn.model_selection import train_test_split

import neptune
from neptune.integrations.xgboost import NeptuneCallback

run = neptune.init_run(
    project="<%= projectIdentifier %>",
    api_token="<%= apiToken %>",
)  # your credentials

params = {
    "max_depth": 4,
    "eta": 1,
    "objective": "reg:squarederror",
    "eval_metric": ["mae", "rmse"],
}
run["parameters"] = params

X, y = load_boston(return_X_y=True)
X_train, X_test, y_train, y_test = train_test_split(
    X, y, test_size=0.2, random_state=123
)
dtrain = xgb.DMatrix(X_train, label=y_train)
dval = xgb.DMatrix(X_test, label=y_test)

evals = [(dtrain, "train"), (dval, "valid")]

# You can also use Neptune with XGBoost scikit-learn API
# Check the user guide in the documentation for more details:
# ${neptuneUrls.integrationXgboostDoc}
npt_callback = NeptuneCallback(run=run)

xgb.train(
    params=params,
    dtrain=dtrain,
    num_boost_round=50,
    evals=evals,
    callbacks=[
        npt_callback,
        xgb.callback.LearningRateScheduler(lambda epoch: 0.99 ** epoch),
    ],
)

run.stop()`,
};

// todo npt-13160: move this lib outside of shared
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  SearchCriterion,
  SearchQuery,
  SearchQueryModelConverter,
} from '@neptune/search-query-domain';

import { KnownAttributes } from 'domain/experiment/attribute';

export function buildTrashLeaderboardQuery(trash: boolean) {
  return SearchQueryModelConverter.convertSearchQueryToNql({
    operator: 'and',
    criteria: [
      {
        attribute: KnownAttributes.Trashed,
        operator: '=',
        type: 'bool',
        value: trash,
      },
    ],
  });
}

export function buildSearchLeaderboardQuery(entityType: string, entityId: string, trash: boolean) {
  const metaQuery = buildSearchLeaderboardMetaQuery(entityType, entityId, trash);

  return SearchQueryModelConverter.convertSearchQueryToNql(metaQuery);
}

export function buildSearchLeaderboardMetaQuery(
  parentType: string,
  parentId: string,
  trash: boolean,
  selectedItems?: string[],
): SearchQuery {
  const criteria: Array<SearchQuery | SearchCriterion> = [
    {
      attribute: KnownAttributes.Trashed,
      operator: '=',
      type: 'bool',
      value: trash,
    },
  ];

  if (parentType === 'model') {
    const modelCriterion: SearchCriterion = {
      attribute: KnownAttributes.ModelId,
      operator: '=',
      type: 'string',
      value: parentId,
    };
    criteria.push(modelCriterion);
  }

  if (selectedItems && selectedItems.length > 0) {
    const itemsCriterions: SearchCriterion[] = selectedItems.map((value: string) => ({
      attribute: KnownAttributes.Id,
      operator: '=',
      type: 'string',
      value,
    }));

    const itemsQuery: SearchQuery = {
      operator: 'or',
      criteria: itemsCriterions,
    };
    criteria.push(itemsQuery);
  }

  return {
    operator: 'and',
    criteria,
  };
}

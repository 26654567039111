import React from 'react';

import { Input } from '@neptune/shared/common-ui';
import { formatDateShort } from '@neptune/shared/common-util';
import { Button, Layout, Modal, Text } from '@neptune/shared/venus-ui';

export const LicenseStatusModal: React.FC<{
  isOpen: boolean;
  close?: () => void;
  onSubmitNewLicense?: (licenseNumber: string) => void;
  submitStatusText?: string;
  expirationDate: Date;
}> = ({ isOpen, close, onSubmitNewLicense, submitStatusText, expirationDate }) => {
  const [newLicenseNumber, setNewLicenseNumber] = React.useState('');

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <Modal.Header title="On-prem license" onClose={close} />
      <Modal.Body>
        <Layout.Column spacedChildren="md">
          <p>
            Expires on <Text fontWeight="bold">{formatDateShort(expirationDate)}</Text>.
          </p>
          {submitStatusText ? <Text italic>{submitStatusText}</Text> : null}
          {onSubmitNewLicense && (
            <Layout.Column spacedChildren="xs">
              <Text fontWeight="semibold">New license</Text>
              <Layout.Row spacedChildren="sm">
                <Layout.Column>
                  <Input
                    placeholder="Enter new license number"
                    value={newLicenseNumber}
                    onChange={(e) => setNewLicenseNumber(e.target.value)}
                  />
                </Layout.Column>
                <Button
                  variant="basic"
                  size="lg"
                  disabled={!newLicenseNumber}
                  onClick={() => onSubmitNewLicense?.(newLicenseNumber)}
                >
                  Apply
                </Button>
              </Layout.Row>
            </Layout.Column>
          )}
        </Layout.Column>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Button variant="primary" onClick={close} children="Close" />
      </Modal.Footer>
    </Modal>
  );
};

import React from 'react';

import type { FilterResultItem } from '@neptune/shared/search-domain';
import {
  ActiveListElementChildrenProps,
  ActiveListElementProvider,
  bemBlock,
  ScrollIntoView,
} from '@neptune/shared/venus-ui';

import { FilterSearchItem, FilterSearchItemProps } from './FilterSearchItem';

import './FilterSearchResults.less';

interface FilterSearchResultsProps<T> {
  query: string;
  results: FilterResultItem<T>[];
  onSelect?: (value: T) => void;
  RenderItem?: React.ComponentType<FilterSearchItemProps<T>>;
  className?: string;
  loading?: boolean;
  error?: boolean;
}

const block = bemBlock('filter-search-results');

export function FilterSearchResults<T>({
  query,
  results,
  onSelect,
  RenderItem = FilterSearchItem,
  className,
  loading,
  error,
}: FilterSearchResultsProps<T>) {
  const selectItem = React.useCallback(
    <E,>(_: E, entry: FilterResultItem<T>) => onSelect && onSelect(entry.original),
    [onSelect],
  );

  return (
    <div
      className={block({ extra: className })}
      data-query={query}
      data-loaded={!loading && !error}
    >
      <ActiveListElementProvider
        entries={results}
        onActivate={selectItem}
        onKeyDown={(event: Event) => event.preventDefault()}
      >
        {({
          entry,
          select,
          isSelected: isActive,
          index,
        }: ActiveListElementChildrenProps<FilterResultItem<T>>) => (
          <ScrollIntoView key={index} enabled={isActive}>
            <RenderItem
              onClick={(event) => {
                event.preventDefault();
                selectItem(event, entry);
                select(event);
              }}
              value={entry.original}
              match={entry.string}
              active={isActive}
            />
          </ScrollIntoView>
        )}
      </ActiveListElementProvider>
    </div>
  );
}

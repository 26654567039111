import { TextWrapping, TextWrappingConverter } from '@neptune/shared/common-domain';

import { storage } from 'common/storage';

export type TextWrappingState = Record<
  string,
  Record<string, TextWrapping | undefined> | undefined
>;

const localStorageKey = 'textWrapping';

export const getInitialState = (): TextWrappingState => {
  const state = storage.local.getItem(localStorageKey);

  if (!state) {
    return {};
  }

  return Object.keys(state).reduce((result: TextWrappingState, key) => {
    const value = state[key];

    if (value) {
      result[key] = Object.keys(value).reduce((acc: Record<string, TextWrapping>, current) => {
        if (value[current]) {
          acc[current] = TextWrappingConverter.fromLocalStorageToDomain(
            value[current],
            TextWrapping.Clip,
          );
        }

        return acc;
      }, {});
    }

    return result;
  }, {});
};

export const storeState = (state: TextWrappingState) => {
  storage.local.setItem(localStorageKey, state);
};

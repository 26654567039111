// Libs
import React from 'react';

import type { GlyphName } from '@neptune/shared/core-glyphs-domain';
import { bemBlock, Icon, Layout, Link } from '@neptune/shared/venus-ui';

// App
import { loadState, persistState } from 'common/localStorage';

// Module
import { isSupportedOs, SupportedOs } from './os-util';

import './TextByOs.less';

type TextByOsProps = { [key in SupportedOs]: React.ReactElement };

type Os = {
  id: SupportedOs;
  label: string;
  glyph: GlyphName;
};

const supportedOs: Os[] = [
  {
    id: 'linux',
    label: 'Linux',
    glyph: 'linux',
  },
  {
    id: 'mac',
    label: 'macOS',
    glyph: 'apple',
  },
  {
    id: 'windows',
    label: 'Windows',
    glyph: 'windows',
  },
];

const block = bemBlock('text-by-os');

const TextByOs: React.FC<TextByOsProps> = (props) => {
  const [selectedId, setSelectedId] = React.useState<SupportedOs>(getDefaultBrowser);

  const callback = React.useCallback(
    (id: SupportedOs) => {
      setSelectedId(id);
      persistState({ os: id });
    },
    [setSelectedId],
  );

  return (
    <Layout.Column className={block()} spacedChildren="md">
      <Layout.Row span="auto" spacedChildren="md">
        {supportedOs.map((os) => (
          <Link
            key={os.id}
            className={block({ element: 'os', modifiers: { clickable: os.id !== selectedId } })}
            onClick={() => callback(os.id)}
          >
            <Layout.Row span="auto" alignItems="center" spacedChildren="sm">
              <Icon glyph={os.glyph} size="lg" />
              <span>{os.label}</span>
            </Layout.Row>
          </Link>
        ))}
      </Layout.Row>
      {props[selectedId]}
    </Layout.Column>
  );
};

function getDefaultBrowser() {
  const state = loadState();

  if (state && state.os && isSupportedOs(state.os)) {
    return state.os;
  }

  if (window.navigator.userAgent.indexOf('Windows') !== -1) {
    return 'windows';
  }

  if (window.navigator.userAgent.indexOf('Mac') !== -1) {
    return 'mac';
  }

  return 'linux';
}

export default TextByOs;

import { navigateTo } from 'router/actions';

import {
  Invitation,
  isOrganizationInvitation,
  isProjectInvitation,
} from '@neptune/invitations-domain';
import { OrganizationType } from '@neptune/shared/core-organizations-domain';

export type NavigateToParams = Parameters<typeof navigateTo>;

export function getInvitationNavigationParams(
  invitation: Invitation,
): NavigateToParams | undefined {
  if (isOrganizationInvitation(invitation)) {
    const { organizationName } = invitation;
    return ['organization.projects', { organizationName }, { forceContext: OrganizationType.team }];
  }

  if (isProjectInvitation(invitation)) {
    const { organizationName, projectName } = invitation;
    return [
      'project',
      { organizationName, projectName },
      { forceContext: OrganizationType.individual },
    ];
  }
}

export function getHomeNavigationParams(): NavigateToParams {
  return ['home', undefined, { replace: true }];
}

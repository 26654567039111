// Libs
import React from 'react';

import { UserAvatar } from '@neptune/shared/avatars-ui';
import { Layout, Link, Text } from '@neptune/shared/venus-ui';

// App
import { UserProfileLink } from 'components/user-panel/user-profile-link/UserProfileLink';

type AvatarGetter = (params: { username: string }) => string;

// Module
export const UserWithAvatar = ({
  avatarGetter,
  user,
}: {
  user: string;
  avatarGetter?: AvatarGetter;
}) => {
  return (
    <Layout.Row spacedChildren="xs" overflow="hidden">
      <UserProfileLink userName={user}>
        <UserAvatar username={user} size="xs" avatarGetter={avatarGetter} />
      </UserProfileLink>
      <Text ellipsis>
        <UserProfileLink component={Link} userName={user} children={user} />
      </Text>
    </Layout.Row>
  );
};

import { invert } from 'lodash';

import { ArrayElementType } from 'common/utility-types';

export const periods: string[] = ['day', 'week', 'month', 'quarter', 'year'];
export type Period = ArrayElementType<typeof periods>;

export const valueToLabel: Record<Period, string> = {
  '-1d': 'day',
  '-7d': 'week',
  '-1M': 'month',
  '-3M': 'quarter',
  '-1y': 'year',
};

export const labelToValue = invert(valueToLabel);

const periodValueRegexp = /^-\d[smhdwMy]$/;

export function isPeriodValue(value?: any): value is string {
  return typeof value === 'string' && periodValueRegexp.test(value);
}

// Libs
import React from 'react';

import { Section, Text } from '@neptune/shared/venus-ui';

// App
import { BenefitsList } from 'components/benefits-list/BenefitsList';

export type BenefitsProps = {
  caption: string;
  items: React.ReactNode[];
};

export const Benefits: React.FC<BenefitsProps> = ({ caption, items }) => {
  return (
    <Section opaque="grey" spacedChildren="md" withPadding="lg">
      <Text fontWeight="semibold" color="text" children={caption} />
      <BenefitsList items={items} spacedChildren="md" iconColor="info" />
    </Section>
  );
};

import { RouterSimpleState } from '@neptune/shared/routing-domain';

import { loadState } from 'common/localStorage';
import { getPageId } from 'common/pages';

import { isValidCompareTab } from '../../isValidCompareTab';
import { isValidRunDetailsTab } from '../../isValidRunDetailsTab';

type LbViewRecoveryParams = { viewId?: string };

export function getLbViewRecoveryParams(projectStorageKey: string): LbViewRecoveryParams {
  const { lbViewIdByProject = {} } = loadState();
  const targetViewId = lbViewIdByProject[projectStorageKey];

  return { viewId: targetViewId };
}

const DEFAULT_SUB_TAB = 'charts';

type RunDetailsRecoveryParams =
  | { tab: ValidTab; dash: string }
  | { detailsTab?: string; dashboardId?: string };

export function getRunDetailsRecoveryParams(
  projectStorageKey: string,
  toState: RouterSimpleState,
): RunDetailsRecoveryParams {
  const { lastExperimentDashboardTab } = loadState();
  const restoredParams: RunDetailsRecoveryParams = {};

  // run details route without runIdentificationKey is invalid
  if (!toState.params.runIdentificationKey) {
    return { tab: 'compare', dash: DEFAULT_SUB_TAB };
  }

  if (toState.params.detailsTab == null || !isValidRunDetailsTab(toState.params.detailsTab)) {
    restoredParams['detailsTab'] = isValidRunDetailsTab(lastExperimentDashboardTab)
      ? lastExperimentDashboardTab
      : DEFAULT_SUB_TAB;
  }

  if (toState.params.detailsTab === 'dashboard' || restoredParams['detailsTab'] === 'dashboard') {
    if (toState.params.dashboardId == null) {
      const { lastExperimentDashboardIds = {} } = loadState();

      const lastExperimentDashboardId = lastExperimentDashboardIds[projectStorageKey];

      if (lastExperimentDashboardId !== null) {
        restoredParams['dashboardId'] = lastExperimentDashboardId;
      }
    }
  }

  return restoredParams;
}

type RunsCompareRecoveryParams = {
  dash?: string;
  dashboardId?: string;
};

export function getRunsCompareRecoveryParams(
  projectStorageKey: string,
  toState: RouterSimpleState,
): RunsCompareRecoveryParams {
  const restoredParams: RunsCompareRecoveryParams = {};

  // to ensure backward compatibility for old URLs, where compare dashboard id is stored in "dash", not in dashboardId
  if (!!getPageId(toState.params.dash)) {
    restoredParams['dash'] = 'dashboard';
    restoredParams['dashboardId'] = toState.params.dash;

    return restoredParams;
  }

  const { compareTabByProject = {}, lastCompareDashboardIds = {} } = loadState();

  if (toState.params.dash == null || !isValidCompareTab(toState.params.dash)) {
    const lastCompareTabRaw = compareTabByProject[projectStorageKey];
    const lastCompareTab = isValidCompareTab(lastCompareTabRaw)
      ? lastCompareTabRaw
      : DEFAULT_SUB_TAB;

    restoredParams['dash'] = lastCompareTab;

    if (lastCompareTab === 'dashboard') {
      restoredParams['dashboardId'] = lastCompareDashboardIds[projectStorageKey];
    }
  }

  if (toState.params.dash === 'dashboard' && toState.params.dashboardId == null) {
    const lastCompareDashboardId = lastCompareDashboardIds[projectStorageKey];

    if (lastCompareDashboardId !== null) {
      restoredParams['dashboardId'] = lastCompareDashboardId;
    }
  }

  return restoredParams;
}

type ValidTab = 'table' | 'details' | 'compare';

export function getLastProjectRunsTabRecovery(
  defaultMainTab: ValidTab,
  defaultSubTab: string = DEFAULT_SUB_TAB,
): {
  tab: ValidTab;
  dash?: string;
} {
  const { lastProjectRunsTab } = loadState();

  if (lastProjectRunsTab === undefined) {
    return {
      tab: defaultMainTab,
      dash: defaultSubTab,
    };
  }

  return {
    tab: lastProjectRunsTab,
  };
}

import { AggregationMode, ColumnIdentifier } from '@neptune/shared/leaderboard-domain';

import { AttributeDefinition } from 'domain/experiment/attribute';

export function columnIdentifierToAttributeDef({
  id,
  columnType,
  aggregationMode,
}: ColumnIdentifier): AttributeDefinition {
  return {
    name: id,
    type: columnType,
    subproperty: aggregationMode,
  };
}

export function attributeDefToColumnIdentifier({
  name,
  type,
  subproperty,
}: AttributeDefinition): ColumnIdentifier {
  return {
    id: name,
    columnType: type,
    aggregationMode: subproperty as AggregationMode,
  };
}

import type Stripe from 'stripe';

import { fetchStatus as FetchStatus } from '@neptune/shared/legacy-domain';

import { PastInvoicesActions, PastInvoicesActionTypes } from './past-invoices-actions';

export type PastInvoicesState = {
  entries?: Stripe.invoices.IInvoice[];
  fetchStatus: FetchStatus;
};

const initialState: PastInvoicesState = {
  fetchStatus: FetchStatus.NONE,
};

export const pastInvoicesReducer = (state = initialState, action: PastInvoicesActions) => {
  switch (action.type) {
    case PastInvoicesActionTypes.request: {
      return {
        ...state,
        fetchStatus: FetchStatus.PENDING,
      };
    }

    case PastInvoicesActionTypes.success: {
      const { organizationIdentifier, entries } = action.payload;

      return {
        ...state,
        organizationIdentifier,
        entries,
        fetchStatus: FetchStatus.SUCCESS,
      };
    }

    case PastInvoicesActionTypes.fail: {
      return {
        ...state,
        fetchStatus: FetchStatus.FAILED,
      };
    }

    default: {
      return state;
    }
  }
};

import React from 'react';

import { bemBlock, DropdownToggleProps } from '@neptune/shared/venus-ui';

import { FilterSearchProps } from './interfaces';

const block = bemBlock('filter-search-dropdown-toggle');

export const FilterSearchDropdownToggle = <T,>({
  autoFocus,
  className,
  elementRef,
  pressed,
  input,
  onCollapse,
  onBlur,
  onExpand,
  onFocus,
}: DropdownToggleProps & {
  autoFocus?: boolean;
  className?: string;
  input: React.ReactElement;
  onBlur?: (event: React.FocusEvent) => void;
  onFocus?: (event: React.FocusEvent) => void;
} & Pick<FilterSearchProps<T>, 'variant' | 'placeholder'>) =>
  React.cloneElement(input, {
    autoFocus,
    className: block({
      element: 'input',
      extra: className,
    }),
    onBlur,
    onFocus: (event: React.FocusEvent) => {
      onExpand?.(event);
      onFocus?.(event);
    },
    onKeyDown: (event: React.KeyboardEvent) => {
      if (pressed && onCollapse && event.key === 'Escape') {
        onCollapse(event);
      }

      if (!pressed && onExpand && event.key !== 'Escape') {
        onExpand(event);
      }

      input.props.onKeyDown?.(event);
    },
    elementRef,
  });

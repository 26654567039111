import React from 'react';

import { bemBlock, Layout } from '@neptune/shared/venus-ui';

import { ApiTokenLayoutComponentProps } from '../api-token-renderer/ApiTokenRenderer';

import './ApiTokenLayout.less';

const block = bemBlock('api-token-layout');

export const ApiTokenLayout: React.FC<ApiTokenLayoutComponentProps> = ({
  apiTokenText,
  copyToClipboardButton,
  showTokenIcon,
}) => {
  return (
    <Layout.Row className={block()} spacedChildren="xs" alignItems="center">
      <Layout.Row className={block('container')} span="greedy" spacedChildren="xs">
        {apiTokenText}
        <Layout.Column className={block('copy-button')} width={22} justifyContent="center">
          {copyToClipboardButton}
        </Layout.Column>
      </Layout.Row>
      {showTokenIcon}
    </Layout.Row>
  );
};

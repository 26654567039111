/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ServiceAccountsTraitDTO } from './ServiceAccountsTraitDTO';
import {
    ServiceAccountsTraitDTOFromJSON,
    ServiceAccountsTraitDTOFromJSONTyped,
    ServiceAccountsTraitDTOToJSON,
} from './ServiceAccountsTraitDTO';
import type { ActiveProjectsTraitDTO } from './ActiveProjectsTraitDTO';
import {
    ActiveProjectsTraitDTOFromJSON,
    ActiveProjectsTraitDTOFromJSONTyped,
    ActiveProjectsTraitDTOToJSON,
} from './ActiveProjectsTraitDTO';
import type { DashboardsLimitsTraitDTO } from './DashboardsLimitsTraitDTO';
import {
    DashboardsLimitsTraitDTOFromJSON,
    DashboardsLimitsTraitDTOFromJSONTyped,
    DashboardsLimitsTraitDTOToJSON,
} from './DashboardsLimitsTraitDTO';
import type { TableViewsLimitsTraitDTO } from './TableViewsLimitsTraitDTO';
import {
    TableViewsLimitsTraitDTOFromJSON,
    TableViewsLimitsTraitDTOFromJSONTyped,
    TableViewsLimitsTraitDTOToJSON,
} from './TableViewsLimitsTraitDTO';
import type { ModelRegistryLimitsTraitDTO } from './ModelRegistryLimitsTraitDTO';
import {
    ModelRegistryLimitsTraitDTOFromJSON,
    ModelRegistryLimitsTraitDTOFromJSONTyped,
    ModelRegistryLimitsTraitDTOToJSON,
} from './ModelRegistryLimitsTraitDTO';
import type { OperationsLimitTraitDTO } from './OperationsLimitTraitDTO';
import {
    OperationsLimitTraitDTOFromJSON,
    OperationsLimitTraitDTOFromJSONTyped,
    OperationsLimitTraitDTOToJSON,
} from './OperationsLimitTraitDTO';
import type { PlanUpgradeTraitDTO } from './PlanUpgradeTraitDTO';
import {
    PlanUpgradeTraitDTOFromJSON,
    PlanUpgradeTraitDTOFromJSONTyped,
    PlanUpgradeTraitDTOToJSON,
} from './PlanUpgradeTraitDTO';
import type { ProjectVisibilityRestrictedTraitDTO } from './ProjectVisibilityRestrictedTraitDTO';
import {
    ProjectVisibilityRestrictedTraitDTOFromJSON,
    ProjectVisibilityRestrictedTraitDTOFromJSONTyped,
    ProjectVisibilityRestrictedTraitDTOToJSON,
} from './ProjectVisibilityRestrictedTraitDTO';

/**
 * 
 * @export
 * @interface OrganizationTraitsSetDTO
 */
export interface OrganizationTraitsSetDTO {
    /**
     * 
     * @type {ModelRegistryLimitsTraitDTO}
     * @memberof OrganizationTraitsSetDTO
     */
    modelRegistryLimits?: ModelRegistryLimitsTraitDTO;
    /**
     * 
     * @type {object}
     * @memberof OrganizationTraitsSetDTO
     */
    uiWorkspaceSwitching?: object;
    /**
     * 
     * @type {PlanUpgradeTraitDTO}
     * @memberof OrganizationTraitsSetDTO
     */
    planUpgrade?: PlanUpgradeTraitDTO;
    /**
     * 
     * @type {TableViewsLimitsTraitDTO}
     * @memberof OrganizationTraitsSetDTO
     */
    tableViewsLimits?: TableViewsLimitsTraitDTO;
    /**
     * 
     * @type {ProjectVisibilityRestrictedTraitDTO}
     * @memberof OrganizationTraitsSetDTO
     */
    projectVisibilityRestricted?: ProjectVisibilityRestrictedTraitDTO;
    /**
     * 
     * @type {DashboardsLimitsTraitDTO}
     * @memberof OrganizationTraitsSetDTO
     */
    dashboardsLimits?: DashboardsLimitsTraitDTO;
    /**
     * 
     * @type {ActiveProjectsTraitDTO}
     * @memberof OrganizationTraitsSetDTO
     */
    activeProjects?: ActiveProjectsTraitDTO;
    /**
     * 
     * @type {OperationsLimitTraitDTO}
     * @memberof OrganizationTraitsSetDTO
     */
    operationLimit?: OperationsLimitTraitDTO;
    /**
     * 
     * @type {ServiceAccountsTraitDTO}
     * @memberof OrganizationTraitsSetDTO
     */
    serviceAccounts?: ServiceAccountsTraitDTO;
}

/**
 * Check if a given object implements the OrganizationTraitsSetDTO interface.
 */
export function instanceOfOrganizationTraitsSetDTO(value: object): value is OrganizationTraitsSetDTO {
    return true;
}

export function OrganizationTraitsSetDTOFromJSON(json: any): OrganizationTraitsSetDTO {
    return OrganizationTraitsSetDTOFromJSONTyped(json, false);
}

export function OrganizationTraitsSetDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationTraitsSetDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'modelRegistryLimits': json['modelRegistryLimits'] == null ? undefined : ModelRegistryLimitsTraitDTOFromJSON(json['modelRegistryLimits']),
        'uiWorkspaceSwitching': json['uiWorkspaceSwitching'] == null ? undefined : json['uiWorkspaceSwitching'],
        'planUpgrade': json['planUpgrade'] == null ? undefined : PlanUpgradeTraitDTOFromJSON(json['planUpgrade']),
        'tableViewsLimits': json['tableViewsLimits'] == null ? undefined : TableViewsLimitsTraitDTOFromJSON(json['tableViewsLimits']),
        'projectVisibilityRestricted': json['projectVisibilityRestricted'] == null ? undefined : ProjectVisibilityRestrictedTraitDTOFromJSON(json['projectVisibilityRestricted']),
        'dashboardsLimits': json['dashboardsLimits'] == null ? undefined : DashboardsLimitsTraitDTOFromJSON(json['dashboardsLimits']),
        'activeProjects': json['activeProjects'] == null ? undefined : ActiveProjectsTraitDTOFromJSON(json['activeProjects']),
        'operationLimit': json['operationLimit'] == null ? undefined : OperationsLimitTraitDTOFromJSON(json['operationLimit']),
        'serviceAccounts': json['serviceAccounts'] == null ? undefined : ServiceAccountsTraitDTOFromJSON(json['serviceAccounts']),
    };
}

export function OrganizationTraitsSetDTOToJSON(value?: OrganizationTraitsSetDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'modelRegistryLimits': ModelRegistryLimitsTraitDTOToJSON(value['modelRegistryLimits']),
        'uiWorkspaceSwitching': value['uiWorkspaceSwitching'],
        'planUpgrade': PlanUpgradeTraitDTOToJSON(value['planUpgrade']),
        'tableViewsLimits': TableViewsLimitsTraitDTOToJSON(value['tableViewsLimits']),
        'projectVisibilityRestricted': ProjectVisibilityRestrictedTraitDTOToJSON(value['projectVisibilityRestricted']),
        'dashboardsLimits': DashboardsLimitsTraitDTOToJSON(value['dashboardsLimits']),
        'activeProjects': ActiveProjectsTraitDTOToJSON(value['activeProjects']),
        'operationLimit': OperationsLimitTraitDTOToJSON(value['operationLimit']),
        'serviceAccounts': ServiceAccountsTraitDTOToJSON(value['serviceAccounts']),
    };
}


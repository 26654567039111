import React from 'react';

import { Input } from '@neptune/shared/common-ui';
import { bemBlock, Layout, RadioWithLabel } from '@neptune/shared/venus-ui';

import { KeycloakLabel } from 'components/keycloak';

import { RoleGroup, RoleGroupProps } from './RoleGroup';

import './RoleForm.less';

const block = bemBlock('role-form');

const roleGroups = [
  ['Data Scientist', 'ML Engineer', 'MLOps Engineer'],
  ['Software Engineer', 'DevOps Engineer'],
  ['ML Team Lead', 'ML Platform Lead', 'Head/C-level'],
  ['Student / Intern', 'AI Researcher'],
];

const otherRole = 'Other';

type RoleFormProps = {
  data: {
    role?: string;
    otherRoleDescription?: string;
  };
  onChange: RoleGroupProps['onChange'];
};

export const NewRoleForm: React.FC<RoleFormProps> = ({ data, onChange }) => {
  return (
    <Layout.Column component="form" spacedChildren="md" data-role="role-form" className={block()}>
      <KeycloakLabel>What best describes your role?</KeycloakLabel>
      <Layout.Column spacedChildren="md" span="auto">
        {roleGroups.map((group) => (
          <RoleGroup key={group.join(' ')} group={group} onChange={onChange} role={data.role} />
        ))}
        <RadioWithLabel
          size="base"
          key={otherRole}
          data-role={otherRole}
          className={block('other')}
          id={otherRole}
          checked={data.role === otherRole}
          onChange={() => onChange({ role: otherRole })}
          label={
            data.role === otherRole ? (
              <>
                {otherRole}
                <Input
                  className={block('narrow-input')}
                  value={data.otherRoleDescription || ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange && onChange({ otherRoleDescription: event.target.value })
                  }
                  placeholder="Please describe"
                />
              </>
            ) : (
              otherRole
            )
          }
          value={otherRole}
        />
      </Layout.Column>
    </Layout.Column>
  );
};

import React from 'react';

import { useUpgradeExpiredPlan } from '@neptune/pricing-commons-api';

import { TrialExpiredView } from './TrialExpiredView';

export const TrialExpired: React.FC = ({}) => {
  const { upgradePlan, workspaceName } = useUpgradeExpiredPlan();

  if (!workspaceName) {
    return null;
  }

  return <TrialExpiredView upgradePlan={upgradePlan} teamName={workspaceName} />;
};

import React from 'react';

import { Dialog } from '@neptune/shared/venus-ui';

import { VisibilityChangeConfirmationModalProps } from './interface';

export const PublicPrivacyChangeConfirmationModal: React.FC<
  VisibilityChangeConfirmationModalProps
> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Dialog
      isOpen={isOpen}
      title="Make the project public"
      primaryButtonLabel="Save"
      primaryButtonRole="visibility-confirm"
      secondaryButtonLabel="Cancel"
      onPrimaryButtonClick={onConfirm}
      onSecondaryButtonClick={onClose}
    >
      You are about to make this project public. Anyone on the Internet will be able to find and see
      this project.
      <br />
      Do you want to continue?
    </Dialog>
  );
};

import { isEqual, maxBy, sortBy } from 'lodash';

import {
  compactGridLayouts,
  createFoldingRowOfLayouts,
  extractSectionItems,
  getValidLayoutXAndWidth,
  insertLayoutsForNewItems,
} from '@neptune/shared/grid-layout-business-logic';
import {
  compareRectsByYX,
  GRID_LAYOUT_COLUMNS,
  LayoutMinDimensions,
  LayoutRect,
  NewLayoutPlacement,
  RGLLayout,
} from '@neptune/shared/grid-layout-domain';

import { naturalStringComparator } from 'common/tools';
import {
  Dashboard,
  getDefaultWidgetHeight,
  getWidgetDefaultDimensions,
  getWidgetMinimumDimensions,
  WidgetDefaultDimensionsTable,
  WidgetLayouts,
} from 'domain/dashboard';
import {
  attributeDefinitionToWidgetSource,
  extractAttributeDefinitions,
  isChartWidget,
  isImageWidget,
  Widget,
  WidgetLayout,
  WidgetType,
} from 'domain/widget';
import { isSectionWidget } from 'domain/widget/support/section-widget';

export function rglLayoutToWidgetLayout({ i, x, y, w, h }: RGLLayout): WidgetLayout {
  return { id: i, x, y, w, h };
}

export function getValidLayoutRectWithMinimalDimensions(
  layout: LayoutRect,
  widgetType: WidgetType,
): LayoutRect & LayoutMinDimensions {
  const { minW, minH } = getWidgetMinimumDimensions(widgetType);
  return {
    ...getValidLayoutXAndWidth(layout, minW),
    y: layout.y,
    h: Math.max(layout.h, minH ?? 0),
    minW,
    minH,
  };
}

export function widgetLayoutsToCompactedGridLayouts(
  widgetLayouts: WidgetLayouts,
  widgetsTypeMap: Partial<Record<string, WidgetType>>,
): RGLLayout[] {
  // Apply all constraints (minimums, compaction) and conversion so that these
  // layouts can be used directly in ReactGridLayout.
  const gridLayouts: RGLLayout[] = [];

  for (const layout of widgetLayouts) {
    const widgetType = widgetsTypeMap[layout.id];

    // Drop layouts that don't have matching widget. This should never happen, but it's a good
    // sanity check.
    if (widgetType) {
      gridLayouts.push({
        i: layout.id,
        ...getValidLayoutRectWithMinimalDimensions(layout, widgetType),
      });
    }
  }

  compactGridLayouts(gridLayouts);
  return gridLayouts;
}

function getWidgetIdFromLayout(layout: WidgetLayout) {
  return layout.id;
}

export function findSectionsIds(widgets: Widget[]): Set<string> {
  return widgets.reduce(
    (result, widget) => (isSectionWidget(widget) ? result.add(widget.id) : result, result),
    new Set<string>(),
  );
}

export function findWidgetIdsFromSection(
  sectionId: string,
  layouts: WidgetLayouts,
  isSection: (widgetId: string) => boolean,
): Set<string> {
  const isSectionWidgetLayout = (layout: WidgetLayout) => isSection(layout.id);
  const sectionItemLayouts = extractSectionItems(
    layouts,
    sectionId,
    isSectionWidgetLayout,
    getWidgetIdFromLayout,
  );

  return sectionItemLayouts.reduce((result, { id }) => (result.add(id), result), new Set<string>());
}

export function insertLayoutsForNewWidgets(
  layouts: WidgetLayouts,
  placement: NewLayoutPlacement,
  widgets: Widget[],
  isSection: (layout: WidgetLayout) => boolean,
  defaultDimensionsTable?: WidgetDefaultDimensionsTable,
) {
  const itemRequests = widgets.map(({ id, type }) => ({
    id,
    ...getWidgetDefaultDimensions(type, defaultDimensionsTable),
  }));

  const getSectionKey = ({ id }: WidgetLayout) => id;

  return insertLayoutsForNewItems({
    layouts,
    placement,
    itemRequests,
    isSection,
    getSectionKey,
    createItemLayout: ({ id }, rect: LayoutRect) => ({ id, ...rect }),
  });
}

export function getLayoutForFilteredWidgets(layouts: WidgetLayouts, filteredWidgets: Widget[]) {
  const getSectionKey = ({ id }: WidgetLayout) => id;

  return insertLayoutsForNewItems({
    layouts: [],
    placement: { at: 'top' },
    itemRequests: layouts
      .filter((layout) => filteredWidgets.some((widget) => widget.id === layout.id))
      .sort(compareRectsByYX),
    isSection: () => false,
    getSectionKey,
    createItemLayout: ({ id }, rect: LayoutRect) => ({ id, ...rect }),
  });
}

export function getLayoutForWidgets(
  widgets: Widget[],
  isExpanded: boolean,
  defaultDimensionsTable?: WidgetDefaultDimensionsTable,
): WidgetLayouts {
  const defaultDimensions = widgets.map(({ id, type }) => ({
    id,
    ...getWidgetDefaultDimensions(type, defaultDimensionsTable),
  }));
  const layoutRequests = isExpanded
    ? defaultDimensions.map(({ id, h }) => ({
        id,
        w: GRID_LAYOUT_COLUMNS,
        h,
      }))
    : defaultDimensions;

  return createFoldingRowOfLayouts(layoutRequests, ({ id }, rect) => ({
    id,
    ...rect,
  }));
}

export function areLayoutsEqual(first: WidgetLayouts, second: WidgetLayouts): boolean {
  return isEqual(sortBy(first, 'id'), sortBy(second, 'id'));
}

export function generateComparableDashboard(
  dashboard: Dashboard,
  widgetDefaultHeightOverride?: Partial<Record<WidgetType, number>>,
): Dashboard | undefined {
  const widgets = generateComparableWidgets(dashboard.widgets);

  if (!widgets.length) {
    return;
  }

  return {
    ...dashboard,
    autoGenerated: true,
    widgets,
    gridLayouts: calculateCompareWidgetLayouts(
      widgets,
      dashboard.gridLayouts,
      widgetDefaultHeightOverride,
    ),
  };
}

function generateComparableWidgets(widgets: Widget[]): Widget[] {
  return widgets.reduce((result: Widget[], widget) => {
    // TODO: consider if we should take care about custom-y-expression charts (with no raw-attributes inside)
    const widgetAttributes = extractAttributeDefinitions(widget);

    if (isChartWidget(widget) && widgetAttributes) {
      // single-attribute widget can be added as is
      if (widgetAttributes.length === 1) {
        result.push(widget);
        // split multi-attribute widget into separate widgets
      } else if (widgetAttributes.length > 1) {
        const { name, ...widgetProps } = widget;

        widgetAttributes.forEach((attribute, i) => {
          // don't add duplicates
          if (
            !result.some(
              ({ sources }) =>
                sources[0].value === attribute.name &&
                sources[0].metadata?.attributeType === attribute.type &&
                sources[0].metadata.subproperty === attribute.subproperty,
            )
          ) {
            result.push({
              ...widgetProps,
              id: `${widget.id}--${i}`,
              sources: [attributeDefinitionToWidgetSource(attribute)],
            });
          }
        });
      }
    }

    if (isImageWidget(widget)) {
      result.push({
        ...widget,
        type: 'imageComparison',
      });
    }

    return result;
  }, []);
}

function calculateCompareWidgetLayouts(
  widgets: Widget[],
  layouts: WidgetLayouts,
  widgetDefaultHeightOverride?: Partial<Record<WidgetType, number>>,
): WidgetLayouts {
  return getCompareLayouts(widgets, layouts, widgetDefaultHeightOverride);
}

function getCompareLayouts(
  widgets: Widget[],
  layouts: WidgetLayouts,
  widgetDefaultHeightOverride?: Partial<Record<WidgetType, number>>,
): WidgetLayout[] {
  // primarily sort widgets by their position in the dashboard (left -> right, top -> bottom)
  const sortedLayouts = sortBy(layouts, 'x', 'y');
  const layoutsOrder = sortedLayouts.map(({ id }) => id);

  return widgets
    .sort((a, b) => {
      const uidA = a.id.split('--')[0];
      const uidB = b.id.split('--')[0];
      const indexA = layoutsOrder.indexOf(uidA);
      const indexB = layoutsOrder.indexOf(uidB);

      return (
        indexA - indexB ||
        // in case of widgets generated from widgets with multiple attributes, sort them by attribute names
        naturalStringComparator(a.sources?.[0].value, b.sources?.[0].value)
      );
    })
    .reduce((result: WidgetLayout[], widget) => {
      const uid = widget.id.split('--')[0];
      const layout = sortedLayouts.find(({ id }) => uid === id);
      const lowest = maxBy(result, 'y') || { h: 0, y: 0 };

      result.push({
        id: widget.id,
        x: 0,
        y: lowest.y + lowest.h,
        w: GRID_LAYOUT_COLUMNS,
        h: Math.max(
          layout?.h ?? 0,
          getDefaultWidgetHeight(widget.type, widgetDefaultHeightOverride),
        ),
      });

      return result;
    }, []);
}

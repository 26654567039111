import React from 'react';

import { DEFAULT_SORT_OPTIONS, LeaderboardSortingParams } from '@neptune/shared/leaderboard-domain';

export type SortOptionsContextValue = [
  LeaderboardSortingParams | undefined,
  (options: LeaderboardSortingParams) => void,
];

export const SortOptionsContext = React.createContext<SortOptionsContextValue>([
  DEFAULT_SORT_OPTIONS,
  () => {},
]);

export const useSortOptions = (): [
  LeaderboardSortingParams,
  (options: LeaderboardSortingParams) => void,
] => {
  const [sortOptions, setSortOptions] = React.useContext(SortOptionsContext);

  return [sortOptions || DEFAULT_SORT_OPTIONS, setSortOptions];
};

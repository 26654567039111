// Libs
import { toString } from 'lodash';
import { createSelector } from 'reselect';
import { getNormalizedMultipleURLParam } from 'router/router-utils';

import { GroupOptions, SortOptions } from '@neptune/shared/leaderboard-domain';

// App
import { createCurrentRouteParamSelector } from 'state/selectors-global';

export const getRawQuery = createSelector([createCurrentRouteParamSelector('query')], (query) =>
  toString(query),
);

export const getRawSortOptions = createSelector(
  [
    createCurrentRouteParamSelector('sortBy'),
    createCurrentRouteParamSelector('sortDirection'),
    createCurrentRouteParamSelector('sortFieldType'),
    createCurrentRouteParamSelector('sortFieldAggregationMode'),
  ],
  (sortBy, sortDirection, sortFieldType, sortFieldAggregationMode): SortOptions | undefined => {
    if (!sortBy || !sortDirection || !sortFieldType) {
      return undefined;
    }

    return {
      sortBy: getNormalizedMultipleURLParam(sortBy),
      sortDirection: getNormalizedMultipleURLParam(sortDirection),
      sortFieldType: getNormalizedMultipleURLParam(sortFieldType),
      sortFieldAggregationMode: getNormalizedMultipleURLParam(sortFieldAggregationMode),
    };
  },
);

export const getRawGroupBy = createSelector(
  [
    createCurrentRouteParamSelector('groupBy'),
    createCurrentRouteParamSelector('groupByFieldType'),
    createCurrentRouteParamSelector('groupByFieldAggregationMode'),
  ],
  (
    groupByParam,
    groupByFieldTypeParam,
    groupByFieldAggregationModeParam,
  ): GroupOptions | undefined => {
    if (!groupByParam || !groupByFieldTypeParam || !groupByFieldAggregationModeParam) {
      return undefined;
    }

    const groupBy = getNormalizedMultipleURLParam(groupByParam);
    const groupByFieldType = getNormalizedMultipleURLParam(groupByFieldTypeParam);
    const groupByFieldAggregationMode = getNormalizedMultipleURLParam(
      groupByFieldAggregationModeParam,
    );

    return {
      groupByFieldType,
      groupBy,
      groupByFieldAggregationMode,
    };
  },
);

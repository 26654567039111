import { naturalStringComparator } from '@neptune/shared/common-util';
import {
  organizationRoleComparator,
  projectRoleComparator,
} from '@neptune/shared/core-permissions-business-logic';
import { UserManagementLevel } from '@neptune/user-management-common-domain';
import {
  BaseMember,
  MemberList,
  MemberListState,
  OrganizationMember,
  ProjectMember,
} from '@neptune/user-management-domain';

import { fetchStatus as FetchStatus } from 'state/fetch-status';

import {
  OrganizationMemberListCreateInvitationActionTypes,
  OrganizationMemberListDeleteMemberActionTypes,
  OrganizationMemberListFetchListActionTypes,
  OrganizationMemberListResendExistingInvitationActionTypes,
  OrganizationMemberListRevokeInvitationActionTypes,
  organizationMemberListSortActionType,
  OrganizationMemberListUpdateMemberActionTypes,
  OrganizationUserManagementActions,
} from './organization-level-actions';
import {
  ProjectMemberListAddMemberActionTypes,
  ProjectMemberListCreateInvitationActionTypes,
  ProjectMemberListDeleteMemberActionTypes,
  ProjectMemberListFetchListActionTypes,
  ProjectMemberListLeaveProjectActionTypes,
  ProjectMemberListRevokeInvitationActionTypes,
  projectMemberListSortActionType,
  ProjectMemberListUpdateMemberActionTypes,
  ProjectUserManagementActions,
} from './project-level-actions';

function getMemberProperty(member: BaseMember, key: string) {
  if (key === 'username') {
    if (member.invitationInfo != null) {
      return member.invitationInfo?.invitee;
    }

    return member.registeredMemberInfo?.username;
  }

  if (key === 'lastName') {
    return member.registeredMemberInfo?.lastName;
  }
}

const projectMemberListReducer = createMemberListReducer<ProjectMember>(projectRoleComparator);
const organizationMemberListReducer = createMemberListReducer<OrganizationMember>(
  organizationRoleComparator,
);

const initialState: MemberListState = {
  [UserManagementLevel.project]: {
    fetchStatus: FetchStatus.NONE,
    updateFetchStatus: FetchStatus.NONE,
    entries: [],
    lastRefreshTime: 0,
    sortOptions: {
      sortBy: 'role',
      sortOrder: 'asc',
    },
  },
  [UserManagementLevel.organization]: {
    fetchStatus: FetchStatus.NONE,
    updateFetchStatus: FetchStatus.NONE,
    entries: [],
    lastRefreshTime: 0,
    sortOptions: {
      sortBy: 'role',
      sortOrder: 'asc',
    },
  },
};

export const memberListReducer = (
  state: MemberListState = initialState,
  action: OrganizationUserManagementActions | ProjectUserManagementActions,
) => {
  switch (action.type) {
    case ProjectMemberListAddMemberActionTypes.request:
    case ProjectMemberListAddMemberActionTypes.success:
    case ProjectMemberListAddMemberActionTypes.fail:
    case ProjectMemberListCreateInvitationActionTypes.request:
    case ProjectMemberListCreateInvitationActionTypes.success:
    case ProjectMemberListCreateInvitationActionTypes.fail:
    case ProjectMemberListDeleteMemberActionTypes.request:
    case ProjectMemberListDeleteMemberActionTypes.success:
    case ProjectMemberListDeleteMemberActionTypes.fail:
    case ProjectMemberListFetchListActionTypes.request:
    case ProjectMemberListFetchListActionTypes.success:
    case ProjectMemberListFetchListActionTypes.fail:
    case ProjectMemberListLeaveProjectActionTypes.request:
    case ProjectMemberListLeaveProjectActionTypes.success:
    case ProjectMemberListLeaveProjectActionTypes.fail:
    case ProjectMemberListRevokeInvitationActionTypes.request:
    case ProjectMemberListRevokeInvitationActionTypes.success:
    case ProjectMemberListRevokeInvitationActionTypes.fail:
    case ProjectMemberListUpdateMemberActionTypes.request:
    case ProjectMemberListUpdateMemberActionTypes.success:
    case ProjectMemberListUpdateMemberActionTypes.fail:
    case projectMemberListSortActionType:
      return {
        ...state,
        [UserManagementLevel.project]: projectMemberListReducer(
          state[UserManagementLevel.project],
          action,
        ),
      };
    case OrganizationMemberListCreateInvitationActionTypes.request:
    case OrganizationMemberListCreateInvitationActionTypes.success:
    case OrganizationMemberListCreateInvitationActionTypes.fail:
    case OrganizationMemberListDeleteMemberActionTypes.request:
    case OrganizationMemberListDeleteMemberActionTypes.success:
    case OrganizationMemberListDeleteMemberActionTypes.fail:
    case OrganizationMemberListResendExistingInvitationActionTypes.request:
    case OrganizationMemberListResendExistingInvitationActionTypes.success:
    case OrganizationMemberListResendExistingInvitationActionTypes.fail:
    case OrganizationMemberListFetchListActionTypes.request:
    case OrganizationMemberListFetchListActionTypes.success:
    case OrganizationMemberListFetchListActionTypes.fail:
    case OrganizationMemberListRevokeInvitationActionTypes.request:
    case OrganizationMemberListRevokeInvitationActionTypes.success:
    case OrganizationMemberListRevokeInvitationActionTypes.fail:
    case OrganizationMemberListUpdateMemberActionTypes.request:
    case OrganizationMemberListUpdateMemberActionTypes.success:
    case OrganizationMemberListUpdateMemberActionTypes.fail:
    case organizationMemberListSortActionType:
      return {
        ...state,
        [UserManagementLevel.organization]: organizationMemberListReducer(
          state[UserManagementLevel.organization],
          action,
        ),
      };
    default:
      return state;
  }
};

function createMemberListReducer<T extends BaseMember>(
  roleComparator: (a: T['role'], b: T['role']) => number,
) {
  return (
    state: MemberList<T>,
    action: OrganizationUserManagementActions | ProjectUserManagementActions,
  ) => {
    switch (action.type) {
      case ProjectMemberListFetchListActionTypes.request:
      case OrganizationMemberListFetchListActionTypes.request:
        return {
          ...state,
          fetchStatus: FetchStatus.PENDING,
        };

      case ProjectMemberListFetchListActionTypes.success:
      case OrganizationMemberListFetchListActionTypes.success:
        return {
          ...state,
          fetchStatus: FetchStatus.SUCCESS,
          entries: action.payload.entries.sort(createSortFunction(state.sortOptions)),
        };

      case ProjectMemberListFetchListActionTypes.fail:
      case OrganizationMemberListFetchListActionTypes.fail:
        return {
          ...state,
          fetchStatus: FetchStatus.FAILED,
          error: action.error,
        };

      case ProjectMemberListUpdateMemberActionTypes.request:

      case OrganizationMemberListUpdateMemberActionTypes.request: {
        return {
          ...state,
          updateFetchStatus: FetchStatus.PENDING,
        };
      }

      case ProjectMemberListUpdateMemberActionTypes.success:
      case OrganizationMemberListUpdateMemberActionTypes.success:
      case ProjectMemberListAddMemberActionTypes.success:
      case ProjectMemberListDeleteMemberActionTypes.success:
      case OrganizationMemberListDeleteMemberActionTypes.success:
      case ProjectMemberListCreateInvitationActionTypes.success:
      case OrganizationMemberListCreateInvitationActionTypes.success:
      case ProjectMemberListRevokeInvitationActionTypes.success:
      case OrganizationMemberListRevokeInvitationActionTypes.success:
      case ProjectMemberListLeaveProjectActionTypes.success:
      case OrganizationMemberListResendExistingInvitationActionTypes.success:
        return {
          ...state,
          lastRefreshTime: state.lastRefreshTime + 1,
        };

      case projectMemberListSortActionType:

      case organizationMemberListSortActionType: {
        const { sortOptions } = action.payload;
        return {
          ...state,
          sortOptions,
          entries: state.entries.sort(createSortFunction(sortOptions)),
        };
      }

      default:
        return state;
    }
  };

  function createSortFunction({ sortBy, sortOrder }: { sortBy: string; sortOrder: string }) {
    const sortSign = sortOrder === 'asc' ? 1 : -1;

    if (sortBy === 'role') {
      return (a: BaseMember, b: BaseMember) => {
        return sortSign * roleComparator(a.role, b.role);
      };
    }

    return (a: BaseMember, b: BaseMember) => {
      const valueA = getMemberProperty(a, sortBy);
      const valueB = getMemberProperty(b, sortBy);
      return sortSign * naturalStringComparator(valueA, valueB);
    };
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LicenseDTO
 */
export interface LicenseDTO {
    /**
     * 
     * @type {string}
     * @memberof LicenseDTO
     */
    license: string;
}

/**
 * Check if a given object implements the LicenseDTO interface.
 */
export function instanceOfLicenseDTO(value: object): value is LicenseDTO {
    if (!('license' in value) || value['license'] === undefined) return false;
    return true;
}

export function LicenseDTOFromJSON(json: any): LicenseDTO {
    return LicenseDTOFromJSONTyped(json, false);
}

export function LicenseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicenseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'license': json['license'],
    };
}

export function LicenseDTOToJSON(value?: LicenseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'license': value['license'],
    };
}


// App
import { operatorRequiresValue } from '../operator-utils';
import {
  EmptyStringSetCriterion,
  NonEmptyStringSetCriterion,
  PartialSearchCriterion,
  StringSetCriterion,
} from '../search-query-model';
import {
  criterionHasAttributeAndOperator,
  getReadableArrayValue,
} from '../search-query-model-core-utils';

// Module
import { CriterionHelpers } from './types';

export function isStringSetCriterion(
  criterion: PartialSearchCriterion,
): criterion is StringSetCriterion {
  return isNonEmptyStringSetCriterion(criterion) || isEmptyStringSetCriterion(criterion);
}

export function isNonEmptyStringSetCriterion(
  criterion: PartialSearchCriterion,
): criterion is NonEmptyStringSetCriterion {
  return (
    criterionHasAttributeAndOperator(criterion) &&
    criterion.type === 'stringSet' &&
    operatorRequiresValue(criterion.operator)
  );
}

export function isEmptyStringSetCriterion(
  criterion: PartialSearchCriterion,
): criterion is EmptyStringSetCriterion {
  return (
    criterionHasAttributeAndOperator(criterion) &&
    criterion.type === 'stringSet' &&
    !operatorRequiresValue(criterion.operator)
  );
}

export const stringSetCriterionHelpers: CriterionHelpers<StringSetCriterion> = {
  getReadableValue(criterion) {
    if (isNonEmptyStringSetCriterion(criterion)) {
      return getReadableArrayValue(criterion.value);
    }

    return '';
  },
  getShortReadableValue(criterion) {
    if (isNonEmptyStringSetCriterion(criterion)) {
      return criterion.value.join(', ');
    }

    return '';
  },
  isComplete(criterion) {
    if (isNonEmptyStringSetCriterion(criterion)) {
      return criterion.value?.length > 0;
    }

    return true;
  },
};

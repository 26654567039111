import React from 'react';

import { listAttributeDefinitions } from '@neptune/shared/attributes-domain';
import { useAsyncValue } from '@neptune/shared/common-util';

import { AttributeType } from 'domain/experiment/attribute';

export const useAttributeDefinitions = (
  experimentId?: string,
  attributeTypes?: AttributeType[],
) => {
  const resolver = React.useCallback(async () => {
    if (!experimentId) {
      return;
    }

    const attributes = await listAttributeDefinitions({
      identifier: experimentId,
      attributeTypes,
    });

    if (attributes.length === 0) {
      return;
    }

    return attributes;
  }, [experimentId, attributeTypes]);

  return useAsyncValue({
    resolver,
  });
};

// App
import { getRouter } from 'router/index';
import desktopRoutes from 'router/routes';

import { isMobile } from '@neptune/shared/common-util';

import { desktopRouterConfig } from 'router/config';

// Module
import {
  LeaderboardLoadTransaction,
  OnboardingProjectsLoadTransaction,
  ProjectsLoadTransaction,
} from './transactions';

export const initTransactions = () => {
  const router = getRouter(desktopRoutes, desktopRouterConfig);
  const initParams = {
    route: router.matchUrl(window.location.href),
    isMobile: isMobile(),
  };

  LeaderboardLoadTransaction(initParams);
  ProjectsLoadTransaction(initParams);
  OnboardingProjectsLoadTransaction(initParams);
};

import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'react-router5';
import { CustomTracingBus, KnownSignals } from 'monitoring/custom-tracing';

import {
  extractInvitationRouteData,
  getHomeNavigationParams,
  getInvitationNavigationParams,
  muteInvitation,
  NavigateToParams,
  prepareInvitationList,
} from '@neptune/invitations-business-logic';
import {
  fetchInvitation,
  Invitation,
  InvitationStatus,
  isOrganizationInvitation,
} from '@neptune/invitations-domain';
import { InvitationError } from '@neptune/invitations-ui';
import { fetchOrganizations } from '@neptune/shared/core-organizations-business-logic';
import { convertStringToUserManagementLevel } from '@neptune/user-management-common-domain';

import { AcceptSingleInvitationContainer } from './AcceptSingleInvitationContainer';

type AcceptInvitationProps = {
  onFinish: (firstAcceptedInvitation: NavigateToParams | undefined) => void;
};

export const BulkAcceptInvitationContainer: React.FC<AcceptInvitationProps> = ({ onFinish }) => {
  const [error, setError] = React.useState<unknown>();
  const [current, setCurrent] = React.useState<Invitation>();
  const [pending, setPending] = React.useState<Invitation[]>();
  const firstAccepted = React.useRef<Invitation>();
  const [forceRedirect, setForceRedirect] = React.useState(false);
  const router = useRouter();

  const dispatch = useDispatch();

  const finish = React.useCallback(async () => {
    let target: NavigateToParams | undefined = undefined;

    if (firstAccepted.current) {
      if (isOrganizationInvitation(firstAccepted.current)) {
        await dispatch(fetchOrganizations());
      }

      target = getInvitationNavigationParams(firstAccepted.current);
    }

    if (!target && forceRedirect) {
      target = getHomeNavigationParams();
    }

    return onFinish(target);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFinish, forceRedirect]);

  React.useEffect(() => {
    doFetchInvitations();

    async function doFetchInvitations() {
      try {
        const routeInvitation = await prepareRouteInvitation();
        const list = await prepareInvitationList(routeInvitation);

        if (routeInvitation && routeInvitation.status !== InvitationStatus.accepted) {
          setCurrent(routeInvitation);
        } else {
          if (routeInvitation) {
            firstAccepted.current = routeInvitation;
          }

          if (list.length === 0) {
            return await finish();
          }

          setCurrent(list.shift());
        }

        setPending(list);
      } catch (error) {
        setError(error);
      }
    }

    async function prepareRouteInvitation(): Promise<Invitation | null> {
      const route = router.matchUrl(window.location.href);
      const routeInvitation = extractInvitationRouteData(route);

      if (!routeInvitation) {
        return null;
      }

      // there is an invitation in link, so we have force to redirect
      setForceRedirect(true);
      const level = convertStringToUserManagementLevel(routeInvitation.type);

      if (!level) {
        return null;
      }

      return await fetchInvitation({ level, invitationId: routeInvitation.invitationId });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const next = React.useCallback(
    async (accepted: boolean) => {
      if (accepted && !firstAccepted.current) {
        firstAccepted.current = current;
      }

      if (!accepted && current) {
        muteInvitation(current);
      }

      if (pending && pending.length > 0) {
        setCurrent(pending?.shift());
        setPending(pending);
      } else {
        await finish();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [current, pending, finish],
  );

  if (error) {
    CustomTracingBus.publish(KnownSignals.INVITATIONS_RENDERED, null);
    return <InvitationError onClose={finish} />;
  }

  if (!current) {
    return null;
  }

  CustomTracingBus.publish(KnownSignals.INVITATIONS_RENDERED, null);

  return (
    <AcceptSingleInvitationContainer
      invitation={current}
      afterAccept={() => next(true)}
      afterClose={() => next(false)}
    />
  );
};

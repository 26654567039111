// Libs
import React from 'react';
import { useSelector } from 'react-redux';

import { Modal, Text } from '@neptune/shared/venus-ui';
import { DELETE_MODAL_TYPE } from '@neptune/user-management-domain';

// App
import ConfirmationModal from 'components/confirmation-modal/ConfirmationModal';
import { getConfirmationModalDataByType } from 'state/selectors';
import { AppState } from 'state/types';

type DeleteModalProps = {
  organizationName: string;
  projectIdentifier?: string;
  onConfirm: (params: { userId: string; invitationId: string }) => void;
};

export const DeleteModal: React.FC<DeleteModalProps> = ({
  organizationName,
  projectIdentifier,
  onConfirm,
}) => {
  const { invitee, userId } = useSelector(
    (state: AppState) => getConfirmationModalDataByType(state, DELETE_MODAL_TYPE) || {},
  );
  let message: React.ReactElement;
  let modalTitle = '';
  let confirm = 'Ok';

  if (userId) {
    confirm = 'Remove';
    modalTitle = 'Remove user';
    const username = <Text fontWeight="semibold">@{userId}</Text>;
    const entityType = projectIdentifier ? 'project' : 'workspace';
    const entityName = projectIdentifier ? projectIdentifier : organizationName;

    message = (
      <Modal.Text>
        You are about to remove {username} from {entityType}{' '}
        <Text fontWeight="semibold">{entityName}</Text>. Are you sure?
      </Modal.Text>
    );
  } else if (invitee) {
    modalTitle = 'Revoke invitation';
    message = (
      <Modal.Text>
        You are about to revoke invitation for <Text fontWeight="semibold">{invitee}</Text>. Are you
        sure?
      </Modal.Text>
    );
  } else {
    modalTitle = 'Leave project';
    message = (
      <Modal.Text>
        You are about to leave project <Text fontWeight="semibold">{projectIdentifier}</Text>. Are
        you sure?
      </Modal.Text>
    );
  }

  return (
    <ConfirmationModal
      type={DELETE_MODAL_TYPE}
      title={modalTitle}
      confirmButtonText={confirm}
      cancelButtonText="Cancel"
      onConfirm={onConfirm}
      children={message}
    />
  );
};

import { omit } from 'lodash';
import { AnyAction } from 'redux';
import { NavigationOptions } from 'router5';

import {
  leaderboardClient,
  ListDashboardsRequest,
} from '@neptune/shared/core-apis-leaderboard-domain';
import { makeProjectIdentifier } from '@neptune/shared/core-project-util';
import { getCurrentRoute, navigateTo } from '@neptune/shared/routing-business-logic';

import { loadState, LocalStorageState, persistState } from 'common/localStorage';
import { Dashboard, DashboardModelConverter, DashboardType, NewDashboard } from 'domain/dashboard';
import { AsyncActionsReturnType, createAsyncActions } from 'state/async-actions';
import { AppState, NThunkAction, NThunkDispatch } from 'state/types';
import { addNotification } from 'state/ui/global/notification-popup/actions';

import { DashboardInfo, makeDashboardRoute } from './make-dashboard-route';
import { getCompareDashboards, getExperimentDashboards } from './selectors';

export enum ProjectDashboardsFetchActionTypes {
  request = 'DASHBOARDS_FETCH_REQUEST',
  success = 'DASHBOARDS_FETCH_SUCCESS',
  fail = 'DASHBOARDS_FETCH_FAIL',
}

export function openDashboard(dashboardInfo: DashboardInfo, options?: NavigationOptions) {
  return (dispatch: NThunkDispatch<AnyAction>, getState: () => AppState) => {
    const currentRoute = getCurrentRoute(getState());

    const dashboardRoute = makeDashboardRoute(currentRoute, dashboardInfo);

    if (dashboardRoute) {
      dispatch(navigateTo(dashboardRoute.name, dashboardRoute.params, options));
    }
  };
}

function updateDashboardCache({
  projectIdentifier,
  dashboardType,
}: {
  dashboardType: DashboardType;
  projectIdentifier: string;
}) {
  return () => {
    if (dashboardType === 'report') {
      return;
    }

    type DashboardStateKeys = Extract<
      keyof LocalStorageState,
      'lastCompareDashboardIds' | 'lastExperimentDashboardIds'
    >;

    const stateKey: DashboardStateKeys =
      dashboardType === 'compare' ? 'lastCompareDashboardIds' : 'lastExperimentDashboardIds';

    const state = loadState();
    const lastDashboardIds = state[stateKey];

    const newState = omit(lastDashboardIds, projectIdentifier);

    persistState({
      [stateKey]: {
        ...newState,
      },
    });
  };
}

function redirectToDefaultDashboard(projectIdentifier: string, branchId: string) {
  return (dispatch: NThunkDispatch<AnyAction>, getState: () => AppState) => {
    const route = getCurrentRoute(getState());
    const { organizationName, projectName } = route.params;
    const currentProjectIdentifier = makeProjectIdentifier(organizationName, projectName);

    if (projectIdentifier !== currentProjectIdentifier) {
      return;
    }

    if (!['project.runs-tab', 'project.trash'].includes(route.name)) {
      return;
    }

    if (route.params.tab === 'details' && route.params.detailsTab === 'dashboard') {
      const dashboards = getExperimentDashboards(getState());
      const defaultDashboard = dashboards.filter((d) => d.versionBranchId !== branchId)[0];

      dispatch(
        navigateTo(route.name, {
          ...route.params,
          detailsTab: 'dashboard',
          dashboardId: defaultDashboard?.versionBranchId,
        }),
      );
      return;
    }

    if (route.params.tab === 'compare' && route.params.dash === 'dashboard') {
      const dashboards = getCompareDashboards(getState());
      const defaultDashboard = dashboards.filter((d) => d.versionBranchId !== branchId)[0];

      dispatch(
        navigateTo(route.name, {
          ...route.params,
          dashboardId: defaultDashboard?.versionBranchId,
        }),
      );
      return;
    }

    // no need to redirect if we're not on a dashboard route anymore
  };
}

const fetchActions = createAsyncActions({
  types: ProjectDashboardsFetchActionTypes,
  async resolver(params: ListDashboardsRequest) {
    const response = await leaderboardClient.listDashboards({
      ...params,
      dashboardType: DashboardModelConverter.listDashboardTypeFromDomainToApi([
        'compare',
        'experiment',
      ]),
    });
    return {
      dashboards: response.dashboards.map(DashboardModelConverter.dashboardFromApiToDomain),
    };
  },
});

export enum ProjectDashboardsCreateActionTypes {
  request = 'DASHBOARDS_CREATE_REQUEST',
  success = 'DASHBOARDS_CREATE_SUCCESS',
  fail = 'DASHBOARDS_CREATE_FAIL',
}

type CreateDashboardParams = {
  projectIdentifier: string;
  createDashboard: NewDashboard;
};

const createActions = createAsyncActions({
  types: ProjectDashboardsCreateActionTypes,
  async resolver({ createDashboard, projectIdentifier }: CreateDashboardParams) {
    const dashboard = await leaderboardClient.createDashboard({
      projectIdentifier,
      createDashboard: DashboardModelConverter.newDashboardFromDomainToApi(createDashboard),
    });
    return DashboardModelConverter.dashboardFromApiToDomain(dashboard);
  },
  onResolve(payload: Dashboard, dispatch) {
    dispatch(openDashboard(payload, { redirected: true, force: true }));
    dispatch(notifyDashboardCreatedAction(payload.name));
  },
});

export enum ProjectDashboardsUpdateActionTypes {
  request = 'DASHBOARDS_UPDATE_REQUEST',
  success = 'DASHBOARDS_UPDATE_SUCCESS',
  fail = 'DASHBOARDS_UPDATE_FAIL',
}

type UpdateDashboardParams = {
  projectIdentifier: string;
  updateDashboard: Dashboard;
};

const updateActions = createAsyncActions({
  types: ProjectDashboardsUpdateActionTypes,
  async resolver({ projectIdentifier, updateDashboard }: UpdateDashboardParams) {
    const dashboard = await leaderboardClient.createNewVersionOfDashboard({
      dashboardId: updateDashboard.id,
      projectIdentifier,
      createVersionDashboard:
        DashboardModelConverter.newVersionDashboardFromDomainToApi(updateDashboard),
    });
    return DashboardModelConverter.dashboardFromApiToDomain(dashboard);
  },
  onResolve(payload: Dashboard, dispatch) {
    dispatch(
      openDashboard(payload, {
        replace: true,
      }),
    );
  },
});

function notifyDashboardCreatedAction(name: string): NThunkAction<void, AnyAction> {
  return (dispatch) => {
    dispatch(addNotification('info', `“${name}” was successfully saved`));
  };
}

export enum ProjectDashboardsDeleteActionTypes {
  request = 'DASHBOARDS_DELETE_REQUEST',
  success = 'DASHBOARDS_DELETE_SUCCESS',
  fail = 'DASHBOARDS_DELETE_FAIL',
}

type DeleteDashboardParams = {
  projectIdentifier: string;
  versionBranchId: string;
  dashboardType: DashboardType;
};

const deleteActions = createAsyncActions({
  types: ProjectDashboardsDeleteActionTypes,
  async resolver({ projectIdentifier, versionBranchId, dashboardType }: DeleteDashboardParams) {
    await leaderboardClient.deleteAllDashboardVersions({
      projectIdentifier,
      branchVersionId: versionBranchId,
    });

    return {
      projectIdentifier,
      dashboardType,
      versionBranchId,
    };
  },
  onResolve(
    { dashboardType, projectIdentifier, versionBranchId }: DeleteDashboardParams,
    dispatch,
  ) {
    dispatch(
      updateDashboardCache({
        dashboardType,
        projectIdentifier,
      }),
    );
    dispatch(redirectToDefaultDashboard(projectIdentifier, versionBranchId));
  },
});

export const { execute: fetchProjectDashboards } = fetchActions;
export const { execute: createDashboard } = createActions;
export const { execute: updateDashboard, success: updateDashboardSuccess } = updateActions;
export const { execute: deleteDashboard } = deleteActions;

export type ProjectDashboardsActions =
  | AsyncActionsReturnType<typeof fetchActions>
  | AsyncActionsReturnType<typeof createActions>
  | AsyncActionsReturnType<typeof updateActions>
  | AsyncActionsReturnType<typeof deleteActions>;

import { DashboardConfigDTO } from 'generated/leaderboard-client';

import { getNewReportsGlobalConfigEnabled } from 'common/featureFlag';
import { AttributeDefinitionConverter } from 'domain/experiment/attribute';
import {
  ChartGlobalConfig,
  DEFAULT_CHART_GLOBAL_CONFIG,
} from 'domain/widget/support/chart-widget-global-configuration';

export type DashboardConfig = {
  // TODO: is this soomthing needed here?
  smoothing?: number;
  chartGlobalConfig?: ChartGlobalConfig;
};

type XAxisMode = 'step' | 'relativeTime' | 'absoluteTime' | 'timeSeries';
type AxisScale = 'linear' | 'logarithmic';

type Dependencies = {
  featureFlags: {
    newReportsGlobalConfigEnabled?: boolean;
  };
};

export const createDashboardConfigModelConverter = (dependencies: Partial<Dependencies> = {}) => {
  const {
    featureFlags = {
      newReportsGlobalConfigEnabled: getNewReportsGlobalConfigEnabled(),
    },
  } = dependencies;

  function fromApiToDomain(config: DashboardConfigDTO | undefined): DashboardConfig | undefined {
    if (!config) {
      return undefined;
    }

    const {
      metricsStepsRange,
      xaxisScale: xAxisScale,
      yaxisScale: yAxisScale,
      xaxisMode: xAxisMode,
      xaxisMetric: xAxisMetric,
      smoothing,
    } = config;

    if (featureFlags.newReportsGlobalConfigEnabled) {
      return {
        chartGlobalConfig: {
          metricsStepsRange: metricsStepsRange
            ? [metricsStepsRange.from, metricsStepsRange.to]
            : DEFAULT_CHART_GLOBAL_CONFIG.metricsStepsRange,
          smoothing,
        },
      };
    }

    const chartGlobalConfig: ChartGlobalConfig = {
      xAxisScale: (xAxisScale as AxisScale | undefined) ?? DEFAULT_CHART_GLOBAL_CONFIG.xAxisScale,
      yAxisScale: (yAxisScale as AxisScale | undefined) ?? DEFAULT_CHART_GLOBAL_CONFIG.yAxisScale,
      xAxisMode: (xAxisMode as XAxisMode | undefined) ?? DEFAULT_CHART_GLOBAL_CONFIG.xAxisMode,
      xAxisMetric:
        xAxisMetric && AttributeDefinitionConverter.attributeDefinitionFromApiToDomain(xAxisMetric),
      metricsStepsRange: metricsStepsRange
        ? [metricsStepsRange.from, metricsStepsRange.to]
        : DEFAULT_CHART_GLOBAL_CONFIG.metricsStepsRange,
      smoothing,
    };

    return {
      chartGlobalConfig,
    };
  }

  function fromDomainToApi(config: DashboardConfig | undefined): DashboardConfigDTO | undefined {
    if (!config) {
      return undefined;
    }

    const { chartGlobalConfig } = config;

    const { xAxisScale, yAxisScale, xAxisMode, xAxisMetric, metricsStepsRange, smoothing } =
      chartGlobalConfig ?? {};

    return {
      metricsStepsRange: {
        from: metricsStepsRange?.[0],
        to: metricsStepsRange?.[1],
      },
      xaxisScale: xAxisScale,
      yaxisScale: yAxisScale,
      xaxisMode: xAxisMode,
      xaxisMetric:
        xAxisMetric && AttributeDefinitionConverter.attributeDefinitionFromDomainToApi(xAxisMetric),
      smoothing,
    };
  }

  return {
    fromDomainToApi,
    fromApiToDomain,
  };
};

export const DashboardConfigModelConverter = createDashboardConfigModelConverter();

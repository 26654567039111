import { escapeRegExp } from 'lodash';

import { Attribute, AttributeType } from 'domain/experiment/attribute';

export const suitableTableWidgetAttributeTypes: AttributeType[] = [
  'bool',
  'datetime',
  'float',
  'int',
  'string',
];

export function createSuitableAttributePredicate(
  namespace: string,
  includeSubnamespaces: boolean,
): (attribute: Attribute) => boolean {
  const namespacePrefix = namespace + '/';

  if (includeSubnamespaces) {
    return ({ attributeName, attributeType }) =>
      attributeName.startsWith(namespacePrefix) &&
      suitableTableWidgetAttributeTypes.includes(attributeType);
  }

  const regex = new RegExp(`^${escapeRegExp(namespacePrefix)}[^/]+$`);
  return ({ attributeName, attributeType }) =>
    regex.test(attributeName) && suitableTableWidgetAttributeTypes.includes(attributeType);
}

export function getSuitableAttributesInNamespace(
  attributes: Attribute[],
  namespace: string,
  includeSubnamespaces: boolean,
): Attribute[] {
  const predicate = createSuitableAttributePredicate(namespace, includeSubnamespaces);

  return attributes.filter(predicate);
}

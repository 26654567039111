// Libs
import React from 'react';

// App
import {
  OrganizationRole,
  organizationRoleLabels,
} from '@neptune/shared/core-organizations-domain';
import { ProjectRole, projectRoleLabels } from '@neptune/shared/core-project-domain';
import {
  bemBlock,
  Checkbox,
  CheckboxProps,
  Icon,
  InfoIcon,
  Text,
  UITooltip,
  useTooltip,
} from '@neptune/shared/venus-ui';

// Module
import './AddToAllProjects.less';

export type AddToAllProjectsProps = Pick<CheckboxProps, 'checked' | 'onChange'> & {
  role: string;
};

const block = bemBlock('n-AddToAllProjects');

export const AddToAllProjects: React.FC<AddToAllProjectsProps> = ({ checked, onChange, role }) => {
  const isOwner = role === OrganizationRole.owner;

  const tooltipProps = useTooltip({
    placement: 'bottom-center',
  });
  const { ref, ...triggerProps } = tooltipProps.triggerProps;

  return (
    <Checkbox
      disabled={isOwner}
      checked={isOwner || checked}
      onChange={onChange}
      role="add-to-all-projects"
    >
      {isOwner ? (
        <>
          <Text className={block({ element: 'label' })} elementRef={ref} {...triggerProps}>
            Add to all projects
          </Text>
          {tooltipProps.isOpen &&
            tooltipProps.renderLayer(
              <UITooltip {...tooltipProps.layerProps}>
                {organizationRoleLabels[OrganizationRole.owner]} of a Workspace is granted{' '}
                <Text italic>{projectRoleLabels[ProjectRole.manager]}</Text> access to all projects
                automatically.
              </UITooltip>,
            )}
        </>
      ) : (
        <>
          <Text>Add to all projects</Text>{' '}
          <InfoIcon triggerElement={<Icon glyph="question-circle" />}>
            By selecting this option, you grant this user{' '}
            <Text italic>{projectRoleLabels[ProjectRole.member].toLowerCase()}</Text> access to all
            currently existing projects. You can also add{' '}
            {organizationRoleLabels[OrganizationRole.member].toLowerCase()}s to particular projects
            later, through the project settings.
          </InfoIcon>
        </>
      )}
    </Checkbox>
  );
};

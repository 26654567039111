import React from 'react';
import { Many, sortBy } from 'lodash';

import { convertRegexpSearchToFilter } from '@neptune/shared/common-util';
import { queryAttributeDefinitions } from '@neptune/shared/leaderboard-domain';
import { regexpSearchFilter } from '@neptune/shared/search-business-logic';
import { FilterResultItem, QueryFilterSearch } from '@neptune/shared/search-domain';

import {
  AttributeDefinition,
  attributeDefinitionComparator,
  AttributeType,
  convertAttributeToSearchString,
} from 'domain/experiment/attribute';

import { unwindSubpropertyStrategies, UnwindSubpropertyStrategy } from './unwind-property-strategy';

type LodashCompatibleSortByIteratee<T> = Many<
  (value: FilterResultItem<T>, index: number, collection: ArrayLike<FilterResultItem<T>>) => unknown
>;

const always: (item: unknown) => boolean = () => true;

type UseAttributeDefinitionDataSourceProps<T extends AttributeDefinition> = {
  defaultFilterAttributeTypes?: AttributeType[];
  enhancer: (input: AttributeDefinition) => T;
  /** @deprecated: only project-wide fields are supported in non-legacy hook */
  entityId: string;
  /** @deprecated: only project-wide fields are supported in non-legacy hook */
  entityType: string;
  excludeAttributes?: AttributeDefinition[];
  filter?: (input: T) => boolean;
  limit?: number;
  prefillAttributes?: AttributeDefinition[];
  projectIdentifier: string;
  sortByIteratee?: LodashCompatibleSortByIteratee<AttributeDefinition>;
  subpropertyStrategy: UnwindSubpropertyStrategy;
  /** @deprecated: only project-wide fields are supported in non-legacy hook */
  trash: boolean;
  /** @deprecated: only project-wide fields are supported in non-legacy hook */
  types: string[];
};

const LIMIT_ATTRIBUTES = 50;

/** @deprecated: use useQueryAttributeDefinitionDataSource, this hook in tech debt to make add-column workable */
export const useAttributeDefinitionDataSource = <
  T extends AttributeDefinition = AttributeDefinition,
>({
  defaultFilterAttributeTypes,
  enhancer,
  entityType,
  excludeAttributes,
  filter = always,
  limit = LIMIT_ATTRIBUTES,
  prefillAttributes,
  projectIdentifier,
  sortByIteratee,
  subpropertyStrategy,
  trash,
  types,
}: UseAttributeDefinitionDataSourceProps<T>): QueryFilterSearch<
  T,
  {
    predicate: (item: T) => boolean;
    filterAttributeTypes?: AttributeType[];
  }
> => {
  if (trash) {
    throw new Error('Trash not supported, maybe it is no case');
  }

  if (entityType !== 'project') {
    throw new Error(`Only project entity type is supported [${entityType}]`);
  }

  if (types.toString() !== 'run') {
    throw new Error(`Only runs type is supported [${types.toString()}]`);
  }

  const rawQuery = React.useCallback(
    async (search: string, filterAttributeTypes?: AttributeType[]) => {
      const { attributeDefinitions } = await queryAttributeDefinitions({
        projectIdentifier,
        filterAttributeTypes,
        limit: limit + 1,
        attributeNameFilter: convertRegexpSearchToFilter(search),
      });

      return unwindSubpropertyStrategies[subpropertyStrategy](attributeDefinitions);
    },
    [limit, projectIdentifier, subpropertyStrategy],
  );

  const prepareOutput = React.useCallback(
    (input: AttributeDefinition[], search: string, predicate: (item: T) => boolean) => {
      const attributes = input.filter((entry) =>
        prefillAttributes ? !includesAttribute(prefillAttributes, entry) : true,
      );

      const collection = (prefillAttributes ? [...prefillAttributes, ...attributes] : attributes)
        .map(enhancer)
        .filter(filter)
        .filter((entry) =>
          defaultFilterAttributeTypes ? defaultFilterAttributeTypes.includes(entry.type) : true,
        )
        .filter((entry) =>
          excludeAttributes ? !includesAttribute(excludeAttributes, entry) : true,
        )
        .filter(predicate);
      const allResults = regexpSearchFilter(search, collection, convertAttributeToSearchString);
      const allResultsSorted = sortByIteratee ? sortBy(allResults, sortByIteratee) : allResults;
      return allResultsSorted;
    },
    [
      defaultFilterAttributeTypes,
      enhancer,
      excludeAttributes,
      filter,
      prefillAttributes,
      sortByIteratee,
    ],
  );

  return React.useCallback(
    async (search: string, params = { predicate: always }) => {
      const filterAttributeTypes = params?.filterAttributeTypes ?? defaultFilterAttributeTypes;
      const collection = await rawQuery(search.trim(), filterAttributeTypes);
      const entries = prepareOutput(collection, search.trim(), params.predicate);
      const limitedEntries = entries.slice(0, limit);
      return {
        entries: limitedEntries,
        collectionCount: undefined,
      };
    },
    [defaultFilterAttributeTypes, rawQuery, prepareOutput, limit],
  );
};

function includesAttribute(collection: AttributeDefinition[], entry: AttributeDefinition) {
  return collection.some((used) => attributeDefinitionComparator(entry, used));
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePolling, UsePollingParams } from '@neptune/shared/common-business-logic';
import { BaseLeaderboardEntry, LeaderboardListModel } from '@neptune/shared/leaderboard-domain';
import { UnderEditContext } from '@neptune/shared/venus-domain';

import { Merge } from 'common/utility-types';
import { AppState } from 'state/types';

import {
  fetchLeaderboard,
  fetchLeaderboardFail,
  fetchLeaderboardSuccess,
} from './redux/leaderboards-actions';
import { LeaderboardState } from './redux/leaderboards-reducer';
import { getLeaderboardState } from './redux/leaderboards-selectors';

export const useLeaderboardPolling = <Q, T extends BaseLeaderboardEntry>({
  id,
  query,
  resolver,
  pollingInterval,
  enabled = true,
}: {
  id: string;
  query: Q;
  resolver: (query: Q) => Promise<LeaderboardListModel<T>>;
  pollingInterval?: number;
  enabled?: boolean;
}): Merge<LeaderboardState<T>, { refresh: () => void }> => {
  const dispatch = useDispatch();

  const { underEdit } = React.useContext(UnderEditContext);

  const handleFetchSuccess = React.useCallback(
    (data: LeaderboardListModel<T>, query: Q) =>
      dispatch(fetchLeaderboardSuccess(id, { data, query })),
    [dispatch, id],
  );
  const handleFetchFailed = React.useCallback(
    (error: any) => dispatch(fetchLeaderboardFail(id, error)),
    [dispatch, id],
  );

  const handleFetchStart = React.useCallback(() => dispatch(fetchLeaderboard(id)), [dispatch, id]);

  const pollingConfig: UsePollingParams<Q, LeaderboardListModel<T>> = React.useMemo(
    () => ({
      resolver,
      onStart: handleFetchStart,
      onFetchSucceed: handleFetchSuccess,
      onFetchFailed: handleFetchFailed,
      pollingInterval,
      enabled: !underEdit && enabled,
    }),
    [
      resolver,
      handleFetchStart,
      handleFetchSuccess,
      handleFetchFailed,
      pollingInterval,
      underEdit,
      enabled,
    ],
  );

  const { refresh } = usePolling(query, pollingConfig);

  const state = useSelector((state: AppState) => getLeaderboardState<T>(state, id));

  return {
    ...state,
    refresh,
  };
};

import React from 'react';
import { useSelector } from 'react-redux';

import {
  enhanceSuggestedColumn,
  getLeaderboardState,
} from '@neptune/shared/leaderboard-business-logic';
import {
  LeaderboardColumn,
  leaderboardColumnFactory,
  SuggestedLeaderboardColumn,
} from '@neptune/shared/leaderboard-domain';

import { AppState } from 'state/types';

type SuggestionWidths = {
  [key: string]: number | undefined;
};

type UseSuggestionsParams = {
  id: string;
  canShowSuggestions: boolean;
  columns: LeaderboardColumn[];
};

type UseSuggestionsReturnType = {
  suggestions: SuggestedLeaderboardColumn[];
  resizeSuggestion: (id: string, columnType: string, width: number) => void;
  lastDeclinedSuggestionTimestamp: number | undefined;
};

const MAX_SUGGESTIONS = 5;
const DEFAULT_COLUMN_WIDTH = 220;
const suggestionsLoadingIndicatorLeaderboardColumns: SuggestedLeaderboardColumn[] = Array.from({
  length: MAX_SUGGESTIONS,
}).map((_, i) => ({
  ...leaderboardColumnFactory({
    id: `neptune--suggestions-loading-indicator-${i}`,
    columnType: 'string',
  }),
  isSuggestion: true,
  isLoading: true,
  title: 'Loading',
  displayName: 'Loading',
  width: DEFAULT_COLUMN_WIDTH,
}));

export const useSuggestions = ({
  id,
  canShowSuggestions,
  columns,
}: UseSuggestionsParams): UseSuggestionsReturnType => {
  const [suggestionWidths, setSuggestionWidths] = React.useState<SuggestionWidths>({});
  const suggestions = useSelector((state: AppState) => getLeaderboardState(state, id).suggestions);
  const lastDeclinedSuggestionTimestamp = useSelector(
    (state: AppState) => getLeaderboardState(state, id).lastDeclinedSuggestionTimestamp,
  );

  const enhancedSuggestions: SuggestedLeaderboardColumn[] = React.useMemo(() => {
    if (!canShowSuggestions) {
      return [];
    }

    if (!suggestions) {
      // note: empty list means there should not be loading columns
      return suggestionsLoadingIndicatorLeaderboardColumns;
    }

    return suggestions
      .filter(
        (suggestion) =>
          !columns.find(
            (column) => column.id === suggestion.name && column.columnType === suggestion.type,
          ),
      )
      .reduce((result: SuggestedLeaderboardColumn[], suggestion) => {
        if (result.length >= MAX_SUGGESTIONS) {
          return result;
        }

        const column = enhanceSuggestedColumn(suggestion);
        const width: number =
          suggestionWidths[getSuggestionIdentifier(column.id, column.columnType)] ||
          DEFAULT_COLUMN_WIDTH;

        result.push({
          ...column,
          ...{ width },
        });

        return result;
      }, []);
  }, [columns, suggestions, canShowSuggestions, suggestionWidths]);

  const resizeSuggestion = React.useCallback(
    (id: string, columnType: string, width: number) => {
      setSuggestionWidths({
        ...suggestionWidths,
        [getSuggestionIdentifier(id, columnType)]: width,
      });
    },
    [suggestionWidths],
  );

  return {
    suggestions: enhancedSuggestions,
    resizeSuggestion,
    lastDeclinedSuggestionTimestamp,
  };
};

function getSuggestionIdentifier(id: string, type: string): string {
  return `${id}-${type}`;
}

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { backendClient } from '@neptune/shared/core-apis-backend-domain';
import {
  Organization,
  ProjectPrivacy,
  projectPrivacyFromDomainToApi,
} from '@neptune/shared/core-organizations-domain';

import { pickDefaultNonPublicVisibilityValue } from './traits/project-visibility-restricted';

export async function updateVisibilityRestrictionsTrait({
  organization,
  disabledByAdmin,
}: {
  organization: Organization;
  disabledByAdmin: ProjectPrivacy[];
}) {
  await backendClient.updateVisibilityRestrictions({
    organizationIdentifier: organization.id,
    updateRequest: {
      disabledByAdmin: disabledByAdmin.map(projectPrivacyFromDomainToApi),
      defaultValue: projectPrivacyFromDomainToApi(
        pickDefaultNonPublicVisibilityValue(organization.traits),
      ),
    },
  });
}

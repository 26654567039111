import { createSelector } from 'reselect';

import { CompareTab } from '@neptune/project-runs-domain';

import { createPageIdUrlParam } from 'common/pages';
import {
  getComparableExperimentDashboards,
  getCompareDashboards,
} from 'state/project-dashboards/selectors';

export const getCompareTabs = createSelector(
  [getCompareDashboards, getComparableExperimentDashboards],
  (userDashboards, autoGeneratedDashboards) => {
    const dashboards = userDashboards || [];
    const dashboardTabs: CompareTab[] = dashboards.map((dashboard) => {
      const pageId = createPageIdUrlParam({
        id: dashboard.versionBranchId,
        title: dashboard.name,
      });

      return {
        type: 'compare',
        glyph: 'compare',
        // we treat branchId as dashboardId to ensure backward compatibility
        dashboardId: dashboard.versionBranchId,
        routeParams: {
          dash: 'dashboard',
          dashboardId: pageId,
        },
        label: dashboard.name,
      };
    });

    const autoGeneratedTabs: CompareTab[] = autoGeneratedDashboards.map((dashboard) => {
      const pageId = createPageIdUrlParam({
        id: dashboard.versionBranchId,
        title: dashboard.name,
      });

      return {
        type: 'compare',
        glyph: 'compare',
        // we treat branchId as dashboardId to ensure backward compatibility
        dashboardId: dashboard.versionBranchId,
        routeParams: {
          dash: 'dashboard',
          dashboardId: pageId,
        },
        label: dashboard.name,
      };
    });

    return [...dashboardTabs, ...autoGeneratedTabs];
  },
);

import React from 'react';

import { Avatar, AvatarProps } from '@neptune/shared/venus-ui';

import serviceAccountIcon from '../assets/service_account_icon.svg';

type ServiceAccountAvatarProps = Omit<AvatarProps, 'src'>;

export const ServiceAccountAvatar: React.FC<ServiceAccountAvatarProps> = (props) => {
  return <Avatar src={serviceAccountIcon} alt="" {...props} />;
};

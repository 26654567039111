import { Configuration, DefaultApi } from 'generated/admin-client';

import { defaultApiClientConfiguration } from '@neptune/shared/core-apis-common-domain';

// eslint-disable-next-line no-restricted-syntax
export * from 'generated/admin-client/apis';
// eslint-disable-next-line no-restricted-syntax
export * from 'generated/admin-client/models';

const config = new Configuration(defaultApiClientConfiguration);

export const adminClient = new DefaultApi(config);

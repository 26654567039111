import React from 'react';
import { bufferTime, combineLatestWith, delay, filter, map, scan } from 'rxjs';

import { EntityLeaderboardNotification } from '@neptune/shared/entity-leaderboard-domain';
import { UnderEditContext } from '@neptune/shared/venus-domain';

import { getEntityLeaderboardNotificationStream } from './entity-leaderboard-notifications';

type HookParams = {
  projectId?: string;
  entityTypes: string[];
  onNotify: (notifications: EntityLeaderboardNotification[]) => void;
};

export const useEntityLeaderboardChangedNotifications = ({
  projectId,
  entityTypes,
  onNotify,
}: HookParams) => {
  const { underEdit$ } = React.useContext(UnderEditContext);

  React.useEffect(() => {
    if (projectId != null) {
      const syntheticNotifications: EntityLeaderboardNotification[] = [
        {
          messageType: 'ProjectLeaderboardChanged',
          messageBody: {
            projectId,
            experimentTypes: entityTypes,
          },
        },
      ];
      const emptyNotifications: EntityLeaderboardNotification[] = [];
      const subscription = getEntityLeaderboardNotificationStream({
        projectId,
        entityTypes,
      })
        .pipe(
          bufferTime(1200),
          // we need to delay by a few seconds because of possible race condition between asking elastic search and reindexing
          delay(2000),
          combineLatestWith(underEdit$),
          scan<
            [EntityLeaderboardNotification[], boolean],
            [EntityLeaderboardNotification[], boolean]
          >(
            ([, lastUnderEdit], [notifications, underEdit]) => {
              const shouldUseSynthetic =
                !underEdit && lastUnderEdit !== underEdit && notifications.length === 0;

              return [shouldUseSynthetic ? syntheticNotifications : notifications, underEdit];
            },
            [emptyNotifications, false],
          ),
          filter(([notifications, underEdit]) => notifications.length > 0 && !underEdit),
          map(([notifications]) => notifications),
        )
        .subscribe(onNotify);

      return () => subscription.unsubscribe();
    }
  }, [projectId, entityTypes, onNotify, underEdit$]);
};

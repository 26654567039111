import {
  InvitationStatusEnumDTO,
  InvitationTypeEnumDTO,
  NewOrganizationInvitationProjectEntryDTO,
  OrganizationInvitationDTO,
  OrganizationInvitationProjectEntryDTO,
  OrganizationRoleDTO,
  ProjectInvitationDTO,
  ProjectRoleDTO,
} from '@neptune/shared/core-apis-backend-domain';
import { organizationRoleFromApiToDomain } from '@neptune/shared/core-organizations-domain';
import {
  projectRoleFromApiToDomain,
  projectRoleFromDomainToApi,
} from '@neptune/shared/core-project-domain';
import { UserManagementLevel } from '@neptune/user-management-common-domain';

import {
  BaseInvitation,
  Invitation,
  InvitationOrganizationRole,
  InvitationProjectRole,
  InvitationStatus,
  InvitationType,
  NewOrganizationInvitationProjectEntry,
  OrganizationInvitation,
  OrganizationInvitationProjectEntry,
  ProjectInvitation,
} from './invitation-model';

const invitationStatusMapping: Partial<Record<InvitationStatusEnumDTO, InvitationStatus>> = {
  [InvitationStatusEnumDTO.Pending]: InvitationStatus.pending,
  [InvitationStatusEnumDTO.Accepted]: InvitationStatus.accepted,
  [InvitationStatusEnumDTO.Rejected]: InvitationStatus.rejected,
  [InvitationStatusEnumDTO.Revoked]: InvitationStatus.revoked,
};

const invitationTypeMapping: Partial<Record<InvitationTypeEnumDTO, InvitationType>> = {
  [InvitationTypeEnumDTO.EmailRecipient]: InvitationType.email,
  [InvitationTypeEnumDTO.User]: InvitationType.user,
};

const invitationTypeMappingReversed: Partial<Record<InvitationType, InvitationTypeEnumDTO>> = {
  [InvitationType.email]: InvitationTypeEnumDTO.EmailRecipient,
  [InvitationType.user]: InvitationTypeEnumDTO.User,
};

const invitationOrganizationRoleMapping: Partial<
  Record<OrganizationRoleDTO, InvitationOrganizationRole>
> = {
  [OrganizationRoleDTO.Member]: InvitationOrganizationRole.member,
  [OrganizationRoleDTO.Owner]: InvitationOrganizationRole.owner,
};

const invitationProjectRoleMapping: Partial<Record<ProjectRoleDTO, InvitationProjectRole>> = {
  [ProjectRoleDTO.Member]: InvitationProjectRole.member,
  [ProjectRoleDTO.Manager]: InvitationProjectRole.manager,
  [ProjectRoleDTO.Viewer]: InvitationProjectRole.viewer,
};

export abstract class InvitationModelConverter {
  static baseInvitationFromApiToDomain(
    source: ProjectInvitationDTO | OrganizationInvitationDTO,
  ): BaseInvitation {
    return {
      createdAt: source.createdAt,
      id: source.id,
      invitedBy: source.invitedBy,
      invitee: source.invitee,
      organizationName: source.organizationName,
      type: InvitationModelConverter.invitationOriginFromApiToDomain(source.invitationType),
      status: InvitationModelConverter.invitationStatusFromApiToDomain(source.status),
    };
  }

  static projectInvitationFromApiToDomain(source: ProjectInvitationDTO): ProjectInvitation {
    return {
      ...InvitationModelConverter.baseInvitationFromApiToDomain(source),
      level: UserManagementLevel.project,
      projectName: source.projectName,
      roleGrant: projectRoleFromApiToDomain(source.roleGrant),
    };
  }

  static organizationInvitationFromApiToDomain(
    source: OrganizationInvitationDTO,
  ): OrganizationInvitation {
    return {
      ...InvitationModelConverter.baseInvitationFromApiToDomain(source),
      level: UserManagementLevel.organization,
      roleGrant: organizationRoleFromApiToDomain(source.roleGrant),
      lastSentAt: source.lastSentAt,
      projects: source.projects.map(
        InvitationModelConverter.convertOrganizationInvitationProjectFromApiToDomain,
      ),
    };
  }

  static invitationFromApiToDomain(
    type: string,
    source: ProjectInvitationDTO | OrganizationInvitationDTO,
  ): Invitation {
    if (isOrganizationInvitationFromApi(type, source)) {
      return InvitationModelConverter.organizationInvitationFromApiToDomain(source);
    }

    return InvitationModelConverter.projectInvitationFromApiToDomain(source);
  }

  static invitationStatusFromApiToDomain(source: InvitationStatusEnumDTO): InvitationStatus {
    return invitationStatusMapping[source] || InvitationStatus.notSupported;
  }

  static invitationOriginFromApiToDomain(source: InvitationTypeEnumDTO): InvitationType {
    return invitationTypeMapping[source] || InvitationType.notSupported;
  }

  static invitationOriginFromDomainToApi(source: InvitationType): InvitationTypeEnumDTO {
    return invitationTypeMappingReversed[source] || InvitationTypeEnumDTO.User;
  }

  static convertAddToProjectsFromDomainToApi(
    source: NewOrganizationInvitationProjectEntry,
  ): NewOrganizationInvitationProjectEntryDTO {
    return {
      projectIdentity: source.id,
      role: projectRoleFromDomainToApi(source.role),
    };
  }

  static convertOrganizationInvitationProjectFromApiToDomain(
    source: OrganizationInvitationProjectEntryDTO,
  ): OrganizationInvitationProjectEntry {
    return {
      id: source.id,
      name: source.name,
      role: projectRoleFromApiToDomain(source.role),
    };
  }

  static invitationOrganizationRoleFromApiToDomain(
    source: OrganizationRoleDTO,
  ): InvitationOrganizationRole {
    return invitationOrganizationRoleMapping[source] || InvitationOrganizationRole.notSupported;
  }

  static invitationProjectRoleFromApiToDomain(source: ProjectRoleDTO): InvitationProjectRole {
    return invitationProjectRoleMapping[source] || InvitationProjectRole.notSupported;
  }
}

// not exported to discourage using of DTOs
function isOrganizationInvitationFromApi(
  type: string,
  source: ProjectInvitationDTO | OrganizationInvitationDTO,
): source is OrganizationInvitationDTO {
  return type === UserManagementLevel.organization;
}

/* tslint:disable */
/* eslint-disable */
/**
 * scissors
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SnipResponse
 */
export interface SnipResponse {
    /**
     * 
     * @type {string}
     * @memberof SnipResponse
     */
    key: string;
}

/**
 * Check if a given object implements the SnipResponse interface.
 */
export function instanceOfSnipResponse(value: object): value is SnipResponse {
    if (!('key' in value) || value['key'] === undefined) return false;
    return true;
}

export function SnipResponseFromJSON(json: any): SnipResponse {
    return SnipResponseFromJSONTyped(json, false);
}

export function SnipResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SnipResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
    };
}

export function SnipResponseToJSON(value?: SnipResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
    };
}


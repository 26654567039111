import {
  fetchOrganizationPricing as fetchOrganizationPricingRaw,
  OrganizationPricing,
} from '@neptune/pricing-commons-domain';

import { createAsyncActions } from 'state/async-actions';

export enum OrganizationPricingActionTypes {
  request = 'ORGANIZATION_PRICING_FETCH_REQUEST',
  success = 'ORGANIZATION_PRICING_FETCH_SUCCESS',
  fail = 'ORGANIZATION_PRICING_FETCH_FAIL',
}

const actions = createAsyncActions({
  types: OrganizationPricingActionTypes,
  async resolver({
    organizationIdentifier,
  }: {
    organizationIdentifier: string;
  }): Promise<OrganizationPricing | undefined> {
    return fetchOrganizationPricingRaw(organizationIdentifier);
  },
});

export const { execute: fetchOrganizationPricing, success: fetchOrganizationPricingSuccess } =
  actions;

export type OrganizationPricingActions = ReturnType<
  typeof actions.request | typeof actions.success | typeof actions.fail
>;

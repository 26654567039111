import { createSelector } from 'reselect';

import { getContextOrganization } from 'state/selectors-global';
import { AppState } from 'state/types';

const getPricing = (state: any) => state.pricing;

export const getPastInvoices = createSelector(
  [
    (state: AppState) => getContextOrganization(state)?.name,
    (state: AppState) => getPricing(state).pastInvoices,
  ],
  (organizationIdentifier, pricingState) => ({
    ...pricingState,
    entries:
      organizationIdentifier === pricingState.organizationIdentifier
        ? pricingState.entries
        : undefined,
  }),
);

import React from 'react';
import { useDispatch } from 'react-redux';

import { ColumnsContext } from '@neptune/shared/columns-domain';
import { ColumnIdentifier } from '@neptune/shared/leaderboard-domain';

import {
  ColumnVisibilityToggleParams,
  hideColumn,
  moveColumn,
  MoveColumnParams,
  setColumns,
  SetColumnsParams,
  setDefaultColumns,
  SetDefaultColumnsParams,
  showColumn,
  showPinnedColumn,
  ShowPinnedColumnParams,
  updateColumn,
  UpdateColumnParams,
} from './redux/actions';

export const useColumnsActionsContext = () => {
  const { columnsSetId, columnsRootKey, persistent } = React.useContext(ColumnsContext);
  const dispatch = useDispatch();

  const persist = persistent !== false;

  const handleShowColumn = React.useCallback(
    ({ column }: Pick<ColumnVisibilityToggleParams, 'column'>) => {
      dispatch(
        showColumn({
          column,
          columnsRootKey,
          columnsSetId,
          persist,
        }),
      );
    },
    [dispatch, columnsSetId, columnsRootKey, persist],
  );

  const handleShowPinnedColumn = React.useCallback(
    ({ column, pinned }: Pick<ShowPinnedColumnParams, 'column' | 'pinned'>) => {
      dispatch(
        showPinnedColumn({
          column,
          columnsRootKey,
          columnsSetId,
          persist,
          pinned,
        }),
      );
    },
    [dispatch, columnsSetId, columnsRootKey, persist],
  );

  const handleHideColumn = React.useCallback(
    ({ column }: Pick<ColumnVisibilityToggleParams, 'column'>) => {
      dispatch(
        hideColumn({
          column,
          columnsRootKey,
          columnsSetId,
          persist,
        }),
      );
    },
    [dispatch, columnsSetId, columnsRootKey, persist],
  );

  const handleUpdateColumn = React.useCallback(
    ({ column, change }: Pick<UpdateColumnParams, 'column' | 'change'>) => {
      dispatch(
        updateColumn({
          column,
          change,
          columnsRootKey,
          columnsSetId,
          persist,
        }),
      );
    },
    [dispatch, columnsSetId, columnsRootKey, persist],
  );

  const handleMoveColumn = React.useCallback(
    ({ order, source, target }: Pick<MoveColumnParams, 'order' | 'source' | 'target'>) => {
      dispatch(
        moveColumn({
          order,
          source,
          target,
          columnsRootKey,
          columnsSetId,
          persist,
        }),
      );
    },
    [dispatch, columnsSetId, columnsRootKey, persist],
  );

  const handleSetColumns = React.useCallback(
    ({
      columns,
      persist: propsPersist,
      override,
    }: Pick<SetColumnsParams, 'columns' | 'persist' | 'override'>) => {
      dispatch(
        setColumns({
          persist: propsPersist ?? persist,
          columns,
          override,
          columnsRootKey,
          columnsSetId,
        }),
      );
    },
    [dispatch, columnsSetId, columnsRootKey, persist],
  );

  const handleSetDefaultColumns = React.useCallback(
    ({ defaultColumnsIds }: Pick<SetDefaultColumnsParams, 'defaultColumnsIds'>) => {
      dispatch(
        setDefaultColumns({
          defaultColumnsIds,
          columnsRootKey,
        }),
      );
    },
    [dispatch, columnsRootKey],
  );

  const handleResizeColumn = React.useCallback(
    (column: ColumnIdentifier, width: number) => {
      handleUpdateColumn({
        column,
        change: { width },
      });
    },
    [handleUpdateColumn],
  );

  return {
    resizeColumn: handleResizeColumn,
    showColumn: handleShowColumn,
    showPinnedColumn: handleShowPinnedColumn,
    hideColumn: handleHideColumn,
    updateColumn: handleUpdateColumn,
    moveColumn: handleMoveColumn,
    setColumns: handleSetColumns,
    setDefaultColumns: handleSetDefaultColumns,
  };
};

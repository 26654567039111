// Libs
import React from 'react';

import { Banner } from '@neptune/shared/venus-ui';

// App
import { UpgradePlanLink } from './UpgradePlanLink';

// Module
type InvitationsLimitReachedNoticeProps = {
  workspaceName: string;
};

export const InvitationsLimitReachedNotice: React.FC<InvitationsLimitReachedNoticeProps> = ({
  workspaceName,
}) => {
  return (
    <Banner.Warning>
      <span>
        You have reached limit of available invitations. If you want to invite more members to your
        workspace, you can{' '}
        <UpgradePlanLink workspaceName={workspaceName}>upgrade your plan</UpgradePlanLink>.
      </span>
    </Banner.Warning>
  );
};

import React from 'react';

import { useServiceAccounts } from '@neptune/service-accounts-business-logic';
import { SelectServiceAccount, SelectServiceAccountProps } from '@neptune/service-accounts-ui';

type SelectServiceAccountContainerProps = Omit<
  SelectServiceAccountProps,
  'serviceAccounts' | 'onTextChange'
> & {
  assignedServiceAccountIds: string[];
  organizationName: string;
};

export const SelectServiceAccountContainer: React.FC<SelectServiceAccountContainerProps> = ({
  assignedServiceAccountIds,
  organizationName,
  ...restProps
}) => {
  const [filter, setFilter] = React.useState<string>();

  const { value } = useServiceAccounts(organizationName, filter);

  const serviceAccounts = React.useMemo(
    () => value?.filter(({ id }) => !assignedServiceAccountIds.includes(id)),
    [assignedServiceAccountIds, value],
  );

  return (
    <SelectServiceAccount
      serviceAccounts={serviceAccounts}
      onTextChange={setFilter}
      {...restProps}
    />
  );
};

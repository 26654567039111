// Libs
import { Integrations } from '@sentry/tracing';
import type { Integration, Options, TransactionContext } from '@sentry/types';
// App
import { getRouter } from 'router/index';
import desktopRoutes from 'router/routes';
import type { Router } from 'router5';

import { desktopRouterConfig } from 'router/config';

// Module
import { getContextName } from './utils';

type TracingOptions = Pick<Options, 'tracesSampleRate' | 'tracesSampler'>;

export const tracingOptions: TracingOptions = {
  tracesSampleRate: 0.2,
};

export const tracingIntegration: Integration = initBrowserTracingIntegration(
  getRouter(desktopRoutes, desktopRouterConfig),
);

function initBrowserTracingIntegration(router: Router): Integration {
  return new Integrations.BrowserTracing({
    tracingOrigins: [window.location.origin],
    // such transactions are disabled because they deliver info about URL they are leaving instead of URL they are entering
    // we can instrument our router and track it manually if needed
    startTransactionOnLocationChange: false,
    beforeNavigate(context: TransactionContext): TransactionContext | undefined {
      const matchedRouteState = router.matchUrl(window.location.href);

      if (matchedRouteState == null) {
        return context;
      }

      return {
        ...context,
        name: getContextName(matchedRouteState),
      };
    },
  });
}

import { fetchPastInvoices } from '@neptune/pricing-commons-domain';

import { createErrorDescriptor } from 'common/error-handler';
import { createAsyncActions } from 'state/async-actions';

export enum PastInvoicesActionTypes {
  request = 'CURRENT_ORGANIZATION_PAST_INVOICES_FETCH_REQUEST',
  success = 'CURRENT_ORGANIZATION_PAST_INVOICES_FETCH_SUCCESS',
  fail = 'CURRENT_ORGANIZATION_PAST_INVOICES_FETCH_FAIL',
}

const actions = createAsyncActions({
  types: PastInvoicesActionTypes,
  async resolver(organizationIdentifier: string) {
    try {
      const response = await fetchPastInvoices(organizationIdentifier);

      return {
        entries: response.data,
        organizationIdentifier,
      };
    } catch (e) {
      const error = createErrorDescriptor(e);

      if (error.code === 404) {
        return {
          entries: [],
          organizationIdentifier,
        };
      }

      throw e;
    }
  },
});

export const { execute: fetchPastInvoicesAction } = actions;

export type PastInvoicesActions = ReturnType<
  typeof actions.request | typeof actions.success | typeof actions.fail
>;

// Libs
import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

// App
import { isMobile, isTablet } from '@neptune/shared/common-util';
import { LoginActionDTO } from '@neptune/shared/core-apis-backend-domain';

import { isClassicDeployment } from 'common/deploymentModes';
import { useAbTest } from 'common/hooks/useAbTest';
import { useHideIntercom } from 'common/hooks/useHideIntercom';
import { useMobileView } from 'common/hooks/useMobileView';
import { teamTrialRegistrationCookieName } from 'common/team-trial-cookies';
import { KeycloakScene, RegistrationAside, TeamTrialAside } from 'components/keycloak';
import { MobileNotice } from 'components/mobile-notice/MobileNotice';
import { setLoginActionFlag } from 'state/users/register/actions';
import { getLoginActions } from 'state/users/register/selectors';

// Module
import { RegistrationSurvey } from './views/RegistrationSurvey';
import { SetUsernameForm } from './views/SetUsernameForm';

type RegistrationProps = {
  onFinish: () => void;
};

export const Registration: React.FC<RegistrationProps> = React.memo(function Registration({
  onFinish,
}) {
  const loginActions = useSelector(getLoginActions);
  useHideIntercom();
  useMobileView();
  const isTeamTrialRegistration = !!useAbTest<string>(teamTrialRegistrationCookieName);
  const loginAction = loginActions[0];

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setLoginActionFlag('registration'));
  }, [dispatch]);

  if (loginActions.length === 0) {
    if (!isMobile() && !isTablet()) {
      onFinish();
    } else {
      return <MobileNotice onClick={onFinish} />;
    }
  }

  const aside = isTeamTrialRegistration ? <TeamTrialAside /> : <RegistrationAside />;

  let actionComponent: JSX.Element | undefined = undefined;

  if (loginAction === LoginActionDTO.SetUsername) {
    actionComponent = <SetUsernameForm />;
  }

  if (loginAction === LoginActionDTO.Survey) {
    actionComponent = <RegistrationSurvey />;
  }

  return (
    <KeycloakScene aside={isClassicDeployment() ? aside : undefined} data-role="registration">
      <Helmet>
        <title>Register</title>
      </Helmet>
      {actionComponent}
    </KeycloakScene>
  );
});

import {
  AttributeTypeDTO,
  LeaderboardGroupParamsDTO,
  LeaderboardGroupParamsDTOGroupByFieldAggregationModeEnum,
} from '@neptune/shared/core-apis-leaderboard-domain';

import { invertMapTyped, isNotUndefined } from 'common/tsHelpers';

import { AggregationMode, ColumnType } from '../column/column-model';

import { GroupOptions } from './group-options-model';

const groupFieldTypeMapping: Partial<Record<AttributeTypeDTO, ColumnType>> = {
  [AttributeTypeDTO.Bool]: 'bool',
  [AttributeTypeDTO.Datetime]: 'datetime',
  [AttributeTypeDTO.ExperimentState]: 'experimentState',
  [AttributeTypeDTO.File]: 'file',
  [AttributeTypeDTO.FileSet]: 'fileSet',
  [AttributeTypeDTO.Float]: 'float',
  [AttributeTypeDTO.FloatSeries]: 'floatSeries',
  [AttributeTypeDTO.GitRef]: 'gitRef',
  [AttributeTypeDTO.Int]: 'int',
  [AttributeTypeDTO.String]: 'string',
  [AttributeTypeDTO.StringSeries]: 'stringSeries',
  [AttributeTypeDTO.StringSet]: 'stringSet',
};

const invertedColumnTypeMapping = invertMapTyped(groupFieldTypeMapping);

const groupFieldAggregationModeMapping: Record<
  LeaderboardGroupParamsDTOGroupByFieldAggregationModeEnum,
  AggregationMode
> = {
  [LeaderboardGroupParamsDTOGroupByFieldAggregationModeEnum.Auto]: 'auto',
  [LeaderboardGroupParamsDTOGroupByFieldAggregationModeEnum.Average]: 'average',
  [LeaderboardGroupParamsDTOGroupByFieldAggregationModeEnum.Last]: 'last',
  [LeaderboardGroupParamsDTOGroupByFieldAggregationModeEnum.Max]: 'max',
  [LeaderboardGroupParamsDTOGroupByFieldAggregationModeEnum.Min]: 'min',
  [LeaderboardGroupParamsDTOGroupByFieldAggregationModeEnum.Variance]: 'variance',
};
const invertedSortFieldAggregationModeMapping = invertMapTyped(groupFieldAggregationModeMapping);

type RequiredGroupBy = Required<
  Pick<GroupOptions, 'groupBy' | 'groupByFieldAggregationMode' | 'groupByFieldType'>
>;

export abstract class GroupOptionsModelConverter {
  static fromApiToDomain(options: LeaderboardGroupParamsDTO): GroupOptions | undefined {
    const { groupByFieldType, groupByFieldAggregationMode, groupBy } = options;

    if (!groupByFieldType || !groupByFieldAggregationMode || !groupBy) {
      return;
    }

    return groupBy.reduce<RequiredGroupBy | undefined>((acc, curr, i) => {
      if (!groupBy[i] || !groupByFieldAggregationMode[i] || !groupByFieldType[i]) {
        return acc;
      }

      const newGroupByFieldType = this.fieldTypeFromApiToDomain(groupByFieldType[i]);
      const newGroupByFieldAggregationMode = this.aggregationModeFromApiToDomain(
        groupByFieldAggregationMode[i],
      );

      if (!newGroupByFieldAggregationMode || !newGroupByFieldType) {
        return acc;
      }

      if (!acc) {
        acc = {
          groupBy: [],
          groupByFieldType: [],
          groupByFieldAggregationMode: [],
        };
      }

      acc.groupBy.push(groupBy[i]);
      acc.groupByFieldAggregationMode.push(newGroupByFieldAggregationMode);
      acc.groupByFieldType.push(newGroupByFieldType);

      return acc;
    }, undefined);
  }

  static fromDomainToApi(options: GroupOptions): LeaderboardGroupParamsDTO {
    const { groupByFieldType, groupByFieldAggregationMode, groupBy, openedGroups } = options;

    return {
      groupBy,
      groupByFieldType: groupByFieldType?.map(this.fieldTypeFromDomainToApi).filter(isNotUndefined),
      groupByFieldAggregationMode: groupByFieldAggregationMode
        ?.map(this.aggregationModeFromDomainToApi)
        .filter(isNotUndefined),
      openedGroups: openedGroups?.map(({ openedGroup }) => openedGroup),
    };
  }

  static fieldTypeFromDomainToApi(type: ColumnType) {
    return invertedColumnTypeMapping[type];
  }

  static fieldTypeFromApiToDomain(type: AttributeTypeDTO) {
    return groupFieldTypeMapping[type];
  }

  static aggregationModeFromApiToDomain(
    mode: LeaderboardGroupParamsDTOGroupByFieldAggregationModeEnum,
  ) {
    return groupFieldAggregationModeMapping[mode];
  }

  static aggregationModeFromDomainToApi(mode?: AggregationMode) {
    if (!mode) {
      return;
    }

    return invertedSortFieldAggregationModeMapping[mode];
  }
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  acceptInvitation,
  Invitation,
  InvitationStatus,
  resendInvitation,
} from '@neptune/invitations-domain';
import {
  AcceptEmailInvitation,
  AcceptUserInvitation,
  InvalidInvitation,
  InvitationError,
} from '@neptune/invitations-ui';
import { composeInvalidInviteeErrorMessage } from '@neptune/invitations-util';

import { showServerErrorModal } from 'state/ui/modals/server-error/actions';
import { getCurrentUser } from 'state/users/current-user/selectors';

type AcceptInvitationProps = {
  invitation: Invitation;
  afterAccept?: () => Promise<void>;
  afterClose?: () => Promise<void>;
};

export const AcceptSingleInvitationContainer: React.FC<AcceptInvitationProps> = ({
  invitation,
  afterAccept,
  afterClose,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const [errMessage, setErrMessage] = React.useState('');

  const skipInvitation = React.useCallback(async () => {
    if (afterClose) {
      await afterClose();
    }
  }, [afterClose]);

  const resendInvite = React.useCallback(async () => {
    await resendInvitation(invitation);

    if (afterClose) {
      await afterClose();
    }
  }, [invitation, afterClose]);

  const confirmInvitation = React.useCallback(async () => {
    try {
      if (!invitation) {
        return;
      }

      await acceptInvitation(invitation);

      if (afterAccept) {
        await afterAccept();
      }
    } catch (error) {
      if (error instanceof Response) {
        error = await error.json();
      }

      const invalidInviteeMessage = composeInvalidInviteeErrorMessage(error);

      if (invalidInviteeMessage) {
        setErrMessage(invalidInviteeMessage);
      } else {
        dispatch(showServerErrorModal());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitation, afterAccept]);

  if (!user || !invitation) {
    return null;
  }

  if (errMessage) {
    return <InvitationError onClose={skipInvitation} message={errMessage || ''} />;
  }

  if (invitation.status !== InvitationStatus.pending) {
    return <InvalidInvitation invitation={invitation} onClose={skipInvitation} />;
  }

  if (!invitation.id) {
    return (
      <AcceptEmailInvitation
        invitation={invitation}
        onClose={skipInvitation}
        onResend={resendInvite}
      />
    );
  }

  return (
    <AcceptUserInvitation
      invitation={invitation}
      username={user.username}
      onClose={skipInvitation}
      onConfirm={confirmInvitation}
    />
  );
};

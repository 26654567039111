import { SimpleState } from 'router5/types/types/base';
import { MiddlewareFactory, Router } from 'router5/types/types/router';

const OLD_ORG_NAME = 'neptune-ml';
const NEW_ORG_NAME = 'neptune-ai';

// Module
export const migrateTLDtoAiMW: MiddlewareFactory =
  ({}: Router) =>
  (toState) => {
    if (toState.params.organizationName !== OLD_ORG_NAME) {
      // pass
      return true;
    }

    const newState: SimpleState = {
      name: toState.name,
      params: {
        ...toState.params,
        organizationName: NEW_ORG_NAME,
      },
    };

    // eslint-disable-next-line no-console
    console.debug('redirecting from', OLD_ORG_NAME, 'to', NEW_ORG_NAME, 'organization');
    return Promise.reject({
      redirect: newState,
    });
  };

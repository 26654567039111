/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PricingPlanDefinitionDTO } from './PricingPlanDefinitionDTO';
import {
    PricingPlanDefinitionDTOFromJSON,
    PricingPlanDefinitionDTOFromJSONTyped,
    PricingPlanDefinitionDTOToJSON,
} from './PricingPlanDefinitionDTO';

/**
 * 
 * @export
 * @interface PlanUpgradeTraitDTO
 */
export interface PlanUpgradeTraitDTO {
    /**
     * 
     * @type {Array<PricingPlanDefinitionDTO>}
     * @memberof PlanUpgradeTraitDTO
     */
    availablePlans: Array<PricingPlanDefinitionDTO>;
}

/**
 * Check if a given object implements the PlanUpgradeTraitDTO interface.
 */
export function instanceOfPlanUpgradeTraitDTO(value: object): value is PlanUpgradeTraitDTO {
    if (!('availablePlans' in value) || value['availablePlans'] === undefined) return false;
    return true;
}

export function PlanUpgradeTraitDTOFromJSON(json: any): PlanUpgradeTraitDTO {
    return PlanUpgradeTraitDTOFromJSONTyped(json, false);
}

export function PlanUpgradeTraitDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanUpgradeTraitDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'availablePlans': ((json['availablePlans'] as Array<any>).map(PricingPlanDefinitionDTOFromJSON)),
    };
}

export function PlanUpgradeTraitDTOToJSON(value?: PlanUpgradeTraitDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'availablePlans': ((value['availablePlans'] as Array<any>).map(PricingPlanDefinitionDTOToJSON)),
    };
}


import { neptuneUrls } from '@neptune/shared/common-domain';

import type { Integration } from './types';

export const optuna: Integration = {
  id: 'optuna',
  title: 'Optuna',
  installCommand: 'pip install neptune neptune-optuna',
  scriptName: 'train_optuna.py',
  runCommand: 'python train_optuna.py',
  link: neptuneUrls.integrationOptunaDoc,
  script: `import optuna

import neptune
import neptune.integrations.optuna as optuna_utils

run = neptune.init_run(
    project="<%= projectIdentifier %>",
    api_token="<%= apiToken %>",
)  # your credentials

params = {"direction": "minimize", "n_trials": 20}
run["parameters"] = params


def objective(trial):
    param = {
        "epochs": trial.suggest_int("epochs", 3, 10),
        "learning_rate": trial.suggest_float("learning_rate", 1e-5, 1e-1, log=True),
        "dropout": trial.suggest_uniform("dropout", 0.2, 0.8),
    }

    loss = (param["dropout"] * param["learning_rate"]) ** param["epochs"]

    return loss


neptune_callback = optuna_utils.NeptuneCallback(run)

study = optuna.create_study(direction=params["direction"])
study.optimize(objective, n_trials=params["n_trials"], callbacks=[neptune_callback])

run.stop()`,
};

import React from 'react';
import { differenceInDays, formatDistance } from 'date-fns';
// eslint-disable-next-line no-restricted-imports
import { WithPaddingRenderer } from 'neptune-core-ui/components/table/renderers';

import { formatDateShort } from '@neptune/shared/common-util';
import { bemBlock, Layout, Link, SimpleTooltip } from '@neptune/shared/venus-ui';
import { OrganizationMember } from '@neptune/user-management-domain';

import './PendingInvitationRenderer.less';

const block = bemBlock('pending-invitation-renderer');

export const PendingInvitationRenderer: React.FC<{
  data: OrganizationMember;
  params: {
    onInviteResend: (invitationId: string) => void;
    canEdit: boolean;
  };
}> = ({ data, params }) => {
  const { invitationInfo } = data;
  const { onInviteResend, canEdit } = params;

  const handleResendClick = React.useCallback(() => {
    if (invitationInfo?.id) {
      onInviteResend(invitationInfo.id);
    }
  }, [onInviteResend, invitationInfo?.id]);

  if (!invitationInfo?.id) {
    return null;
  }

  const { lastSentAt } = invitationInfo;

  const timeAgo =
    differenceInDays(lastSentAt, new Date()) === 0
      ? 'less than one day ago'
      : formatDistance(lastSentAt, new Date(), { addSuffix: true });
  return (
    <WithPaddingRenderer>
      <Layout.Column className={block()}>
        <Layout.Row justifyContent="end">
          <SimpleTooltip content={formatDateShort(lastSentAt)}>
            <span>Invite sent {timeAgo}</span>
          </SimpleTooltip>
        </Layout.Row>
        <Layout.Row justifyContent="end">
          {canEdit && (
            <Link
              size="sm"
              fontWeight="normal"
              onClick={handleResendClick}
              className={block('link')}
            >
              Resend invite
            </Link>
          )}
        </Layout.Row>
      </Layout.Column>
    </WithPaddingRenderer>
  );
};

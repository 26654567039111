import React from 'react';

import { bemBlock, Layout, LayoutColumnProps } from '@neptune/shared/venus-ui';

import './ServiceAccountListView.less';

type ServiceAccountListViewProps = LayoutColumnProps<{}>;

const block = bemBlock('service-account-list-view');

export const ServiceAccountListView: React.FC<ServiceAccountListViewProps> = ({
  className,
  ...restProps
}) => {
  return (
    <Layout.Column
      className={block({
        extra: className,
      })}
      {...restProps}
    />
  );
};

import React from 'react';

import { EmptyView, EmptyViewButtonData } from '@neptune/shared/common-ui';
import { Icon } from '@neptune/shared/venus-ui';

import emptyStateIcon from '../assets/empty-state-service-accounts.svg';

type WorkspaceEmptyStateProps = {
  onNewServiceAccount?: () => void;
};

export const WorkspaceEmptyState: React.FC<WorkspaceEmptyStateProps> = ({
  onNewServiceAccount,
}) => {
  const title = 'Create service accounts and automate your processes!';
  const description =
    'A service account is a special type of Neptune account ' +
    'intended to represent a non-human user that needs to access Neptune such ' +
    'as a CI/CD pipeline or a report generating script.';
  const buttons: EmptyViewButtonData[] = [
    {
      caption: (
        <>
          <Icon glyph="plus-circle" /> New service account
        </>
      ),
      'data-role': 'new-service-account-button',
      onClick: onNewServiceAccount,
    },
  ];
  const icon = <img src={emptyStateIcon} alt="" />;

  return (
    <EmptyView
      variant="legacy"
      title={title}
      icon={icon}
      description={description}
      buttons={buttons}
      data-role="empty-state"
    />
  );
};

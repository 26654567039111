import {
  backendClient,
  GraduatedPricingDTO,
  OrganizationPricingDTO,
  PriceTierDTO,
  SimplePricingDTO,
} from '@neptune/shared/core-apis-backend-domain';
import { PricingPlan, pricingPlanFromApiToDomain } from '@neptune/shared/core-organizations-domain';

import { createErrorDescriptor } from 'common/error-handler';

export type OrganizationPricing = {
  pricingPlan: PricingPlan;
  supportsPayments: boolean;
  users?: UserBasedSubscriptionPricing;
  monitoringTime?: UsagePricing;
  flatFeeInCents?: number;
  activeProjects?: UsagePricing;
  storage?: UsagePricing;
};

/**
 * We have two types of pricing in Neptune:
 * - User based - legacy, users pay for each member of organization, we hope we can drop it in near future when all customers switch to:
 * - Usage based pricing - users pay for things they use (like storage, monitoring hours etc.)
 */
export type UsageBasedSubscriptionPricing = {
  flatPriceInCents: number;
  storagePricing: UsagePricing;
  monitoringTimePricing: UsagePricing;
};

export type UserBasedSubscriptionPricing = {
  numberOfUsers: number;
  userPriceInCents: number;
};

export type UsagePricing = {
  currentValue: number;
  stepSize: number;
  unitSymbol: string;
  priceTiers: PriceTier[];
};

export type PriceTier = {
  lowerAmount: number;
  upperAmount: number;
  unitPriceInCents: number;
};

/**
 * This type represents UsagePricing which should contain at least one pricing tier (0-1 free tiers, 0-1 paid tiers).
 * This model flattens all tiers into one object with precalculated information that is most useful for UI.
 * All prices are floats in dollars.
 * WARNING: This model assumes existence of only one free, and one paid tier for simplicity (agreed with backend).
 * For more complex tiers use more low level UsagePricing model.
 */
export type UsageFlattenedPricing = {
  // Units that user doesn't pay for
  unitsInFreeTier: number;

  // Units that user pays for (excludes free tier)
  paidUnits: number;
  // Price per unit for units that user pays for
  unitPriceInPaidTier: number;

  // Total units that user has currently set/bought
  currentUnits: number;
  // Total price for current units (includes free tier and paid tier)
  totalPriceForCurrentUnits: number;

  // Symbol of unit: GB, hours etc.
  unitSymbol: string;
  // user can set units in steps, this is the step size
  stepSize: number;
};

export type SimplePricing = {
  currentValue: number;
  unitPriceInCents: number;
};

// region converters
export function organizationPricingFromApiToDomain(
  source: OrganizationPricingDTO,
): OrganizationPricing {
  return {
    pricingPlan: pricingPlanFromApiToDomain(source.pricingPlan),
    supportsPayments: source.supportsPayments,
    // v1
    users: source.users ? paidUserBasedSubscriptionFromApiToDomain(source.users) : undefined,
    // v2
    monitoringTime: source.monitoringTime
      ? usagePricingFromApiToDomain(source.monitoringTime)
      : undefined,
    flatFeeInCents: source.flatFee ? source.flatFee.priceInCents : undefined,
    // v3
    activeProjects: source.activeProjects
      ? usagePricingFromApiToDomain(source.activeProjects)
      : undefined,
    storage: source.storage ? usagePricingFromApiToDomain(source.storage) : undefined,
  };
}

export function paidUserBasedSubscriptionFromApiToDomain(
  source: SimplePricingDTO,
): UserBasedSubscriptionPricing {
  return {
    numberOfUsers: source.currentValue,
    userPriceInCents: source.unitPriceInCents,
  };
}

export function usagePricingFromApiToDomain(pricing: GraduatedPricingDTO): UsagePricing {
  return {
    ...pricing,
    priceTiers: priceTiersFromApiToDomain(pricing.priceTiers),
  };
}

// todo use this for user pricing to pay some old debt
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function simplePricingFromApiToDomain(source: SimplePricingDTO): SimplePricing {
  return {
    currentValue: source.currentValue,
    unitPriceInCents: source.unitPriceInCents,
  };
}

export function priceTiersFromApiToDomain(priceTiers: PriceTierDTO[]): PriceTier[] {
  const tiers = [...priceTiers].sort((a, b) => a.upperAmount - b.upperAmount);

  return tiers.map((tier, i) => ({
    ...tier,
    lowerAmount: tiers[i - 1]?.upperAmount || 0,
  }));
}
// endregion converters

export async function fetchOrganizationPricing(
  organizationIdentifier: string,
): Promise<OrganizationPricing | undefined> {
  try {
    const organizationPricing = await backendClient.getOrganizationPricing({
      organizationIdentifier,
    });
    return organizationPricingFromApiToDomain(organizationPricing);
  } catch (e) {
    const error = createErrorDescriptor(e);

    if (error.code === 404) {
      return undefined;
    }

    throw e;
  }
}

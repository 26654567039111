import { AnyAction } from 'redux';

import {
  SearchCriterion,
  SearchQuery,
  SearchQueryModelConverter,
} from '@neptune/search-query-domain';
import {
  LeaderboardListGroupKey,
  LeaderboardSortingParams,
  searchLeaderboard,
  SearchLeaderboardRequest,
} from '@neptune/shared/leaderboard-domain';

import { makeProjectIdentifier } from 'common/project';
import { createGroupQuery } from 'common/query';
import { KnownAttributes } from 'domain/experiment/attribute';
import { createAsyncActions } from 'state/async-actions';
import { getCurrentRouteParams } from 'state/selectors-global';
import type { AppState, NThunkDispatch } from 'state/types';

export enum LeaderboardGroupPreviewActionTypes {
  closeGroup = 'LEADERBOARD_GROUP_CLOSE',
  request = 'LEADERBOARD_GROUP_PREVIEW_FETCH',
  success = 'LEADERBOARD_GROUP_PREVIEW_FETCH_SUCCESS',
  fail = 'LEADERBOARD_GROUP_PREVIEW_FETCH_FAIL',
}

const getGroupPreview = createAsyncActions({
  types: LeaderboardGroupPreviewActionTypes,
  async resolver(
    params: SearchLeaderboardRequest & {
      groupIdentifier: string;
      leaderboardIdentifier: string;
      paginationTarget?: { beforeToken: string } | { continuationToken: string };
    },
  ) {
    const { leaderboardIdentifier, groupIdentifier, paginationTarget, ...requestParams } = params;
    const result = await searchLeaderboard(requestParams);

    return {
      leaderboardIdentifier,
      groupIdentifier,
      paginationTarget,
      ...result,
    };
  },
});

const { execute: fetchGroupPreview } = getGroupPreview;

type CloseGroupPreviewParams = {
  leaderboardIdentifier: string;
  groupId: string;
};

export function closeGroupPreview({ leaderboardIdentifier, groupId }: CloseGroupPreviewParams) {
  return {
    type: LeaderboardGroupPreviewActionTypes.closeGroup,
    payload: { groupId, leaderboardIdentifier },
  } as const;
}

type OpenGroupPreviewParams = {
  currentQuery: string;
  entityId: string;
  entityType: string;
  groupId: string;
  groupKey: LeaderboardListGroupKey[];
  leaderboardIdentifier: string;
  sortOptions?: LeaderboardSortingParams;
  paginationTarget?: { beforeToken: string } | { continuationToken: string };
  types: string[];
  experimentsOnly: boolean;
};

export const openGroupPreview = ({
  currentQuery,
  entityId,
  entityType,
  groupId,
  groupKey,
  leaderboardIdentifier,
  paginationTarget,
  sortOptions,
  types,
  experimentsOnly,
}: OpenGroupPreviewParams) => {
  return async (dispatch: NThunkDispatch<AnyAction>, getState: () => AppState) => {
    const { projectName, organizationName } = getCurrentRouteParams(getState());

    const groupNql = createGroupQuery(currentQuery, groupKey);

    const groupQuery = SearchQueryModelConverter.convertNqlToSearchQuery(groupNql);
    const baseQuery = buildSearchLeaderboardMetaQuery(entityType, entityId, false);

    const query = SearchQueryModelConverter.convertSearchQueryToNql({
      criteria: [baseQuery, groupQuery],
      operator: 'and',
    });

    const projectIdentifier = makeProjectIdentifier(organizationName, projectName);
    const grouping: SearchLeaderboardRequest['grouping'] = {
      groupBy: groupKey.map(({ field }) => ({
        name: field.name,
        aggregationMode: field.aggregation,
        type: field.type,
      })),
      openedGroups: [{ openedGroup: groupId, ...paginationTarget }],
    };

    return dispatch(
      fetchGroupPreview({
        leaderboardIdentifier,
        paginationTarget,
        type: types,
        projectIdentifier,
        groupIdentifier: groupId,
        query,
        sorting: sortOptions,
        grouping,
        experimentsOnly,
      }),
    );
  };
};

export type GroupActions = ReturnType<
  | typeof closeGroupPreview
  | typeof getGroupPreview.request
  | typeof getGroupPreview.success
  | typeof getGroupPreview.fail
>;

// FIXME: this is an ugly copy&paste to avoid circular dependencies between shared/leaderboard and shared/entity-leaderboard silos,
//        should be sorted with group by/runs leaderboard polling refactor
function buildSearchLeaderboardMetaQuery(
  entityType: string,
  entityId: string,
  trash: boolean,
): SearchQuery {
  const modelCriterion: SearchCriterion = {
    attribute: KnownAttributes.ModelId,
    operator: '=',
    type: 'string',
    value: entityId,
  };

  const trashCriterion: SearchCriterion = {
    attribute: KnownAttributes.Trashed,
    operator: '=',
    type: 'bool',
    value: trash,
  };

  return {
    operator: 'and',
    criteria: entityType === 'model' ? [trashCriterion, modelCriterion] : [trashCriterion],
  };
}

import React from 'react';

import { useColumnsContext } from '@neptune/shared/columns-business-logic';

import { AttributeDefinition } from 'domain/experiment/attribute';

export const useAttributeDefinitionsFromCurrentColumns = (): AttributeDefinition[] => {
  const { publicColumns } = useColumnsContext();

  return React.useMemo(
    () =>
      publicColumns.map(
        ({ id: name, columnType: type, aggregationMode: subproperty, displayName }) => ({
          name,
          type,
          subproperty,
          displayName,
        }),
      ),
    [publicColumns],
  );
};

import { Namespaces } from 'domain/common/namespaces';

import config from 'config';

import type { ColumnIdentifier, LeaderboardColumn } from './column-model';
import { columnIdentityComparator } from './column-model-utils';

export const NEPTUNE_COLUMN_IDS = {
  GROUP_BY: 'neptune-group-by',
  SELECTION: 'neptune-selection',
  COMPARE: 'neptune-compare',
  CREATION_TIME: `${Namespaces.System}/creation_time`,
  ID: `${Namespaces.System}/id`,
  OWNER: `${Namespaces.System}/owner`,
  MONITORING_TIME: `${Namespaces.System}/monitoring_time`,
  NAME: `${Namespaces.System}/name`,
  SIZE: `${Namespaces.System}/size`,
  STATE: `${Namespaces.System}/state`,
  RUNNING_TIME: `${Namespaces.System}/running_time`,
  TAGS: `${Namespaces.System}/tags`,
  TYPE: 'neptune-type',
  DESCRIPTION: `${Namespaces.System}/description`,
  STAGE: `${Namespaces.System}/stage`,
  GROUP_TAGS: `${Namespaces.System}/group_tags`,
};

type ColumnSettings = ColumnIdentifier & LeaderboardColumn & { visibleByDefault?: boolean };

const getColumnIdsFromConfig = (configColumns: string): string[] => {
  const columns = configColumns.split(',').map((s) => s.trim());

  return columns;
};

const primaryColumnMap: Record<string, string> = {
  id: NEPTUNE_COLUMN_IDS.ID,
  name: NEPTUNE_COLUMN_IDS.NAME,
};
export const primaryColumnId: string =
  primaryColumnMap[config.primaryColumn] ?? NEPTUNE_COLUMN_IDS.ID;

export function isPrimaryColumn(columnId: string): boolean {
  return columnId === primaryColumnId;
}

function isNameColumn(columnId: string): boolean {
  return columnId === NEPTUNE_COLUMN_IDS.NAME;
}

function isIdColumn(columnId: string): boolean {
  return columnId === NEPTUNE_COLUMN_IDS.ID;
}

export function isOneOfPrimaryColumns(columnId: string): boolean {
  return isPrimaryColumn(columnId) || isNameColumn(columnId) || isIdColumn(columnId);
}

export const localTableStateColumns = [
  NEPTUNE_COLUMN_IDS.GROUP_BY,
  NEPTUNE_COLUMN_IDS.SELECTION,
  NEPTUNE_COLUMN_IDS.COMPARE,
];

const visibleColumns: readonly string[] = getColumnIdsFromConfig(
  config.defaultVisibleObjectTableFields,
);

const pinnedColumns: readonly string[] = getColumnIdsFromConfig(
  config.defaultPinnedObjectTableFields,
);

const NAME_COLUMN: ColumnSettings = {
  id: NEPTUNE_COLUMN_IDS.NAME,
  columnType: 'string',
  exportable: true,
  groupable: true,
  hideable: config.primaryColumn === 'id',
  pinned: pinnedColumns.includes(NEPTUNE_COLUMN_IDS.NAME),
  reorderable:
    config.primaryColumn !== 'id'
      ? {
          draggable: false,
          droppable: { after: true },
        }
      : true,
  resizable: true,
  sortable: true,
  width: 150,
  visibleByDefault: visibleColumns.includes(NEPTUNE_COLUMN_IDS.NAME),
};

const builtinColumnsSettings: ColumnSettings[] = [
  {
    id: NEPTUNE_COLUMN_IDS.GROUP_BY,

    // this type is just virtual as this column is represented by its own specific renderer
    columnType: 'string',
    displayName: 'Group By',
    exportable: false,
    groupable: false,
    hideable: false,
    pinned: true,
    reorderable: false,
    resizable: true,
    sortable: false,
    width: 150,
    visibleByDefault: true,
  },
  {
    id: NEPTUNE_COLUMN_IDS.SELECTION,
    // this type is just virtual as this column is represented by its own specific renderer
    columnType: 'string',
    exportable: false,
    groupable: false,
    hideable: false,
    pinned: true,
    reorderable: false,
    resizable: false,
    sortable: false,
    width: 3 * 16,
    visibleByDefault: true,
  },
  {
    id: NEPTUNE_COLUMN_IDS.COMPARE,
    // this type is just virtual as this column is represented by its own specific renderer
    columnType: 'string',
    exportable: false,
    groupable: false,
    hideable: false,
    pinned: true,
    reorderable: false,
    resizable: false,
    sortable: false,
    width: 16,
    visibleByDefault: true,
  },
  {
    id: NEPTUNE_COLUMN_IDS.TYPE,
    columnType: 'string',
    displayName: 'Type',
    exportable: true,
    groupable: false,
    hideable: false,
    pinned: true,
    reorderable: false,
    resizable: true,
    sortable: false,
    width: 110,
  },
  // Name column should be aligned to the left when primary
  ...(config.primaryColumn === 'name' ? [NAME_COLUMN] : []),
  {
    id: NEPTUNE_COLUMN_IDS.ID,
    columnType: 'string',
    exportable: true,
    groupable: false,
    hideable: config.primaryColumn !== 'id',
    pinned: pinnedColumns.includes(NEPTUNE_COLUMN_IDS.ID),
    reorderable:
      config.primaryColumn === 'id'
        ? {
            draggable: false,
            droppable: { after: true },
          }
        : true,
    resizable: true,
    sortable: true,
    width: 200,
    visibleByDefault: visibleColumns.includes(NEPTUNE_COLUMN_IDS.ID),
  },
  {
    id: NEPTUNE_COLUMN_IDS.STAGE,
    columnType: 'string',
    exportable: true,
    groupable: true,
    hideable: true,
    pinned: pinnedColumns.includes(NEPTUNE_COLUMN_IDS.STAGE),
    reorderable: true,
    resizable: true,
    sortable: true,
    width: 110,
    visibleByDefault: visibleColumns.includes(NEPTUNE_COLUMN_IDS.STAGE),
  },
  {
    id: NEPTUNE_COLUMN_IDS.CREATION_TIME,
    columnType: 'datetime',
    exportable: true,
    groupable: false,
    hideable: true,
    pinned: pinnedColumns.includes(NEPTUNE_COLUMN_IDS.CREATION_TIME),
    reorderable: true,
    resizable: true,
    sortable: true,
    width: 140,
    visibleByDefault: visibleColumns.includes(NEPTUNE_COLUMN_IDS.CREATION_TIME),
  },
  {
    id: NEPTUNE_COLUMN_IDS.OWNER,
    columnType: 'string',
    exportable: true,
    groupable: true,
    hideable: true,
    pinned: pinnedColumns.includes(NEPTUNE_COLUMN_IDS.OWNER),
    reorderable: true,
    resizable: true,
    sortable: true,
    width: 150,
    visibleByDefault: visibleColumns.includes(NEPTUNE_COLUMN_IDS.OWNER),
  },
  // Name column should not be aligned to the left when not primary
  ...(config.primaryColumn === 'name' ? [] : [NAME_COLUMN]),
  {
    id: NEPTUNE_COLUMN_IDS.STATE,
    columnType: 'string',
    exportable: true,
    groupable: true,
    hideable: true,
    pinned: pinnedColumns.includes(NEPTUNE_COLUMN_IDS.STATE),
    reorderable: true,
    resizable: true,
    sortable: true,
    width: 120,
    visibleByDefault: visibleColumns.includes(NEPTUNE_COLUMN_IDS.STATE),
  },
  {
    id: NEPTUNE_COLUMN_IDS.MONITORING_TIME,
    columnType: 'int',
    exportable: true,
    groupable: false,
    hideable: true,
    pinned: pinnedColumns.includes(NEPTUNE_COLUMN_IDS.MONITORING_TIME),
    reorderable: true,
    resizable: true,
    sortable: true,
    width: 140,
    visibleByDefault: visibleColumns.includes(NEPTUNE_COLUMN_IDS.MONITORING_TIME),
  },
  {
    id: NEPTUNE_COLUMN_IDS.TAGS,
    columnType: 'stringSet',
    exportable: true,
    groupable: true,
    hideable: true,
    pinned: pinnedColumns.includes(NEPTUNE_COLUMN_IDS.TAGS),
    reorderable: true,
    resizable: true,
    sortable: false,
    width: 200,
    visibleByDefault: visibleColumns.includes(NEPTUNE_COLUMN_IDS.TAGS),
  },
  {
    id: NEPTUNE_COLUMN_IDS.DESCRIPTION,
    columnType: 'string',
    exportable: true,
    groupable: true,
    hideable: true,
    pinned: pinnedColumns.includes(NEPTUNE_COLUMN_IDS.DESCRIPTION),
    reorderable: true,
    resizable: true,
    sortable: false,
    width: 200,
    visibleByDefault: visibleColumns.includes(NEPTUNE_COLUMN_IDS.DESCRIPTION),
  },
  {
    id: NEPTUNE_COLUMN_IDS.GROUP_TAGS,
    columnType: 'stringSet',
    exportable: true,
    groupable: true,
    hideable: true,
    pinned: pinnedColumns.includes(NEPTUNE_COLUMN_IDS.GROUP_TAGS),
    reorderable: true,
    resizable: true,
    sortable: false,
    width: 200,
    visibleByDefault: visibleColumns.includes(NEPTUNE_COLUMN_IDS.GROUP_TAGS),
  },
];

export const defaultVisibleColumnsSettings = builtinColumnsSettings.filter(
  (colSetting) => colSetting.visibleByDefault,
);

export function getBuiltinColumnSettings(
  columnIdentifier: ColumnIdentifier,
): ColumnSettings | undefined {
  return builtinColumnsSettings.find((colSettings) =>
    columnIdentityComparator(colSettings, columnIdentifier),
  );
}

export function getBuiltInColumnSettingsById(id: string): ColumnSettings | undefined {
  return builtinColumnsSettings.find((colSettings) => colSettings.id === id);
}

export function isColumnExportable(id: string): boolean {
  return getBuiltInColumnSettingsById(id)?.exportable !== false;
}

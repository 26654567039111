import React from 'react';
import { retryBackoff, RetryBackoffConfig } from 'backoff-rxjs';
import {
  BehaviorSubject,
  catchError,
  combineLatestWith,
  EMPTY,
  filter,
  of,
  Subject,
  switchMap,
  tap,
} from 'rxjs';

import { handleFetch } from './handle-fetch';
import { tempo } from './tempo';

export type UsePollingParams<T, R> = {
  resolver: (query: T) => Promise<R>;
  pollingInterval?: number;
  onFetchSucceed?: (value: R, query: T) => void;
  onFetchStarted?: (query: T) => void;
  onFetchFailed?: (value: any | undefined, query: T) => void;
  onAfterRetriesError?: (value: any | undefined, query: T) => void;
  onStart?: (query: T) => void;
  enabled?: boolean;
};

export type UsePollingResult<R> = {
  result: R | undefined;
  refresh: () => void;
};

export const usePolling = <T, R>(
  query: T,
  {
    resolver,
    pollingInterval = 30_000,
    onFetchStarted,
    onFetchSucceed,
    onFetchFailed,
    onAfterRetriesError,
    onStart,
    enabled = true,
  }: UsePollingParams<T, R>,
  {
    backoffDelay = undefined,
    initialInterval = 250,
    maxInterval = 60_000,
    maxRetries = 100,
  }: Partial<RetryBackoffConfig> = {},
): UsePollingResult<R> => {
  const defaultBackoffDelay = React.useCallback(
    (iteration, initialInterval) => Math.pow(1.612, iteration) * initialInterval,
    [],
  );
  backoffDelay = backoffDelay || defaultBackoffDelay;
  const [result, setResult] = React.useState<R>();

  // observable that emits on every query change
  const query$ = React.useRef(new Subject<T>());

  // subject (writable and readable observable) that triggers leaderboard refresh
  const refresh$ = React.useRef(new Subject<void>());
  // callback that hides subject
  const refresh = React.useCallback(() => refresh$.current.next(), [refresh$]);

  const enabled$ = React.useRef(new BehaviorSubject(enabled));

  React.useEffect(() => {
    // implementation:
    // * every query change
    // * resets tempo (which emits with changed query, with interval, with manual refresh and with application visibility change)
    // * initiates fetch (with lifecycle callbacks) dropping any earlier
    // * in case of error retry fetch with sophisticated backoff settings
    const subscription = query$.current
      .pipe(
        tap<T>((query) => onStart?.(query)),
        tempo(pollingInterval, refresh$.current, 10),
        combineLatestWith(enabled$.current),
        switchMap(([query, enabled]) =>
          of(query).pipe(
            filter(() => enabled),
            handleFetch({
              resolver,
              onFetchStarted,
              onFetchSucceed,
              onFetchFailed,
            }),
            retryBackoff({
              backoffDelay,
              initialInterval,
              maxInterval,
              maxRetries,
              resetOnSuccess: true,
            }),
            catchError((err) => {
              onAfterRetriesError?.(err, query);
              return EMPTY;
            }),
          ),
        ),
      )
      .subscribe(setResult);

    return () => {
      subscription.unsubscribe();
    };
  }, [
    resolver,
    pollingInterval,
    backoffDelay,
    initialInterval,
    maxInterval,
    maxRetries,
    onFetchStarted,
    onFetchSucceed,
    onFetchFailed,
    onAfterRetriesError,
    onStart,
  ]);

  React.useEffect(() => {
    enabled$.current.next(enabled);
  }, [enabled]);

  React.useEffect(() => {
    query$.current.next(query);
  }, [query]);

  return {
    result,
    refresh,
  };
};

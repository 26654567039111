// Libs
import { createSelector } from 'reselect';
import { toNormalizedRouteName } from 'router/helpers';
import { State as RouterState } from 'router5';

// App
import { OrganizationWithRole } from '@neptune/shared/core-organizations-domain';

import {
  getContextOrganization as untypedGetContextOrganization,
  getCurrentOrganization as untypedGetCurrentOrganization,
  getOrganizationsList as untypedGetOrganizationsList,
} from 'state/selectors';
import { AppState } from 'state/types';

/**
 * This module will be renamed to `selectors.ts` once untyped version disappears
 */
export default function getState(state: AppState) {
  return state;
}

type RouterStateParams = RouterState['params'];

/**
 * @deprecated use @neptune/shared/routing-business-logic instead
 */
export const getRouterState = (state: any): RouterState => state.router.route;

/**
 * @deprecated use @neptune/shared/routing-business-logic instead
 */
export const getRouterPrevState = (state: any): RouterState => state.router.previousRoute;

const getCurrentRouteMemoized = createSelector(
  getRouterState,
  (route: RouterState): RouterState => {
    if (route == null) {
      return route;
    }

    const { name, ...other } = route;
    return {
      name: toNormalizedRouteName(name),
      ...other,
    };
  },
);

const getPreviousRouteMemoized = createSelector(
  getRouterPrevState,
  (route: RouterState): RouterState => {
    if (route == null) {
      return route;
    }

    const { name, ...other } = route;
    return {
      name: toNormalizedRouteName(name),
      ...other,
    };
  },
);

/**
 * @deprecated use @neptune/shared/routing-business-logic instead
 */
export const getCurrentRoute = <T extends RouterState = RouterState>(state: any) =>
  getCurrentRouteMemoized(state) as T;

/**
 * @deprecated use @neptune/shared/routing-business-logic instead
 */
export const getPreviousRoute = <T extends RouterState = RouterState>(state: any) =>
  getPreviousRouteMemoized(state) as T;

/**
 * @deprecated use @neptune/shared/routing-business-logic instead
 */
export const getCurrentRouteName = (state: AppState): string => getCurrentRoute(state).name;

const getCurrentRouteParamsMemoized = createSelector(getCurrentRouteMemoized, (route) => {
  const { params } = route || {};
  return params || {};
});

/**
 * @deprecated use @neptune/shared/routing-business-logic instead
 */
export const getCurrentRouteParams = <T extends RouterStateParams = RouterStateParams>(
  state: any,
): T => getCurrentRouteParamsMemoized(state) as T;

/**
 * @deprecated use @neptune/shared/routing-business-logic instead
 *
 * Creates a selector returning current route parameter's value
 * @param name Name of the parameter to return
 * Unless you can use one of the predefined parameter selectors, make sure to define your local selector outside the component.
 * Otherwise, reference updating on each render won't allow redux to return cached values.
 * @see https://react-redux.js.org/api/hooks#useselector
 */
export const createCurrentRouteParamSelector = (name: string) => (state: AppState) =>
  getCurrentRouteParams(state)[name];

export const getOrganizationNameFromRouteParams =
  createCurrentRouteParamSelector('organizationName');
export const getProjectNameFromRouteParams = createCurrentRouteParamSelector('projectName');
export const getShortIdFromRouteParams = createCurrentRouteParamSelector('shortId');
export const getRunIdentificationKeyFromRouteParams =
  createCurrentRouteParamSelector('runIdentificationKey');
export const getTypeFromRouteParams = createCurrentRouteParamSelector('type');
export const getDetailsTabFromRouteParams = createCurrentRouteParamSelector('detailsTab');
export const getQueryFromRouteParams = createCurrentRouteParamSelector('query');
export const getViewIdFromRouteParams = createCurrentRouteParamSelector('viewId');

export const getCurrentOrganization: (state: any) => OrganizationWithRole | undefined = (state) =>
  untypedGetCurrentOrganization(state);
export const getContextOrganization: (state: any) => OrganizationWithRole | undefined = (state) =>
  untypedGetContextOrganization(state);
const getOrganizationsList: (state: any) => OrganizationWithRole[] = (state) =>
  untypedGetOrganizationsList(state);
export const getWorkspaceByName: (
  state: any,
  workspaceName: string,
) => OrganizationWithRole | undefined = (state, workspaceName) =>
  getOrganizationsList(state).find(({ name }) => name === workspaceName);

export const getUserNameFromRouteParams = createCurrentRouteParamSelector('userName');

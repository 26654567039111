// Libs
import React from 'react';

import { bemBlock, Icon, Layout, Link } from '@neptune/shared/venus-ui';

import './CancelSubscriptionButton.less';

const block = bemBlock('cancel-subscription-button');

export const CancelSubscriptionButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Layout.Column spacedChildren="md" className={block()}>
      <Layout.Element>
        <div className={block('title')}>Delete account</div>
      </Layout.Element>

      <Layout.Row spacedChildren="sm" alignItems="center">
        <Icon className={block('icon')} glyph="warning" />
        <span>Once you delete your account, there is no going back. Please be certain.</span>
      </Layout.Row>

      <Layout.Element>
        <Link onClick={onClick} className={block('link')}>
          Delete my account
        </Link>
      </Layout.Element>
    </Layout.Column>
  );
};

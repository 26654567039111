import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { WithPaddingRenderer } from 'neptune-core-ui/components/table/renderers';

import { InvitationType, OrganizationInvitation } from '@neptune/invitations-domain';
import { UserAvatar } from '@neptune/shared/avatars-ui';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UserProfileDTO } from '@neptune/shared/core-apis-backend-domain';
import { Layout, LegacyEmblem, Link } from '@neptune/shared/venus-ui';
import { BaseMember } from '@neptune/user-management-domain';

import { UserProfileLink } from 'components/user-panel/user-profile-link/UserProfileLink';

type UsernameRendererProps = {
  data: BaseMember & { organizationInvitationInfo?: OrganizationInvitation };
  params: {
    user: UserProfileDTO | null;
    pendingInviteBadgeEnabled?: boolean;
  };
};

export const UsernameRenderer: React.FC<UsernameRendererProps> = ({ data, params }) => {
  const contextInvitationInfo = data?.invitationInfo
    ? data?.invitationInfo
    : data?.organizationInvitationInfo;

  const isEmailInvitation = contextInvitationInfo?.type === InvitationType.email;

  let content;
  const { user } = params;

  if (isEmailInvitation) {
    content = (
      <Layout.Row spacedChildren="sm">
        <span>{contextInvitationInfo?.invitee}</span>
        {params.pendingInviteBadgeEnabled && <LegacyEmblem>Pending Invite</LegacyEmblem>}
      </Layout.Row>
    );
  } else {
    const { username, avatarUrl } = data.registeredMemberInfo || {};

    const isMeText = username && user && username === user.username ? '(me)' : '';
    const usernameText = `${username} ${isMeText}`;

    content = (
      <Layout.Row spacedChildren="sm">
        <UserProfileLink userName={username}>
          <UserAvatar
            alt={undefined} // TODO: remove
            className={undefined} // TODO: remove
            username={username}
            imageSrc={avatarUrl}
            size="xs"
          />
        </UserProfileLink>
        <UserProfileLink userName={username} component={Link} children={usernameText} />
      </Layout.Row>
    );
  }

  return <WithPaddingRenderer>{content}</WithPaddingRenderer>;
};

import { createRouteNodeSelector } from 'redux-router5';

import { isEnterpriseRouteName } from '@neptune/shared/enterprise-context-util';

import { AppState } from 'state/types';

export function isEnterpriseContext(state: AppState) {
  const { route } = createRouteNodeSelector('')(state);
  return isEnterpriseRouteName(route?.name || '');
}

import React from 'react';

import { InfoTooltip } from '@neptune/shared/common-ui';
import { bemBlock, Dropdown } from '@neptune/shared/venus-ui';

import { AvatarPicker } from 'components/projects/project-form/avatar-picker/AvatarPicker';

import { ProjectAvatar } from './ProjectAvatar';

import './ProjectAvatarWithPicker.less';

const block = bemBlock('project-avatar-with-picker');

type ProjectAvatarWithPickerProps = {
  onAvatarChange: (avatarUrl: string) => void;
} & Omit<React.ComponentProps<typeof ProjectAvatar>, 'avatar'>;

export const ProjectAvatarWithPicker = ({
  projectData,
  loading,
  size,
  iconSize,
  glyph,
  radius,
  onAvatarChange,
  withBackground,
}: ProjectAvatarWithPickerProps) => {
  return (
    <>
      <AvatarPicker
        onAvatarChange={onAvatarChange}
        toggle={
          <Dropdown.Toggle
            data-role="emoji-picker-toggle"
            variant="secondary"
            size="lg"
            className={block('toggle')}
          >
            <InfoTooltip text="Update avatar">
              <ProjectAvatar
                projectData={projectData}
                loading={loading}
                size={size}
                glyph={glyph}
                iconSize={iconSize}
                radius={radius}
                withBackground={withBackground}
              />
            </InfoTooltip>
          </Dropdown.Toggle>
        }
      />
    </>
  );
};

import { UserManagementLevel } from '@neptune/user-management-common-domain';
import { MemberListState } from '@neptune/user-management-domain';

import { fetchStatus as FetchStatus } from 'state/fetch-status';
import { AppState } from 'state/types';

const getMemberListState = (state: any): MemberListState => {
  return state.memberList;
};

export const getOrganizationMembersState = (state: AppState) =>
  getMemberListState(state)[UserManagementLevel.organization];
export const getProjectMembersState = (state: AppState) =>
  getMemberListState(state)[UserManagementLevel.project];

export const getOrganizationMembersCount = (state: AppState) =>
  getOrganizationMembersState(state).entries.length;
export const getProjectMembersCount = (state: AppState) =>
  getProjectMembersState(state).entries.length;

export const getOrganizationMembersFetchStatus = (state: AppState): FetchStatus =>
  getOrganizationMembersState(state).fetchStatus;

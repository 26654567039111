import React, { useState } from 'react';

import { uploadLicense } from '@neptune/license-domain';
import { LicenseErrorPage } from '@neptune/license-ui';
import { HttpErrorPage } from '@neptune/shared/common-ui';

import config from 'config';

export const LicenseErrorPageContainer: React.FC = ({}) => {
  const [newLicense, setNewLicense] = useState('');
  const [reloading, setReloading] = useState(false);
  const [reloadMessage, setReloadMessage] = useState('');

  const applyNewLicese = async () => {
    if (newLicense) {
      try {
        setReloading(true);
        await uploadLicense(newLicense);
        setReloadMessage('License sent to server. This page will reload soon');

        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } catch (error) {
        if (error instanceof Response && error.status === 403) {
          setReloading(false);
          setReloadMessage(
            'You are not authorized to perform this operation. Contact your Neptune administrator to apply the new license',
          );
        } else {
          setReloading(false);
          setReloadMessage('Provided license is not valid. Please verify your license');
        }
      }
    }
  };

  return config.allowAddingNewLicense ? (
    <LicenseErrorPage
      onInputChange={setNewLicense}
      onSubmit={applyNewLicese}
      message={reloadMessage}
      enableSubmit={!!newLicense && !reloading}
      reloading={reloading}
    />
  ) : (
    <HttpErrorPage isNotFoundOrGoneError={false} />
  );
};

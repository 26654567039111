import { type Layout as RGLLayout } from 'react-grid-layout';
import { sortBy } from 'lodash';

export { RGLLayout };

export type LayoutRect = {
  x: number;
  y: number;
  w: number;
  h: number;
};

export type LayoutDimensions = {
  w: number;
  h: number;
};

export type LayoutMinDimensions = {
  minW: number;
  minH: number;
};

export type NewLayoutPlacement =
  | {
      sectionKey: string;
      at: 'top' | 'bottom';
    }
  | {
      sectionKey?: undefined;
      at: 'top' | 'bottom' | 'above first section';
    };

export type SectionLayoutDetails = {
  firstItemUnfoldedY: number;
  contentsRowHeight: number;
  firstItemIndex: number;
  endIndex: number;
  itemCount: number;
  folded: boolean;
};

export type SectionsLayoutMap = Partial<Record<string, SectionLayoutDetails>>;

export const GRID_LAYOUT_COLUMNS = 12;

export function areLayoutRectsEqual(first: LayoutRect, second: LayoutRect): boolean {
  return (
    first.x === second.x && first.y === second.y && first.w === second.w && first.h === second.h
  );
}

export function compareRectsByYX(a: LayoutRect, b: LayoutRect) {
  if (a.y === b.y) {
    return a.x - b.x;
  }

  return a.y - b.y;
}

function areSortedRGLLayoutsListsEqual(l1: RGLLayout[], l2: RGLLayout[]) {
  return l1.every((l, i) => l.i === l2[i].i && areLayoutRectsEqual(l, l2[i]));
}

export function areRGLLayoutSetsEqual(l1: RGLLayout[], l2: RGLLayout[]): boolean {
  return l1.length === l2.length && areSortedRGLLayoutsListsEqual(sortBy(l1, 'i'), sortBy(l2, 'i'));
}

import React from 'react';

import { neptuneUrls } from '@neptune/shared/common-domain';
import { AdminAction, StatusBanner } from '@neptune/shared/status-banner-ui';
import { Link, Text } from '@neptune/shared/venus-ui';

type ProjectAdminStorageErrorBannerProps = {
  onChangeLimit: () => void;
};

export const ProjectAdminStorageErrorBanner: React.FC<ProjectAdminStorageErrorBannerProps> = ({
  onChangeLimit,
}) => {
  return (
    <StatusBanner data-role="admin-project-storage-status-banner" error>
      <Text size="xs">
        This project <Text fontWeight="semibold">has reached its storage limit</Text>. You can
        continue using Neptune in a{' '}
        <Link href={neptuneUrls.workspaceOrProjectReadOnlyDoc} external>
          limited read-only mode
        </Link>
        . Consider increasing the storage limit for this project, or deleting some data.
      </Text>
      <AdminAction caption="Change limit" onAction={onChangeLimit} />
    </StatusBanner>
  );
};

import { fetchWorkspaceStatus } from '@neptune/pricing-workspace-status-domain';

import { createAsyncActions } from 'state/async-actions';

export enum WorkspaceStatusActionTypes {
  request = 'WORKSPACE_STATUS_FETCH_REQUEST',
  success = 'WORKSPACE_STATUS_FETCH_SUCCESS',
  fail = 'WORKSPACE_STATUS_FETCH_FAIL',
}

const actions = createAsyncActions({
  types: WorkspaceStatusActionTypes,
  async resolver({ organizationIdentifier }: { organizationIdentifier: string }) {
    const status = await fetchWorkspaceStatus(organizationIdentifier);
    const date = new Date().toISOString();
    return { status, organizationIdentifier, date };
  },
});

export const { execute: fetchWorkspaceStatusAction, success: workspaceStatusDataAction } = actions;
export type WorkspaceStatusActions = ReturnType<
  typeof actions.request | typeof actions.success | typeof actions.fail
>;

/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NewOrganizationMemberDTO } from './NewOrganizationMemberDTO';
import {
    NewOrganizationMemberDTOFromJSON,
    NewOrganizationMemberDTOFromJSONTyped,
    NewOrganizationMemberDTOToJSON,
} from './NewOrganizationMemberDTO';

/**
 * 
 * @export
 * @interface NewUsersDTO
 */
export interface NewUsersDTO {
    /**
     * 
     * @type {string}
     * @memberof NewUsersDTO
     */
    organizationName: string;
    /**
     * 
     * @type {Array<NewOrganizationMemberDTO>}
     * @memberof NewUsersDTO
     */
    members: Array<NewOrganizationMemberDTO>;
}

/**
 * Check if a given object implements the NewUsersDTO interface.
 */
export function instanceOfNewUsersDTO(value: object): value is NewUsersDTO {
    if (!('organizationName' in value) || value['organizationName'] === undefined) return false;
    if (!('members' in value) || value['members'] === undefined) return false;
    return true;
}

export function NewUsersDTOFromJSON(json: any): NewUsersDTO {
    return NewUsersDTOFromJSONTyped(json, false);
}

export function NewUsersDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewUsersDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'organizationName': json['organizationName'],
        'members': ((json['members'] as Array<any>).map(NewOrganizationMemberDTOFromJSON)),
    };
}

export function NewUsersDTOToJSON(value?: NewUsersDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'organizationName': value['organizationName'],
        'members': ((value['members'] as Array<any>).map(NewOrganizationMemberDTOToJSON)),
    };
}


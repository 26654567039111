// Libs
import React from 'react';
import { useSelector } from 'react-redux';

// App
import { backendClient } from '@neptune/shared/core-apis-backend-domain';

import { fetchStatus } from 'state/fetch-status';
import { isUserAuthenticated } from 'state/users/current-user/selectors';

// Module

type UseApiTokenValue = {
  token?: string;
  error?: any;
  status: fetchStatus;
  refetchToken(): void;
};

export const useApiToken = (deps: any[] = []): UseApiTokenValue => {
  const [apiToken, setApiToken] = React.useState<string | undefined>();
  const [status, setStatus] = React.useState<fetchStatus>(fetchStatus.NONE);
  const [error, setError] = React.useState<unknown>();
  const [lastFetchTimestamp, setLastFetchTimestamp] = React.useState<number | undefined>();
  const isAuthenticated = useSelector(isUserAuthenticated);

  async function fetchToken() {
    setError(undefined);
    setStatus(fetchStatus.PENDING);

    try {
      const response = await backendClient.getApiToken();
      setApiToken(response.token);
      setStatus(fetchStatus.SUCCESS);
    } catch (error) {
      setError(error);
      setStatus(fetchStatus.FAILED);
    }
  }

  React.useEffect(() => {
    if (isAuthenticated) {
      fetchToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, isAuthenticated, lastFetchTimestamp]);

  function refetchToken() {
    setLastFetchTimestamp(Date.now());
  }

  return {
    token: apiToken,
    error,
    refetchToken,
    status,
  };
};

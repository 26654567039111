import React from 'react';
import { debounce } from 'lodash';

import { InputWithValidation } from '@neptune/shared/common-ui';
import { Layout } from '@neptune/shared/venus-ui';

type SelfAttributionProps = {
  data: { selfAttributedChannel?: string };
  onChange: (option: { selfAttributedChannel?: string }) => void;
};

export const SelfAttributionForm: React.FC<SelfAttributionProps> = ({ data, onChange }) => {
  const [error, setError] = React.useState<string>();

  const debouncedSetError = React.useMemo(
    () =>
      debounce((text?: string) => {
        const newError = text && text.length < 2 ? 'Please use at least 2 characters.' : undefined;
        setError(newError);
      }, 200),
    [setError],
  );

  React.useEffect(() => {
    debouncedSetError(data.selfAttributedChannel);
  }, [debouncedSetError, data.selfAttributedChannel]);

  return (
    <Layout.Element component="form" data-role="role-form" span="greedy">
      <InputWithValidation
        label="How did you find out about Neptune?"
        status={error ? 'invalid' : undefined}
        errorMessage={error}
        inputProps={{
          'data-role': 'self-attribution-input',
          value: data.selfAttributedChannel || '',
          placeholder: 'Go to as much detail as you want..',
          rows: 4,
          onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) =>
            onChange && onChange({ selfAttributedChannel: event.target.value }),
        }}
        type="textarea"
      />
    </Layout.Element>
  );
};

// Libs
import { createSelector } from 'reselect';

import { generateComparableDashboard } from 'common/dashboards';
import { naturalStringComparator } from 'common/tools';
import { Dashboard } from 'domain/dashboard';
import { fetchStatus } from 'state/fetch-status';
// App
import { AppState } from 'state/types';

// Module
import getState from '../selectors';

import { DashboardsState } from './types';

function getProjectDashboardsRaw(state: AppState): DashboardsState {
  return getState(state).projectDashboards;
}

export const getProjectDashboardFetchStatus = (state: AppState): fetchStatus =>
  getProjectDashboardsRaw(state).fetchStatus;

export const getProjectDashboards = createSelector([getProjectDashboardsRaw], (dashboardsState) => {
  const dashboards = dashboardsState.dashboards.sort((a, b) =>
    naturalStringComparator(a.name, b.name),
  );

  return {
    ...dashboardsState,
    dashboards,
  };
});

export const getCompareDashboards = createSelector(getProjectDashboards, ({ dashboards }) =>
  dashboards.filter(({ type }) => type === 'compare'),
);

export const getExperimentDashboards = createSelector(getProjectDashboards, ({ dashboards }) =>
  dashboards.filter(({ type }) => type === 'experiment'),
);

export const getComparableExperimentDashboards = createSelector(
  getExperimentDashboards,
  (dashboards) =>
    dashboards.reduce((result: Dashboard[], dashboard) => {
      const comparableDashboard = generateComparableDashboard(dashboard);

      if (comparableDashboard) {
        result.push(comparableDashboard);
      }

      return result;
    }, []),
);

export const getCompareDashboard = createSelector(
  [
    getCompareDashboards,
    getComparableExperimentDashboards,
    (state: AppState, branchId: string) => branchId,
  ],
  (compareDashboards, generatedDashboards, branchId) => {
    return (
      compareDashboards.find(({ versionBranchId }) => versionBranchId === branchId) ||
      generatedDashboards.find(({ versionBranchId }) => versionBranchId === branchId)
    );
  },
);

export const getDefaultCompareDashboard = createSelector(
  [getCompareDashboards, getComparableExperimentDashboards],
  (dashboards, generatedDashboards) => {
    if (dashboards.length > 0) {
      return dashboards[0];
    }

    if (generatedDashboards.length > 0) {
      return generatedDashboards[0];
    }

    return null;
  },
);

export const getExperimentDashboard = createSelector(
  [getExperimentDashboards, (state: AppState, branchId: string) => branchId],
  (dashboards, branchId) => {
    return dashboards.find(({ versionBranchId }) => versionBranchId === branchId);
  },
);

export const getDefaultExperimentDashboard = createSelector(
  getExperimentDashboards,
  (dashboards) => {
    if (dashboards.length === 0) {
      return null;
    }

    return dashboards[0];
  },
);

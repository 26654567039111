// Libs
import React from 'react';
import { Field, FieldProps, Form } from 'formik';

// App
import { ServiceAccountsCreateErrors } from '@neptune/service-accounts-domain';
import {
  Checkbox,
  Icon,
  InfoIcon,
  InputWithSuffix,
  Layout,
  Link,
  Text,
} from '@neptune/shared/venus-ui';

// Module
type CreateServiceAccountFormProps = {
  organizationName: string;
  isValid?: boolean;
  renderFields: React.FC;
  renderSubmit: React.FC<{ isValid?: boolean }>;
  onReactivate(): void;
};

export const CreateServiceAccountForm: React.FC<CreateServiceAccountFormProps> = ({
  isValid,
  renderSubmit,
  renderFields,
  organizationName,
  onReactivate,
}) => {
  const info = (
    <InfoIcon placement="bottom-center" triggerElement={<Icon glyph="question-circle" />}>
      You can grant this service account <Text italic>Contributor</Text> access to all projects
      automatically.
    </InfoIcon>
  );
  const children = (
    <Text size="sm">
      <Layout.Column spacedChildren="md">
        <Layout.Column>
          <Text fontWeight="semibold">Account name</Text>
          <Field name="name">
            {({ field, meta }: FieldProps<string>) => (
              <Layout.Column spacedChildren="sm">
                <InputWithSuffix
                  data-role="create-service-account-name-input"
                  error={!!meta.error}
                  inputProps={field}
                  suffix={
                    <Layout.Element withPadding={['sm', 'md']}>
                      <Text fontWeight="semibold" title={`@${organizationName}`} ellipsis>
                        @{organizationName}
                      </Text>
                    </Layout.Element>
                  }
                />
                {getElementFromError(meta.error as ServiceAccountsCreateErrors)}
              </Layout.Column>
            )}
          </Field>
        </Layout.Column>

        <Field name="addToAllProjects">
          {({ field }: FieldProps<boolean>) => (
            <Checkbox {...field} value={field.value.toString()} checked={field.value}>
              <span data-role="add-to-all-projects-checkbox-label">Add to all projects {info}</span>
            </Checkbox>
          )}
        </Field>
      </Layout.Column>
    </Text>
  );

  return (
    <Form>
      {renderFields({ children })}
      {renderSubmit({ isValid })}
    </Form>
  );

  function getElementFromError(error?: ServiceAccountsCreateErrors): React.ReactNode | undefined {
    if (!error) {
      return;
    }

    const map: Record<ServiceAccountsCreateErrors, React.ReactNode> = {
      [ServiceAccountsCreateErrors.TAKEN]:
        'Looks like this account name is already taken. Please try to pick another one.',
      [ServiceAccountsCreateErrors.DEACTIVATED]: (
        <>
          A service account with the same name was previously deactivated. Do you want to{' '}
          <Link data-role="error-reactivate-link" onClick={onReactivate}>
            reactivate
          </Link>{' '}
          it instead?
        </>
      ),
      [ServiceAccountsCreateErrors.TOO_SHORT]:
        'Account name is too short. Please use at least 3 characters.',
      [ServiceAccountsCreateErrors.TOO_LONG]:
        'Account name is too long. Could you fit it in 50 characters?',
      [ServiceAccountsCreateErrors.REQUIRED]: 'Account name is required',
      [ServiceAccountsCreateErrors.INVALID_CHARACTERS]:
        'Username may only contain lowercase letters, digits, and hyphens. Please try to pick another one.',
      [ServiceAccountsCreateErrors.UNKNOWN]: 'Unknown error',
    };

    return (
      <Text data-role="create-new-service-account-error" color="error">
        {map[error]}
      </Text>
    );
  }
};

import { RouterSimpleState } from '@neptune/shared/routing-domain';

import { createPageIdUrlParam } from 'common/pages';
import { Dashboard } from 'domain/dashboard';

export type DashboardInfo = Pick<Dashboard, 'versionBranchId' | 'id' | 'type'> &
  Partial<Pick<Dashboard, 'name'>>;

export function makeDashboardRoute(
  currentRoute: RouterSimpleState,
  { versionBranchId, id, name, type }: DashboardInfo,
  versionView?: 'versionView',
): RouterSimpleState | undefined {
  const { name: routeName, params } = currentRoute;

  if (type === 'experiment') {
    return {
      name: routeName,
      params: {
        tab: 'details',
        organizationName: params.organizationName,
        projectName: params.projectName,
        runIdentificationKey: params.runIdentificationKey,
        'distraction-free': params['distraction-free'],
        detailsTab: 'dashboard',
        dashboardId: createPageIdUrlParam({
          id: versionBranchId,
          title: name,
        }),
        dashVersionId: versionView && id,
        type: params.type,
      },
    };
  }

  if (type === 'compare') {
    return {
      name: 'project.runs-tab',
      params: {
        ...params,
        tab: 'compare',
        dash: 'dashboard',
        dashboardId: createPageIdUrlParam({
          id: versionBranchId,
          title: name,
        }),
        dashVersionId: versionView && id,
      },
    };
  }
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  type ComplexSearchFilter,
  isEmptyFilter,
  loadFilter,
  persistFilter,
} from '@neptune/recent-searches-domain';
import { useAsyncValue } from '@neptune/shared/common-util';
import {
  createCurrentRouteParamSelector,
  getCurrentRouteName,
  navigateTo,
} from '@neptune/shared/routing-business-logic';
import { SearchMode } from '@neptune/shared/search-domain';

import { getCompoundSearch } from 'common/featureFlag';

const modeSelector = createCurrentRouteParamSelector('searchMode');

export function useCompoundSearchFilters(namespace: string): {
  searchFilter: ComplexSearchFilter | undefined;
  changeFilter: (complexSearchFilter?: ComplexSearchFilter) => Promise<void>;
  mode: SearchMode;
  setMode: (newMode: SearchMode) => void;
  isLoading: boolean;
} {
  const dispatch = useDispatch();
  const compoundNamespace = `${namespace}-compound`;
  const routeName = useSelector(getCurrentRouteName);
  const textFilter: string | undefined = useSelector(createCurrentRouteParamSelector(namespace));
  const compoundSearchFilterKey: string | undefined = useSelector(
    createCurrentRouteParamSelector(compoundNamespace),
  );

  const resolver = React.useCallback(
    async () => loadFilter(compoundSearchFilterKey),
    [compoundSearchFilterKey],
  );
  const { value: compoundSearchFilter, loading } = useAsyncValue({ resolver });

  const defaultMode = getCompoundSearch() ? SearchMode.REGEX : SearchMode.SUBSTRING;

  const mode: SearchMode = useSelector(modeSelector) ?? defaultMode;

  const setMode = React.useCallback(
    (newMode: SearchMode) => {
      dispatch(
        navigateTo(
          routeName,
          {
            searchMode: newMode,
            [namespace]: undefined,
            [compoundNamespace]: undefined,
          },
          { extendParams: true, replace: true },
        ),
      );
    },
    [dispatch, routeName, namespace, compoundNamespace],
  );
  const searchFilter: ComplexSearchFilter | undefined = React.useMemo(() => {
    if (mode === SearchMode.REGEX) {
      return compoundSearchFilter && compoundSearchFilter.length > 0
        ? {
            searchType: SearchMode.REGEX,
            value: compoundSearchFilter,
          }
        : undefined;
    }

    return textFilter?.length && textFilter.length > 0
      ? {
          searchType: SearchMode.SUBSTRING,
          value: textFilter,
        }
      : undefined;
  }, [textFilter, compoundSearchFilter, mode]);

  const changeFilter = React.useCallback(
    async (newFilter?: ComplexSearchFilter): Promise<void> => {
      let newState = {};

      if (!newFilter) {
        newState = {
          [namespace]: undefined,
          [compoundNamespace]: undefined,
        };
      }

      if (newFilter?.searchType === SearchMode.REGEX) {
        const key = !isEmptyFilter(newFilter) ? await persistFilter(newFilter.value) : undefined;
        newState = {
          [namespace]: undefined,
          [compoundNamespace]: key,
        };
      }

      if (newFilter?.searchType === SearchMode.SUBSTRING) {
        newState = {
          [namespace]: newFilter.value,
          [compoundNamespace]: undefined,
        };
      }

      dispatch(navigateTo(routeName, newState, { extendParams: true, replace: true }));
    },
    [dispatch, routeName, namespace, compoundNamespace],
  );

  return { mode, setMode, searchFilter, changeFilter, isLoading: loading };
}

import React from 'react';

import type { PropertyRow } from '@neptune/shared/common-ui';
import { getReadableFileSize } from '@neptune/shared/common-util';

import { MonitoringLabel } from 'components/monitoring-pricing-section/MonitoringLabel';
import { ProjectMonitoringValue } from 'components/monitoring-pricing-section/ProjectMonitoringValue';
import { isFetchStatusCompleted } from 'state/fetch-status';

import { ProjectUsageWidget } from './ProjectUsageWidget';
import { useProjectUsage } from './useProjectUsage';

type ProjectUsageWidgetContainerProps = {
  showMonitoring?: boolean;
  projectIdentifier: string;
};

export const ProjectUsageWidgetContainer: React.FC<ProjectUsageWidgetContainerProps> = ({
  projectIdentifier,
  showMonitoring,
}) => {
  const { data: usageData, fetchStatus: usageFetchStatus } = useProjectUsage(projectIdentifier);

  const isLoading = !isFetchStatusCompleted(usageFetchStatus);

  const properties: PropertyRow[] = [];

  if (usageData?.experimentCount != null) {
    properties.push(['Runs', usageData.experimentCount]);
  }

  if (usageData?.storageBytes != null) {
    properties.push(['Storage', getReadableFileSize(usageData.storageBytes)]);
  }

  if (showMonitoring && usageData?.totalMinutes != null && usageData?.last30DaysMinutes != null) {
    properties.push([
      <MonitoringLabel />,
      <ProjectMonitoringValue
        usedMinutes={usageData.last30DaysMinutes}
        totalMinutes={usageData.totalMinutes}
      />,
    ]);
  }

  return <ProjectUsageWidget isLoading={isLoading} properties={properties} />;
};

import { useSelector } from 'react-redux';

import { AppState } from 'state/types';

import {
  selectIsReadonlyDueToMonitoring,
  selectIsReadonlyDueToPayment,
  selectIsReadonlyDueToStorage,
  selectIsReadonlyDueToTrial,
  selectShowAdminMonitoringBanner,
  selectShowAdminPaymentBanner,
  selectShowAdminStorageBanner,
  selectShowAdminTrialBanner,
} from './selectors';

export function useAdminBannersVisibility(
  organizationName: string,
  hasCreditCardAttached: boolean | undefined,
) {
  const showTrialBanner = useSelector((state: AppState) =>
    selectShowAdminTrialBanner(state, organizationName),
  );
  const showPaymentBanner = useSelector((state: AppState) =>
    selectShowAdminPaymentBanner(state, organizationName),
  );
  const showMonitoringBanner = useSelector((state: AppState) =>
    selectShowAdminMonitoringBanner(state, organizationName),
  );
  const showStorageBanner = useSelector((state: AppState) =>
    selectShowAdminStorageBanner(state, organizationName),
  );

  const isReadonlyDueToTrial = useSelector((state: AppState) =>
    selectIsReadonlyDueToTrial(state, organizationName),
  );
  const isReadonlyDueToPayment = useSelector((state: AppState) =>
    selectIsReadonlyDueToPayment(state, organizationName),
  );
  const isReadonlyDueToMonitoring = useSelector((state: AppState) =>
    selectIsReadonlyDueToMonitoring(state, organizationName),
  );
  const isReadonlyDueToStorage = useSelector((state: AppState) =>
    selectIsReadonlyDueToStorage(state, organizationName),
  );

  return {
    showAdminTrialErrorBanner: isReadonlyDueToTrial && showTrialBanner,
    showAdminCardErrorBanner:
      hasCreditCardAttached === false && isReadonlyDueToPayment && showPaymentBanner,
    showAdminPaymentErrorBanner:
      hasCreditCardAttached && isReadonlyDueToPayment && showPaymentBanner,
    showAdminMonitoringErrorBanner: isReadonlyDueToMonitoring && showMonitoringBanner,
    showAdminStorageErrorBanner: isReadonlyDueToStorage && showStorageBanner,
    showAdminTrialWarnBanner: !isReadonlyDueToTrial && showTrialBanner,
    showAdminCardWarnBanner:
      hasCreditCardAttached === false && !isReadonlyDueToPayment && showPaymentBanner,
    showAdminPaymentWarnBanner:
      hasCreditCardAttached && !isReadonlyDueToPayment && showPaymentBanner,
    showAdminMonitoringWarnBanner: !isReadonlyDueToMonitoring && showMonitoringBanner,
    showAdminStorageWarnBanner: !isReadonlyDueToStorage && showStorageBanner,
  };
}

import { UserManagementLevel } from '@neptune/user-management-common-domain';

import { Invitation, OrganizationInvitation, ProjectInvitation } from './invitation-model';

const targetName: Record<UserManagementLevel, string> = {
  [UserManagementLevel.organization]: 'workspace',
  [UserManagementLevel.project]: 'project',
};

export const isOrganizationInvitation = (
  invitation: Invitation,
): invitation is OrganizationInvitation => invitation.level === UserManagementLevel.organization;
export const isOrganizationInvitationType = (
  invitationType: UserManagementLevel,
): invitationType is UserManagementLevel.organization =>
  invitationType === UserManagementLevel.organization;
export const isProjectInvitation = (invitation: Invitation): invitation is ProjectInvitation =>
  invitation.level === UserManagementLevel.project;
export const isProjectInvitationType = (
  invitationType: UserManagementLevel,
): invitationType is UserManagementLevel.project => invitationType === UserManagementLevel.project;
export const getInvitationTarget = (invitation: Invitation): string => targetName[invitation.level];

export const getInvitationEntityName = (invitation: Invitation): string => {
  const entity = isOrganizationInvitation(invitation)
    ? invitation.organizationName
    : `${invitation.organizationName}/${invitation.projectName}`;
  return `${invitation.level} ${entity}`;
};

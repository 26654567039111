/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectVisibilityDTO } from './ProjectVisibilityDTO';
import {
    ProjectVisibilityDTOFromJSON,
    ProjectVisibilityDTOFromJSONTyped,
    ProjectVisibilityDTOToJSON,
} from './ProjectVisibilityDTO';

/**
 * 
 * @export
 * @interface ProjectVisibilityRestrictedTraitDTO
 */
export interface ProjectVisibilityRestrictedTraitDTO {
    /**
     * 
     * @type {Array<ProjectVisibilityDTO>}
     * @memberof ProjectVisibilityRestrictedTraitDTO
     */
    limitedTo: Array<ProjectVisibilityDTO>;
    /**
     * 
     * @type {Array<ProjectVisibilityDTO>}
     * @memberof ProjectVisibilityRestrictedTraitDTO
     */
    disabledByAdmin: Array<ProjectVisibilityDTO>;
    /**
     * 
     * @type {Array<ProjectVisibilityDTO>}
     * @memberof ProjectVisibilityRestrictedTraitDTO
     */
    limitedToByPlan: Array<ProjectVisibilityDTO>;
}

/**
 * Check if a given object implements the ProjectVisibilityRestrictedTraitDTO interface.
 */
export function instanceOfProjectVisibilityRestrictedTraitDTO(value: object): value is ProjectVisibilityRestrictedTraitDTO {
    if (!('limitedTo' in value) || value['limitedTo'] === undefined) return false;
    if (!('disabledByAdmin' in value) || value['disabledByAdmin'] === undefined) return false;
    if (!('limitedToByPlan' in value) || value['limitedToByPlan'] === undefined) return false;
    return true;
}

export function ProjectVisibilityRestrictedTraitDTOFromJSON(json: any): ProjectVisibilityRestrictedTraitDTO {
    return ProjectVisibilityRestrictedTraitDTOFromJSONTyped(json, false);
}

export function ProjectVisibilityRestrictedTraitDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectVisibilityRestrictedTraitDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'limitedTo': ((json['limitedTo'] as Array<any>).map(ProjectVisibilityDTOFromJSON)),
        'disabledByAdmin': ((json['disabledByAdmin'] as Array<any>).map(ProjectVisibilityDTOFromJSON)),
        'limitedToByPlan': ((json['limitedToByPlan'] as Array<any>).map(ProjectVisibilityDTOFromJSON)),
    };
}

export function ProjectVisibilityRestrictedTraitDTOToJSON(value?: ProjectVisibilityRestrictedTraitDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'limitedTo': ((value['limitedTo'] as Array<any>).map(ProjectVisibilityDTOToJSON)),
        'disabledByAdmin': ((value['disabledByAdmin'] as Array<any>).map(ProjectVisibilityDTOToJSON)),
        'limitedToByPlan': ((value['limitedToByPlan'] as Array<any>).map(ProjectVisibilityDTOToJSON)),
    };
}


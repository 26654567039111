import React from 'react';

import {
  ColumnIdentifier,
  GroupByContext,
  GroupViewMode,
} from '@neptune/shared/leaderboard-domain';

import { AttributeDefinition, attributeDefinitionComparator } from 'domain/experiment/attribute';

type HookResult = {
  attributesInUse: AttributeDefinition[];
  addToGroupBy: (...selected: AttributeDefinition[]) => void;
  resetGroupBy: () => void;
  isAttributeUsed: (attr: AttributeDefinition) => boolean;
  reorderGroupBy: (attributes: AttributeDefinition[]) => void;
  viewMode: GroupViewMode;
  removeFromGroupBy: (columnIdentifier: ColumnIdentifier) => void;
  setIsGroupByEdited: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useLeaderboardGroupByStorage = (): HookResult => {
  const {
    attributesInUse,
    addToGroupBy,
    resetGroupBy,
    reorderGroupBy,
    viewMode,
    removeFromGroupBy,
    setIsGroupByEdited,
  } = React.useContext(GroupByContext);

  const isAttributeUsed = React.useCallback(
    (attr: AttributeDefinition) => {
      const found = attributesInUse.find((attributeInUse) =>
        attributeDefinitionComparator(attributeInUse, attr),
      );
      return found !== undefined;
    },
    [attributesInUse],
  );

  return {
    attributesInUse,
    isAttributeUsed,
    addToGroupBy,
    resetGroupBy,
    reorderGroupBy,
    viewMode,
    removeFromGroupBy,
    setIsGroupByEdited,
  };
};

import React from 'react';

import { ServiceAccountReactivateData } from '@neptune/service-accounts-domain';
import { Dialog, Layout, Text } from '@neptune/shared/venus-ui';

type ReactivateServiceAccountModalProps = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(): void;
  serviceAccount: ServiceAccountReactivateData;
};

export const ReactivateServiceAccountModal: React.FC<ReactivateServiceAccountModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  serviceAccount,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onPrimaryButtonClick={onSubmit}
      onSecondaryButtonClick={onClose}
      primaryButtonLabel="Reactivate account"
      primaryButtonRole="reactivate-service-account-submit"
      role="reactivate-service-account-modal"
      title="Reactivate service account"
    >
      <Layout.Column spacedChildren="md">
        <Text>
          You are about to reactivate{' '}
          <Text fontWeight="semibold">{serviceAccount.displayName}</Text> service account.
          <br />
          Note that the previous API token for this account is no longer valid.
        </Text>
        <Text>Are you sure?</Text>
      </Layout.Column>
    </Dialog>
  );
};

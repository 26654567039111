import {
  GRID_LAYOUT_COLUMNS,
  LayoutDimensions,
  LayoutRect,
  NewLayoutPlacement,
} from '@neptune/shared/grid-layout-domain';

import { compactGridLayouts } from './compact-grid-layouts';

export function insertLayoutsForNewItems<
  ItemLayoutT extends LayoutRect,
  ItemRequestT extends LayoutDimensions,
>({
  layouts,
  placement,
  itemRequests,
  isSection,
  getSectionKey,
  createItemLayout,
}: {
  layouts: ItemLayoutT[];
  placement: NewLayoutPlacement;
  itemRequests: ItemRequestT[];
  isSection: (layout: ItemLayoutT) => boolean;
  getSectionKey: (layout: ItemLayoutT) => string;
  createItemLayout: (itemRequest: ItemRequestT, rect: LayoutRect) => ItemLayoutT;
}): ItemLayoutT[] {
  if (!placement.sectionKey && placement.at !== 'above first section') {
    return addLayoutsForNewItems(layouts, placement.at, itemRequests, createItemLayout);
  }

  const compactedLayouts = layouts.map((layout) => ({ ...layout }));

  compactGridLayouts(compactedLayouts);

  // sectionKey may be undefined, in that case we will get -1 and it's fine, see below.
  const sectionIdx = compactedLayouts.findIndex(
    (layout) => isSection(layout) && getSectionKey(layout) === placement.sectionKey,
  );

  const effectiveAt = placement.at === 'above first section' ? 'bottom' : placement.at;

  // In case of 'above first section' section wouldn't be found, so
  // sectionContentsStartIdx will be correctly set to 0, and hence
  // nextSectionIdx will be set correctly too, and so the whole
  // layout slicing logic works the same for both cases.
  const sectionContentsStartIdx = sectionIdx + 1;
  const nextSectionIdx = compactedLayouts.findIndex(
    (layout, idx) => idx >= sectionContentsStartIdx && isSection(layout),
  );
  const sectionEnd = nextSectionIdx !== -1 ? nextSectionIdx : compactedLayouts.length;

  const layoutsAboveSectionContents = compactedLayouts.slice(0, sectionContentsStartIdx);
  const sectionContents = compactedLayouts.slice(sectionContentsStartIdx, sectionEnd);
  const layoutsBelowSectionContents = compactedLayouts.slice(sectionEnd);

  const newSectionContents = addLayoutsForNewItems(
    sectionContents,
    effectiveAt,
    itemRequests,
    createItemLayout,
  );

  const newLayouts = stackLayouts(
    stackLayouts(layoutsAboveSectionContents, newSectionContents),
    layoutsBelowSectionContents,
  );

  compactGridLayouts(newLayouts);

  return newLayouts;
}

export function addLayoutsForNewItems<
  ItemLayoutT extends LayoutRect,
  ItemRequestT extends LayoutDimensions,
>(
  layouts: ItemLayoutT[],
  at: 'top' | 'bottom',
  itemRequests: ItemRequestT[],
  createItemLayout: (itemRequest: ItemRequestT, rect: LayoutRect) => ItemLayoutT,
): ItemLayoutT[] {
  const newLayouts =
    tryToFitNewLayoutsInOuterLine(layouts, at, itemRequests, createItemLayout) ??
    createFoldingRowOfLayouts(itemRequests, createItemLayout);
  const stacked =
    at === 'top' ? stackLayouts(newLayouts, layouts) : stackLayouts(layouts, newLayouts);

  compactGridLayouts(stacked);

  return stacked;
}

export function createFoldingRowOfLayouts<
  ItemLayoutT extends LayoutRect,
  ItemRequestT extends LayoutDimensions,
>(
  requestedLayouts: ItemRequestT[],
  createItemLayout: (itemRequest: ItemRequestT, rect: LayoutRect) => ItemLayoutT,
): ItemLayoutT[] {
  // We will be inserting widgets one by one, trying to fit as many in a row as will fit.
  // When the space in line ends, we will start the next line at such height that all subsequent
  // layouts will be below previous one. It may leave a vertical gap, but we will fix it
  // with compaction at the end.
  let currentInsertX = 0;
  let currentInsertY = 0;
  let nextLineInsertY = 0;

  const layouts: ItemLayoutT[] = [];

  for (const request of requestedLayouts) {
    const { w, h } = request;

    if (currentInsertX + w > GRID_LAYOUT_COLUMNS) {
      // Start next line.
      currentInsertX = 0;
      currentInsertY = nextLineInsertY;
    }

    layouts.push(
      createItemLayout(request, {
        x: currentInsertX,
        y: currentInsertY,
        w,
        h,
      }),
    );

    currentInsertX += w;
    nextLineInsertY = Math.max(nextLineInsertY, currentInsertY + h);
  }

  compactGridLayouts(layouts);

  return layouts;
}

function stackLayouts<ItemLayoutT extends LayoutRect>(
  topLayouts: ItemLayoutT[],
  bottomLayouts: ItemLayoutT[],
): ItemLayoutT[] {
  const topLayoutsTotalHeight = Math.max(0, ...topLayouts.map(({ y, h }) => y + h));

  const layouts = [
    ...topLayouts.map((layout) => ({ ...layout })),
    ...shiftLayoutsY(bottomLayouts, topLayoutsTotalHeight),
  ];

  compactGridLayouts(layouts);

  return layouts;
}

function shiftLayoutsY<ItemLayoutT extends LayoutRect>(
  layouts: ItemLayoutT[],
  yOffset: number,
): ItemLayoutT[] {
  return layouts.map((layout) => ({ ...layout, y: layout.y + yOffset }));
}

function tryToFitNewLayoutsInOuterLine<
  ItemLayoutT extends LayoutRect,
  ItemRequestT extends LayoutDimensions,
>(
  layouts: ItemLayoutT[],
  at: 'top' | 'bottom',
  requestedLayouts: ItemRequestT[],
  createItemLayout: (itemRequest: ItemRequestT, rect: LayoutRect) => ItemLayoutT,
): ItemLayoutT[] | undefined {
  return (at === 'top' ? tryToFitNewLayoutsInFirstLine : tryToFitNewLayoutsInLastLine)(
    layouts,
    requestedLayouts,
    createItemLayout,
  );
}

function tryToFitNewLayoutsInFirstLine<
  ItemLayoutT extends LayoutRect,
  ItemRequestT extends LayoutDimensions,
>(
  layouts: ItemLayoutT[],
  requestedLayouts: ItemRequestT[],
  createItemLayout: (itemRequest: ItemRequestT, rect: LayoutRect) => ItemLayoutT,
): ItemLayoutT[] | undefined {
  const minYsOfCurrentLayouts = new Array(GRID_LAYOUT_COLUMNS).fill(Infinity);

  for (const layout of layouts) {
    const xEnd = layout.x + layout.w;

    for (let i = layout.x; i < xEnd; ++i) {
      minYsOfCurrentLayouts[i] = Math.min(minYsOfCurrentLayouts[i], layout.y);
    }
  }

  const minY = Math.min(...minYsOfCurrentLayouts);

  // Insert items only to the right of the last item touching the top line.
  let insertX = minYsOfCurrentLayouts.lastIndexOf(minY) + 1;

  const newLayouts: ItemLayoutT[] = [];

  for (const request of requestedLayouts) {
    const { w, h } = request;

    function canFitVerticallyAtCurrentInsertX() {
      const xEnd = insertX + w;

      for (let i = insertX; i < xEnd; ++i) {
        if (minYsOfCurrentLayouts[i] < h) {
          return false;
        }
      }

      return true;
    }

    const maxX = GRID_LAYOUT_COLUMNS - w;

    while (true) {
      if (insertX > maxX) {
        // Can't fit widget in top line.
        return undefined;
      }

      if (canFitVerticallyAtCurrentInsertX()) {
        newLayouts.push(
          createItemLayout(request, {
            x: insertX,
            y: 0,
            w,
            h,
          }),
        );
        insertX += w;
        break;
      } else {
        ++insertX;
      }
    }
  }

  return newLayouts;
}

function tryToFitNewLayoutsInLastLine<
  ItemLayoutT extends LayoutRect,
  ItemRequestT extends LayoutDimensions,
>(
  layouts: ItemLayoutT[],
  requestedLayouts: ItemRequestT[],
  createItemLayout: (itemRequest: ItemRequestT, rect: LayoutRect) => ItemLayoutT,
): ItemLayoutT[] | undefined {
  const maxYsOfCurrentLayouts = new Array(GRID_LAYOUT_COLUMNS).fill(0);

  for (const layout of layouts) {
    const xEnd = layout.x + layout.w;

    for (let i = layout.x; i < xEnd; ++i) {
      maxYsOfCurrentLayouts[i] = Math.max(maxYsOfCurrentLayouts[i], layout.y + layout.h);
    }
  }

  const maxY = Math.max(...maxYsOfCurrentLayouts);

  // Insert items only to the right of the last item touching the bottom line.
  let insertX = maxYsOfCurrentLayouts.lastIndexOf(maxY) + 1;

  const newLayouts: ItemLayoutT[] = [];

  for (const request of requestedLayouts) {
    const { w, h } = request;

    function canFitVerticallyAtCurrentInsertX() {
      const xEnd = insertX + w;

      for (let i = insertX; i < xEnd; ++i) {
        if (maxYsOfCurrentLayouts[i] + h > maxY) {
          return false;
        }
      }

      return true;
    }

    const maxX = GRID_LAYOUT_COLUMNS - w;

    while (true) {
      if (insertX > maxX) {
        // Can't fit widget in bottom line.
        return undefined;
      }

      if (canFitVerticallyAtCurrentInsertX()) {
        newLayouts.push(
          createItemLayout(request, {
            x: insertX,
            y: maxY - h, // not compacted - may leave a gap above.
            w,
            h,
          }),
        );
        insertX += w;
        break;
      } else {
        ++insertX;
      }
    }
  }

  return newLayouts;
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import useKeyboardShortcut from 'use-keyboard-shortcut';

import { useFullscreen } from '@neptune/shared/common-business-logic';

import { navigateTo } from './actions';
import {
  createCurrentRouteParamSelector,
  getCurrentRouteName,
  getCurrentRouteParams,
} from './selectors';

const distractionFreeSelector = createCurrentRouteParamSelector('distraction-free');
export const getDistractionFree = createSelector(distractionFreeSelector, (value) => !!value);

export const getCurrentRouteParamsWithoutDistractionFree = createSelector(
  getCurrentRouteParams,
  ({ 'distraction-free': valueToDiscard, ...rest }) => rest,
);

const SPACE = ' ';

export const useDistractionFree = () => {
  const isDistractionFree = useSelector(getDistractionFree);
  const dispatch = useDispatch();
  const routeName = useSelector(getCurrentRouteName);
  const routeParams = useSelector(getCurrentRouteParams);
  const routeParamsWithoutDistractionFree = useSelector(
    getCurrentRouteParamsWithoutDistractionFree,
  );
  const { fullscreen, maximize, restore } = useFullscreen();

  React.useEffect(() => {
    if (isDistractionFree && !fullscreen) {
      maximize();
    }

    if (!isDistractionFree && fullscreen) {
      restore();
    }
  }, [fullscreen, isDistractionFree, maximize, restore]);

  const routeParamsWithDistractionFree = { ...routeParams, 'distraction-free': '1' };

  useKeyboardShortcut(
    [SPACE],
    () => {
      if (isDistractionFree) {
        dispatch(navigateTo(routeName, routeParamsWithoutDistractionFree));
        restore();
      } else {
        dispatch(navigateTo(routeName, routeParamsWithDistractionFree));
        maximize();
      }
    },
    { repeatOnHold: false },
  );

  return {
    isDistractionFree,
    routeParams: isDistractionFree
      ? routeParamsWithoutDistractionFree
      : routeParamsWithDistractionFree,
    routeName,
  };
};

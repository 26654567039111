/* tslint:disable */
/* eslint-disable */
/**
 * scissors
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExpandResponse,
  SnipRequest,
  SnipResponse,
} from '../models/index';
import {
    ExpandResponseFromJSON,
    ExpandResponseToJSON,
    SnipRequestFromJSON,
    SnipRequestToJSON,
    SnipResponseFromJSON,
    SnipResponseToJSON,
} from '../models/index';

export interface ExpandRequest {
    key: string;
}

export interface SnipOperationRequest {
    snipRequest: SnipRequest;
}

/**
 * 
 */
export class ScissorsApi extends runtime.BaseAPI {

    /**
     */
    async expandRaw(requestParameters: ExpandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExpandResponse>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling expand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/scissors/v1/expand/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExpandResponseFromJSON(jsonValue));
    }

    /**
     */
    async expand(requestParameters: ExpandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExpandResponse> {
        const response = await this.expandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async snipRaw(requestParameters: SnipOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SnipResponse>> {
        if (requestParameters['snipRequest'] == null) {
            throw new runtime.RequiredError(
                'snipRequest',
                'Required parameter "snipRequest" was null or undefined when calling snip().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/scissors/v1/snip`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SnipRequestToJSON(requestParameters['snipRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SnipResponseFromJSON(jsonValue));
    }

    /**
     */
    async snip(requestParameters: SnipOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SnipResponse> {
        const response = await this.snipRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

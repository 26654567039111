import { InvitationModelConverter, OrganizationInvitation } from '@neptune/invitations-domain';
import { backendClient, OrganizationMemberDTO } from '@neptune/shared/core-apis-backend-domain';
import {
  OrganizationRole,
  organizationRoleFromApiToDomain,
  organizationRoleFromDomainToApi,
} from '@neptune/shared/core-organizations-domain';

import {
  RegisteredMemberInfo,
  registeredMemberInfoFromApiToDomain,
} from './registered-member-info';

export interface OrganizationMember {
  role: OrganizationRole;
  joinedAt?: Date;
  editableRole: boolean;
  registeredMemberInfo?: RegisteredMemberInfo;
  invitationInfo?: OrganizationInvitation;
  totalNumberOfProjects?: number;
  numberOfProjects?: number;
}

export function organizationMemberFromApiToDomain(
  source: OrganizationMemberDTO,
): OrganizationMember {
  return {
    role: organizationRoleFromApiToDomain(source.role),
    joinedAt: source.joinedAt,
    editableRole: source.editableRole,
    registeredMemberInfo:
      source.registeredMemberInfo &&
      registeredMemberInfoFromApiToDomain(source.registeredMemberInfo),
    invitationInfo:
      source.invitationInfo &&
      InvitationModelConverter.organizationInvitationFromApiToDomain(source.invitationInfo),
    totalNumberOfProjects: source.totalNumberOfProjects,
    numberOfProjects: source.numberOfProjects,
  };
}

export async function fetchOrganizationMembers({
  organizationIdentifier,
}: {
  organizationIdentifier: string;
}): Promise<OrganizationMember[]> {
  const result = await backendClient.listOrganizationMembers({
    organizationIdentifier,
  });
  return result.map(organizationMemberFromApiToDomain);
}

type ModifyOrganizationMemberProps = {
  organizationIdentifier: string;
  userId: string;
  member: {
    role: OrganizationRole;
  };
};

export async function modifyOrganizationMember({
  organizationIdentifier,
  userId,
  member,
}: ModifyOrganizationMemberProps): Promise<OrganizationMember> {
  const result = await backendClient.updateOrganizationMember({
    organizationIdentifier,
    userId,
    member: { role: organizationRoleFromDomainToApi(member.role) },
  });
  return organizationMemberFromApiToDomain(result);
}

export async function removeOrganizationMember({
  organizationIdentifier,
  userId,
}: {
  organizationIdentifier: string;
  userId: string;
}): Promise<void> {
  await backendClient.deleteOrganizationMember({
    organizationIdentifier,
    userId,
  });
}

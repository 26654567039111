// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { isNumber } from 'lodash';

import { getWorkspaceUsersPricing } from '@neptune/pricing-commons-api';
// App
import { formatMoney } from '@neptune/shared/common-util';
import { Banner, Text } from '@neptune/shared/venus-ui';

import { getContextOrganization } from 'state/selectors-global';

// Module
type PricePerUserNoticeContainerProps = {
  membersLimit?: number;
};

const PricePerUserNoticeContainer: React.FC<PricePerUserNoticeContainerProps> = ({
  membersLimit,
}) => {
  const organization = useSelector(getContextOrganization);
  const pricingPlan = organization?.pricingPlan;
  const usersPricing = useSelector(getWorkspaceUsersPricing);

  if (pricingPlan !== 'v1_team' || !usersPricing || !organization) {
    return null;
  }

  const price = formatMoney(usersPricing.userPriceInCents / 100);

  return (
    <Banner.Info>
      <span>
        In your <Text fontWeight="semibold">{organization.pricingPlanDisplayName}</Text> plan, you
        can invite {isNumber(membersLimit) ? `up to ${membersLimit}` : 'unlimited number of'} users.
        You will be charged {price}/monthly per each user once they accept invitation.
      </span>
    </Banner.Info>
  );
};

export default PricePerUserNoticeContainer;

// Libs
import React from 'react';

import { Checkbox, Dropdown, Layout } from '@neptune/shared/venus-ui';

// App
import { PickRequired } from 'common/utility-types';

import { FilterSearch } from './FilterSearch';
import { FilterSearchItemProps } from './FilterSearchItem';
import { FilterSearchProps } from './interfaces';

type BaseMultiselectFilterSearchProps<T> = FilterSearchProps<T> &
  PickRequired<FilterSearchProps<T>, 'RenderItem'>;

export type MultiselectFilterSearchProps<T> =
  | (BaseMultiselectFilterSearchProps<T> & {
      isSelected(value: T): boolean;
      multi: true;
    })
  | (BaseMultiselectFilterSearchProps<T> & {
      multi?: false;
    });

export const MultiselectFilterSearch = <T,>(props: MultiselectFilterSearchProps<T>) => {
  const WrappedItem = (innerProps: FilterSearchItemProps<T>) =>
    props.multi
      ? ItemWithCheckbox({
          ...innerProps,
          RenderItem: props.RenderItem,
          selected: props.isSelected(innerProps.value),
        })
      : Item({
          ...innerProps,
          RenderItem: props.RenderItem,
        });

  const { RenderItem, ...restProps } = props;
  return <FilterSearch RenderItem={WrappedItem} {...restProps} />;
};

type ItemProps<T> = FilterSearchItemProps<T> & {
  RenderItem: React.ComponentType<FilterSearchItemProps<T>>;
};

const Item = <T,>({ active, match, onClick, RenderItem, value }: ItemProps<T>) => (
  <Dropdown.Item active={active} withGutter="sm" onClick={onClick}>
    <RenderItem active={active} match={match} onClick={onClick} value={value} />
  </Dropdown.Item>
);

type ItemWithCheckboxProps<T> = ItemProps<T> & {
  selected: boolean;
};

const ItemWithCheckbox = <T,>({
  active,
  match,
  onClick,
  selected,
  RenderItem,
  value,
}: ItemWithCheckboxProps<T>) => (
  <Dropdown.Item active={active} withGutter="sm" onClick={onClick}>
    <Layout.Row spacedChildren="sm" alignItems="center">
      <Checkbox checked={selected} />
      <RenderItem active={active} match={match} onClick={onClick} value={value} />
    </Layout.Row>
  </Dropdown.Item>
);

// Libs
import React from 'react';

import { OrganizationType, ProjectPrivacy } from '@neptune/shared/core-organizations-domain';
import { NormalizedLink } from '@neptune/shared/routing-ui';
import { bemBlock, Button, Icon, Layout, Modal, Text } from '@neptune/shared/venus-ui';

// App
import { ShareInput } from 'components/share-input/ShareInput';

// Module
import './ShareModal.less';

const block = bemBlock('share-modal');

export type ShareModalProps = {
  link: string;
  isOnPremiseDeployment: boolean;
  organizationName?: string;
  organizationType?: OrganizationType;
  projectPrivacy?: ProjectPrivacy;
  isOpen: boolean;
  onClose(): void;
  onSendMail(): void;
  onSendTwitter(): void;
};

const privacyNameCopy: Record<ProjectPrivacy, string> = {
  [ProjectPrivacy.private]: 'private project',
  [ProjectPrivacy.workspace]: 'workspace project',
  [ProjectPrivacy.public]: 'public project',
  [ProjectPrivacy.notSupported]: 'project',
};

const defaultDescriptionCopy: Record<ProjectPrivacy, string> = {
  [ProjectPrivacy.private]: '- your teammates who have access to this project can enter this page.',
  [ProjectPrivacy.workspace]:
    '- your teammates who have access to this workspace can enter this page.',
  [ProjectPrivacy.public]: '- anyone with this link can view this page.',
  [ProjectPrivacy.notSupported]: '',
};

export const ShareModal: React.FC<ShareModalProps> = ({
  link,
  isOnPremiseDeployment,
  projectPrivacy,
  organizationName,
  organizationType,
  isOpen,
  onClose,
  onSendMail,
  onSendTwitter,
}) => {
  const projectPrivacyCopy: string = privacyNameCopy[projectPrivacy || ProjectPrivacy.private];
  const descriptionCopy: string = getPrivacyDescriptionCopy(organizationType, projectPrivacy);

  const description = (
    <Text align="center">
      This is a <Text fontWeight="semibold">{projectPrivacyCopy}</Text> {descriptionCopy}
    </Text>
  );

  const action =
    organizationType === 'individual' && projectPrivacy !== ProjectPrivacy.public ? (
      <Layout.Column spacedChildren="sm" alignItems="center">
        <Text align="center">
          If you want to share Neptune projects with others, you need to create a{' '}
          <Text fontWeight="semibold">team workspace</Text>.
        </Text>
        <Button<React.ComponentProps<typeof NormalizedLink>>
          className={block('trial')}
          component={NormalizedLink}
          variant="secondary"
          size="lg"
          routeName="organization.subscription"
          routeParams={{ organizationName }}
        >
          Try team workspace for 14 days for free
        </Button>
      </Layout.Column>
    ) : (
      <Layout.Column spacedChildren="sm" alignItems="center">
        <Text align="center">To share it with your teammates, just copy this link:</Text>
        <ShareInput link={link} />
        <Text align="center">or send directly via</Text>
        <Layout.Row spacedChildren="md">
          <Button onClick={onSendMail} size="lg" variant="secondary">
            <Icon glyph="envelope" />
            &nbsp; Email
          </Button>
          {projectPrivacy === ProjectPrivacy.public && !isOnPremiseDeployment && (
            <Button onClick={onSendTwitter} size="lg" variant="secondary">
              <Icon glyph="twitter" />
              &nbsp; Twitter
            </Button>
          )}
        </Layout.Row>
      </Layout.Column>
    );

  return (
    <Modal className={block()} isOpen={isOpen} variant="simple" onClose={onClose}>
      <Modal.Close className={block('close')} onClose={onClose} />

      <Layout.Column
        spacedChildren="lg"
        alignItems="center"
        withPadding="lg"
        data-role="share-modal"
      >
        <Icon glyph="user-friends" color="decorator" size="3x" />
        <Text size="lg" fontWeight="semibold" align="center">
          Share this page with others!
        </Text>
        {description}
        {action}
      </Layout.Column>
    </Modal>
  );
};

function getPrivacyDescriptionCopy(
  organizationType?: OrganizationType,
  privacy?: ProjectPrivacy,
): string {
  if (!organizationType || !privacy) {
    return '';
  }

  if (organizationType === 'individual' && privacy === ProjectPrivacy.private) {
    return 'in your individual workspace - only you have access to it.';
  }

  return defaultDescriptionCopy[privacy];
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextWrapping } from '@neptune/shared/common-domain';

import { AppState } from 'state/types';

import { setTextWrapping } from './redux/actions';
import { getTextWrapping } from './redux/selectors';

type UseTextWrappingReturn = [TextWrapping, (newMode: TextWrapping) => void];

export const useTextWrapping = (id: string, projectIdentifier: string): UseTextWrappingReturn => {
  const dispatch = useDispatch();
  const wrapping = useSelector(
    (state: AppState) => getTextWrapping(state, id, projectIdentifier) || TextWrapping.Clip,
  );

  const setWrapping = React.useCallback(
    (value) => {
      dispatch(setTextWrapping(id, value, projectIdentifier));
    },
    [dispatch, id, projectIdentifier],
  );

  return [wrapping, setWrapping];
};

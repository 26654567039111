import { getInitialState } from '../storage-utils';

import { TextWrappingActions, TextWrappingActionTypes } from './actions';

const initialState = getInitialState();

export const textWrappingReducer = (state = initialState, action: TextWrappingActions) => {
  switch (action.type) {
    case TextWrappingActionTypes.Set: {
      const { id, projectIdentifier, wrapping } = action.payload;

      return {
        ...state,
        [projectIdentifier]: {
          ...state[projectIdentifier],
          [id]: wrapping,
        },
      };
    }
  }

  return state;
};

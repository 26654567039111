import React from 'react';

import { ServiceAccountAvatar } from '@neptune/shared/service-accounts-ui';
import { Dropdown, Text } from '@neptune/shared/venus-ui';

type ServiceAccountItemProps = {
  name: string;
  onClick?(event: React.MouseEvent): void;
  style?: React.CSSProperties;
};

export const ServiceAccountItem: React.FC<ServiceAccountItemProps> = ({ name, onClick, style }) => {
  return (
    <Dropdown.Item spacedChildren="sm" onClick={onClick} style={style}>
      <ServiceAccountAvatar size="xs" />
      <Text wordBreak="keep-all">{name}</Text>
    </Dropdown.Item>
  );
};

// Libs
import React from 'react';

// App
import { DotsHButton } from '@neptune/shared/common-ui';
// Module
import { ServiceAccountAvatar } from '@neptune/shared/service-accounts-ui';
import { bemBlock, Dropdown, Layout, Text } from '@neptune/shared/venus-ui';

import './ServiceAccountHeader.less';

const block = bemBlock('service-account-header');

type MenuItem = {
  caption: string;
  action: () => void;
  dataRole?: string;
};

type ServiceAccountHeaderProps = {
  name?: string;
  menu?: MenuItem[];
};

export const ServiceAccountHeader: React.FC<ServiceAccountHeaderProps> = ({ name, menu }) => {
  return (
    <Layout.Row className={block()} span="auto" alignItems="center" spacedChildren="sm" height={30}>
      <ServiceAccountAvatar size="sm" />
      <Text className={block('name')} fontWeight="semibold" title={name} ellipsis>
        {name}
      </Text>
      {menu ? (
        <Layout.Element width={30}>
          <Dropdown
            toggle={<DotsHButton data-role="service-account-menu" />}
            attachment="top right"
            targetAttachment="bottom right"
          >
            {({ collapse }) => (
              <Dropdown.Menu>
                {menu.map((item) => (
                  <Dropdown.Item
                    key={item.caption}
                    label={item.caption}
                    onClick={() => {
                      item.action();
                      collapse();
                    }}
                    data-role={item.dataRole}
                  />
                ))}
              </Dropdown.Menu>
            )}
          </Dropdown>
        </Layout.Element>
      ) : undefined}
    </Layout.Row>
  );
};

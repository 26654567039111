import { Store } from 'redux';
import { Router } from 'router5';

import { authClient } from '@neptune/shared/core-auth-domain';
import {
  fetchOrganizationByName,
  OrganizationRole,
} from '@neptune/shared/core-organizations-domain';
import { superReaderRepository } from '@neptune/shared/core-super-reader-domain';

import { fetchSuperReaderOrganizationSuccess, updateNeptuneRole } from './actions';
import { isSuperReader } from './selectors';

export async function initializeSuperReader(store: Store, router: Router): Promise<void> {
  store.dispatch(
    updateNeptuneRole(
      authClient.tokenParsed?.neptune_instance_role,
      authClient.tokenParsed?.neptune_role,
    ),
  );

  if (!isSuperReader(store.getState())) {
    return;
  }

  superReaderRepository.active = true;

  const route = router.matchUrl(window.location.href);

  if (!route?.params?.organizationName) {
    return;
  }

  try {
    // In normal operation, listOrganizations() is the only way to fetch workspaces,
    // so only user workspaces are fetched.
    // To make the context workspace available for superreader, we make an extra fetch here.
    //
    // Moreover, useOrganizationAccessControl uses getOrganizationsList as ground truth
    // for workspace access, so getOrganizationsList will return this entry in the first place,
    // on top of the results from listOrganizations().
    //
    // An alternative would be to extend listOrganizations() to add this extra fetch when
    // superreader is active. This way we wouldn't have a separate fetch in different place
    // (which is never refreshed, as well).
    const organization = await fetchOrganizationByName(route.params.organizationName);
    const superReaderOrganization = {
      organization,
      userRole: OrganizationRole.owner,
      ...organization,
      superUser: true,
    };
    store.dispatch(fetchSuperReaderOrganizationSuccess(superReaderOrganization));
  } catch {}
}

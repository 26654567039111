// Libs
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';

// App
import { isFetchStatusCompleted } from 'state/fetch-status';
import { redirectToDefaultView } from 'state/project-leaderboard/lb-views/actions';
import {
  getActiveView,
  getLeaderboardViewsState,
} from 'state/project-leaderboard/lb-views/selectors';

// Module
export function useRedirectToExistingViewIfNeeded() {
  const projectIdentifier = useSelector(getCurrentProjectIdentifier);
  const activeView = useSelector(getActiveView);
  const lbViewsState = useSelector(getLeaderboardViewsState);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (
      isFetchStatusCompleted(lbViewsState.fetchStatus) &&
      lbViewsState.projectIdentifier === projectIdentifier &&
      activeView == null
    ) {
      dispatch(redirectToDefaultView());
    }
  }, [
    dispatch,
    projectIdentifier,
    lbViewsState.fetchStatus,
    lbViewsState.projectIdentifier,
    activeView,
  ]);
}

import { AttributeType } from 'domain/experiment/attribute';

export const suitableLeaderboardAttributeTypes: AttributeType[] = [
  'bool',
  'datetime',
  'experimentState',
  'float',
  'floatSeries',
  'gitRef',
  'int',
  'string',
  'stringSeries',
  'stringSet',
];

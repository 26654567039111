import React from 'react';

import { getRegistry, getRegistryEntries, SubviewEntry } from '@neptune/project-domain';
import { ProjectWithRole } from '@neptune/shared/core-project-domain';

type UseOverlaySubviewsReturnType = {
  currentSubviewLabel?: string;
  currentSubviewComponent?: React.ReactElement<{ project: ProjectWithRole }>;
  openSubview: (subviewId: string, project: ProjectWithRole) => void;
  closeCurrentSubview: () => void;
  getSubviewEntries: (project?: ProjectWithRole) => SubviewEntry[];
};

/**
 * @deprecated Avoid using overlays, consider Modal and useGenericModal
 */
export const useOverlaySubviews: () => UseOverlaySubviewsReturnType = () => {
  const [subviewId, setSubviewId] = React.useState<string>();
  const [project, setProject] = React.useState<ProjectWithRole>();
  const subViewsMap = getRegistry();
  const currentSubview = subviewId ? subViewsMap[subviewId] : undefined;
  const getSubviewEntries = React.useCallback((project?: ProjectWithRole) => {
    return getRegistryEntries().map(({ id, label, icon, permission, noPermissionCopy }) => {
      const hasPermission = permission ? permission(project) : true;
      const disabled = !hasPermission;
      return {
        id,
        label,
        icon,
        disabled,
        disabledReason: disabled ? noPermissionCopy : undefined,
      };
    });
  }, []);
  const openSubview = React.useCallback((subviewId: string, project: ProjectWithRole) => {
    setSubviewId(subviewId);
    setProject(project);
  }, []);

  const memoizedCurrentSubviewComponent = React.useMemo(() => {
    if (!currentSubview || !project) {
      return undefined;
    }

    const { Component } = currentSubview;
    return <Component project={project} />;
  }, [currentSubview, project]);

  const closeCurrentSubview = React.useCallback(() => setSubviewId(undefined), []);

  return {
    currentSubviewComponent: memoizedCurrentSubviewComponent,
    currentSubviewLabel: currentSubview?.label,
    openSubview,
    closeCurrentSubview,
    getSubviewEntries,
  };
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InputWithValidation, ProgressBar, Validation } from '@neptune/shared/common-ui';
import { updateInputPreservingCaretPosition } from '@neptune/shared/common-util';
import {
  backendClient,
  LoginActionDTO,
  UsernameValidationStatusEnumDTO,
} from '@neptune/shared/core-apis-backend-domain';
import { getPreferredUsername, refreshAuthToken } from '@neptune/shared/core-auth-domain';
import { bemBlock, Button, Card, Layout, Text } from '@neptune/shared/venus-ui';

import { createErrorDescriptor } from 'common/error-handler';
import { useUsernameValidation } from 'common/hooks/useUsernameValidation';
import { KeycloakTitle } from 'components/keycloak';
import { completeLoginAction } from 'state/users/register/actions';
import { getLoginActions } from 'state/users/register/selectors';

import './SetUsernameForm.less';

const block = bemBlock('set-username-form');

export const SetUsernameForm: React.FC = React.memo(function SetUsernameForm() {
  const dispatch = useDispatch();
  const [username, setUsername] = React.useState<string>(sanitizeUsername(getPreferredUsername()));
  const [validationStatus, setValidationStatus] = React.useState<
    UsernameValidationStatusEnumDTO | undefined
  >();
  const [pending, setPending] = React.useState(false);
  const loginActions = useSelector(getLoginActions);
  const ratio = loginActions.length === 1 ? 0.8 : 0.5;
  const errorText = getErrorText(validationStatus, username);

  const { validate } = useUsernameValidation({
    onValidationChange: ({ status }: { status: UsernameValidationStatusEnumDTO }) => {
      setValidationStatus(status);
      setPending(false);
    },
    onValidationStart: () => setPending(true),
  });

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValidationStatus(undefined);
      updateInputPreservingCaretPosition(event.target, (value: string) =>
        value.replace(/[^0-9a-z-.]/gi, '-').toLowerCase(),
      );
      setUsername(event.target.value);
      validate(event.target.value);
    },
    [validate],
  );

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      await backendClient.setUsername({ username });
      // temporary username is in auth token which should be refreshed after changing username
      await refreshAuthToken();
      dispatch(completeLoginAction(LoginActionDTO.SetUsername));
    } catch (error) {
      const errorDescriptor = createErrorDescriptor(error);

      if (errorDescriptor.code === 409) {
        setValidationStatus(UsernameValidationStatusEnumDTO.Unavailable);
      } else {
        setValidationStatus(UsernameValidationStatusEnumDTO.Invalid);
      }
    }
  };

  React.useEffect(() => {
    if (username !== '') {
      validate(username);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasError = !!errorText;

  return (
    <Layout.Column
      className={block()}
      component="form"
      data-role="set-username-form"
      spacedChildren="lg"
      span="auto"
      onSubmit={handleSubmit}
    >
      <Layout.Column alignItems="center">
        <KeycloakTitle>Create a free account</KeycloakTitle>
      </Layout.Column>
      <Card>
        <Card.Section>
          <Card.Column spacedChildren="md">
            <Card.Column spacedChildren="xxs">
              <Card.HeaderText>Choose your username</Card.HeaderText>
              <Text size="xs" color="text-alt">
                This will be a unique way to identify you in Neptune.
              </Text>
            </Card.Column>
            <ProgressBar ratio={ratio} size="xs" />
          </Card.Column>
        </Card.Section>
        <Card.Section>
          <Card.Column className={block('username-column')} spacedChildren="sm">
            <InputWithValidation
              label="Your username"
              status={pending ? 'pending' : convertStatusToInputStatus(validationStatus)}
              inputProps={{
                name: 'username',
                value: username,
                'data-role': 'username',
                onChange: handleChange,
              }}
              errorMessage={errorText}
            />
            {!hasError && (
              <Text color="text-alt" size="xs">
                Use 3 or more characters. You can use lowercase letters, digits, dots and hyphens.
              </Text>
            )}
          </Card.Column>
        </Card.Section>
        <Card.Footer
          nextSlot={
            <Button
              size="lg"
              type="submit"
              children="Next"
              disabled={validationStatus !== UsernameValidationStatusEnumDTO.Available}
            />
          }
        />
      </Card>
    </Layout.Column>
  );
});

function getErrorText(
  validationStatus: UsernameValidationStatusEnumDTO | undefined,
  username: string,
) {
  switch (validationStatus) {
    case UsernameValidationStatusEnumDTO.Invalid: {
      if (username.length < 3) {
        return 'Username is too short. Please use at least 3 characters.';
      }

      if (username.length > 50) {
        return 'Username is too long. Could you fit it in 50 characters?';
      }

      return 'Username may only contain lowercase letters, digits, and hyphens. Please try to pick another one.';
    }

    case UsernameValidationStatusEnumDTO.Unavailable: {
      return 'Looks like this username is already taken. Please try to pick another one.';
    }
  }
}

function convertStatusToInputStatus(
  status?: UsernameValidationStatusEnumDTO,
): Validation | undefined {
  if (status === UsernameValidationStatusEnumDTO.Available) {
    return 'valid';
  }

  if (
    status === UsernameValidationStatusEnumDTO.Unavailable ||
    status === UsernameValidationStatusEnumDTO.Invalid
  ) {
    return 'invalid';
  }

  // only undefined left
  return status;
}

function sanitizeUsername(username: string): string {
  if (username.includes('@')) {
    return username.split('@')[0];
  }

  return username;
}

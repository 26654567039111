import { AnyAction } from 'redux';

import {
  deleteOrganizationInvitation,
  newOrganizationInvitation,
  resendOrganizationInvitation,
} from '@neptune/invitations-domain';
import {
  ALREADY_AN_ADMIN_MODAL,
  ALREADY_INVITED_MODAL_TYPE,
  fetchOrganizationMembers,
  MEMBERS_LIMIT_REACHED_MODAL,
  modifyOrganizationMember,
  ONLY_ADMIN_MODAL,
  removeOrganizationMember,
} from '@neptune/user-management-domain';

import { handleError } from 'common/error-handler';
import { AsyncActionsReturnType, createAsyncActions, RejectPayload } from 'state/async-actions';
import { NThunkDispatch } from 'state/types';
import { openConfirmationModal } from 'state/ui/global/confirmation-modal/actions';
import { showGenericModal } from 'state/ui/modals/generic/actions';

export enum OrganizationMemberListFetchListActionTypes {
  request = 'ORGANIZATION_MEMBER_LIST_FETCH',
  success = 'ORGANIZATION_MEMBER_LIST_FETCH_SUCCESS',
  fail = 'ORGANIZATION_MEMBER_LIST_FETCH_FAIL',
}

export enum OrganizationMemberListUpdateMemberActionTypes {
  request = 'ORGANIZATION_MEMBER_UPDATE',
  success = 'ORGANIZATION_MEMBER_UPDATE_SUCCESS',
  fail = 'ORGANIZATION_MEMBER_UPDATE_FAIL',
}

export enum OrganizationMemberListDeleteMemberActionTypes {
  request = 'ORGANIZATION_MEMBER_DELETE',
  success = 'ORGANIZATION_MEMBER_DELETE_SUCCESS',
  fail = 'ORGANIZATION_MEMBER_DELETE_FAIL',
}

export enum OrganizationMemberListCreateInvitationActionTypes {
  request = 'ORGANIZATION_INVITE_CREATE',
  success = 'ORGANIZATION_INVITE_CREATE_SUCCESS',
  fail = 'ORGANIZATION_INVITE_CREATE_FAIL',
}

export enum OrganizationMemberListRevokeInvitationActionTypes {
  request = 'ORGANIZATION_INVITE_REVOKE',
  success = 'ORGANIZATION_INVITE_REVOKE_SUCCESS',
  fail = 'ORGANIZATION_INVITE_REVOKE_FAIL',
}

export enum OrganizationMemberListResendExistingInvitationActionTypes {
  request = 'ORGANIZATION_INVITE_RESEND',
  success = 'ORGANIZATION_INVITE_RESEND_SUCCESS',
  fail = 'ORGANIZATION_INVITE_RESEND_FAIL',
}

export enum OrganizationMemberListLeaveProjectActionTypes {
  request = 'ORGANIZATION_LEAVE_PROJECT',
  success = 'ORGANIZATION_LEAVE_PROJECT_SUCCESS',
  fail = 'ORGANIZATION_LEAVE_PROJECT_FAIL',
}

export const organizationMemberListSortActionType = 'ORGANIZATION_MEMBER_LIST_SORT';
const fetchOrganizationMembersActions = createAsyncActions({
  types: OrganizationMemberListFetchListActionTypes,
  resolver: async (params: Parameters<typeof fetchOrganizationMembers>[0]) => {
    const entries = await fetchOrganizationMembers(params);
    return { entries };
  },
});

const updateOrganizationMemberActions = createAsyncActions({
  types: OrganizationMemberListUpdateMemberActionTypes,
  resolver: async (params: Parameters<typeof modifyOrganizationMember>[0]) => {
    const updatedMember = await modifyOrganizationMember(params);
    return { updatedMember };
  },
  onReject: handleAdminErrors,
});

const deleteOrganizationMemberActions = createAsyncActions({
  types: OrganizationMemberListDeleteMemberActionTypes,
  resolver: async (params: Parameters<typeof removeOrganizationMember>[0]) => {
    const data = await removeOrganizationMember(params);
    return { data };
  },
});

const inviteOrganizationMemberActions = createAsyncActions({
  types: OrganizationMemberListCreateInvitationActionTypes,
  resolver: async (params: Parameters<typeof newOrganizationInvitation>[0]) => {
    const data = await newOrganizationInvitation(params);

    return { data };
  },
  onReject: handleAddingErrors,
});

const revokeOrganizationInvitationActions = createAsyncActions({
  types: OrganizationMemberListRevokeInvitationActionTypes,
  resolver: deleteOrganizationInvitation,
});

const resendExistingOrganizationInvitationActions = createAsyncActions({
  types: OrganizationMemberListResendExistingInvitationActionTypes,
  resolver: resendOrganizationInvitation,
});

function handleAddingErrors<T>(payload: RejectPayload<T>, dispatch: NThunkDispatch<AnyAction>) {
  handleError(409, payload.error, () => {
    dispatch(openConfirmationModal(ALREADY_INVITED_MODAL_TYPE, payload));
  });
  handleError(422, payload.error, () => {
    dispatch(showGenericModal(MEMBERS_LIMIT_REACHED_MODAL));
  });
}

function handleAdminErrors<T>(payload: RejectPayload<T>, dispatch: NThunkDispatch<AnyAction>) {
  handleError(412, payload.error, () => {
    dispatch(showGenericModal(ALREADY_AN_ADMIN_MODAL));
  });
  handleError(409, payload.error, () => {
    dispatch(showGenericModal(ONLY_ADMIN_MODAL));
  });
}

export const { execute: fetchOrganizationMemberList } = fetchOrganizationMembersActions;
export const { execute: updateOrganizationMember } = updateOrganizationMemberActions;
export const { execute: deleteOrganizationMember } = deleteOrganizationMemberActions;
export const { execute: createOrganizationInvitation } = inviteOrganizationMemberActions;
export const { execute: revokeOrganizationInvitation } = revokeOrganizationInvitationActions;
export const { execute: resendExistingOrganizationInvitation } =
  resendExistingOrganizationInvitationActions;

export function sortOrganizationMemberList(sortOptions: { sortBy: string; sortOrder: string }) {
  return {
    type: organizationMemberListSortActionType,
    payload: { sortOptions },
  } as const;
}

export type OrganizationUserManagementActions =
  | AsyncActionsReturnType<typeof fetchOrganizationMembersActions>
  | AsyncActionsReturnType<typeof updateOrganizationMemberActions>
  | AsyncActionsReturnType<typeof deleteOrganizationMemberActions>
  | AsyncActionsReturnType<typeof inviteOrganizationMemberActions>
  | AsyncActionsReturnType<typeof revokeOrganizationInvitationActions>
  | AsyncActionsReturnType<typeof resendExistingOrganizationInvitationActions>
  | ReturnType<typeof sortOrganizationMemberList>;

import React from 'react';

import {
  FullscreenContext,
  FullscreenContextOptions,
  GlobalFullscreenContext,
} from '@neptune/shared/common-domain';

export const useGlobalFullscreen = () => {
  return React.useContext(GlobalFullscreenContext);
};

export const useFullscreen = () => {
  return React.useContext(FullscreenContext);
};

export const useGlobalFullscreenProvider = ({
  onFullscreen,
  onRestore,
}: {
  onFullscreen?: () => void;
  onRestore?: () => void;
} = {}): { globalFullscreenContext: FullscreenContextOptions } => {
  const [fullscreen, setFullscreen] = React.useState(false);
  const fullscreenRef = React.useRef(0);

  const maximize = React.useCallback(() => {
    if (fullscreenRef.current === 0) {
      setFullscreen(true);
      onFullscreen?.();
    }

    fullscreenRef.current++;
  }, [onFullscreen]);
  const restore = React.useCallback(() => {
    fullscreenRef.current--;

    if (fullscreenRef.current === 0) {
      setFullscreen(false);
      onRestore?.();
    }
  }, [onRestore]);

  const globalFullscreenContext = React.useMemo(
    () => ({
      fullscreen,
      maximize,
      restore,
    }),
    [fullscreen, maximize, restore],
  );

  return { globalFullscreenContext };
};

export const useFullscreenProvider = (): { fullscreenContext: FullscreenContextOptions } => {
  const [fullscreen, setFullscreen] = React.useState(false);
  const { maximize: globalMaximize, restore: globalRestore } = useGlobalFullscreen();
  const maximize = React.useCallback(() => {
    setFullscreen(true);
    globalMaximize();
  }, [globalMaximize]);
  const restore = React.useCallback(() => {
    setFullscreen(false);
    globalRestore();
  }, [globalRestore]);

  const keyHandler = React.useCallback(
    (event: KeyboardEvent) => {
      if (isInputLikeElement(event.target)) {
        return;
      }

      if (event.code !== 'Escape') {
        return;
      }

      event.stopPropagation();

      restore();
    },
    [restore],
  );

  React.useEffect(() => {
    if (fullscreen) {
      window.addEventListener('keydown', keyHandler);
      return () => window.removeEventListener('keydown', keyHandler);
    }
  }, [fullscreen, keyHandler]);

  const fullscreenContext = React.useMemo(
    () => ({
      fullscreen,
      maximize,
      restore,
    }),
    [fullscreen, maximize, restore],
  );

  return { fullscreenContext };
};

function isElement(maybeElement: any): maybeElement is Element {
  return !!(maybeElement && maybeElement.tagName);
}

function isInputLikeElement(
  element: EventTarget | null,
): element is HTMLInputElement | HTMLTextAreaElement {
  return isElement(element) && (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA');
}

import { OrganizationWithRole } from '@neptune/shared/core-organizations-domain';

import { AppState } from 'state/types';

import { NeptuneInternalsState } from './reducer';

export function getNeptuneInternalsState(state: AppState): NeptuneInternalsState | undefined {
  return (state as any).neptune;
}

export function isSuperReader(state: AppState): boolean {
  const subState = getNeptuneInternalsState(state);
  return subState?.neptuneInstanceRole === 'super-reader' || subState?.neptuneRole === 'reader';
}

export function getSuperReaderOrganization(state: AppState): OrganizationWithRole | undefined {
  const subState = getNeptuneInternalsState(state);
  return subState?.superReaderOrganization;
}

export function isNeptuneAdmin(state: AppState): boolean {
  const subState = getNeptuneInternalsState(state);
  return subState?.neptuneRole === 'admin';
}

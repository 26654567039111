import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';
import {
  convertEntityChangesToOperations,
  EntityChanges,
  EntityModelConverter,
  EntityType,
  fetchEntityChunk,
} from '@neptune/shared/entity-domain';

import { isNotUndefined } from 'common/tsHelpers';
import { AsyncActionsReturnType, createAsyncActions } from 'state/async-actions';

export enum ExperimentAttributeOperation {
  request = 'EXPERIMENT_ATTRIBUTE_OPERATION_REQUEST',
  success = 'EXPERIMENT_ATTRIBUTE_OPERATION_SUCCESS',
  fail = 'EXPERIMENT_ATTRIBUTE_OPERATION_FAIL',
}

const requestOperationActions = createAsyncActions({
  types: ExperimentAttributeOperation,
  resolver: async (params: {
    parentId: string;
    parentType: EntityType;
    entityChanges: Partial<EntityChanges>;
  }) => {
    const operations = convertEntityChangesToOperations(params.entityChanges);

    await leaderboardClient.executeOperations({
      holderIdentifier: params.parentId,
      holderType: EntityModelConverter.entityTypeToHolderType(params.parentType),
      operations,
    });

    const operationsPaths = operations.map((operation) => operation.path).filter(isNotUndefined);

    return {
      params,
      experiment: await fetchEntityChunk({
        id: params.parentId,
        type: params.parentType,
        fieldsToFetch: operationsPaths,
      }),
    };
  },
});

const { execute: requestOperation } = requestOperationActions;

export { requestOperation };

export type OperationActions = AsyncActionsReturnType<typeof requestOperationActions>;

import { AnyAction } from 'redux';
import { navigateTo } from 'router/actions';
import { parseRouteParams } from 'router/router-utils';

import { makeLbViewRouteState } from '@neptune/shared/lb-views-domain';

import { getActiveView } from 'state/project-leaderboard/lb-views/selectors';
import { getCurrentRoute } from 'state/selectors-global';
import { NThunkAction } from 'state/types';

export function updateDirtyLbView(
  newParams: Record<string, unknown>,
): NThunkAction<void, AnyAction> {
  return (dispatch, getState) => {
    const route = getCurrentRoute(getState());

    const activeView = getActiveView(getState());

    // If we don't have an activeView, we shouldn't try to update route params.
    if (!activeView) {
      return;
    }

    const newRouterState = makeLbViewRouteState(newParams, route.params, activeView);

    dispatch(navigateTo(route.name, parseRouteParams(newRouterState)));
  };
}

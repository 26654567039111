import { AnyAction } from 'redux';
import { navigateTo } from 'router/actions';

import { activeProjectQuotaExceededModalName } from '@neptune/projects-domain';
import { OrganizationWithRole } from '@neptune/shared/core-organizations-domain';
import {
  createProject as coreCreateProject,
  deleteProjectById,
  NewProject,
  ProjectUpdate,
  ProjectWithRole,
  updateProjectById,
} from '@neptune/shared/core-project-domain';

import { handleError } from 'common/error-handler';
import {
  NO_INVITE_IN_PRIVATE_PROJECT_MODAL_NAME,
  PROJECTS_LIMIT_MODAL_NAME,
  SAAS_LIMIT_MODAL_NAME,
} from 'components/projects/projects-modal/modal-names';
import actionTypes from 'state/actionTypes';
import { AsyncActionsReturnType, createAsyncActions } from 'state/async-actions';
import { getOrganizationsList } from 'state/selectors';
import { AppState, NThunkDispatch } from 'state/types';
import { showGenericModal } from 'state/ui/modals/generic/actions';

export function createProject(project: NewProject) {
  return (dispatch: NThunkDispatch<AnyAction>, getState: () => AppState) => {
    dispatch({
      type: actionTypes.PROJECT_CREATE,
      payload: { project },
    });

    const organizations = getOrganizationsList(getState());

    const organization =
      organizations.find((org: OrganizationWithRole) => org.id === project.organizationId) || {};

    const promise = coreCreateProject(project);

    promise
      .then((project) => {
        dispatch({
          type: actionTypes.PROJECT_CREATE_SUCCESS,
          payload: { project },
        });
        dispatch(
          navigateTo('project', { organizationName: organization.name, projectName: project.name }),
        );
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.PROJECT_CREATE_FAIL,
          payload: { error },
        });

        handleError(402, error, () => {
          // This is needed on prem.
          dispatch(showGenericModal(PROJECTS_LIMIT_MODAL_NAME));
        });

        handleError(422, error, ({ error }) => {
          if (error.errorType === 'LIMIT_OF_ACTIVE_PROJECTS_REACHED') {
            dispatch(showGenericModal(activeProjectQuotaExceededModalName));
          } else {
            dispatch(showGenericModal(SAAS_LIMIT_MODAL_NAME));
          }
        });
      });
    return promise;
  };
}

export enum UpdateProjectActionTypes {
  request = 'PROJECT_UPDATE',
  success = 'PROJECT_UPDATE_SUCCESS',
  fail = 'PROJECT_UPDATE_FAIL',
}

export const updateProjectActions = createAsyncActions({
  types: UpdateProjectActionTypes,
  async resolver({
    projectIdentifier,
    projectUpdate,
  }: {
    projectIdentifier: string;
    projectUpdate: ProjectUpdate;
  }) {
    const project = await updateProjectById({ projectIdentifier, projectUpdate });
    return { project };
  },
  onReject: (error: any, dispatch: NThunkDispatch<AnyAction>) => {
    handleError(422, error, () => {
      dispatch(showGenericModal(NO_INVITE_IN_PRIVATE_PROJECT_MODAL_NAME));
    });
  },
});

export type UpdateProjectActions = AsyncActionsReturnType<typeof updateProjectActions>;

export function deleteProject(project: ProjectWithRole) {
  return (dispatch: NThunkDispatch<AnyAction>) => {
    dispatch({
      type: actionTypes.PROJECT_DELETE,
      payload: { project },
    });

    const projectId = project.id;
    const organizationId = project.organizationId;

    deleteProjectById({ projectIdentifier: projectId })
      .then(() =>
        dispatch({
          type: actionTypes.PROJECT_DELETE_SUCCESS,
          payload: { projectId, organizationId },
        }),
      )
      .catch((error) =>
        dispatch({
          type: actionTypes.PROJECT_DELETE_FAIL,
          payload: { error },
        }),
      );
  };
}

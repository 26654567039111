import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMatch } from 'lodash';
import { navigateTo } from 'router/actions';
import { createNormalizedRouteTest } from 'router/helpers';

import { getCurrentRoute } from 'state/selectors-global';

export type BaseTab = {
  routeName: string;
  routeParams: Record<string, string>;
  isDisabled: boolean;
  component: React.ReactElement;
};

type SimpleRouteableTabsProps<T extends BaseTab> = {
  tabs: T[];
  renderer?: (tab: T) => React.ReactElement;
};

const fallbackRoute = { routeName: 'home', routeParams: undefined };

export const SimpleRouteableTabs = <T extends BaseTab>({
  tabs,
  renderer,
}: SimpleRouteableTabsProps<T>) => {
  const tab = useMatchCurrentTab(tabs);
  useRedirectToFallback(tabs, tab);

  if (!tab || tab.isDisabled) {
    return null;
  }

  return renderer ? renderer(tab) : React.cloneElement(tab.component);
};

function useMatchCurrentTab<T extends BaseTab>(tabs: T[]): T | undefined {
  const { name, params } = useSelector(getCurrentRoute);

  return React.useMemo(() => {
    const testRoute = createNormalizedRouteTest(name);
    return tabs.find(
      ({ routeName, routeParams }) => testRoute(routeName) && isMatch(params, routeParams),
    );
  }, [name, params, tabs]);
}

function useRedirectToFallback<T extends BaseTab>(tabs: T[], tab: T | undefined): void {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!tab || tab.isDisabled) {
      const newTab = tabs.find((tab) => !tab.isDisabled) || fallbackRoute;
      dispatch(navigateTo(newTab.routeName, newTab.routeParams, { replace: true }));
    }
  }, [dispatch, tab, tabs]);
}

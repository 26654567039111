import { createSelector } from 'reselect';
import { State as RouterState } from 'router5';

import { toNormalizedRouteName } from '@neptune/shared/enterprise-context-util';
import { RouterStateParams } from '@neptune/shared/routing-domain';

import { AppState } from 'state/types';

export const getRouterState = (state: any): RouterState => state.router.route;
export const getRouterPrevState = (state: any): RouterState => state.router.previousRoute;

const getCurrentRouteMemoized = createSelector(
  getRouterState,
  (route: RouterState): RouterState => {
    if (route == null) {
      return route;
    }

    const { name, ...other } = route;
    return {
      name: toNormalizedRouteName(name),
      ...other,
    };
  },
);

const getPreviousRouteMemoized = createSelector(
  getRouterPrevState,
  (route: RouterState): RouterState => {
    if (route == null) {
      return route;
    }

    const { name, ...other } = route;
    return {
      name: toNormalizedRouteName(name),
      ...other,
    };
  },
);

const getCurrentRouteParamsMemoized = createSelector(getCurrentRouteMemoized, (route) => {
  const { params } = route || {};
  return params || {};
});

export const getCurrentRouteParams = <T extends RouterStateParams = RouterStateParams>(
  state: any,
): T => getCurrentRouteParamsMemoized(state) as T;
export const getCurrentRoute = <T extends RouterState = RouterState>(state: any) =>
  getCurrentRouteMemoized(state) as T;
export const getPreviousRoute = <T extends RouterState = RouterState>(state: any) =>
  getPreviousRouteMemoized(state) as T;
export const getCurrentRouteName = (state: AppState): string => getCurrentRoute(state).name;

/**
 * Creates a selector returning current route parameter's value
 * @param name Name of the parameter to return
 * Unless you can use one of the predefined parameter selectors, make sure to define your local selector outside the component.
 * Otherwise, reference updating on each render won't allow redux to return cached values.
 * @see https://react-redux.js.org/api/hooks#useselector
 */
export const createCurrentRouteParamSelector = (name: string) => (state: AppState) =>
  getCurrentRouteParams(state)[name];

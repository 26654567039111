import { searchLeaderboard } from '@neptune/shared/leaderboard-domain';

import { AsyncActionsReturnType, createAsyncActions } from 'state/async-actions';

export enum LeaderboardCountActionTypes {
  request = 'LEADERBOARD_COUNT_FETCH',
  success = 'LEADERBOARD_COUNT_SUCCESS',
  fail = 'LEADERBOARD_COUNT_FAIL',
}

type FetchLeaderboardCountParams = {
  projectIdentifier: string;
  trashed: boolean;
};

const actions = createAsyncActions({
  types: LeaderboardCountActionTypes,
  async resolver(params: FetchLeaderboardCountParams) {
    return await searchLeaderboard({
      projectIdentifier: params.projectIdentifier,
      query: `sys/trashed:bool = ${params.trashed}`,
      pagination: {
        limit: 0,
        offset: 0,
      },
      experimentsOnly: false,
      type: ['run'],
    });
  },
});

export const { execute: fetchLeaderboardCount } = actions;

export type LeaderboardCountActions = AsyncActionsReturnType<typeof actions>;

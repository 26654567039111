import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNumber } from 'lodash';

import { sendEventToGTM } from '@neptune/analytics-api';
import { InvitationType, NewOrganizationInvitationProjectEntry } from '@neptune/invitations-domain';
import { useConfirmationModal, useGenericModal } from '@neptune/shared/common-business-logic';
import { ErrorPlaceholderContainer } from '@neptune/shared/common-feature';
import { availableOrganizationRoles } from '@neptune/shared/core-organizations-business-logic';
import {
  OrganizationRole,
  organizationRoleLabels,
  OrganizationType,
  ProjectPrivacy,
} from '@neptune/shared/core-organizations-domain';
import {
  availableProjectRoles,
  hasMembersManagePermission,
  hasProjectMembersManagePermission,
} from '@neptune/shared/core-permissions-business-logic';
import {
  ProjectRole,
  projectRoleLabels,
  ProjectWithRole,
} from '@neptune/shared/core-project-domain';
import { makeProjectIdentifier } from '@neptune/shared/core-project-util';
import { RoleDropdown } from '@neptune/shared/user-management-ui';
import { Card, Layout, SimpleTooltip, Text } from '@neptune/shared/venus-ui';
import {
  addProjectMember,
  createOrganizationInvitation,
  createProjectInvitation,
  deleteProjectMember,
  fetchProjectMemberList,
  getOrganizationMembersState,
  getProjectMembersState,
  leaveProject,
  revokeProjectInvitation,
  shouldShowProjectRoleTooltip,
  sortProjectMemberList,
  updateProjectMember,
} from '@neptune/user-management-business-logic';
import {
  ALREADY_INVITED_MODAL_TYPE,
  DELETE_MODAL_TYPE,
  MEMBERS_LIMIT_REACHED_MODAL,
  ProjectMember,
} from '@neptune/user-management-domain';
import {
  AlreadyInvitedModal,
  CantInviteToIndividualNotice,
  MemberList,
  MemberListContext,
  MemberListView,
  MembersLimitReachedModal,
  ProjectVisibilityInfo,
  RoleDropdownItemProps,
} from '@neptune/user-management-ui';

import { isClassicDeployment } from 'common/deploymentModes';
import { isFetchStatusFailed } from 'state/fetch-status';
import { fetchOrganizationLimits } from 'state/organization-limits/actions';
import { getMembersLimit } from 'state/organization-limits/selectors';
import { getContextOrganization } from 'state/selectors-global';
import { openConfirmationModal } from 'state/ui/global/confirmation-modal/actions';
import { getCurrentUser } from 'state/users/current-user/selectors';

import { AddMember, AddMemberContext } from './AddMember';
import { DeleteModal } from './DeleteModal';

type ProjectMemberListContainerProps = {
  project: ProjectWithRole;
};

const noWorkspaceManagePermissionCopy =
  'You don’t have permission to invite people to this workspace.';

export const ProjectMemberListContainer: React.FC<ProjectMemberListContainerProps> = ({
  project,
}) => {
  const canEdit = hasProjectMembersManagePermission(project);
  const {
    entries,
    fetchStatus,
    lastRefreshTime: projectStateLastRefreshTime,
    sortOptions,
  } = useSelector(getProjectMembersState);
  const { lastRefreshTime: organizationStateLastRefreshTime } = useSelector(
    getOrganizationMembersState,
  );
  const currentOrganization = useSelector(getContextOrganization);
  const currentUser = useSelector(getCurrentUser);

  const isEnterpriseOrganization = currentOrganization?.organizationType === OrganizationType.team;
  const projectPrivacy = project.visibility;
  const membersLimit = useSelector(getMembersLimit);

  const projectIdentifier = makeProjectIdentifier(project.organizationName, project.name);

  const invitesLeft = isNumber(membersLimit)
    ? Math.max(membersLimit - entries.length, 0)
    : undefined;
  const invitationsLimitReached = invitesLeft === 0;

  const isAddingBlockedDueToPrivateIndividualProject =
    projectPrivacy === ProjectPrivacy.private && !isEnterpriseOrganization;
  const isAddingBlockedDueToNoWorkspaceManagePermission =
    projectPrivacy === ProjectPrivacy.workspace && !hasMembersManagePermission(currentOrganization);
  const isWorkspaceProject = projectPrivacy === ProjectPrivacy.workspace;
  const isPrivateProject = projectPrivacy === ProjectPrivacy.private;
  const isAddingBlocked =
    isAddingBlockedDueToPrivateIndividualProject ||
    isAddingBlockedDueToNoWorkspaceManagePermission ||
    invitationsLimitReached;

  const user = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  const { isOpen: isAlreadyInvitedModalOpen, close: closeAlreadyInvitedModal } =
    useConfirmationModal(ALREADY_INVITED_MODAL_TYPE);
  const { isOpen: isMembersLimitReachedModalOpen, close: closeMembersLimitReachedModal } =
    useGenericModal(MEMBERS_LIMIT_REACHED_MODAL);

  React.useEffect(() => {
    dispatch(fetchProjectMemberList({ projectIdentifier }));
  }, [dispatch, projectIdentifier, projectStateLastRefreshTime, organizationStateLastRefreshTime]);
  React.useEffect(() => {
    dispatch(fetchOrganizationLimits({ organizationIdentifier: project.organizationName }));
  }, [dispatch, project.organizationName]);

  const handleUpdateRole = React.useCallback(
    ({ userId, role }: { userId?: string; role: ProjectRole }) => {
      if (isWorkspaceProject) {
        return;
      }

      if (!userId) {
        return;
      }

      dispatch(
        updateProjectMember({
          projectIdentifier,
          userId,
          member: { role },
        }),
      );
    },
    [dispatch, isWorkspaceProject, projectIdentifier],
  );

  const handleDeleteMember = React.useCallback(
    ({
      userId,
      invitee,
      invitationId,
    }: {
      userId?: string;
      invitee?: string;
      invitationId?: string;
    }) => {
      if (invitationId) {
        dispatch(
          openConfirmationModal(DELETE_MODAL_TYPE, {
            invitationId,
            invitee,
          }),
        );
      } else if (userId) {
        dispatch(openConfirmationModal(DELETE_MODAL_TYPE, { userId }));
      } else {
        dispatch(openConfirmationModal(DELETE_MODAL_TYPE, {}));
      }
    },
    [dispatch],
  );

  const handleConfirmDeleteMember = React.useCallback(
    ({ userId, invitationId }: { userId: string; invitationId: string }) => {
      if (invitationId) {
        dispatch(revokeProjectInvitation({ invitationId }));
      } else if (userId) {
        dispatch(deleteProjectMember({ projectIdentifier, userId }));
      } else {
        dispatch(leaveProject({ projectIdentifier }));
      }
    },
    [dispatch, projectIdentifier],
  );

  const handleInviteToProject = React.useCallback(
    ({
      invitee,
      roleGrant,
      invitationType,
    }: {
      invitee: string;
      roleGrant: ProjectRole;
      invitationType?: InvitationType;
    }) => {
      if (isAddingBlocked) {
        return;
      }

      if (!invitationType) {
        return;
      }

      dispatch(
        createProjectInvitation({
          invitee,
          invitationType,
          roleGrant,
          projectIdentifier,
        }),
      );
    },
    [dispatch, isAddingBlocked, projectIdentifier],
  );

  const handleAssignToProject = React.useCallback(
    ({ invitee, roleGrant }: { invitee: string; roleGrant: ProjectRole }) => {
      if (isAddingBlocked) {
        return;
      }

      dispatch(
        addProjectMember({
          member: {
            userId: invitee,
            role: roleGrant,
          },
          projectIdentifier,
        }),
      );
    },
    [dispatch, isAddingBlocked, projectIdentifier],
  );

  const handleInviteToWorkspaceAndProject = React.useCallback(
    ({
      invitee,
      roleGrant,
      invitationType,
      addToAllProjects,
      addToProjects,
    }: {
      invitee: string;
      roleGrant: OrganizationRole;
      invitationType: InvitationType;
      addToAllProjects: boolean;
      addToProjects?: NewOrganizationInvitationProjectEntry[];
    }) => {
      dispatch(
        createOrganizationInvitation({
          organizationIdentifier: project.organizationName,
          invitationsEntries: [
            {
              invitee,
              invitationType,
              roleGrant,
              addToProjects,
              addToAllProjects,
            },
          ],
        }),
      );
    },
    [dispatch, project.organizationName],
  );

  const handleSubmit = React.useCallback(
    ({
      invitee,
      roleGrant,
      invitationType,
    }: {
      invitee: string;
      invitationType: InvitationType;
      roleGrant: ProjectRole;
      addToProjects?: NewOrganizationInvitationProjectEntry[];
    }) => {
      if (isEnterpriseOrganization && invitationType === InvitationType.email) {
        handleInviteToWorkspaceAndProject({
          invitee,
          roleGrant: OrganizationRole.member,
          addToProjects: [{ role: roleGrant, id: projectIdentifier }],
          addToAllProjects: false,
          invitationType,
        });
        return;
      }

      if (isEnterpriseOrganization && invitationType === InvitationType.user) {
        handleAssignToProject({ invitee, roleGrant });
        return;
      }

      handleInviteToProject({ invitee, roleGrant, invitationType });
    },
    [
      handleAssignToProject,
      handleInviteToProject,
      handleInviteToWorkspaceAndProject,
      isEnterpriseOrganization,
      projectIdentifier,
    ],
  );
  const handleInviteToWorkspace = React.useCallback(
    ({
      invitee,
      roleGrant,
      invitationType,
    }: {
      invitee: string;
      roleGrant: OrganizationRole;
      invitationType: InvitationType;
      addToAllProjects: boolean;
    }) => {
      dispatch(
        createOrganizationInvitation({
          organizationIdentifier: project.organizationName,
          invitationsEntries: [
            {
              invitee,
              invitationType,
              roleGrant,
              addToAllProjects: false,
            },
          ],
        }),
      );

      if (origin && currentUser?.username) {
        sendEventToGTM({
          eventName: 'invite_sent',
          senderUsername: currentUser.username,
          attributes: {
            origin: 'user-management',
          },
        });
      }
    },
    [currentUser?.username, dispatch, project.organizationName],
  );

  const availableRoles = isEnterpriseOrganization
    ? availableProjectRoles[OrganizationType.team]
    : availableProjectRoles[OrganizationType.individual];

  if (isFetchStatusFailed(fetchStatus)) {
    return <ErrorPlaceholderContainer context="project_member_list" withBorder />;
  }

  const addMember = isWorkspaceProject ? (
    <AddMember<OrganizationRole>
      context={AddMemberContext.workspaceProject}
      invitesLeft={invitesLeft}
      isAddingBlocked={invitationsLimitReached}
      defaultRole={OrganizationRole.member}
      renderRoleDropdown={(params) => (
        <RoleDropdown
          data-role="invited-user-role"
          roleLabels={organizationRoleLabels}
          availableRoles={availableOrganizationRoles}
          {...params}
        />
      )}
      onSubmit={handleInviteToWorkspace}
    />
  ) : (
    <AddMember<ProjectRole>
      context={
        isWorkspaceProject ? AddMemberContext.workspaceProject : AddMemberContext.privateProject
      }
      defaultRole={ProjectRole.member}
      isAddingBlocked={isAddingBlocked}
      isAddingBlockedReason={
        isAddingBlockedDueToNoWorkspaceManagePermission
          ? noWorkspaceManagePermissionCopy
          : undefined
      }
      renderRoleDropdown={(params) => (
        <RoleDropdown {...params} roleLabels={projectRoleLabels} availableRoles={availableRoles} />
      )}
      onSubmit={handleSubmit}
    />
  );

  const pricingPlan = currentOrganization?.pricingPlan;
  const shouldShowProjectPrivacyUnavailableNotice = !!(
    projectPrivacy &&
    pricingPlan &&
    shouldShowProjectRoleTooltip(pricingPlan, projectPrivacy)
  );
  const projectRoleTooltipText = shouldShowProjectPrivacyUnavailableNotice
    ? 'Setting project role is available only in the Organization plan.'
    : undefined;

  const ProjectRoleDropdown: React.ComponentType<RoleDropdownItemProps<ProjectRole>> = ({
    userId,
    ...params
  }) => {
    return (
      <SimpleTooltip content={projectRoleTooltipText}>
        <RoleDropdown
          {...params}
          roleLabels={projectRoleLabels}
          availableRoles={availableRoles}
          onSelect={(role: ProjectRole) => handleUpdateRole({ userId, role })}
        />
      </SimpleTooltip>
    );
  };

  return (
    <MemberListView>
      <ProjectVisibilityInfo projectName={project.name} projectPrivacy={projectPrivacy} />
      {canEdit && (
        <>
          <Card>
            <Card.Section>
              <Card.Column spacedChildren="sm">
                {isAddingBlockedDueToPrivateIndividualProject && (
                  <CantInviteToIndividualNotice canCreateWorkspace={isClassicDeployment()} />
                )}
                <Layout.Column spacedChildren="xs">
                  <Text size="xs" fontWeight="bold">
                    {isWorkspaceProject
                      ? 'Add people to your team workspace'
                      : 'Add people to project'}
                  </Text>
                  {addMember}
                  {isPrivateProject && (
                    <Text size="xs" color="text-subdued">
                      The people you invite to this project will also be added as workspace members.
                    </Text>
                  )}
                </Layout.Column>
              </Card.Column>
            </Card.Section>
          </Card>
        </>
      )}
      <MemberList<ProjectMember>
        data-role="project-member-list"
        user={user}
        entries={entries}
        fetchStatus={fetchStatus}
        sortOptions={sortOptions}
        renderRoleDropdown={ProjectRoleDropdown}
        onMemberDelete={isWorkspaceProject ? undefined : handleDeleteMember}
        onSort={(sortOptions: { sortBy: string; sortOrder: string }) =>
          dispatch(sortProjectMemberList(sortOptions))
        }
        context={MemberListContext.project}
      />
      <AlreadyInvitedModal isOpen={isAlreadyInvitedModalOpen} close={closeAlreadyInvitedModal} />
      <MembersLimitReachedModal
        isOpen={isMembersLimitReachedModalOpen}
        close={closeMembersLimitReachedModal}
        workspaceName={project.organizationName}
      />
      <DeleteModal
        organizationName={project.organizationName}
        projectIdentifier={projectIdentifier}
        onConfirm={handleConfirmDeleteMember}
      />
    </MemberListView>
  );
};

import React from 'react';

import { isMacOperatingSystem } from '@neptune/shared/common-util';

export const useQueryBuilderKeyboardShortcuts = ({
  commandShortcuts,
}: {
  commandShortcuts: Record<string, () => void>;
}) => {
  const cmdPressed = React.useRef(false);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      cmdPressed.current = isMacOperatingSystem ? event.metaKey : event.ctrlKey;

      if (commandShortcuts[event.key] && cmdPressed.current) {
        event.preventDefault();
        commandShortcuts[event.key]();
      }
    },
    [commandShortcuts],
  );

  const handleKeyUp = React.useCallback((event: React.KeyboardEvent) => {
    const wasCmdPressed = isMacOperatingSystem ? event.metaKey : event.ctrlKey;
    cmdPressed.current = !wasCmdPressed;
  }, []);

  return { handleKeyDown, handleKeyUp };
};

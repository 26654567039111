/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActiveProjectsTraitDTO
 */
export interface ActiveProjectsTraitDTO {
    /**
     * 
     * @type {number}
     * @memberof ActiveProjectsTraitDTO
     */
    currentQuota: number;
    /**
     * 
     * @type {number}
     * @memberof ActiveProjectsTraitDTO
     */
    maxQuota?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ActiveProjectsTraitDTO
     */
    quotaEditable: boolean;
}

/**
 * Check if a given object implements the ActiveProjectsTraitDTO interface.
 */
export function instanceOfActiveProjectsTraitDTO(value: object): value is ActiveProjectsTraitDTO {
    if (!('currentQuota' in value) || value['currentQuota'] === undefined) return false;
    if (!('quotaEditable' in value) || value['quotaEditable'] === undefined) return false;
    return true;
}

export function ActiveProjectsTraitDTOFromJSON(json: any): ActiveProjectsTraitDTO {
    return ActiveProjectsTraitDTOFromJSONTyped(json, false);
}

export function ActiveProjectsTraitDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveProjectsTraitDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'currentQuota': json['currentQuota'],
        'maxQuota': json['maxQuota'] == null ? undefined : json['maxQuota'],
        'quotaEditable': json['quotaEditable'],
    };
}

export function ActiveProjectsTraitDTOToJSON(value?: ActiveProjectsTraitDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'currentQuota': value['currentQuota'],
        'maxQuota': value['maxQuota'],
        'quotaEditable': value['quotaEditable'],
    };
}


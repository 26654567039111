import {
  entityMatchesDisplayType,
  fetchEntityForInProjectDisplayRoute,
  getInProjectEntityDisplayRoute,
  typeOfProjectEntityDisplayRoute,
} from '@neptune/project-business-logic';
import { projectRunsRoutes } from '@neptune/project-runs-shell';
import { reportsRoutes } from '@neptune/reports-shell';
import { extractNamespaceAndField } from '@neptune/shared/attributes-business-logic';
import { tick } from '@neptune/shared/common-util';
import {
  convertStateToContextAwareState,
  makeEnterpriseRoute,
} from '@neptune/shared/enterprise-context-business-logic';
import {
  isEnterpriseRouteName,
  toEnterpriseRouteName,
} from '@neptune/shared/enterprise-context-util';
import { fetchEntityChunk, getEntityShortId } from '@neptune/shared/entity-domain';
import { makeEntityIdentifier } from '@neptune/shared/entity-util';
import { rejectActivation } from '@neptune/shared/routing-business-logic';
import { AppRoute } from '@neptune/shared/routing-domain';

import { storage } from 'common/storage';
import projectDashboardRoutes from 'views/project-dashboard/routes';

const projectRoute: AppRoute = {
  name: 'project',
  path: '/:organizationName/:projectName',
  forwardTo: 'project.redirect-to-persisted-route',
  onActivate: (dispatch, getState, router) => (route, previousRoute) => {
    tick(async () => {
      // we'd like to run resolving logic only when directly opened URL
      if (previousRoute != null) {
        return true;
      }

      // we have to return earlier due to lack of support for sys/name
      // POST: api/leaderboard/v1/attributes/attributes
      if (route.params.type === 'experiment') {
        return;
      }

      const isEnterprise = isEnterpriseRouteName(route.name);

      const displayRouteType = typeOfProjectEntityDisplayRoute(route);

      if (!displayRouteType) {
        // This route doesn't display entity, no redirection.
        return;
      }

      const entity = await fetchEntityForInProjectDisplayRoute(route).catch(() => {
        // Entity not found or other fetch error.
      });

      if (!entity || entityMatchesDisplayType(entity, displayRouteType)) {
        // No entity found, or this is a right route for this entity.
        return;
      }

      // There is a mismatch between entity type/trash state and requested route.
      // Redirect to correct route for entity display.

      const baseDisplayRoute = getInProjectEntityDisplayRoute(entity);

      if (!baseDisplayRoute) {
        // No display route for this kind of entity (currenty only 'project', that shoudn't happen here anyway).
        return;
      }

      const displayRoute = {
        name: baseDisplayRoute.name,
        params: {
          // Keep all non-conficting params from requested route. This is useful for params like detailsTab,
          // so that the details view will be open in the same place.
          ...route.params,
          ...baseDisplayRoute.params,
        },
      };

      const awareDisplayRoute = convertStateToContextAwareState(displayRoute, isEnterprise);

      router.navigate(awareDisplayRoute.name, awareDisplayRoute.params, { replace: true });
    });
  },
  canActivate:
    () =>
    ({ name, params }) => {
      const { shortId, type, ...restParams } = params;

      if (shortId) {
        return rejectActivation({
          name,
          params: {
            runIdentificationKey: shortId,
            type: type ?? 'run',
            ...restParams,
          },
        });
      }

      return true;
    },
};

const projectTabToRouteMapping: Record<string, string> = {
  runs: 'project.runs',
};

const redirectToPersistedRouteRoute: AppRoute = {
  name: 'project.redirect-to-persisted-route',
  path: '/-/-',
  onActivate: (dispatch, getState, router) => (route) => {
    tick(() => {
      const tab = storage.local.getItem('lastProjectTab');
      const target = (tab && projectTabToRouteMapping[tab]) || projectTabToRouteMapping.runs;
      const targetEnterpriseAware = isEnterpriseRouteName(route.name)
        ? toEnterpriseRouteName(target)
        : target;
      router.navigate(targetEnterpriseAware, route.params, { replace: true });
    });
  },
};

/**
 * Route that can be used when we'd like to link to Entity without knowing its type or trash state
 * (for example in neptune-client or in app without full entity object)
 */
const virtualEntityRoute: AppRoute = {
  name: 'project.entity-with-shortid-only',
  path: '/-/entity',
  canActivate: () => async (toState) => {
    const isEnterpriseRoute = isEnterpriseRouteName(toState.name);
    const entity = await fetchEntityForInProjectDisplayRoute(toState).catch(() => undefined);
    const baseDisplayRoute = entity && getInProjectEntityDisplayRoute(entity);

    if (baseDisplayRoute) {
      const displayRoute = {
        name: baseDisplayRoute.name,
        params: {
          // Keep all (non-conflicting) requested params. This is useful for keeping params like detailsTab.
          ...toState.params,
          ...baseDisplayRoute.params,
        },
      };
      return rejectActivation(convertStateToContextAwareState(displayRoute, isEnterpriseRoute));
    }

    // Redirect to project runs as a fallback.
    return rejectActivation(
      convertStateToContextAwareState(
        {
          name: 'project.runs-tab',
          params: {
            ...toState.params,
            tab: 'table',
          },
        },
        isEnterpriseRoute,
      ),
    );
  },
};

/**
 * Route that can be used when we'd like to link to Entity and specific attribute without knowing its type or trash state
 * (for example in neptune-client or in app without full entity object)
 */
const virtualRunWithMetricRoute: AppRoute = {
  name: 'project.entity-with-attribute-by-custom-id-only',
  path: '/-/run?:customId',
  canActivate: () => async (toState) => {
    const { organizationName, projectName, customId } = toState.params;

    const entity = await fetchEntityChunk({
      id: makeEntityIdentifierByCustomId(organizationName, projectName, customId),
      holderType: 'experiment',
      fieldsToFetch: ['sys/id'],
    });

    const isEnterpriseRoute = isEnterpriseRouteName(toState.name);

    const { customId: customIdFromRouteParams, field, ...passParams } = toState.params;
    const fieldInfo = field ? extractNamespaceAndField(field) : undefined;
    const displayRoute = {
      name: virtualEntityRoute.name,
      params: {
        // Keep all (non-conflicting) requested params. This is useful for keeping params like detailsTab.
        ...passParams,
        shortId: getEntityShortId(entity),
        detailsTab: 'metadata',
        path: fieldInfo?.namespace,
        attribute: fieldInfo?.field,
      },
    };
    return rejectActivation(convertStateToContextAwareState(displayRoute, isEnterpriseRoute));

    // This code exists here and not moved to common place because customId will be merged with shortId in the future,
    // and we don't want to make it used in the app more than really needed.
    function makeEntityIdentifierByCustomId(
      organizationName: string,
      projectName: string,
      customId: string,
    ) {
      return `CUSTOM/${makeEntityIdentifier(organizationName, projectName, customId)}`;
    }
  },
};

export const projectRoutes: AppRoute[] = [
  projectRoute,
  {
    ...makeEnterpriseRoute(projectRoute),
    path: '/o/:organizationName/org/:projectName',
  },
  virtualEntityRoute,
  makeEnterpriseRoute(virtualEntityRoute),
  virtualRunWithMetricRoute,
  makeEnterpriseRoute(virtualRunWithMetricRoute),
  redirectToPersistedRouteRoute,
  makeEnterpriseRoute(redirectToPersistedRouteRoute),
  ...projectRunsRoutes,
  ...projectDashboardRoutes,
  ...reportsRoutes,
];

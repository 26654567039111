import { Router } from 'router5';
import urlParse from 'url-parse';

import { authClient } from '@neptune/shared/core-auth-domain';
import { cancelSubscription } from '@neptune/user-management-domain';

import { heap } from 'analytics/heap';

export const cancelSubscriptionAndLogout = async (router: Router, organizationId: string) => {
  await cancelSubscription({
    organizationId,
  });
  heap.resetIdentity();
  const redirectPath = router.buildPath('global-views/user-cancellation-survey', {
    organizationId,
  });
  const absoluteRedirectPath = urlParse(redirectPath).toString();
  await authClient.logout({ redirectUri: absoluteRedirectPath });
};

import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentProject } from '@neptune/current-project-business-logic';
import { useProjectSectionName } from '@neptune/project-business-logic';
import { ProjectHeader } from '@neptune/project-ui';
import { OverlayView } from '@neptune/shared/venus-ui';

import { ProjectDropdownMenuContainer } from './ProjectDropdownMenuContainer';
import { ProjectStorageBannersContainer } from './ProjectStorageBannersContainer';
import { useOverlaySubviews } from './useOverlaySubviews';

export const ProjectHeaderContainer = React.forwardRef<HTMLElement>((props, ref) => {
  const project = useSelector(getCurrentProject);
  const { closeCurrentSubview, currentSubviewComponent, openSubview, getSubviewEntries } =
    useOverlaySubviews();
  const projectSectionName = useProjectSectionName();

  const subviewEntries = React.useMemo(
    () => getSubviewEntries(project),
    [getSubviewEntries, project],
  );

  if (!project) {
    return null;
  }

  return (
    <>
      <ProjectStorageBannersContainer project={project} />
      <ProjectHeader
        menu={
          <ProjectDropdownMenuContainer
            openSubview={openSubview}
            subviewEntries={subviewEntries}
            project={project}
          />
        }
        projectSectionName={projectSectionName}
        ref={ref}
      />
      <OverlayView
        children={currentSubviewComponent || null}
        onClose={closeCurrentSubview}
        isVisible={!!currentSubviewComponent}
      />
    </>
  );
});

// App
import { AppState } from 'state/types';

import getParentState from '../selectors';

// Module
export function getLeaderboardState(state: AppState) {
  return getParentState(state).projectLeaderboard;
}

export default getLeaderboardState;

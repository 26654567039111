// Libs
import React from 'react';

import { Banner, Icon } from '@neptune/shared/venus-ui';

// App
import { CreateWorkspaceLink } from 'components/create-workspace-link/CreateWorkspaceLink';
import { PricingLink } from 'components/pricing-link/PricingLink';

// Module
type CantInviteToIndividualNoticeProps = {
  canCreateWorkspace: boolean;
};

export const CantInviteToIndividualNotice: React.FC<CantInviteToIndividualNoticeProps> = ({
  canCreateWorkspace,
}) => {
  const content = canCreateWorkspace ? (
    <CreateWorkspaceEnabledContent />
  ) : (
    <CreateWorkspaceDisabledContent />
  );
  return <Banner.Info>{content}</Banner.Info>;
};

const CreateWorkspaceEnabledContent = () => (
  <span>
    You can’t invite people to your projects in an individual workspace.{' '}
    <CreateWorkspaceLink>
      Create a team workspace&nbsp;
      <Icon glyph="external-link" />
    </CreateWorkspaceLink>{' '}
    to work with your teammates (first 14 days for free).
    <br />
    Learn more about available plans on the{' '}
    <PricingLink>
      pricing page&nbsp;
      <Icon glyph="external-link" />
    </PricingLink>
    .
  </span>
);

const CreateWorkspaceDisabledContent = () => (
  <span>You can’t invite people to your projects in an individual workspace.</span>
);

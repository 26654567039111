import React from 'react';

import { EmptyView } from '@neptune/shared/common-ui';
import { OrganizationType } from '@neptune/shared/core-organizations-domain';
import { NormalizedLink } from '@neptune/shared/routing-ui';
import { Button, Icon } from '@neptune/shared/venus-ui';

export const IndividualServiceAccountsPlaceholder: React.FC = () => {
  return (
    <EmptyView
      variant="default"
      title="Service accounts are not available in individual workspaces"
      subtitle="To add service accounts to your workflow, create a team workspace. You can try all the team features during a 14-day free trial."
      icon="service-account"
      data-role="individual-empty-state"
    >
      <Button<React.ComponentProps<typeof NormalizedLink>>
        variant="basic"
        component={NormalizedLink}
        routeName="create-workspace"
        routeParams={{}}
        forceContext={OrganizationType.individual}
        external
        data-role="new-workspace-button"
      >
        <Icon glyph="subscription" /> Create team workspace
      </Button>
    </EmptyView>
  );
};

// App
import {
  backendClient,
  GetOrganizationLimitsRequest,
} from '@neptune/shared/core-apis-backend-domain';

import { createAsyncActions } from 'state/async-actions';
import { isUserAuthenticated } from 'state/users/current-user/selectors';

// Module
export enum OrganizationLimitsActionTypes {
  request = 'CURRENT_ORGANIZATION_LIMITS_FETCH_REQUEST',
  success = 'CURRENT_ORGANIZATION_LIMITS_FETCH_SUCCESS',
  fail = 'CURRENT_ORGANIZATION_LIMITS_FETCH_FAIL',
}

const actions = createAsyncActions({
  types: OrganizationLimitsActionTypes,
  shouldResolve(getState) {
    return !!isUserAuthenticated(getState());
  },
  async resolver(params: GetOrganizationLimitsRequest) {
    return backendClient.getOrganizationLimits(params);
  },
});

export const { execute: fetchOrganizationLimits } = actions;
export type OrganizationLimitsActions = ReturnType<
  typeof actions.request | typeof actions.success | typeof actions.fail
>;

import React from 'react';
import { debounce, isFunction, uniq } from 'lodash';

import { AvatarSource, listUsers, UserEntry } from '@neptune/shared/core-users-domain';
import { LegacyEmblem, Multiselect } from '@neptune/shared/venus-ui';
import { UserEmblem, UserItem } from '@neptune/user-management-ui';

import { Merge } from 'common/utility-types';
import { fetchStatus as FetchStatus, isFetchStatusCompleted } from 'state/fetch-status';

import './SelectMember.less';

type SelectMemberProps = {
  selected: string;
  allowMails: boolean;
  placeholder: string;
  filterOptions?: {};
  disabled?: boolean;
  onChange?: (username?: string) => void;
  onValidationChange?: (valid: boolean) => void;
};

type SelectMemberState = {
  fetchStatus: FetchStatus;
  allUsers: UserEntry[];
  users: UserEntry[];
  candidate: string | null;
  text: string;
  error?: boolean;
  registered?: boolean;
};

export class SelectMember extends React.Component<SelectMemberProps, SelectMemberState> {
  state: SelectMemberState = {
    fetchStatus: FetchStatus.NONE,
    allUsers: [],
    users: [],
    candidate: null,
    text: '',
    error: undefined,
    registered: undefined,
  };

  componentDidUpdate(
    prevProps: Readonly<SelectMemberProps>,
    prevState: Readonly<SelectMemberState>,
  ) {
    const { onValidationChange } = this.props;

    if (prevState.text !== this.state.text) {
      this.refetchUsers();
    }

    if (prevState.error !== this.state.error && isFunction(onValidationChange)) {
      onValidationChange(!this.state.error);
    }
  }

  refetchUsers() {
    if (this.state.text.length > 0) {
      this.setState({ fetchStatus: FetchStatus.PENDING });
      this.callApi();
    } else {
      this.setState({ users: [] });
    }
  }

  callApi = debounce(() => {
    const { filterOptions } = this.props;

    listUsers({
      ...filterOptions,
      usernamePrefix: this.state.text,
      offset: 0,
      limit: 10,
    }).then((entries) => {
      this.setState(
        {
          allUsers: uniq([...this.state.allUsers, ...entries]),
          users: entries,
          fetchStatus: FetchStatus.SUCCESS,
        },
        () => this.validateUsername(this.state.candidate),
      );
    });
  }, 300);

  handleTextChange = (text: string) => {
    this.setState({ text });
  };

  handleSelectionChange = (member: UserEntry | string) => {
    const { selected } = this.props;

    if (selected === member) {
      this.clearSelectedUser();
    } else {
      this.selectUser(typeof member === 'string' ? { username: member } : member);
    }

    this.setState({ text: '' });
  };

  handleCreate = (text: string) => {
    if (!text) {
      return;
    }

    if (isFetchStatusCompleted(this.state.fetchStatus)) {
      this.validateUsername(text);
    } else {
      this.setState({ candidate: text });
    }
  };

  validateUsername(username: string | null): void {
    if (!username) {
      return;
    }

    const { allowMails } = this.props;

    this.setState({ candidate: null, text: '' });

    if (allowMails && username.indexOf('@') > 0) {
      return this.selectUser({ username, registered: false });
    }

    const matchingUser = this.state.allUsers.find((user) => user.username === username);

    if (matchingUser) {
      this.selectUser(matchingUser);
    } else {
      this.selectUser({ username, error: true, registered: false });
    }
  }

  handleRemove = () => {
    this.clearSelectedUser();
    this.setState({ text: '', users: [] });
  };

  selectUser(
    user?: Merge<{ username: string; error?: boolean; registered?: boolean }, Partial<UserEntry>>,
  ) {
    const { onChange } = this.props;

    const { username, registered, error } = user || {};

    this.setState({
      registered,
      error,
    });

    onChange?.(username);
  }

  clearSelectedUser() {
    this.selectUser();
  }

  render() {
    const { disabled, placeholder, selected } = this.props;

    const { allUsers, error, registered, text, users } = this.state;

    const userOnList = allUsers.find((user) => user.username === selected) || {};
    const selectedUser: UserEntry | undefined = selected
      ? {
          username: selected,
          avatarSource: AvatarSource.default,
          avatarUrl: '',
          firstName: '',
          lastName: '',
          ...userOnList,
        }
      : undefined;

    return (
      <Multiselect<UserEntry>
        data-role="select-member"
        className="select-member"
        text={text}
        disabled={disabled}
        placeholder={placeholder}
        error={error}
        items={users}
        selectedItems={selectedUser ? [selectedUser] : []}
        selectedLimit={1}
        renderSelectedItem={({ username, avatarUrl }: UserEntry) => {
          return registered !== false ? (
            <UserEmblem
              key={username}
              username={username}
              avatarUrl={avatarUrl}
              onRemove={this.handleRemove}
            />
          ) : (
            <LegacyEmblem key={username} error={error} onRemove={this.handleRemove}>
              <div>{username}</div>
            </LegacyEmblem>
          );
        }}
        renderMenuItem={({ item, props, key, style }) => (
          <UserItem
            key={key}
            style={style}
            user={item}
            onClick={(event) => props.onSelectionChange?.(item, event)}
          />
        )}
        onTextChange={this.handleTextChange}
        onSelectionChange={this.handleSelectionChange}
        onItemAdd={this.handleCreate}
      />
    );
  }
}

import { isArray } from 'lodash';

import {
  AttributeFilterDTO,
  AttributeTypeDTO,
  leaderboardClient,
} from '@neptune/shared/core-apis-leaderboard-domain';
import { makeEntityIdentifier } from '@neptune/shared/entity-util';

import {
  AttributeDefinition,
  AttributeDefinitionConverter,
  AttributeType,
} from 'domain/experiment/attribute';

type QueryAttributeDefinitionsFilter = Partial<Record<AttributeType, {}>>;

export type QueryAttributeDefinitionsRequestBase = {
  filterAttributeTypes?: AttributeType[] | QueryAttributeDefinitionsFilter;
  attributeNameFilter?: {
    mustMatchRegexes?: string[];
    mustNotMatchRegexes?: string[];
  };
  limit?: number;
  nextPageToken?: string;
};

export type QueryAttributeDefinitionsRequestMultiProject = QueryAttributeDefinitionsRequestBase & {
  projectIdentifiers: string[];
  projectIdentifier?: never;
  shortIds?: never;
};

export type QueryAttributeDefinitionsRequestSingleProject = QueryAttributeDefinitionsRequestBase & {
  shortIds?: string[];
  projectIdentifier: string;
  projectIdentifiers?: never;
};

export type QueryAttributeDefinitionsRequest =
  | QueryAttributeDefinitionsRequestMultiProject
  | QueryAttributeDefinitionsRequestSingleProject;

type QueryAttributeDefinitionsResult = {
  attributeDefinitions: AttributeDefinition[];
  nextPageToken?: string;
};

export async function queryAttributeDefinitions(
  params: QueryAttributeDefinitionsRequestMultiProject,
): Promise<QueryAttributeDefinitionsResult>;
export async function queryAttributeDefinitions(
  params: QueryAttributeDefinitionsRequestSingleProject,
): Promise<QueryAttributeDefinitionsResult>;

export async function queryAttributeDefinitions({
  filterAttributeTypes,
  limit,
  nextPageToken,
  projectIdentifier,
  projectIdentifiers,
  attributeNameFilter,
  shortIds,
}: QueryAttributeDefinitionsRequest): Promise<QueryAttributeDefinitionsResult> {
  const attributeFilter = isArray(filterAttributeTypes)
    ? convertAttributeTypeArrayToApiAttributeFilter(filterAttributeTypes)
    : convertAttributeFilterFromDomainToApi(filterAttributeTypes);

  if (attributeFilter?.length === 0 && lengthOfFilterAttributeTypes(filterAttributeTypes) !== 0) {
    // If this happened, it means that we have desynchronized FE and BE applications.
    throw new Error(
      'Requested attribute type filter, but all requested types are not supported, which may cause filtering to be disabled',
    );
  }

  const nextPage =
    limit === undefined && nextPageToken === undefined ? undefined : { limit, nextPageToken };

  let experimentIdsFilter;

  if (projectIdentifier) {
    experimentIdsFilter = shortIds?.map((shortId) =>
      makeEntityIdentifier(projectIdentifier, shortId),
    );
  }

  const result = await leaderboardClient.queryAttributeDefinitionsWithinProject({
    query: {
      nextPage,
      attributeFilter,
      attributeNameFilter,
      experimentIdsFilter,
      projectIdentifiers:
        projectIdentifier !== undefined ? [projectIdentifier] : projectIdentifiers,
    },
  });

  return {
    nextPageToken: result.nextPage.nextPageToken,
    attributeDefinitions: result.entries.map(
      AttributeDefinitionConverter.attributeDefinitionFromApiToDomain,
    ),
  };
}

type QueryAttributeDefinitionsOnlyWithDiffsRequest = Required<
  Pick<QueryAttributeDefinitionsRequest, 'shortIds' | 'projectIdentifier'>
> &
  Omit<QueryAttributeDefinitionsRequest, 'shortIds'>;

export async function queryAttributeDefinitionsOnlyWithDiffs({
  filterAttributeTypes,
  limit,
  nextPageToken,
  projectIdentifier,
  attributeNameFilter,
  shortIds: experimentIdsFilter,
}: QueryAttributeDefinitionsOnlyWithDiffsRequest): Promise<QueryAttributeDefinitionsResult> {
  const attributeFilter = isArray(filterAttributeTypes)
    ? convertAttributeTypeArrayToApiAttributeFilter(filterAttributeTypes)
    : convertAttributeFilterFromDomainToApi(filterAttributeTypes);

  try {
    assertThereIsNoMismatchBetweenApiAndApp(attributeFilter, filterAttributeTypes);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {
      nextPageToken: undefined,
      attributeDefinitions: [],
    };
  }

  const nextPage =
    limit === undefined && nextPageToken === undefined ? undefined : { limit, nextPageToken };

  const result = await leaderboardClient.queryAttributeDefinitionsOnlyWithDiffs({
    projectIdentifier,
    query: {
      attributeFilter,
      attributeNameFilter,
      experimentIdsFilter,
      nextPage,
    },
  });

  return {
    nextPageToken: result.nextPage.nextPageToken,
    attributeDefinitions: result.entries.map(
      AttributeDefinitionConverter.attributeDefinitionFromApiToDomain,
    ),
  };
}

function convertAttributeFilterFromDomainToApi(
  input: QueryAttributeDefinitionsFilter | undefined,
): AttributeFilterDTO[] | undefined {
  if (!input) {
    return;
  }

  return Object.entries(input)
    .map(([domainAttributeType]): AttributeFilterDTO | undefined => {
      const attributeType = AttributeDefinitionConverter.attributeTypeToApi(domainAttributeType);

      if (!attributeType) {
        return;
      }

      return { attributeType };
    })
    .filter((input: AttributeFilterDTO | undefined): input is AttributeFilterDTO => !!input);
}

function convertAttributeTypeArrayToApiAttributeFilter(
  input: AttributeType[],
): AttributeFilterDTO[] {
  return input
    .map(AttributeDefinitionConverter.attributeTypeToApi)
    .filter((attributeType): attributeType is AttributeTypeDTO => !!attributeType)
    .map((attributeType) => ({ attributeType }));
}

function lengthOfFilterAttributeTypes(
  input: AttributeType[] | QueryAttributeDefinitionsFilter | undefined,
): number {
  if (!input) {
    return 0;
  }

  if (isArray(input)) {
    return input.length;
  }

  return Object.keys(input).length;
}

function assertThereIsNoMismatchBetweenApiAndApp(
  attributeFilter?: AttributeFilterDTO[],
  filterAttributeTypes?: AttributeType[] | QueryAttributeDefinitionsFilter,
) {
  if (attributeFilter?.length === 0 && lengthOfFilterAttributeTypes(filterAttributeTypes) !== 0) {
    // If this happened, it means that we have desynchronized FE and BE applications.
    throw new Error(
      'Requested attribute type filter, but all requested types are not supported, which may cause filtering to be disabled',
    );
  }
}
